import { METRIC_DEFAULTS } from 'shared/constants';
import { Unit } from 'shared/models';
import { Math_round } from '../../mathRound';
import { ConvertUnit } from '../../unitConverter';
import { cbEDCEmitter } from '../models/cbEDCEmitter';
import { Calculate } from './Calculate/Calculate';

type Table = {
  step: number;
  cu?: number[];
  du?: number[];
  avg?: number[];
  min?: number[];
};

type TablesResult = Table[];

type CalcTablesParams = {
  maxL: number;
  maxE: number;
  minL: number;
  minE: number;
  stepL: number;
  stepE: number;
  avg: boolean;
  min: boolean;
  cu: boolean;
  du: boolean;
  unitSettings: Unit;
  placement: number;
};
type CalcParams = {
  Placement: number;
  cbEDCEmitter: cbEDCEmitter;
  EdgeType: number;
  _Shadows: boolean;
  txtLateralSpacing: string;
  txtEmitterSpacing: string;
  txtBedWidth: number;
  lblBedWidthUnit: string;
  dEDGEPOSITIONX: number;
  dEDGEPOSITIONY: number;
  BILATERAL_TRIANGULAR_LAYOUT: number;
  TRIANGULAR_Y_LAYOUT: number;
  LATERAL_LAYOUT: number;
  lastplacement: number;
  range: boolean;
  Graph: boolean;
  mouseClickPositionY1: number;
  mouseClickPositionY2: number;
};

export const calcTables = (params: CalcTablesParams, calcParams: CalcParams) => {
  const { maxL, maxE, stepL, stepE, minL, minE, unitSettings, placement, avg, min, cu, du } = params;

  let dMaxE = maxE;
  let dMaxL = maxL;
  let dMinE = minE;
  let dMinL = minL;
  let dStepE = stepE;
  let dStepL = stepL;
  let numcols = 0;
  let numrows = 0;

  dMaxE = ConvertUnit(dMaxE, unitSettings.emitterSpacing, METRIC_DEFAULTS.EmitterSpacing, null);
  dMaxL = ConvertUnit(dMaxL, unitSettings.emitterSpacing, METRIC_DEFAULTS.EmitterSpacing, null);
  dMinE = ConvertUnit(dMinE, unitSettings.emitterSpacing, METRIC_DEFAULTS.EmitterSpacing, null);
  dMinL = ConvertUnit(dMinL, unitSettings.lateralSpacing, METRIC_DEFAULTS.EmitterSpacing, null);

  dStepE = ConvertUnit(dStepE, unitSettings.emitterSpacing, METRIC_DEFAULTS.EmitterSpacing, null);
  dStepL = ConvertUnit(dStepL, unitSettings.emitterSpacing, METRIC_DEFAULTS.EmitterSpacing, null);

  switch (placement) {
    case 0:
    case 1:
    case 2:
    case 3:
      numcols = Math.floor((dMaxL - dMinL) / dStepL + 1);
      break;
    case 5:
      numcols = 0;
      break;
  }
  numrows = Math.floor((dMaxE - dMinE) / dStepE + 1);

  let X = 0;

  let CUTABLE: any = [];
  let stepsArray: number[] = [];

  for (let x = 0; x < numcols + 2; x++) {
    CUTABLE.push([]);
    for (let y = 0; y < numcols + 2; y++) {
      CUTABLE[x][y] = [];
    }
  }

  let FactorE = 1;
  // const tebleRes: TablesResult = [];
  for (let y = 0; y <= numrows; y++) {
    const txtEmitterSpacing = dMinE + dStepE * y;
    stepsArray.push(txtEmitterSpacing);

    switch (placement) {
      case 0:
        // let tableItem: Partial<Table> = { step: y };
        if (dMinE === dMinL && dMaxE === dMaxL && dStepE === dStepL) {
          for (X = y; X <= numcols; X++) {
            const txtLateralSpacing = dMinL + dStepL * X;
            const resultCalc = Calculate(
              calcParams.Placement,
              calcParams.cbEDCEmitter,
              calcParams.EdgeType,
              calcParams._Shadows,
              unitSettings,
              txtLateralSpacing.toString(),
              txtEmitterSpacing.toString(),
              calcParams.txtBedWidth,
              calcParams.lblBedWidthUnit,
              calcParams.dEDGEPOSITIONX,
              calcParams.dEDGEPOSITIONY,
              calcParams.BILATERAL_TRIANGULAR_LAYOUT,
              calcParams.TRIANGULAR_Y_LAYOUT,
              calcParams.LATERAL_LAYOUT,
              calcParams.lastplacement,
              calcParams.range,
              calcParams.Graph,
              calcParams.mouseClickPositionY1,
              calcParams.mouseClickPositionY2,
            );
            CUTABLE[X][y][0] = resultCalc!.TheCu;
            CUTABLE[X][y][1] = resultCalc!.TheDu;
            CUTABLE[X][y][2] = ConvertUnit(
              resultCalc!.txtAverage,
              METRIC_DEFAULTS.AppnRate,
              unitSettings.appnRate,
              null,
            );
            CUTABLE[X][y][3] = ConvertUnit(resultCalc!.TheMin, METRIC_DEFAULTS.AppnRate, unitSettings.appnRate, null);
            if (resultCalc!.TheMin < 1 * FactorE) {
              CUTABLE[X][y][0] = '*' + CUTABLE[X][y][0] + '*';
            }
            CUTABLE[y][X][0] = CUTABLE[X][y][0];
            CUTABLE[y][X][1] = CUTABLE[X][y][1];
            CUTABLE[y][X][2] = CUTABLE[X][y][2];
            CUTABLE[y][X][3] = CUTABLE[X][y][3];
          }
          //its a rectangle
        } else {
          for (X = 0; X <= numcols; X++) {
            const txtLateralSpacing = dMinL + dStepL * X;

            const resultCalc = Calculate(
              calcParams.Placement,
              calcParams.cbEDCEmitter,
              calcParams.EdgeType,
              calcParams._Shadows,
              unitSettings,
              txtLateralSpacing.toString(),
              txtEmitterSpacing.toString(),
              calcParams.txtBedWidth,
              calcParams.lblBedWidthUnit,
              calcParams.dEDGEPOSITIONX,
              calcParams.dEDGEPOSITIONY,
              calcParams.BILATERAL_TRIANGULAR_LAYOUT,
              calcParams.TRIANGULAR_Y_LAYOUT,
              calcParams.LATERAL_LAYOUT,
              calcParams.lastplacement,
              calcParams.range,
              calcParams.Graph,
              calcParams.mouseClickPositionY1,
              calcParams.mouseClickPositionY2,
            );
            CUTABLE[X][y][1] = resultCalc!.TheDu;
            CUTABLE[X][y][0] = resultCalc!.TheCu;
            CUTABLE[X][y][2] = ConvertUnit(
              resultCalc!.txtAverage,
              METRIC_DEFAULTS.AppnRate,
              unitSettings.appnRate,
              null,
            );
            CUTABLE[X][y][3] = ConvertUnit(resultCalc!.TheMin, METRIC_DEFAULTS.AppnRate, unitSettings.appnRate, null);
            if (resultCalc!.TheMin < 1 * FactorE) {
              CUTABLE[X][y][0] = '*' + CUTABLE[X][y][0] + '*';
            }
          }
        }
        break;
      case 1:
        for (X = 0; X <= numcols; X++) {
          const txtLateralSpacing = dMinL + dStepL * X;
          const resultCalc = Calculate(
            calcParams.Placement,
            calcParams.cbEDCEmitter,
            calcParams.EdgeType,
            calcParams._Shadows,
            unitSettings,
            txtLateralSpacing.toString(),
            txtEmitterSpacing.toString(),
            calcParams.txtBedWidth,
            calcParams.lblBedWidthUnit,
            calcParams.dEDGEPOSITIONX,
            calcParams.dEDGEPOSITIONY,
            calcParams.BILATERAL_TRIANGULAR_LAYOUT,
            calcParams.TRIANGULAR_Y_LAYOUT,
            calcParams.LATERAL_LAYOUT,
            calcParams.lastplacement,
            calcParams.range,
            calcParams.Graph,
            calcParams.mouseClickPositionY1,
            calcParams.mouseClickPositionY2,
          );

          CUTABLE[X][y][1] = resultCalc!.TheDu;
          CUTABLE[X][y][0] = resultCalc!.TheCu;
          CUTABLE[X][y][2] = ConvertUnit(resultCalc!.txtAverage, METRIC_DEFAULTS.AppnRate, unitSettings.appnRate, null);
          CUTABLE[X][y][3] = ConvertUnit(resultCalc!.TheMin, METRIC_DEFAULTS.AppnRate, unitSettings.appnRate, null);
          if (resultCalc!.TheMin < 1 * FactorE) {
            CUTABLE[X][y][0] = '*' + CUTABLE[X][y][0] + '*';
          }
        }

        break;
      case 2:
        //Lateral
        X = 0;
        const txtLateralSpacing = X;

        const resultCalc = Calculate(
          calcParams.Placement,
          calcParams.cbEDCEmitter,
          calcParams.EdgeType,
          calcParams._Shadows,
          unitSettings,
          txtLateralSpacing.toString(),
          txtEmitterSpacing.toString(),
          calcParams.txtBedWidth,
          calcParams.lblBedWidthUnit,
          calcParams.dEDGEPOSITIONX,
          calcParams.dEDGEPOSITIONY,
          calcParams.BILATERAL_TRIANGULAR_LAYOUT,
          calcParams.TRIANGULAR_Y_LAYOUT,
          calcParams.LATERAL_LAYOUT,
          calcParams.lastplacement,
          calcParams.range,
          calcParams.Graph,
          calcParams.mouseClickPositionY1,
          calcParams.mouseClickPositionY2,
        );

        CUTABLE[X][y][1] = resultCalc!.TheDu;
        CUTABLE[X][y][0] = resultCalc!.TheCu;
        CUTABLE[X][y][2] = ConvertUnit(resultCalc!.txtAverage, METRIC_DEFAULTS.AppnRate, unitSettings.appnRate, null);
        CUTABLE[X][y][3] = ConvertUnit(resultCalc!.TheMin, METRIC_DEFAULTS.AppnRate, unitSettings.appnRate, null);
        if (resultCalc!.TheMin < 1 * FactorE) {
          CUTABLE[X][y][0] = '*' + CUTABLE[X][y][0] + '*';
        }

        break;
      case 3:
      case 4:
        //, EDGE_LAYOUT
        for (X = 0; X <= numcols; X++) {
          const txtLateralSpacing = dMinL + dStepL * X;

          const resultCalc = Calculate(
            calcParams.Placement,
            calcParams.cbEDCEmitter,
            calcParams.EdgeType,
            calcParams._Shadows,
            unitSettings,
            txtLateralSpacing.toString(),
            txtEmitterSpacing.toString(),
            calcParams.txtBedWidth,
            calcParams.lblBedWidthUnit,
            calcParams.dEDGEPOSITIONX,
            calcParams.dEDGEPOSITIONY,
            calcParams.BILATERAL_TRIANGULAR_LAYOUT,
            calcParams.TRIANGULAR_Y_LAYOUT,
            calcParams.LATERAL_LAYOUT,
            calcParams.lastplacement,
            calcParams.range,
            calcParams.Graph,
            calcParams.mouseClickPositionY1,
            calcParams.mouseClickPositionY2,
          );

          CUTABLE[X][y][0] = resultCalc!.TheCu;
          CUTABLE[X][y][1] = resultCalc!.TheDu;
          CUTABLE[X][y][2] = ConvertUnit(resultCalc!.txtAverage, METRIC_DEFAULTS.AppnRate, unitSettings.appnRate, null);
          CUTABLE[X][y][3] = ConvertUnit(resultCalc!.TheMin, METRIC_DEFAULTS.AppnRate, unitSettings.appnRate, null);
          if (resultCalc!.TheMin < 1 * FactorE) {
            CUTABLE[X][y][0] = '*' + CUTABLE[X][y][0] + '*';
          }
        }

        break;
    }
  }

  const options = {
    min,
    avg,
    cu,
    du,
  };

  const res = createResponse(CUTABLE, stepsArray, options);
  return res;
};

function createResponse(data: any, steps: number[], options: Record<string, boolean>) {
  const tableRes: TablesResult = [];
  let index = 0;
  for (const block of data) {
    const tableItem: Table = {
      step: Math_round(steps[index], 2),
      ...(options.cu && { cu: [] }),
      ...(options.du && { du: [] }),
      ...(options.avg && { avg: [] }),
      ...(options.min && { min: [] }),
    };
    for (const row of block) {
      if (row[0]) {
        options.cu && tableItem.cu?.push(row[0]);
        options.du && tableItem.du?.push(row[1]);
        options.avg && tableItem.avg?.push(row[2]);
        options.min && tableItem.min?.push(round(row[3], 1));
      }
    }
    if (tableItem.step) {
      tableRes.push(tableItem);
    }
    index++;
  }
  return tableRes;
}

function round(value: any, precision: any) {
  const multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}
