import { styled, Table, TableBody, TableCell, TableContainer } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'shared/hooks';
import { Math_round } from 'shared/lib/calculation/mathRound';
import { PaperBox, TableRow } from 'shared/ui';

const Cell = styled(TableCell)({
  // whiteSpace: 'nowrap',
});

export const PressureLossTable: FC = () => {
  const { t } = useTranslation();
  const { projectData } = useAppSelector((state) => state.projectData);
  const { units } = useAppSelector((state) => state.units);

  const { lateralValues } = useAppSelector((state) => state.lateralState);
  const { mainlineValues } = useAppSelector((state) => state.mainlineState);
  const { submainValues } = useAppSelector((state) => state.submainState);

  const pressureAtHead = Math_round(
    mainlineValues.pumpPressure && mainlineValues.pipesHeadloss
      ? mainlineValues.pumpPressure - mainlineValues.pipesHeadloss
      : 0,
    3,
  );

  const pressureAtEnd = Math_round(
    lateralValues.resultReportArray[lateralValues.resultReportArray.length - 1]?.inletPressure ?? 0,
    3,
  );

  return (
    <PaperBox height={'100%'}>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow withBg>
              <Cell>{t('pumpPressure')}</Cell>
              <Cell>{`${mainlineValues.pumpPressure} (${units.pressure})`}</Cell>
            </TableRow>
            <TableRow>
              <Cell>{t('pumpHeadloss')}</Cell>
              <Cell>{`${mainlineValues.pumpHeadloss} (${units.pressure})`}</Cell>
            </TableRow>
            <TableRow withBg>
              <Cell>{t('pressureAtHead')}</Cell>
              <Cell>{`${isNaN(pressureAtHead) ? 0 : pressureAtHead} (${units.pressure})`}</Cell>
            </TableRow>
            <TableRow>
              <Cell>{t('pipesHeadloss')}</Cell>
              <Cell>{`${mainlineValues.pipesHeadloss} (${units.pressure})`}</Cell>
            </TableRow>
            <TableRow withBg>
              <Cell>{t('pressureAtValve')}</Cell>
              <Cell>{`${+submainValues.valvePressure + +mainlineValues.valveHeadloss} (${units.pressure})`}</Cell>
            </TableRow>
            <TableRow>
              <Cell>{t('valveHeadloss')}</Cell>
              <Cell>{`${mainlineValues.valveHeadloss} (${units.pressure})`}</Cell>
            </TableRow>
            <TableRow withBg>
              <Cell>{t('prAtManifoldInlet')}</Cell>
              <Cell>{`${projectData.valvePressure} (${units.pressure})`}</Cell>
            </TableRow>
            <TableRow>
              <Cell>{t('submainHeadloss')}</Cell>
              <Cell>{`${submainValues.totalHeadloss} (${units.pressure})`}</Cell>
            </TableRow>
            <TableRow withBg>
              <Cell>{t('inletPr')}</Cell>
              <Cell>{`${projectData.lateralInletPressure} (${units.pressure})`}</Cell>
            </TableRow>
            <TableRow>
              <Cell>{t('lateralHL')}</Cell>
              <Cell>{`${lateralValues.resultTotalHeadloss || lateralValues.resultTotalHeadloss || 0} (${
                units.pressure
              })`}</Cell>
            </TableRow>
            <TableRow withBg>
              <Cell>{t('prAtLateralEnd')}</Cell>
              <Cell>{`${isNaN(pressureAtEnd) ? 0 : pressureAtEnd} (${units.pressure})`}</Cell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </PaperBox>
  );
};
