import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type ReportItem = {
  averageFlow: number;
  headloss: number;
  inletPressure: number;
  lateralFlow: number;
  length: number;
  totalHeadloss: number;
  velocity: number;
};

export type LateralValues = {
  maxLengthStart: number | string;
  maxLengthEnd: number | string;
  maxLengthIncrement: number | string;
  isAdvanced: boolean;
  isCustom: boolean;
  isMaxLength: boolean;
  uniformityType: string;
  minimumVariation: string | number | null;
  minimumEu: string | number;
  resultEU: string | number;
  resultMaxVelocity: string | number;
  resultTotalHeadloss: string | number;
  resultTotalFlow: string | number;
  resultDU: string | number;
  resultPMin: string | number;
  resultPMax: string | number;
  resultQMinQMax: string | number;
  resultReportArray: ReportItem[];
  resultMaxLengthEU: string | number;
  resultMaxLengthMaxVelocity: string | number;
  resultMaxLengthTotalHeadloss: string | number;
  resultMaxLengthTotalFlow: string | number;
  resultMaxLengthDU: string | number;
  resultMaxLengthPMin: string | number;
  resultMaxLengthPMax: string | number;
  resultMaxLength: string | number;
  resultMaxLengthQMinQMax: string | number;
  resultMaxLengthReportArray: ReportItem[];
  lateralClass: number | string;
  lateralDiameter: number | string;
  lateralFlowPer: number | string;
  lateralNominalFlow: number | string;
  lateralSpacing: number | string;
  emitterNominalFlow: number | string;
  emitterSpacing: number | string;
  integratedMasterGroup: string | null;
  integratedGroup: string | null;
  integratedCatalog: string | null;
  onlineMasterGroup: string | null;
  onlineGroup: string | null;
  onlineCatalog: string | null;
  expandedResultsAccordion: boolean;
};

type LateralErrors = {
  velocity: boolean;
  pMin: boolean;
  pMax: boolean;
  maxLengthVelocity: boolean;
  maxLengthPMax: boolean;
  maxLengthPMin: boolean;
  inletPressure: boolean;
  length: boolean;
  spacing: boolean;
  maxLengthStart: boolean;
  maxLengthEnd: boolean;
  maxLengthIncrement: boolean;
};

interface LateralState {
  lateralValues: LateralValues;
  lateralErrors: LateralErrors;
}

const initialState: LateralState = {
  lateralValues: {
    maxLengthStart: 0,
    maxLengthEnd: 0,
    maxLengthIncrement: 0,
    isAdvanced: false,
    isCustom: false,
    isMaxLength: false,
    uniformityType: 'Minimum EU',
    minimumVariation: 15,
    minimumEu: 90,
    resultEU: '',
    resultMaxVelocity: '',
    resultTotalHeadloss: '',
    resultTotalFlow: '',
    resultDU: '',
    resultPMin: '',
    resultPMax: '',
    resultQMinQMax: '',
    resultReportArray: [],
    resultMaxLength: '',
    resultMaxLengthEU: '',
    resultMaxLengthMaxVelocity: '',
    resultMaxLengthTotalHeadloss: '',
    resultMaxLengthTotalFlow: '',
    resultMaxLengthDU: '',
    resultMaxLengthPMin: '',
    resultMaxLengthPMax: '',
    resultMaxLengthQMinQMax: '',
    resultMaxLengthReportArray: [],
    lateralClass: 'All',
    lateralDiameter: 'All',
    lateralFlowPer: 'All',
    lateralNominalFlow: 'All',
    lateralSpacing: 'All',
    emitterNominalFlow: 'All',
    emitterSpacing: 'All',
    integratedMasterGroup: null,
    integratedGroup: null,
    integratedCatalog: null,
    onlineMasterGroup: null,
    onlineGroup: null,
    onlineCatalog: null,
    expandedResultsAccordion: true,
  },
  lateralErrors: {
    velocity: false,
    pMax: false,
    pMin: false,
    maxLengthVelocity: false,
    maxLengthPMax: false,
    maxLengthPMin: false,
    inletPressure: false,
    spacing: false,
    length: false,
    maxLengthEnd: false,
    maxLengthIncrement: false,
    maxLengthStart: false,
  },
};

const lateralStateSlice = createSlice({
  name: 'lateralSate',
  initialState,
  reducers: {
    setLateralStateValue: (state, action: PayloadAction<Partial<LateralValues>>) => {
      state.lateralValues = { ...state.lateralValues, ...action.payload };
    },
    setLateralErrorValue: (state, action: PayloadAction<Partial<LateralErrors>>) => {
      state.lateralErrors = { ...state.lateralErrors, ...action.payload };
    },
  },
});

export const { setLateralStateValue, setLateralErrorValue } = lateralStateSlice.actions;

export const lateralStateReducer = lateralStateSlice.reducer;
