import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type ReportItem = {
  len: number;
  flow: number;
  vel: number;
  headloss: number;
  pressure: number;
};

export type SubmainValues = {
  isSubmainLength: boolean;
  isCalcLength: boolean;
  velocity1: number;
  velocity2: number;
  velocity3: number;
  firstLateral: number;
  lastLateral: number;
  valvePressure: number;
  totalHeadloss: number;
  resultReportArray: ReportItem[];
  submainClass: string | number;
  allowedHeadloss:  string | number;
};

export type SubmainErrors = {
  velocity1: boolean;
  velocity2: boolean;
  velocity3: boolean;
  length1: boolean;
  length2: boolean;
  length3: boolean;
  spacing: boolean;
  numberOfRows: boolean;
  totalSubmainLength: boolean;
};
interface SubmainState {
  submainValues: SubmainValues;
  submainErrors: SubmainErrors;
}

const initialState: SubmainState = {
  submainValues: {
    isSubmainLength: false,
    isCalcLength: true,
    velocity1: 0,
    velocity2: 0,
    velocity3: 0,
    firstLateral: 0,
    lastLateral: 0,
    valvePressure: 0,
    totalHeadloss: 0,
    resultReportArray: [],
    submainClass: 'All',
    allowedHeadloss: 5,
  },
  submainErrors: {
    velocity1: false,
    velocity2: false,
    velocity3: false,
    length1: false,
    length2: false,
    length3: false,
    spacing: false,
    numberOfRows: false,
    totalSubmainLength: false,
  },
};

const submainStateSlice = createSlice({
  name: 'submainSate',
  initialState,
  reducers: {
    setSubmainStateValue: (state, action: PayloadAction<Partial<SubmainValues>>) => {
      state.submainValues = { ...state.submainValues, ...action.payload };
    },
    setSubmainErrorValue: (state, action: PayloadAction<Partial<SubmainErrors>>) => {
      state.submainErrors = { ...state.submainErrors, ...action.payload };
    },
  },
});

export const { setSubmainStateValue, setSubmainErrorValue } = submainStateSlice.actions;

export const submainStateReducer = submainStateSlice.reducer;
