import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

interface Props {
  value: string;
  onChange: (phone: string) => void;
  countryCode: string | undefined;
  onBlur: () => boolean;
  error?: boolean;
  disabled?: boolean;
}

export const InputPhone: FC<Props> = ({ value, onChange, countryCode, onBlur, error, disabled }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography
        noWrap
        sx={{
          color: '#242731',
          fontFamily: 'Roboto',
          fontSize: '18px',
          lineHeight: '130%',
          letterSpacing: '0.18px',
          mb: '8px',
        }}
      >
        {t('Phone')}*
      </Typography>
      <Box sx={{ position: 'relative' }}>
        <Box
          sx={{
            height: '44px',
            width: '1px',
            background: error ? '#F00' : 'rgba(177, 177, 177, 1)',
            position: 'absolute',
            top: 0,
            left: 52,
            zIndex: 999,
          }}
        />
        <Box
          sx={{
            height: '44px',
            width: '1px',
            background: error ? '#F00' : 'rgba(177, 177, 177, 1)',
            position: 'absolute',
            top: 0,
            left: 56,
            zIndex: 999,
          }}
        />
        <PhoneInput
          autoFormat={true}
          onBlur={onBlur}
          value={value}
          onChange={onChange}
          enableSearch
          searchPlaceholder={'Search'}
          country={countryCode}
          buttonStyle={{ borderRight: `5px #fff solid` }}
          placeholder={''}
          specialLabel={''}
          searchStyle={{
            width: '250px',
          }}
          inputStyle={{
            width: '100%',
            borderRadius: '8px',
            height: '44px',
            borderColor: error ? '#F00' : 'rgba(177, 177, 177, 1)',
            backgroundColor: disabled ? 'rgba(212, 212, 212, 1)' : 'transparent',
          }}
          disabled={disabled}
        />
      </Box>
    </Box>
  );
};
