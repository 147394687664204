import { BuildPlantsField } from './BuildPlantsField';
import { Ellipse } from './Ellipse';
import { Line } from './Line';

export function DrawGrowth(
  fieldpic: {
    width: number;
    height: number;
  },
  dXPOSITION: number,
  dYPOSITION: number,
  _Shadows: boolean,
  BetweenPlants: number,
  BetweenRows: number,
  PlantDiameter: number,
  StaggeredRows: boolean,
  GroupPlantRows: boolean,
  RowsinGroup: number,
  BetweenGroups: number,
  InRows: number,
  OffRows: number,
  Placement: number,
  EdgeType: number,
  dEDGEPOSITIONX: number,
  dEDGEPOSITIONY: number,
) {
  let Xorg = 0;
  let Yorg = 0;
  let pl = null;
  let HalfDiameter = 0;

  const PlantsLayout = {
    BetweenPlants: BetweenPlants,
    BetweenRows: BetweenRows,
    GroupPlantRows: GroupPlantRows,
    RowsInGroup: RowsinGroup,
    BetweenGroups: BetweenGroups,
    OffRow: OffRows,
    InRows: InRows,
    Placement: Placement,
    EdgeType: EdgeType,
    dEDGEPOSITIONX: dEDGEPOSITIONX,
    dXPOSITION: dXPOSITION,
    dEDGEPOSITIONY: dEDGEPOSITIONY,
    dYPOSITION: dYPOSITION,
    StaggeredRows: StaggeredRows,
    PlantDiameter: PlantDiameter,
  };

  let PlantsField = BuildPlantsField(PlantsLayout);
  const result: Array<Ellipse> = [];
  PlantsField.forEach((grp) => {
    grp.Rows.forEach((row: any) => {
      row.Plants.forEach((pl_loopVariable: any) => {
        pl = pl_loopVariable;
        HalfDiameter = ((pl.PlantDiameter / 2) * fieldpic.width) / dXPOSITION;
        Xorg = (pl.X * fieldpic.width) / dXPOSITION;
        Yorg = (pl.y * fieldpic.height) / dYPOSITION;
        if (Xorg <= fieldpic.width + HalfDiameter && Xorg > -HalfDiameter) {
          const e: Ellipse = {
            Stroke: '#000000',
            width: 2 * HalfDiameter,
            height: 2 * HalfDiameter,
            left: Math.floor(Xorg - HalfDiameter),
            top: Math.floor(Yorg - HalfDiameter),
          };

          result.push(e);

          const e1: Ellipse = {
            Stroke: '#FFFFFF',
            width: 2 * HalfDiameter,
            height: 2 * HalfDiameter,
            left: Math.floor(Xorg - HalfDiameter),
            top: Math.floor(Yorg - HalfDiameter),
          };
          result.push(e1);

          if (_Shadows) {
            const e2: Ellipse = {
              Stroke: 'gray',
              width: 2 * HalfDiameter - 2,
              height: 2 * HalfDiameter - 2,
              left: Xorg - HalfDiameter + 1,
              top: Yorg - HalfDiameter + 1,
            };
            result.push(e2);
          }
        }
      });
    });
  });

  let lineRes = DrawPlantLines(fieldpic, dXPOSITION, PlantsField);
  let allresult = {
    resElip: result,
    resLine: lineRes,
  };
  return allresult;
}

export function DrawPlantLines(
  fieldpic: {
    width: number;
    height: number;
  },
  dXPOSITION: number,
  PlantsField: any,
) {
  let i = 0;
  let LineX = 0;
  let pg = null;
  let pr = null;
  let result: Array<Line> = [];

  //scan groups
  PlantsField.forEach((pg_loopVariable: any) => {
    pg = pg_loopVariable;
    pg.Rows.forEach((pr_loopVariable: any) => {
      pr = pr_loopVariable;
      LineX = (pr.Left * fieldpic.width) / dXPOSITION;
      //check if in painted field
      if (LineX >= 0 && LineX <= fieldpic.width) {
        const l: Line = {
          Stroke: '#FFFFFF',
          X1: LineX,
          Y1: 0,
          X2: LineX,
          Y2: fieldpic.height,
          Tag: 'PlantLine',
        };
        result.push(l);
      }
      i = i + 1;
    });
  });

  return result;
}
