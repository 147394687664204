export const UpdateConnectingLine = (
  mouseClickPositionY1: number,
  mouseClickPositionY2: number,
  fieldpic: { width: number; height: number },
) => {
  const CANVAS_WIDTH = 650;
  const SOURCE_CANVAS_WIDTH = 420;

  const Coefficient = CANVAS_WIDTH / SOURCE_CANVAS_WIDTH;

  const X1 = 0;
  const Y1 = Math.round(Math.round(mouseClickPositionY1) / Coefficient);
  const X2 = Math.round(fieldpic.width);
  const Y2 = Math.round(Math.round(mouseClickPositionY2) / Coefficient);

  const ConnectingLine = {
    X1: X1,
    X2: X2,
    Y1: Y1,
    Y2: Y2,
  };

  return ConnectingLine;
};
