import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Table,
  TableBody,
  TableCell as Cell,
  TableContainer,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { ChangeEvent, FC } from 'react';
import { tech_data_ico } from 'shared/assets';
import { Accordion, IconLabel, Row, TableRow, TechDataInput, TechDataSelect } from 'shared/ui';
import { EU_TYPE, HEADLOSS_CALCULATION } from 'shared/constants';
import { Divider } from './components';

import { useAppDispatch, useAppSelector, useLateralFilters } from 'shared/hooks';
import { setLateralStateValue, setProjectValue } from 'shared/slices';
import { useTranslation } from 'react-i18next';
import { formattedInputValue } from 'shared/lib';

const TechDataContent = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { masterGroups } = useAppSelector((state) => state.masterGroups);
  const { projectData } = useAppSelector((st) => st.projectData);
  const { lateralValues } = useAppSelector((st) => st.lateralState);
  const { laterals } = useAppSelector((st) => st.laterals);
  const { emitters } = useAppSelector((st) => st.emitters);
  const { units } = useAppSelector((st) => st.units);
  const isTablet = useMediaQuery('(max-width:850px)');
  const isMobile = useMediaQuery('(max-width:550px)');

  const { masterGroupId } = useLateralFilters();

  const toggleAdvanced = () => dispatch(setLateralStateValue({ isAdvanced: !lateralValues.isAdvanced }));
  const toggleCustom = () => dispatch(setLateralStateValue({ isCustom: !lateralValues.isCustom }));

  const changeLateralInletDia = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setProjectValue({ lateralInletDia: formattedInputValue(e) }));
  };
  const changeEmitterSpacing = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setProjectValue({ emitterSpacing: formattedInputValue(e) }));
  };
  const changeEmitterPMin = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setProjectValue({ emitterPMin: formattedInputValue(e) }));
  };
  const changeEmitterPMax = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setProjectValue({ emitterPMax: formattedInputValue(e) }));
  };
  const changeHeadlossCalc = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setProjectValue({ headlossCalc: e.target.value }));
  };
  const changeEuType = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setProjectValue({ euType: e.target.value }));
  };
  const changeEmitterA = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setProjectValue({ emitterA: formattedInputValue(e) }));
  };
  const changeEmitterB = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setProjectValue({ emitterB: formattedInputValue(e) }));
  };
  const changeLateralKd = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setProjectValue({ lateralKd: formattedInputValue(e) }));
  };
  const changeHwCoef = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setProjectValue({ hwCoef: formattedInputValue(e) }));
  };
  const changeCvv = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setProjectValue({ cvv: formattedInputValue(e) }));
  };

  const currentProduct = projectData.integrated
    ? laterals.find((item) => item.catlog === projectData.lateralCatalog)
    : emitters.find((item) => item.catlog === projectData.emitterCatalog);

  const currentMasterGroupChosen = masterGroups.find((mg) => mg.id === masterGroupId);
  const isDripTapeTypeChosen = currentMasterGroupChosen?.mastergroupname.includes('Drip Tape');

  return (
    <>
      <Typography
        noWrap
        sx={{
          color: 'primary.main',
          fontSize: '16px',
          fontWeight: 400,
          letterSpacing: '0.01em',
          my: '15px',
        }}
      >
        {projectData.region === 'USA' ? currentProduct?.altCatlogDesc : currentProduct?.catlogDesc}
      </Typography>
      <FormGroup row sx={{ gap: '2px', mt: '-10px' }}>
        <FormControlLabel
          control={<Checkbox checked={lateralValues.isAdvanced} onChange={toggleAdvanced} />}
          label={`${t('advanced')}`}
        />
        <FormControlLabel
          control={<Checkbox checked={lateralValues.isCustom} onChange={toggleCustom} />}
          label={`${t('custom')}`}
        />
      </FormGroup>

      <Row
        alignItems="flex-start"
        direction={isMobile ? 'column' : isTablet ? 'row' : 'column'}
        spacing={isMobile ? 0 : isTablet ? '10px' : 0}
      >
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow withBg>
                <Cell>{`${t('internalDia')} (${units.pipeDiameter})`}</Cell>
                <Cell align="right">
                  <TechDataInput
                    disabled={!lateralValues.isAdvanced}
                    value={projectData.lateralInletDia}
                    onChange={changeLateralInletDia}
                    inputProps={{ step: 0.1 }}
                  />
                </Cell>
              </TableRow>
              <TableRow>
                <Cell>{`${t('spacing')} (${units.emitterSpacing})`}</Cell>
                <Cell align="right">
                  <TechDataInput
                    disabled={isDripTapeTypeChosen && !lateralValues.isAdvanced}
                    value={projectData.emitterSpacing ?? 0}
                    onChange={changeEmitterSpacing}
                    inputProps={{ step: 0.1 }}
                  />
                </Cell>
              </TableRow>
              <TableRow withBg>
                <Cell>{`${t('pMin')} (${units.pressure})`}</Cell>
                <Cell align="right">
                  <TechDataInput
                    value={projectData.emitterPMin ?? 0}
                    onChange={changeEmitterPMin}
                    inputProps={{ step: 0.1 }}
                  />
                </Cell>
              </TableRow>
              <TableRow>
                <Cell>{`${t('pMax')} (${units.pressure})`}</Cell>
                <Cell align="right">
                  <TechDataInput
                    value={projectData.emitterPMax ?? 0}
                    onChange={changeEmitterPMax}
                    inputProps={{ step: 0.1 }}
                  />
                </Cell>
              </TableRow>

              {lateralValues.isAdvanced && (
                <>
                  {!isTablet && <Divider />}

                  <TableRow withBg>
                    <Cell>{`${t('equation')}`}</Cell>
                    <Cell align="right">
                      <TechDataSelect value={projectData.headlossCalc} onChange={changeHeadlossCalc}>
                        {HEADLOSS_CALCULATION.map((item) => (
                          <MenuItem key={item.value} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </TechDataSelect>
                    </Cell>
                  </TableRow>
                  <TableRow>
                    <Cell>{`${t('euType')}`}</Cell>
                    <Cell align="right">
                      <TechDataSelect value={projectData?.euType ?? 'Classic'} onChange={changeEuType}>
                        {EU_TYPE.map((item) => (
                          <MenuItem key={item.value} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </TechDataSelect>
                    </Cell>
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TableContainer>
          <Table>
            <TableBody>
              {lateralValues.isCustom && (
                <>
                  {!isTablet && <Divider />}

                  <TableRow withBg>
                    <Cell>{`${t('a')}`}</Cell>
                    <Cell align="right">
                      <TechDataInput value={projectData.emitterA} onChange={changeEmitterA} />
                    </Cell>
                  </TableRow>
                  <TableRow>
                    <Cell>{`${t('b')}`}</Cell>
                    <Cell align="right">
                      <TechDataInput value={projectData.emitterB} onChange={changeEmitterB} />
                    </Cell>
                  </TableRow>
                  <TableRow withBg>
                    <Cell>{`${t('kd')}`}</Cell>
                    <Cell align="right">
                      <TechDataInput value={projectData.lateralKd} onChange={changeLateralKd} />
                    </Cell>
                  </TableRow>
                  <TableRow>
                    <Cell>{`${t('hwCoef')}`}</Cell>
                    <Cell align="right">
                      <TechDataInput value={projectData.hwCoef} onChange={changeHwCoef} />
                    </Cell>
                  </TableRow>
                  <TableRow withBg>
                    <Cell>{`${t('cv')}`}</Cell>
                    <Cell align="right">
                      <TechDataInput value={projectData.cvv} onChange={changeCvv} />
                    </Cell>
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Row>
    </>
  );
};

export const TechDataBlock: FC = () => {
  const isTablet = useMediaQuery('(max-width:850px)');

  const { t } = useTranslation();

  return (
    <Box
      pt={isTablet ? '10px' : 0}
      width={isTablet ? '100%' : '40%'}
      px={isTablet ? 0 : '15px'}
      pl={{ xs: 0, xl: '15px' }}
    >
      {isTablet ? (
        <Accordion header={<IconLabel iconSrc={tech_data_ico}>{t('technicalData')}</IconLabel>}>
          <TechDataContent />
        </Accordion>
      ) : (
        <>
          <IconLabel iconSrc={tech_data_ico}>{t('technicalData')}</IconLabel>

          <TechDataContent />
        </>
      )}
    </Box>
  );
};
