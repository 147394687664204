export interface SlopesForm {
  slopes: {
    slope: number;
    length: number;
    direction: string;
    elevation: number;
  }[];
}

export type Slope = {
  name: string;
  slope: number | string;
  length: number | string;
  direction: string;
  elevation: string;
};

export const parseSlopes = (slopesSource: string) => {
  const slopes = slopesSource?.split(';').filter((item) => item);
  const slopesData = slopes?.map((item) => {
    const parsedItem = item.split(':');
    return {
      length: +parsedItem[0] ?? '',
      slope: +parsedItem[1] ?? '',
      direction: parsedItem[2] ?? '',
    };
  });

  return slopesData;
};

export const convertSlopes = (data: Slope[]) => {
  const newSlopes = data
    .map((item) => `${item.length}:${item.slope}:${item.direction === 'Uphill' ? 'U' : 'D'}`)
    .join(';');

  return newSlopes;
};

export const getFormattedElevation = (direction: string, value: number): string => {
  if (direction === 'Uphill' && value !== 0) return `+${value}`;

  if (direction === 'Downhill' && value !== 0) return `-${value}`;

  return `${value}`;
};

export const mapSlopesForCalculation = (slopes: string) => {
  return parseSlopes(slopes).map((item) => ({
    Length: item.length,
    Slope: item.slope,
    SlopeDirection: item.direction === 'D' ? 'Downhill' : 'Uphill',
  }));
};
