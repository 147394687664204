import { Math_round } from './calculation/mathRound';

type ParamsType = {
  flow1: number;
  flow2: number;
  totalRows: number;
  isBothSides: boolean;
};

export const calculateFlow = (params: ParamsType) => {
  const { flow1, flow2, totalRows, isBothSides } = params;

  if (isBothSides) {
    return Math_round((flow1 + flow2) * totalRows, 3);
  } else {
    return Math_round(flow1 * totalRows, 3);
  }
};

export const calculateNumOfRows = (totalLength: number, spacing: number) => {
  return spacing > 0 ? (Math.floor(totalLength / spacing) + 1).toString() : '';
};

export const calculateTotalLength = (totalRows: number, spacing: number) => {
  return totalRows > 0 ? ((totalRows - 1) * spacing).toString() : '';
};
