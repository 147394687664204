import { Box, Grid, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { results_ico } from 'shared/assets';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { Math_round } from 'shared/lib/calculation/mathRound';
import { toggleMainlineReport } from 'shared/slices';
import { Accordion, Button, IconLabel, Input, PaperBox, ShepherdBlock } from 'shared/ui';

const ResultsContent = () => {
  const { t } = useTranslation();
  const { units } = useAppSelector((state) => state.units);
  const { projectData } = useAppSelector((state) => state.projectData);
  const { mainlineValues } = useAppSelector((state) => state.mainlineState);
  const { submainValues } = useAppSelector((state) => state.submainState);

  const isMobile = useMediaQuery('(max-width:550px)');

  return (
    <Grid item container xs={12} columnSpacing={3} rowSpacing={1}>
      <Grid item xs={isMobile ? 12 : 4}>
        <Input label={`${t('pumpPressure')} (${units.pressure})`} isResultBox value={mainlineValues.pumpPressure} />
      </Grid>

      <Grid item xs={isMobile ? 12 : 4}>
        <Input
          label={`${t('pressureAtValve')} (${units.pressure})`}
          isResultBox
          value={Math_round(
            Math_round(+projectData.lateralInletPressure + submainValues.totalHeadloss, 3) +
            +mainlineValues.valveHeadloss,
            3,
          )}
        />
      </Grid>

      <Grid item xs={isMobile ? 12 : 4}>
        <Input label={`${t('pipesHeadloss')} (${units.pressure})`} isResultBox value={mainlineValues.pipesHeadloss} />
      </Grid>
    </Grid>
  );
};

const ReportButton = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { mainlineValues } = useAppSelector((state) => state.mainlineState);
  const isTablet = useMediaQuery('(max-width:850px)');
  const isMobile = useMediaQuery('(max-width:550px)');

  const openMainlineReport = () => dispatch(toggleMainlineReport());

  return (
    <Grid item container xs={12} columnSpacing={3} mt={1}>
      <Grid item xs={isTablet ? 6 : 9}></Grid>
      <Grid item xs={isMobile ? 12 : isTablet ? 6 : 2.8}>
        <ShepherdBlock id='submain-result-report'>
          <Button iconType='show-report' onClick={openMainlineReport} disabled={!mainlineValues.pumpPressure}>
            {t('showReport')}
          </Button>
        </ShepherdBlock>
      </Grid>
    </Grid>
  );
};

export const ResultsBlock = () => {
  const isTablet = useMediaQuery('(max-width:850px)');
  const isMobile = useMediaQuery('(max-width:550px)');

  const { t } = useTranslation();

  return (
    <Box mt={isTablet ? '10px' : '-20px'}>
      {isTablet ? (
        <Accordion defaultExpanded header={<IconLabel iconSrc={results_ico}>{t('results')}</IconLabel>}>
          <>
            <ResultsContent />
            {!isMobile && <ReportButton />}
          </>
        </Accordion>
      ) : (
        <>
          <IconLabel iconSrc={results_ico}>{t('results')}</IconLabel>

          <PaperBox>
            <ResultsContent />
          </PaperBox>
          <ReportButton />
        </>
      )}
    </Box>
  );
};
