import React, { useEffect, useRef } from 'react';

type Line = {
  Stroke: string;

  X1: number;
  X2: number;
  Y1: number;
  Y2: number;
};

interface CanvasLinesProps {
  lines: Line[];
  width: number;
  height: number;
}

export const CanvasLines: React.FC<CanvasLinesProps> = ({ lines, width, height }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    canvas.width = width;
    canvas.height = height;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.lineWidth = 3;

    lines.forEach((line) => {
      ctx.strokeStyle = line.Stroke;
      ctx.beginPath();
      ctx.moveTo(line.X1, line.Y1);
      ctx.lineTo(line.X2, line.Y2);

      ctx.stroke();
    });
  }, [lines]);

  return (
    <canvas
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
      }}
      ref={canvasRef}
    />
  );
};
