import { METRIC_DEFAULTS } from 'shared/constants';
import { Unit } from 'shared/models';
import { LimitsRangeManiFolds } from '../common/limitRange';
import { Manifold } from './Manifold';

import { Math_round } from '../mathRound';
import { ConvertUnit } from '../unitConverter';
import { CalculateManifoldLengths } from './CalculateManifoldLengths';

export function manifoldCalculate(
  inputData: Manifold,
  unitSettings: Unit,
  bothSides: boolean,
  isCalculateLengths: boolean,
  showReport: boolean,
) {
  const validationResult = inputDataValidation(inputData);
  // eslint-disable-next-line no-prototype-builtins
  if (validationResult!.hasOwnProperty('error')) {
    return validationResult;
  }
  let result = {};
  var reportResult = {};
  let limitRangeMainFolds: LimitsRangeManiFolds = {};
  result = { ...result, flow1: flow1(inputData.Flow1, unitSettings) };
  result = { ...result, flow2: flow2(inputData.Flow2, unitSettings) };
  let lateralFlow = flow1(inputData.Flow1, unitSettings);
  if (bothSides) {
    lateralFlow = lateralFlow + flow2(inputData.Flow2, unitSettings);
  }
  let spacing = spacingCalc(inputData.ManifoldSpacing, unitSettings);
  let dia1 = dia1Calc(inputData.InternalDiameter1, unitSettings);
  let dia2 = dia2Calc(inputData.InternalDiameter2, unitSettings);
  let dia3 = dia3Calc(inputData.InternalDiameter3, unitSettings);
  result = { ...result, spacing: spacing };
  result = { ...result, dia1: dia1 };
  result = { ...result, dia2: dia2 };
  result = { ...result, dia3: dia3 };
  let HWcoefDef = inputData.ManifoldHWCoef;
  let HWcoef = 0;
  let firstLateralHeadloss = 0;
  let arrDia = [];
  let arrFlow = [];
  let arrHeadloss = [];
  let arrPressure = [];
  let arrHeights: any[] = [];
  let arrVelocity = [];
  let arrLength = [];
  let velocity1 = 0;
  let velocity2 = 0;
  let velocity3 = 0;

  let HWCoefPipe1 = inputData.ManifoldPipe1;
  let HWCoefPipe2 = inputData.ManifoldPipe2;
  let HWCoefPipe3 = inputData.ManifoldPipe3;

  let length1 = length1Calc(inputData.Pipe1Length, unitSettings);
  let length2 = length2Calc(inputData.Pipe2Length, unitSettings);
  let length3 = length3Calc(inputData.Pipe3Length, unitSettings);

  result = { ...result, length1: length1CalcRes(length1, unitSettings) };
  result = { ...result, length2: length2CalcRes(length2, unitSettings) };
  result = { ...result, length3: length3CalcRes(length3, unitSettings) };
  let totalLength = totalSubmainLengthCalc(inputData.TotalSubmainLength, unitSettings);
  result = { ...result, totalLength: totalLength };

  // let actualTotal = (inputData.NumberOfRows - 1) * spacing;
  let firstLateral = distanceTo1stLateral(inputData.DistanceTo1stLateral, unitSettings);
  totalLength = totalLength + firstLateral;
  if (isCalculateLengths == false) {
    let total123 = length1 + length2 + length3;
    if (Math.round(total123 * 100) != Math.round(totalLength * 100)) {
      return { data: inputData, error: 'msgInvalidManifoldLengths' };
    }
  }

  if (inputData.MultiSlopesManifold.length > 0) {
    let slopeIndex = 0;
    let slope = inputData.MultiSlopesManifold[0].Slope;
    let slopesLength = inputData.MultiSlopesManifold[0].Length;
    if (inputData.MultiSlopesManifold[0].SlopeDirection == 'Downhill') slope *= -1;

    for (let i = 0; i <= inputData.NumberOfRows - 1; i++) {
      if (i * spacing >= slopesLength) {
        if (slopeIndex < inputData.MultiSlopesManifold.length - 1) {
          slopeIndex += 1;
          let len = Number.parseInt(Math.round(inputData.MultiSlopesManifold[slopeIndex].Length).toString());
          //len = UnitConverter.ConvertUnit(len, unitSettings.length, unitSettings.lengthMetric, Nothing)
          totalLength += len;
          slope = inputData.MultiSlopesManifold[slopeIndex].Slope;
          if (inputData.MultiSlopesManifold[slopeIndex].SlopeDirection == 'Downhill') slope *= -1;
        } else {
          slope = 0;
        }
      }
      arrHeights[i] = (slope / 100.0) * spacing;
    }
  }
  let maxVelocity = maxVelocityCalc(inputData.ManifoldMaxVelocity, unitSettings);
  let valvePressure = valvePressureCalc(inputData.ValvePressure, unitSettings);
  result = { ...result, valvePressure: valvePressure };

  if (isCalculateLengths) {
    var res = CalculateManifoldLengths(
      dia1,
      dia2,
      dia3,
      lateralFlow,
      HWcoefDef,
      HWCoefPipe1,
      HWCoefPipe2,
      HWCoefPipe3,
      arrHeights,
      maxVelocity,
      spacing,
      inputData.NumberOfRows,
      inputData,
      unitSettings,
      isCalculateLengths,
    );

    result = { ...result, length1: res.length1 };
    result = { ...result, length2: res.length2 };
    result = { ...result, length3: res.length3 };
    velocity1 = res.velocity1;
    velocity2 = res.velocity2;
    velocity3 = res.velocity3;
    result = { ...result, velocity1: velocity1 };
    result = { ...result, velocity2: velocity2 };
    result = { ...result, velocity3: velocity3 };

    length1 = res.length1;
    length2 = res.length2;
    length3 = res.length3;
  }

  if (length1 != 0 || length2 != 0 || length3 != 0) {
    // length3 = length3Calc(inputData.Pipe2Length, unitSettings);
    if (isNaN(inputData.LateralInletPressure)) {
      return { data: inputData, error: 'msgInvalidInletPr' };
    }
    let inletPress = 0;
    inletPress = ConvertUnit(inputData.LateralInletPressure, unitSettings.pressure, METRIC_DEFAULTS.Pressure, null);
    arrPressure[0] = inletPress;
    arrDia[0] = dia1;
    if (HWCoefPipe1 == 0) {
      HWCoefPipe1 = HWcoefDef;
    }

    if (inputData.ManifoldHeadlossCalculation == 'H.W') {
      arrHeadloss[0] =
        (1.131 * Math.pow(10.0, 12.0) * Math.pow(lateralFlow / HWCoefPipe1, 1.852)) / Math.pow(arrDia[0], 4.87);
    } else {
      if (arrDia[0] < 125) {
        arrHeadloss[0] = 8.38 * Math.pow(10, 7) * Math.pow(lateralFlow, 1.75) * Math.pow(arrDia[0], -4.75);
      } else {
        arrHeadloss[0] = 9.19 * Math.pow(10, 7) * Math.pow(lateralFlow, 1.83) * Math.pow(arrDia[0], -4.83);
      }
    }
    arrHeadloss[0] = (arrHeadloss[0] * spacing) / 1000;
    let j = 0;
    let i = 0;
    while (j < inputData.NumberOfRows) {
      if (i < length3) {
        arrDia[j] = dia3;
        HWcoef = HWCoefPipe3;
      }
      if (i >= length3 && i < length3 + length2) {
        arrDia[j] = dia2;
        HWcoef = HWCoefPipe2;
      }
      if (i >= length3 + length2 && i < length1 + length2 + length3) {
        arrDia[j] = dia1;
        HWcoef = HWCoefPipe1;
      }

      if (HWcoefDef > 0) {
        HWcoef = HWcoefDef;
      }
      if (arrDia[j] == 0) {
        arrHeadloss[j] = 0;
      } else {
        if (inputData.ManifoldHeadlossCalculation == 'H.W') {
          arrHeadloss[j] =
            (1.131 * Math.pow(10.0, 12.0) * Math.pow((lateralFlow * (j + 1)) / HWcoef, 1.852)) /
            Math.pow(arrDia[j], 4.87);
        } else {
          if (arrDia[j] < 125) {
            arrHeadloss[j] =
              8.38 * Math.pow(10, 7) * Math.pow(lateralFlow * (j + 1), 1.75) * Math.pow(arrDia[j], -4.75);
          } else {
            arrHeadloss[j] =
              9.19 * Math.pow(10, 7) * Math.pow(lateralFlow * (j + 1), 1.83) * Math.pow(arrDia[j], -4.83);
          }
        }
      }
      arrHeadloss[j] = (arrHeadloss[j] * spacing) / 1000;
      if (j > 0) {
        let arrPressureRes = 0;
        let arrHeightsRes = 0;
        arrPressureRes = isNaN(arrPressure[j - 1]) ? 0 : arrPressure[j - 1];
        arrHeightsRes = isNaN(arrHeights[j - 1]) ? 0 : arrHeights[j - 1];
        arrPressure[j] = arrPressureRes + arrHeadloss[j - 1] + arrHeightsRes;
      } else {
        arrPressure[j] = inletPress;
      }
      let velocity = 0;
      if (arrDia[j] != 0)
        velocity = (lateralFlow * (j + 1)) / ((((Math.PI * arrDia[j]) / 1000) * arrDia[j]) / 1000 / 4) / 3600;
      arrVelocity[j] = velocity;
      arrLength[j] = i;
      arrFlow[j] = lateralFlow * (j + 1);
      i += spacing;
      j += 1;
    }
    let dia = 0;
    if (length1 > 0) {
      dia = dia1;
    } else {
      if (length2 > 0) {
        dia = dia2;
      } else {
        if (length3 > 0) {
          dia = dia3;
        }
      }
    }
    if (!isCalculateLengths) {
      velocity1 = (lateralFlow * inputData.NumberOfRows) / ((((Math.PI * dia1) / 1000) * dia1) / 1000 / 4) / 3600;
      velocity2 =
        (((length3 + length2) / spacing) * lateralFlow) / ((((Math.PI * dia2) / 1000) * dia2) / 1000 / 4) / 3600;
      velocity3 = ((length3 / spacing) * lateralFlow) / ((((Math.PI * dia3) / 1000) * dia3) / 1000 / 4) / 3600;
      velocity1 = velocity1Calc(velocity1, unitSettings);
      velocity2 = velocity2Calc(velocity2, unitSettings);
      velocity3 = velocity3Calc(velocity3, unitSettings);

      result = { ...result, velocity1: velocity1 };
      result = { ...result, velocity2: velocity2 };
      result = { ...result, velocity3: velocity3 };
    }
    if (firstLateral > 0) {
      if (inputData.ManifoldHeadlossCalculation == 'H.W') {
        firstLateralHeadloss =
          (1.131 * Math.pow(10.0, 12.0) * Math.pow((lateralFlow * inputData.NumberOfRows) / HWcoef, 1.852)) /
          Math.pow(dia, 4.87);
      } else {
        if (dia1 < 125) {
          firstLateralHeadloss =
            8.38 * Math.pow(10, 7) * Math.pow(lateralFlow * inputData.NumberOfRows, 1.75) * Math.pow(dia, -4.75);
        } else {
          firstLateralHeadloss =
            9.19 * Math.pow(10, 7) * Math.pow(lateralFlow * inputData.NumberOfRows, 1.83) * Math.pow(dia, -4.83);
        }
      }
      firstLateralHeadloss = (firstLateralHeadloss * firstLateral) / 1000;
    }

    if (velocity1 > maxVelocity) {
      limitRangeMainFolds.velocity1 = { range: 'over' };
    } else {
      limitRangeMainFolds.velocity1 = { range: 'in' };
    }

    if (velocity2 > maxVelocity) {
      limitRangeMainFolds.velocity2 = { range: 'over' };
    } else {
      limitRangeMainFolds.velocity2 = { range: 'in' };
    }

    if (velocity3 > maxVelocity) {
      limitRangeMainFolds.velocity3 = { range: 'over' };
    } else {
      limitRangeMainFolds.velocity3 = { range: 'in' };
    }
    valvePressure = arrPressure[arrPressure.length - 1] + firstLateralHeadloss;
    result = { ...result, ValvePressure: valvePressureCalc(valvePressure, unitSettings) };
    result = {
      ...result,
      LastLateralPressure: lastLateralPressureCalc(arrPressure[0], unitSettings),
    };
    result = {
      ...result,
      FirstLateralPressure: firstLateralPressureCalc(arrPressure[arrPressure.length - 1], unitSettings),
    };
    let AllowableHeadloss = 0;
    AllowableHeadloss = valvePressure - inletPress;
    result = {
      ...result,
      SubmainAllowableHeadloss: submainAllowableHeadloss(AllowableHeadloss, unitSettings),
    };
    let mainlineValvePressure = 0;
    let headlossAtValve = valveHeadlossCalc(inputData.ValveHeadloss, unitSettings);
    mainlineValvePressure = headlossAtValve + valvePressure;
    result = {
      ...result,
      mainlineValvePressure: mainlineValvePressure,
    };
  }
  if (showReport) {
    let cat1 = '';
    let cat2 = '';
    let cat3 = '';
    cat1 = inputData.ManifoldPipe1.toString();
    cat2 = inputData.ManifoldPipe2.toString();
    cat3 = inputData.ManifoldPipe3.toString();
    const reportData = [];
    for (let index = 0; index < arrLength.length; index++) {
      let reportItem = {};
      let len = length1Calc(arrLength[index], unitSettings);
      reportItem = { ...reportItem, len: len };
      let flow = flow1(arrFlow[index], unitSettings);
      reportItem = { ...reportItem, flow: flow };
      let vel = velocity1Calc(arrVelocity[index], unitSettings);
      reportItem = { ...reportItem, vel: vel };
      let headloss = valveHeadlossCalc(arrHeadloss[index], unitSettings);
      reportItem = { ...reportItem, headloss: headloss };
      let pressure = valvePressureCalc(arrPressure[index], unitSettings);
      reportItem = { ...reportItem, pressure: pressure };

      reportData.push(reportItem);
    }

    reportResult = { ...reportResult, cat1: cat1 };
    reportResult = { ...reportResult, cat2: cat2 };
    reportResult = { ...reportResult, cat3: cat3 };
    reportResult = { ...reportResult, length1: length1 };
    reportResult = { ...reportResult, length2: length2 };
    reportResult = { ...reportResult, length3: length3 };
    reportResult = { ...reportResult, dia1: dia1 };
    reportResult = { ...reportResult, dia2: dia2 };
    reportResult = { ...reportResult, dia3: dia3 };
    reportResult = { ...reportResult, velocity1: velocity1Calc(velocity1, unitSettings) };
    reportResult = { ...reportResult, velocity2: velocity2Calc(velocity2, unitSettings) };
    reportResult = { ...reportResult, velocity3: velocity3Calc(velocity3, unitSettings) };
    reportResult = { ...reportResult, firstLateral: firstLateral };
    reportResult = { ...reportResult, firstLateralHeadloss: firstLateralHeadloss };
    reportResult = { ...reportResult, valvePressure: valvePressure };

    reportResult = { ...reportResult, reportResults: reportData };
    result = { ...result, reportResult: reportResult };
  }
  result = {
    ...result,
    limitRangeMainFolds: limitRangeMainFolds,
  };
  return result;
}

export function flow1(flow: number, unitSettings: Unit) {
  let flowRes = ConvertUnit(flow, unitSettings.totalFlow, METRIC_DEFAULTS.TotalFlow, null);
  return Math_round(flowRes, 3);
}

export function flow2(flow2: number, unitSettings: Unit) {
  let flowRes = ConvertUnit(flow2, unitSettings.totalFlow, METRIC_DEFAULTS.TotalFlow, null);
  return Math_round(flowRes, 3);
}

export function spacingCalc(spacing: number, unitSettings: Unit) {
  let spacingRes = ConvertUnit(spacing, unitSettings.lateralSpacing, METRIC_DEFAULTS.LateralSpacing, null);
  return Math_round(spacingRes, 3);
}

export function dia1Calc(dia1: number, unitSettings: Unit) {
  let dia1Res = ConvertUnit(dia1, unitSettings.pipeDiameter, METRIC_DEFAULTS.PipeDiameter, null);
  return Math_round(dia1Res, 3);
}

export function dia2Calc(dia2: number, unitSettings: Unit) {
  let dia2Res = ConvertUnit(dia2, unitSettings.pipeDiameter, METRIC_DEFAULTS.PipeDiameter, null);
  return Math_round(dia2Res, 3);
}

export function dia3Calc(dia3: number, unitSettings: Unit) {
  let dia3Res = ConvertUnit(dia3, unitSettings.pipeDiameter, METRIC_DEFAULTS.PipeDiameter, null);
  return Math_round(dia3Res, 3);
}

export function length1Calc(length1: number, unitSettings: Unit) {
  let length1Res = ConvertUnit(length1, unitSettings.length, METRIC_DEFAULTS.Length, null);
  return Math_round(length1Res, 3);
}

export function length2Calc(length2: number, unitSettings: Unit) {
  let length2Res = ConvertUnit(length2, unitSettings.length, METRIC_DEFAULTS.Length, null);
  return Math_round(length2Res, 3);
}

export function length3Calc(length3: number, unitSettings: Unit) {
  let length3Res = ConvertUnit(length3, unitSettings.length, METRIC_DEFAULTS.Length, null);
  return Math_round(length3Res, 3);
}

export function length1CalcRes(length1: number, unitSettings: Unit) {
  let length1Res = ConvertUnit(length1, METRIC_DEFAULTS.Length, unitSettings.length, null);
  return Math_round(length1Res, 3);
}

export function length2CalcRes(length2: number, unitSettings: Unit) {
  let length2Res = ConvertUnit(length2, METRIC_DEFAULTS.Length, unitSettings.length, null);
  return Math_round(length2Res, 3);
}

export function length3CalcRes(length3: number, unitSettings: Unit) {
  let length3Res = ConvertUnit(length3, METRIC_DEFAULTS.Length, unitSettings.length, null);
  return Math_round(length3Res, 3);
}

export function totalSubmainLengthCalc(totalSubmainLength: number, unitSettings: Unit) {
  let totalSubmainLengthRes = ConvertUnit(totalSubmainLength, unitSettings.length, METRIC_DEFAULTS.Length, null);
  return Math_round(totalSubmainLengthRes, 3);
}

export function distanceTo1stLateral(distanceTo1stLateral: number, unitSettings: Unit) {
  let distanceTo1stLateralRes = ConvertUnit(distanceTo1stLateral, unitSettings.length, METRIC_DEFAULTS.Length, null);
  return Math_round(distanceTo1stLateralRes, 3);
}

export function maxVelocityCalc(maxVelocity: number, unitSettings: Unit) {
  let maxVelocitylRes = ConvertUnit(maxVelocity, unitSettings.velocity, METRIC_DEFAULTS.Velocity, null);
  return Math_round(maxVelocitylRes, 3);
}

export function valvePressureCalc(valvePressure: number, unitSettings: Unit) {
  let valvePressureRes = ConvertUnit(valvePressure, METRIC_DEFAULTS.Pressure, unitSettings.pressure, null);
  return Math_round(valvePressureRes, 3);
}

export function lastLateralPressureCalc(arrPressure: number, unitSettings: Unit) {
  let arrPressureRes = ConvertUnit(arrPressure, METRIC_DEFAULTS.Pressure, unitSettings.pressure, null);
  return Math_round(arrPressureRes, 3);
}

export function firstLateralPressureCalc(arrPressure: number, unitSettings: Unit) {
  let arrPressureRes = ConvertUnit(arrPressure, METRIC_DEFAULTS.Pressure, unitSettings.pressure, null);
  return Math_round(arrPressureRes, 3);
}

export function submainAllowableHeadloss(allowableHeadloss: number, unitSettings: Unit) {
  let allowableHeadlossRes = ConvertUnit(allowableHeadloss, METRIC_DEFAULTS.Pressure, unitSettings.pressure, null);
  return Math_round(allowableHeadlossRes, 3);
}

export function valveHeadlossCalc(valveHeadloss: number, unitSettings: Unit) {
  let valveHeadlossRes = ConvertUnit(valveHeadloss, METRIC_DEFAULTS.Pressure, unitSettings.pressure, null);
  return Math_round(valveHeadlossRes, 3);
}

export function velocity1Calc(velocity1: number, unitSettings: Unit) {
  let velocity1Res = ConvertUnit(velocity1, METRIC_DEFAULTS.Velocity, unitSettings.velocity, null);
  return Math_round(velocity1Res, 3);
}

export function velocity2Calc(velocity2: number, unitSettings: Unit) {
  let velocity2Res = ConvertUnit(velocity2, METRIC_DEFAULTS.Velocity, unitSettings.velocity, null);
  return Math_round(velocity2Res, 3);
}

export function velocity3Calc(velocity3: number, unitSettings: Unit) {
  let velocity3Res = ConvertUnit(velocity3, METRIC_DEFAULTS.Velocity, unitSettings.velocity, null);
  return Math_round(velocity3Res, 3);
}

function inputDataValidation(inputData: Manifold) {
  if (isNaN(inputData.ManifoldSpacing) || inputData.ManifoldSpacing <= 0) {
    return { data: inputData, error: 'msgInvalidSpacing' };
  }
  if (isNaN(inputData.NumberOfRows) || inputData.NumberOfRows <= 0) {
    return { data: inputData, error: 'msgInvalidRows' };
  }
  if (inputData.InternalDiameter1 + inputData.InternalDiameter2 + inputData.InternalDiameter3 === 0) {
    return { data: inputData, error: 'msgAllInvalidDia' };
  }
  return inputData;
}
