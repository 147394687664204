import { ChangeEvent } from 'react';

export const formattedInputValue = (e: ChangeEvent<HTMLInputElement>) => {
  const value = e.target.value;

  if (value == '') {
    return '';
  }

  if (+value == 0) {
    return 0;
  }

  const input = value?.toString().split('');
  if (input?.[0] === '0' && input?.[1] !== '.' && input.length > 1) {
    input[0] = '';
    return input.join('');
  }

  return +e.target.value;
};
