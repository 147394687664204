import { Box } from '@mui/system';
import { RegisterForm } from 'widgets';

export const RegisterPage = () => {
  return (
    <Box>
      <RegisterForm />
    </Box>
  );
};
