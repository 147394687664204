import { useEffect, useMemo } from 'react';
import { SelectChangeEvent } from '@mui/material';
import { useAppDispatch } from './useAppDispatch';
import { useAppSelector } from './useAppSelector';
import {
  getComparisonEmittersEDCThunk,
  setComparisonCurrentEmitter,
  setComparisonCurrentEmitterFamily,
  setEDCStateComparisonEmitterValue,
} from 'shared/slices';
import { EmitterEDC, EmittersEnamesItem } from 'shared/models';
import { useTranslation } from 'react-i18next';

const ALL = 'All';

export const useEDCComparisonFilters = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const {
    edcValues: { comparisonModeEmitter },
  } = useAppSelector((st) => st.edcState);
  const { comparisonEmittersEDC, comparisonCurrentEmitter } = useAppSelector((st) => st.emittersEDC);
  const { comparisonEmitterFamilies, comparisonCurrentEmitterFamily } = useAppSelector((st) => st.emitterFamilies);

  const onEmitterFamilyChange = async (e: SelectChangeEvent<unknown>) => {
    const emitterFamily = comparisonEmitterFamilies.find(
      (ef) => ef.groupId === (e.target.value as string)
    ) as EmittersEnamesItem;
    dispatch(setComparisonCurrentEmitterFamily(emitterFamily));
    resetFilters();

    const emitters = await dispatch(getComparisonEmittersEDCThunk({ ename: e.target.value as string })).unwrap();

    if (!emitters) return;

    const isUPSDAble = emitters.getEmittersForEnameResult.rootResults.find((item) => item.orient);
    const isUPAble = emitters.getEmittersForEnameResult.rootResults.find((item) => !item.orient);
    const isBothAble = isUPSDAble && isUPAble;

    const orient = isBothAble ? 'both' : isUPAble ? 'up' : 'upsd';
    dispatch(setEDCStateComparisonEmitterValue({ orient }));
  };
  const onEmitterEDCChange = (_: any, item: EmitterEDC) => {
    dispatch(setComparisonCurrentEmitter(item));
  };
  const onOrientChange = (e: SelectChangeEvent<unknown>) => {
    dispatch(setEDCStateComparisonEmitterValue({ orient: e.target.value as string }));
  };
  const onSpinnerChange = (e: SelectChangeEvent<unknown>) => {
    dispatch(setEDCStateComparisonEmitterValue({ spinner: e.target.value as string }));
  };
  const onNozzleChange = (e: SelectChangeEvent<unknown>) => {
    dispatch(setEDCStateComparisonEmitterValue({ nozzle: e.target.value as string }));
  };
  const onPressureChange = (e: SelectChangeEvent<unknown>) => {
    dispatch(setEDCStateComparisonEmitterValue({ pressure: e.target.value as string }));
  };
  const onRiserChange = (e: SelectChangeEvent<unknown>) => {
    dispatch(setEDCStateComparisonEmitterValue({ riser: e.target.value as string }));
  };
  const onFlowRateChange = (e: SelectChangeEvent<unknown>) => {
    dispatch(setEDCStateComparisonEmitterValue({ flowRate: e.target.value as string }));
  };
  const onRangeChange = (e: SelectChangeEvent<unknown>) => {
    dispatch(setEDCStateComparisonEmitterValue({ range: e.target.value as string }));
  };

  const orientFilter = (item: EmitterEDC) => {
    if (comparisonModeEmitter.orient === 'upsd') return item.orient;
    if (comparisonModeEmitter.orient === 'up') return !item.orient;
    return true;
  };

  const spinnerFilter = (item: EmitterEDC) =>
    comparisonModeEmitter.spinner !== ALL ? item.spinner === comparisonModeEmitter.spinner : true;
  const nozzleFilter = (item: EmitterEDC) =>
    comparisonModeEmitter.nozzle !== ALL ? item.nozzle === comparisonModeEmitter.nozzle : true;
  const pressureFilter = (item: EmitterEDC) =>
    comparisonModeEmitter.pressure !== ALL ? item.pressure === comparisonModeEmitter.pressure : true;
  const riserFilter = (item: EmitterEDC) =>
    comparisonModeEmitter.riser !== ALL ? item.riser === comparisonModeEmitter.riser : true;
  const flowRateFilter = (item: EmitterEDC) =>
    comparisonModeEmitter.flowRate !== ALL ? item.flowRate === comparisonModeEmitter.flowRate : true;
  const rangeFilter = (item: EmitterEDC) =>
    comparisonModeEmitter.range !== ALL ? item.range === comparisonModeEmitter.range : true;

  const emittersEDCFiltered = useMemo(() => {
    return comparisonEmittersEDC.filter(
      (item) =>
        orientFilter(item) &&
        spinnerFilter(item) &&
        nozzleFilter(item) &&
        pressureFilter(item) &&
        riserFilter(item) &&
        flowRateFilter(item) &&
        rangeFilter(item)
    );
  }, [
    comparisonEmittersEDC,
    comparisonModeEmitter.orient,
    comparisonModeEmitter.spinner,
    comparisonModeEmitter.nozzle,
    comparisonModeEmitter.pressure,
    comparisonModeEmitter.riser,
    comparisonModeEmitter.flowRate,
    comparisonModeEmitter.range,
  ]);

  useEffect(() => {
    if (
      emittersEDCFiltered.length &&
      !emittersEDCFiltered.find((item) => item.uniqueId === comparisonCurrentEmitter.uniqueId)
    ) {
      onEmitterEDCChange(null, emittersEDCFiltered[0]);
    }
  }, [emittersEDCFiltered]);

  const orients = useMemo(() => {
    const isUPSDAble = comparisonEmittersEDC.find((item) => item.orient);
    const isUPAble = comparisonEmittersEDC.find((item) => !item.orient);
    const isBothAble = isUPSDAble && isUPAble;

    return [
      ...(isBothAble ? [{ value: 'both', label: `${t('both')}` }] : []),
      ...(isUPAble ? [{ value: 'up', label: `${t('upright')}` }] : []),
      ...(isUPSDAble
        ? [
            {
              value: 'upsd',
              label: `${t('inverted')}`,
            },
          ]
        : []),
    ];
  }, [comparisonEmittersEDC]);

  const spinners = useMemo(() => {
    return [
      ALL,
      ...new Set(
        comparisonEmittersEDC
          .filter((item) => orientFilter(item))
          .map((item) => item.spinner)
          .sort()
      ),
    ];
  }, [comparisonEmittersEDC, comparisonModeEmitter.orient]);

  useEffect(() => {
    if (!emittersEDCFiltered.find((item) => item.uniqueId === comparisonCurrentEmitter.uniqueId)) {
      dispatch(
        setEDCStateComparisonEmitterValue({
          spinner: ALL,
          nozzle: ALL,
          pressure: ALL,
          riser: ALL,
          flowRate: ALL,
          range: ALL,
        })
      );
    }
  }, [spinners]);

  const nozzles = useMemo(() => {
    return [
      ALL,
      ...new Set(
        comparisonEmittersEDC
          .filter((item) => orientFilter(item) && spinnerFilter(item))
          .map((item) => item.nozzle)
          .sort()
      ),
    ];
  }, [comparisonEmittersEDC, comparisonModeEmitter.orient, comparisonModeEmitter.spinner]);

  useEffect(() => {
    if (!emittersEDCFiltered.find((item) => item.uniqueId === comparisonCurrentEmitter.uniqueId)) {
      dispatch(
        setEDCStateComparisonEmitterValue({
          nozzle: ALL,
          pressure: ALL,
          riser: ALL,
          flowRate: ALL,
          range: ALL,
        })
      );
    }
  }, [nozzles]);

  const pressures = useMemo(() => {
    return [
      ALL,
      ...new Set(
        comparisonEmittersEDC
          .filter((item) => orientFilter(item) && spinnerFilter(item) && nozzleFilter(item))
          .map((item) => item.pressure)
          .sort((a, b) => a - b)
      ),
    ];
  }, [
    comparisonEmittersEDC,
    comparisonModeEmitter.orient,
    comparisonModeEmitter.spinner,
    comparisonModeEmitter.nozzle,
  ]);

  useEffect(() => {
    if (!emittersEDCFiltered.find((item) => item.uniqueId === comparisonCurrentEmitter.uniqueId)) {
      dispatch(
        setEDCStateComparisonEmitterValue({
          pressure: ALL,
          riser: ALL,
          flowRate: ALL,
          range: ALL,
        })
      );
    }
  }, [pressures]);

  const risers = useMemo(() => {
    return [
      ALL,
      ...new Set(
        comparisonEmittersEDC
          .filter((item) => orientFilter(item) && spinnerFilter(item) && nozzleFilter(item) && pressureFilter(item))
          .map((item) => item.riser)
          .sort((a, b) => a - b)
      ),
    ];
  }, [
    comparisonEmittersEDC,
    comparisonModeEmitter.orient,
    comparisonModeEmitter.spinner,
    comparisonModeEmitter.nozzle,
    comparisonModeEmitter.pressure,
  ]);

  useEffect(() => {
    if (!emittersEDCFiltered.find((item) => item.uniqueId === comparisonCurrentEmitter.uniqueId)) {
      dispatch(
        setEDCStateComparisonEmitterValue({
          riser: ALL,
          flowRate: ALL,
          range: ALL,
        })
      );
    }
  }, [risers]);

  const flowRates = useMemo(() => {
    return [
      ALL,
      ...new Set(
        comparisonEmittersEDC
          .filter(
            (item) =>
              orientFilter(item) &&
              spinnerFilter(item) &&
              nozzleFilter(item) &&
              pressureFilter(item) &&
              riserFilter(item)
          )
          .map((item) => item.flowRate)
          .sort((a, b) => a - b)
      ),
    ];
  }, [
    comparisonEmittersEDC,
    comparisonModeEmitter.orient,
    comparisonModeEmitter.spinner,
    comparisonModeEmitter.nozzle,
    comparisonModeEmitter.pressure,
    comparisonModeEmitter.riser,
  ]);

  useEffect(() => {
    if (!emittersEDCFiltered.find((item) => item.uniqueId === comparisonCurrentEmitter.uniqueId)) {
      dispatch(
        setEDCStateComparisonEmitterValue({
          flowRate: ALL,
          range: ALL,
        })
      );
    }
  }, [flowRates]);

  const ranges = useMemo(() => {
    return [
      ALL,
      ...new Set(
        comparisonEmittersEDC
          .filter(
            (item) =>
              orientFilter(item) &&
              spinnerFilter(item) &&
              nozzleFilter(item) &&
              pressureFilter(item) &&
              riserFilter(item) &&
              flowRateFilter(item)
          )
          .map((item) => item.range)
          .sort((a, b) => a - b)
      ),
    ];
  }, [
    comparisonEmittersEDC,
    comparisonModeEmitter.orient,
    comparisonModeEmitter.spinner,
    comparisonModeEmitter.nozzle,
    comparisonModeEmitter.pressure,
    comparisonModeEmitter.riser,
    comparisonModeEmitter.flowRate,
  ]);

  useEffect(() => {
    if (!emittersEDCFiltered.find((item) => item.uniqueId === comparisonCurrentEmitter.uniqueId)) {
      dispatch(
        setEDCStateComparisonEmitterValue({
          range: ALL,
        })
      );
    }
  }, [ranges]);

  const resetFilters = () => {
    dispatch(
      setEDCStateComparisonEmitterValue({
        spinner: ALL,
        nozzle: ALL,
        pressure: ALL,
        riser: ALL,
        flowRate: ALL,
        range: ALL,
      })
    );
  };

  const orientValue = useMemo(
    () => orients.find((item) => item.value === comparisonModeEmitter.orient)?.value ?? '',
    [comparisonModeEmitter.orient, orients]
  );
  const spinnerValue = useMemo(
    () => spinners.find((item) => item === comparisonModeEmitter.spinner) ?? ALL,
    [comparisonModeEmitter.spinner, spinners]
  );
  const nozzleValue = useMemo(
    () => nozzles.find((item) => item === comparisonModeEmitter.nozzle) ?? ALL,
    [comparisonModeEmitter.nozzle, nozzles]
  );
  const riserValue = useMemo(
    () => risers.find((item) => item === comparisonModeEmitter.riser) ?? ALL,
    [comparisonModeEmitter.riser, risers]
  );
  const pressureValue = useMemo(
    () => pressures.find((item) => item === comparisonModeEmitter.pressure) ?? ALL,
    [comparisonModeEmitter.pressure, pressures]
  );
  const flowRateValue = useMemo(
    () => flowRates.find((item) => item === comparisonModeEmitter.flowRate) ?? ALL,
    [comparisonModeEmitter.flowRate, flowRates]
  );
  const rangeValue = useMemo(
    () => ranges.find((item) => item === comparisonModeEmitter.range) ?? ALL,
    [comparisonModeEmitter.range, ranges]
  );

  const emitterEDCProduct = useMemo(
    () =>
      emittersEDCFiltered.find((item) => item.uniqueId === comparisonCurrentEmitter.uniqueId) ?? emittersEDCFiltered[0],
    [comparisonCurrentEmitter, emittersEDCFiltered]
  );

  return {
    emitterFamilies: comparisonEmitterFamilies,
    currentEmitterFamily: comparisonCurrentEmitterFamily,
    onEmitterFamilyChange,
    orients,
    orientValue,
    onOrientChange,
    spinners,
    spinnerValue,
    onSpinnerChange,
    nozzles,
    nozzleValue,
    onNozzleChange,
    pressures,
    pressureValue,
    onPressureChange,
    risers,
    riserValue,
    onRiserChange,
    flowRates,
    flowRateValue,
    onFlowRateChange,
    ranges,
    rangeValue,
    onRangeChange,
    emittersEDCFiltered,
    emitterEDCProduct,
    onEmitterEDCChange,
  };
};
