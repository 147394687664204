import { METRIC_DEFAULTS } from 'shared/constants';
import { CatalogItem, Unit } from 'shared/models';
import { ConvertUnit } from '../unitConverter';

export function FilterManifoldCatalogs({
  maxVelocity,
  totalFlow,
  cbManifoldPipe,
  manifoldClass,
  units,
}: FilterManifoldProps) {
  if (cbManifoldPipe.length == 0) return;
  if (maxVelocity <= 0 || totalFlow <= 0) {
    return;
  }

  let dia1 = 0;
  let dia2 = 0;
  let dia3 = 0;

  totalFlow = ConvertUnit(totalFlow, units.totalFlow, METRIC_DEFAULTS.TotalFlow, null);
  maxVelocity = ConvertUnit(maxVelocity, units.velocity, METRIC_DEFAULTS.Velocity, null);

  const minDia = Math.sqrt(((totalFlow / 3600 / maxVelocity) * 4) / Math.PI) * 1000;

  if (cbManifoldPipe.length <= 0) return;

  let cbManifoldPipe1SelectedItem = -1;
  let cbManifoldPipe2SelectedItem = -1;
  let cbManifoldPipe3SelectedItem = -1;

  if (manifoldClass) {
    if (cbManifoldPipe.length >= 2) {
      let dr = null;
      let index = 0;

      for (const dr_loopVariable of cbManifoldPipe) {
        dr = dr_loopVariable;
        if (dia1 == 0 && dr.intrnl >= minDia) {
          dia1 = dr.intrnl;
          break;
        }
        index += 1;

        if (index >= cbManifoldPipe.length) {
          index = cbManifoldPipe.length - 1;
        }

        if (index >= 0) {
          cbManifoldPipe1SelectedItem = index;
          cbManifoldPipe2SelectedItem = index - 1;
        }
        if (index == 0) {
          cbManifoldPipe1SelectedItem = 0;
        }
        if (index > 1) {
          cbManifoldPipe3SelectedItem = index - 2;
        } else {
          cbManifoldPipe3SelectedItem = 0;
        }
      }
    }
    if (cbManifoldPipe1SelectedItem == -1) {
      cbManifoldPipe1SelectedItem = 0;
    }
    if (cbManifoldPipe2SelectedItem == -1) {
      cbManifoldPipe2SelectedItem = 0;
    }
    if (cbManifoldPipe3SelectedItem == -1) {
      cbManifoldPipe3SelectedItem = 0;
    }
  } else {
    if (cbManifoldPipe1SelectedItem == -1) {
      cbManifoldPipe1SelectedItem = 0;
    }
    if (cbManifoldPipe2SelectedItem == -1) {
      cbManifoldPipe2SelectedItem = 0;
    }
    if (cbManifoldPipe3SelectedItem == -1) {
      cbManifoldPipe3SelectedItem = 0;
    }
  }

  return {
    manifoldPipe1Selected: cbManifoldPipe[cbManifoldPipe1SelectedItem].catlog,
    manifoldPipe2Selected: cbManifoldPipe[cbManifoldPipe2SelectedItem].catlog,
    manifoldPipe3Selected: cbManifoldPipe[cbManifoldPipe3SelectedItem].catlog,
  };
}

export type FilterManifoldProps = {
  maxVelocity: number;
  totalFlow: number;
  cbManifoldPipe: CatalogItem[];
  manifoldClass: string;
  units: Unit;
};
