import Plot from 'react-plotly.js';
import { ColorScale, Data, Layout, PlotData } from 'plotly.js';
import { getRange } from 'shared/lib';
import { useAppSelector } from 'shared/hooks';
import { Box } from '@mui/material';
import { Row } from 'shared/ui';

const colorscale: ColorScale = [
  [0, '#D2D228'],
  [0.11, '#C8FA14'],
  [0.22, '#AAFA32'],
  [0.33, '#82E61E'],
  [0.44, '#57fa7d'],
  [0.55, '#62FF62'],
  [0.66, '#00F000'],
  [0.77, '#00C800'],
  [0.88, '#009600'],
  [1, '#006400'],
];

export const ThreeDChart = () => {
  const {
    calcResult: { ELIPS },
    edcValues: { lateralSpacing, emitterSpacing },
  } = useAppSelector((st) => st.edcState);

  const ZValues = ELIPS?.map((item) => item.map((item) => item.value ?? 0)).filter(Boolean);

  const zData1: Partial<PlotData> = {
    type: 'surface',
    z: ZValues,
    colorscale,
    hoverinfo: 'none',
  };

  const zData2: Partial<PlotData & { surfacecolor: number[][] }> = {
    type: 'surface',
    z: ELIPS.filter(Boolean).map((item) => item.map(() => -1.0)),
    colorscale,
    hoverinfo: 'none',
    showscale: false,
    showlegend: false,
    surfacecolor: ZValues,
  };

  const emitterSpacingTicks = getRange(+emitterSpacing, 0, 5).map((item) => (+item).toString());
  const lateralSpacingTicks = getRange(+lateralSpacing, 0, 5).map((item) => (+item).toString());

  const data: Data[] = [zData1, zData2];

  const layout: Partial<Layout> = {
    margin: {
      b: 0,
      l: 0,
      r: 0,
      t: 0,
    },
    scene: {
      bgcolor: '#F5FBFF',

      camera: {
        center: { x: 0, y: 0, z: -0.15 },
        eye: { x: 1.11, y: 1.46, z: 0.69 },
        up: { x: 0, y: 0, z: 1 },
      },
      aspectratio: {
        x: 1,
        y: 1,
        z: 0.95,
      },
      xaxis: {
        title: '',
        gridcolor: 'rgb(255, 255, 255)',
        zerolinecolor: 'rgb(255, 255, 255)',
        showbackground: true,
        backgroundcolor: 'rgb(230, 230,230)',
        ticktext: emitterSpacingTicks,
        tickvals: [0, 10, 20, 30, 40],
        showspikes: false,
      },
      yaxis: {
        title: '',
        gridcolor: 'rgb(255, 255, 255)',
        zerolinecolor: 'rgb(255, 255, 255)',
        showbackground: true,
        backgroundcolor: 'rgb(230, 230,230)',
        ticktext: lateralSpacingTicks,
        tickvals: [0, 10, 20, 30, 40],
        showspikes: false,
      },
      zaxis: {
        title: '',
        gridcolor: 'rgb(255, 255, 255)',
        zerolinecolor: 'rgb(255, 255, 255)',
        showbackground: true,
        backgroundcolor: 'rgb(230, 230,230)',
        showspikes: false,
      },
    },
    autosize: true,
  };

  return (
    <Row
      py={2}
      px={2}
      justifyContent="center"
      overflow="auto"
      // sx={{
      //   '&::-webkit-scrollbar': {
      //     width: '7px',
      //     height: '13px',
      //   },
      //   '&::-webkit-scrollbar-track': {
      //     background: 'primary.main',
      //   },
      //   '&::-webkit-scrollbar-thumb': {
      //     backgroundColor: 'primary.main',
      //     borderRadius: '13px',
      //   },
      // }}
    >
      <Plot config={{ displayModeBar: false, editable: false }} data={data} layout={layout} />
    </Row>
  );
};
