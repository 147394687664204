import { Box, Grid, MenuItem, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { emitter_ico } from 'shared/assets';
import { useAppSelector, useEmitterFilters } from 'shared/hooks';
import { AvailableInfo, IconLabel, PaperBox, ProductSelect, Select } from 'shared/ui';

export const EmitterBlock = () => {
  const { emitterGroups, emittersLoading, emitterGroupsLoading } = useAppSelector((state) => state.emitters);
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width: 550px)');

  const {
    masterGroups,
    masterGroupId,
    onMasterGroupChange,
    emitterSubtype,
    onGroupTypeChange,
    nominalFlow,
    onNominalFlowChange,
    spacing,
    onSpacingChange,
    nominalFlows,
    spacings,
    emitterProduct,
    emittersFiltered,
    onEmitterChange,
  } = useEmitterFilters();

  return (
    <Box mb={1}>
      <Box sx={{ mb: 1 }}>
        <IconLabel iconSrc={emitter_ico}>{t('emitter')}</IconLabel>
      </Box>
      <PaperBox>
        <Grid container columnSpacing={1}>
          <Grid item xs={isMobile ? 6 : 3}>
            <Select label={`${t('type')}`} value={masterGroupId} onChange={onMasterGroupChange}>
              {masterGroups.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.mastergroupname}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={isMobile ? 6 : 3}>
            <Select
              label={`${t('subtype')}`}
              value={emitterSubtype}
              onChange={onGroupTypeChange}
              loading={emitterGroupsLoading}
              disabled={emitterGroupsLoading}
            >
              {emitterGroups.map((item) => (
                <MenuItem key={item.groups} value={item.groups}>
                  {item.grouptypename}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={isMobile ? 6 : 3}>
            <Select
              label={`${t('nominalFlow')} (m³/h)`}
              value={nominalFlow}
              onChange={onNominalFlowChange}
              disabled={emitterGroupsLoading || emittersLoading}
            >
              {nominalFlows.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={isMobile ? 6 : 3}>
            <Select
              label={`${t('spacing')} (m)`}
              value={spacing}
              onChange={onSpacingChange}
              disabled={emitterGroupsLoading || emittersLoading}
            >
              {spacings.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
        <Grid container columnSpacing={1} mt={1}>
          <Grid item xs={isMobile ? 12 : 9}>
            <ProductSelect
              label={`${t('selectedProduct')}`}
              value={emitterProduct}
              onChange={onEmitterChange}
              options={emittersFiltered}
              loading={emittersLoading}
              disabled={emittersLoading || emitterGroupsLoading}
              error={!emitterProduct}
            />
          </Grid>
          <Grid item xs={isMobile ? 12 : 3} mt={1}>
            <AvailableInfo amount={emittersFiltered.length} />
          </Grid>
        </Grid>
      </PaperBox>
    </Box>
  );
};
