import { METRIC_DEFAULTS } from 'shared/constants';
import { ConvertUnit } from '../../../unitConverter';
import { Filed as Field } from '../../models/Handle';
import { Calculate } from '../Calculate/Calculate';
import { Color } from './ArrayColor';
import { CalcColorField } from './CalcColorField';
import { DoLegend } from './DoLegend';
import { LateralCondition } from './LateralCondition';
import { TriangularCondition } from './TriangularCondition';

export function HandleField(field: Field) {
  let ACCMULATOR = 0;
  let TRIANGULAR_Y_LAYOUT = 1;
  let LATERAL_LAYOUT = 2;
  let BILATERAL_TRIANGULAR_LAYOUT = 6;

  let stepd = 1;
  let ResultCalc;

  ResultCalc = Calculate(
    field.Placement,
    field.cbEDCEmitter,
    field.EdgeType,
    field._Shadows,
    field.unitSettings,
    field.txtLateralSpacing,
    field.txtEmitterSpacing,
    field.txtBedWidth,
    field.lblBedWidthUnit,
    field.dEDGEPOSITIONX,
    field.dEDGEPOSITIONY,
    BILATERAL_TRIANGULAR_LAYOUT,
    TRIANGULAR_Y_LAYOUT,
    LATERAL_LAYOUT,
    field.lastplacement,
    field.range,
    field.Graph,
    field.mouseClickPositionY1,
    field.mouseClickPositionY2
  );

  let FieldRainAt = field.FieldRainAt == null ? (ResultCalc?.FieldRainAt as Array<Array<number>>) : field.FieldRainAt;

  let TheMin = ResultCalc?.TheMin;
  let TheMax = ResultCalc?.TheMax;
  let txtMaximum = ResultCalc?.txtMaximum;
  let txtMinimum = ResultCalc?.txtMinimum;
  let txtAverage = ResultCalc?.txtAverage;
  let XM = FieldRainAt.length;
  let YM = FieldRainAt[0].length;
  let dXPOSITION = ResultCalc?.dXPOSITION;
  let dYPOSITION = ResultCalc?.dYPOSITION;
  let fieldpic = ResultCalc?.fieldpic;
  let TotalPoints = 1;
  let minimum = TheMin;
  let ranges = ResultCalc?.ranges;
  let StoreValues = ResultCalc?.StoreValues;
  let tempdrainat = field.tempdrainat == null ? ResultCalc?.tempdrainat : field.tempdrainat;

  let Legend = DoLegend(stepd, TheMax, TheMin, field.unitSettings);
  let Gap = Legend.legend;
  stepd = Legend.stepd;
  if (stepd == 0) {
    stepd = 0.001;
  }
  let ELIPS = CalcColorField(
    XM,
    YM,
    TotalPoints,
    minimum,
    FieldRainAt,
    tempdrainat,
    field.Placement,
    stepd,
    TRIANGULAR_Y_LAYOUT,
    BILATERAL_TRIANGULAR_LAYOUT,
    LATERAL_LAYOUT,
    field.EdgeType,
    field.dEDGEPOSITIONX,
    field.dEDGEPOSITIONY,
    //@ts-ignore
    dXPOSITION,
    //@ts-ignore
    fieldpic,
    field._ShowGrowth,
    dYPOSITION,
    field._Shadows,
    field.BetweenPlants,
    field.BetweenRows,
    field.PlantDiameter,
    field.StaggeredRows,
    field.GroupPlantRows,
    field.RowsinGroup,
    field.BetweenGroups,
    field.InRows,
    field.OffRows
  );
  let cfrac = ELIPS.cfrac;
  TotalPoints = ELIPS.TotalPoints;
  let X = 0;
  let y = 0;
  let i = 0;
  let COLORI = 0;

  let result = {
    ELIPS: ELIPS.value,
    gap: Gap,
    Maximum: txtMaximum,
    Minimum: txtMinimum,
    Average: txtAverage,
    TheDu: ResultCalc?.TheDu,
    TheCu: ResultCalc?.TheCu,
    txtSprinklerCount: ResultCalc?.txtSprinklerCount,
    ranges: ranges,
    GrowthResult: ELIPS.GrowthResult,
    GraphPoint: ResultCalc?.GraphPoint,
    FieldRainAt: FieldRainAt,
    tempdrainat: tempdrainat,
    StoreValues: StoreValues,
    EdgeLine: ELIPS.EdgeLine,
    fieldpic: fieldpic,
  };

  for (X = 0; X < Math.trunc(FieldRainAt.length / 2); X++) {
    for (y = 0; y < Math.trunc(FieldRainAt[0].length / 2); y++) {
      let rate = FieldRainAt[X * 2][y * 2];
      rate = ConvertUnit(rate, METRIC_DEFAULTS.AppnRate, field.unitSettings.appnRate, null);
      if (TriangularCondition(field.Placement, TRIANGULAR_Y_LAYOUT, BILATERAL_TRIANGULAR_LAYOUT)) {
        if (tempdrainat[X * 2][y * 2] <= 0) {
          COLORI = 0;
        } else {
          if (minimum) {
            COLORI = (tempdrainat[X * 2][y * 2] - minimum) / stepd + 1;
            if (COLORI <= 0) {
              COLORI = 1;
            }
            if (COLORI > 9) {
              COLORI = 9;
            }
          }
        }
        if (tempdrainat[X * 2][y * 2] != -1) {
          if (result.ELIPS[X][y].text) {
            if (tempdrainat) {
              result.ELIPS[X][y].text = Math.trunc(tempdrainat[X * 2][y * 2] + 0.5);
            } else {
              result.ELIPS[X][y].text = 0;
            }
          }
        }
      } else if (LateralCondition(field.Placement, LATERAL_LAYOUT)) {
        if (tempdrainat[X * 2][y * 2] <= 0) {
          COLORI = 0;
        } else {
          if (minimum) {
            COLORI = (tempdrainat[X * 2][y * 2] - minimum) / stepd + 1;
            if (COLORI <= 0) {
              COLORI = 1;
            }
            if (COLORI > 9) {
              COLORI = 9;
            }
          }
        }
        if (tempdrainat[X * 2][y * 2] != -1) {
          if (result.ELIPS[X][y]) {
            if (tempdrainat) {
              result.ELIPS[X][y].text = Math.trunc(tempdrainat[X * 2][y * 2] + 0.5);
            } else {
              result.ELIPS[X][y].text = 0;
            }
          }
        }
      } else {
        if (FieldRainAt[X * 2][y * 2] <= 0) {
          COLORI = 0;
        } else {
          if (minimum) {
            COLORI = (FieldRainAt[X * 2][y * 2] - minimum) / stepd + 1;
            if (COLORI <= 0) {
              COLORI = 1;
            }
            if (COLORI > 9) {
              COLORI = 9;
            }
          }
        }
        if (FieldRainAt[X * 2][y * 2] != -1) {
          if (result.ELIPS[X][y].text) {
            if (tempdrainat) {
              result.ELIPS[X][y].text = Math.trunc(tempdrainat[X * 2][y * 2] + 0.5);
            } else {
              result.ELIPS[X][y].text = 0;
            }
          }
        }
      }
    }
  }
  let TEMPNUM = Array(11);

  for (i = 0; i <= 10; i++) {
    TEMPNUM[i] = (cfrac[i] / TotalPoints) * 100 + 0.5;
    ACCMULATOR = ACCMULATOR + TEMPNUM[i];
  }
  let Factor = 100 / ACCMULATOR;
  for (i = 0; i <= 10; i++) {
    if (Color[i] != null) {
      //@ts-ignore
      result.gap[i].percent = TEMPNUM[i] * Factor;
    }
  }

  return result;
}
