import { TextField, TextFieldProps } from '@mui/material';
import { forwardRef } from 'react';

export const TechDataSelect = forwardRef<unknown, TextFieldProps>((props, ref) => {
  return (
    <TextField
      fullWidth
      select
      sx={{ width: 90, '& fieldset': { border: 'none' }, '.MuiSelect-select': { p: 0 } }}
      inputRef={ref}
      SelectProps={{
        sx: { cursor: 'pointer' },
        MenuProps: { PaperProps: { sx: { maxHeight: '40%' } } },
      }}
      {...props}
    >
      {props.children}
    </TextField>
  );
});
