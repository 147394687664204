import { Math_round } from '../../mathRound';
import { cbEDCEmitter } from '../models/cbEDCEmitter';
import { GetEmission } from './getMaxMinEmissions';

export function v_at(
  X: number,
  y: number,
  fieldintervalX: number,
  fieldintervalY: number,
  dRange: number,
  cbEDCEmitter: cbEDCEmitter,
  TUBENUM: number
) {
  let functionReturnValue = 0.0;
  let i = 0;
  const tempDistance = Math_round(Math.sqrt(Math.pow(X * fieldintervalX, 2) + Math.pow(y * fieldintervalY, 2)), 4);
  if (tempDistance > dRange) {
    functionReturnValue = 0;
    return functionReturnValue;
  }
  let dr = cbEDCEmitter.SelectedItem;
  const res = tempDistance / dr.Basedis;
  
  i = parseInt(res.toString());

  if (i > TUBENUM) {
    functionReturnValue = 0.0;
  } else {
    functionReturnValue = Math.fround(+GetEmission(dr, i));
  }
  return functionReturnValue;
}
