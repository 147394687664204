import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { customAxios } from 'shared/api';
import { CatalogItem, CatalogsResponse, Group, GroupsBody, GroupsResponse, SubmainsData } from 'shared/models';

interface UserState {
  submainsLoading: boolean;
  submainGroupsLoading: boolean;
  submains: CatalogItem[];
  submainsLoadedOnce: boolean;
  submainGroupsLoadedOnce: boolean;
  submainGroups: Group[];
}

const initialState: UserState = {
  submainsLoading: true,
  submainsLoadedOnce: false,
  submains: [],
  submainGroupsLoadedOnce: false,
  submainGroupsLoading: true,
  submainGroups: [],
};

export const getSubmainsThunk = createAsyncThunk('getSubmains', async (params: SubmainsData, thunkAPI) => {
  try {
    const response = await customAxios.get<CatalogsResponse>('be/HtCatalog/GetCatalogPerDiaClassFlowSpacing', {
      params,
    });
    return response.data;
  } catch (e) {
    if (e instanceof Error) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
});

export const getSubmainGroupsThunk = createAsyncThunk(
  'groupTypes/getSubmainGroupTypes',
  async (params: GroupsBody, thunkAPI) => {
    try {
      const response = await customAxios.get<GroupsResponse>('be/HtCatalog/GetGroupTypes', { params });
      return response.data;
    } catch (e) {
      if (e instanceof Error) {
        return thunkAPI.rejectWithValue(e.message);
      }
    }
  }
);

const submainsSlice = createSlice({
  name: 'submains',
  initialState,
  reducers: {
    setSubmains: (state, action: PayloadAction<CatalogItem[]>) => {
      state.submains = action.payload;
    },
    resetSubmains: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSubmainsThunk.pending.type, (state) => {
        state.submainsLoading = true;
      })
      .addCase(getSubmainsThunk.fulfilled.type, (state, action: PayloadAction<CatalogsResponse>) => {
        state.submains = action.payload.data;
        state.submainsLoading = false;
        if (!state.submainsLoadedOnce) state.submainsLoadedOnce = true;
      })
      .addCase(getSubmainsThunk.rejected.type, (state) => {
        state.submainsLoading = false;
        state.submainGroupsLoadedOnce = true;
      })
      .addCase(getSubmainGroupsThunk.pending.type, (state) => {
        state.submainGroupsLoading = true;
      })
      .addCase(getSubmainGroupsThunk.fulfilled.type, (state, action: PayloadAction<GroupsResponse>) => {
        state.submainGroups = action.payload.data.rootResults;
        state.submainGroupsLoading = false;
        if (!state.submainGroupsLoadedOnce) state.submainGroupsLoadedOnce = true;
      })
      .addCase(getSubmainGroupsThunk.rejected.type, (state) => {
        state.submainGroupsLoading = false;
      });
  },
});

export const { resetSubmains, setSubmains } = submainsSlice.actions;

export const submainsReducer = submainsSlice.reducer;
