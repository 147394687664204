import { List, ListItemButton, ListItemIcon, ListItemText, Stack, Typography, useMediaQuery } from '@mui/material';
import { toggleAddToHome, toggleExit } from 'shared/slices';
import { ExitIcon, GuideIcon, HelpIcon, UserIcon } from 'shared/assets';
import { useAppDispatch, useAppSelector, useDialog, useMobileDetect } from 'shared/hooks';
import { useTranslation } from 'react-i18next';
import { ShepherdBlock } from 'shared/ui';
import { useContext } from 'react';
import { ShepherdTourContext } from 'react-shepherd';
import { EndUserLicense } from 'features';

export const BottomButtons = () => {
  const { open: openAdditionalTerms, toggle: toggleAdditionalTerms } = useDialog();
  const dispatch = useAppDispatch();
  const mobileInfo = useMobileDetect();
  const { t } = useTranslation();

  const { username } = useAppSelector((state) => state.auth);
  const isLaptopL = useMediaQuery('(min-width:1200px)');

  const tour = useContext(ShepherdTourContext);

  const handleExit = () => dispatch(toggleExit());

  const handleGuide = () => tour?.start();

  const handleHelp = () => window.open(process.env.REACT_APP_HELP_URL, '_blank')?.focus();

  const isMobile = (mobileInfo?.isAndroid() || mobileInfo?.isIos()) && mobileInfo?.standalone === false;

  const isShepherdGuideAble = !isMobile;

  return (
    <>
      <EndUserLicense open={openAdditionalTerms} onClose={toggleAdditionalTerms} onlyForView />

      <Stack sx={{ flex: '1 1 auto', minHeight: '30px' }} />

      <List disablePadding>
        {isMobile && (
          <ListItemButton
            onClick={() => {
              dispatch(toggleAddToHome());
            }}
            sx={{ pb: 1 }}
          >
            <ListItemText
              primary={t('addToHomeScreen')}
              sx={{
                span: { fontSize: 12 },
                // textTransform: 'uppercase',
                textAlign: 'center',
                color: 'info.main',
              }}
            />
          </ListItemButton>
        )}
        <ListItemButton disableGutters sx={{ pl: '20px', pointerEvents: 'none' }}>
          <ListItemIcon sx={{ minWidth: '20px', mr: '5px' }}>
            <UserIcon />
          </ListItemIcon>

          <ListItemText
            primary={
              <Typography
                noWrap
                sx={{
                  textOverflow: 'ellipsis',
                  maxWidth: 140,
                  overflow: 'hidden',
                  fontSize: 18,
                  lineHeight: '18px',
                  textTransform: 'none',
                }}
                color='info.main'
              >
                {username}
              </Typography>
            }
          />
        </ListItemButton>
        <ListItemButton onClick={toggleAdditionalTerms} disableGutters sx={{ pl: '20px' }}>
          <ListItemText
            primary={
              <Typography
                sx={{
                  textOverflow: 'ellipsis',
                  maxWidth: 140,
                  overflow: 'hidden',
                  fontSize: 14,
                  lineHeight: '18px',
                  textTransform: 'none',
                }}
                color='info.main'
              >
                {t('end_user_license_agreement_0')}
              </Typography>
            }
          />
        </ListItemButton>
        {isShepherdGuideAble && isLaptopL && (
          <ListItemButton onClick={handleGuide} sx={{ p: 0, pl: '20px' }}>
            <ListItemIcon sx={{ minWidth: '20px' }}>
              <GuideIcon style={{ paddingRight: '4px', width: '20px', height: '20px' }} />
            </ListItemIcon>

            <ListItemText
              primary={t('interactiveGuide')}
              sx={{
                span: { fontSize: 12 },
                // textTransform: 'uppercase',
                color: 'info.main',
              }}
            />
          </ListItemButton>
        )}

        <ShepherdBlock id='sidebar-help'>
          <ListItemButton onClick={handleHelp} sx={{ p: 0, pl: '20px' }}>
            <ListItemIcon sx={{ minWidth: '20px' }}>
              <HelpIcon />
            </ListItemIcon>

            <ListItemText
              primary={t('help')}
              sx={{
                span: { fontSize: 12 },
                // textTransform: 'uppercase',
                color: 'info.main',
              }}
            />
          </ListItemButton>
        </ShepherdBlock>
        <ListItemButton onClick={handleExit} sx={{ p: 0, pl: '20px' }}>
          <ListItemIcon sx={{ minWidth: '20px' }}>
            <ExitIcon />
          </ListItemIcon>

          <ListItemText
            primary={t('exit')}
            sx={{
              span: { fontSize: 12 },
              // textTransform: 'uppercase',
              color: 'info.main',
            }}
          />
        </ListItemButton>

        <Typography
          align='center'
          sx={{
            span: { fontSize: 12 },
            // textTransform: 'uppercase',
            color: 'info.main',
            my: '18px',
          }}
        >
          {`${t('version')} ${process.env.REACT_APP_VERSION}`}
        </Typography>
      </List>
    </>
  );
};
