import { Button, Checkbox, FormControlLabel, FormGroup, Grid, MenuItem, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TIMER_UNITS } from 'shared/constants';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { setEDCStateToolBar } from 'shared/slices';
import { Input, Modal, Select } from 'shared/ui';

export const EDCTimer = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { showTimer } = useAppSelector((st) => st.edcState.edcToolBar);

  const toggle = () => dispatch(setEDCStateToolBar({ showTimer: !showTimer }));

  const [isMinimum, setIsMinimum] = useState(false);
  const onChangeIsMinimum = (e: React.ChangeEvent<HTMLInputElement>) => setIsMinimum(e.target.checked);

  return (
    <Modal
      maxWidth="xs"
      title="Timer"
      open={showTimer}
      onClose={toggle}
      hideBackdrop
      disableEnforceFocus
      PaperProps={{
        sx: { position: 'absolute', top: 10, left: 10, m: 0 },
        style: { pointerEvents: 'auto' },
      }}
    >
      <Grid container rowGap={1}>
        <Grid item xs={12}>
          <Input label={`${t('Start')}`} type="number" />
        </Grid>
        <Grid item xs={12}>
          <Input label={`${t('End')}`} type="number" />
        </Grid>

        <Select label={`${t('Time Unit')}`}>
          {TIMER_UNITS.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
        <Grid item xs={12}>
          <Input label={`${t('Interval')}`} type="number" />
        </Grid>
        <Select label={`${t('Interval Unit')}`}>
          {TIMER_UNITS.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>

        <Grid item xs={12}>
          <Input label={`${t('Delay (sec)')}`} type="number" />
        </Grid>

        <Grid container item xs={12}>
          <Grid item xs="auto">
            <FormControlLabel control={<Checkbox defaultChecked />} label={`${t('Set Limit')}`} />
          </Grid>
          <Grid item xs="auto">
            <FormControlLabel
              control={<Checkbox checked={isMinimum} onChange={onChangeIsMinimum} />}
              label={`${t('Set Minimum')}`}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Input disabled={!isMinimum} label={`${t('Minimum (mm/h)')}`} type="number" />
        </Grid>

        <Grid item xs={12}>
          <Typography align="center" variant="h6">
            0000:00:00
          </Typography>
        </Grid>

        <Grid container mt={1} columnSpacing={2} rowGap={1}>
          <Grid item sm xs={12}>
            <Button color="primary" fullWidth variant="outlined">
              {t('eject')}
            </Button>
          </Grid>
          <Grid item sm xs={12}>
            <Button color="primary" fullWidth variant="outlined">
              {t('pause')}
            </Button>
          </Grid>
          <Grid item sm xs={12}>
            <Button color="primary" fullWidth variant="outlined">
              {t('stop')}
            </Button>
          </Grid>
          <Grid item sm xs={12}>
            <Button color="primary" fullWidth variant="outlined">
              {t('start')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};
