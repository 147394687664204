// import { Shadow } from 'calculation/edc/models/Shadow';
import { METRIC_DEFAULTS } from 'shared/constants';
import { Unit } from 'shared/models';
import { Math_round } from '../../../mathRound';
import { ConvertUnit } from '../../../unitConverter';
import { Average } from '../../models/Average';
import { cbEDCEmitter } from '../../models/cbEDCEmitter';
import { EDGE } from '../../models/EDGE';
import { Emitter } from '../../models/Emitter';
import { LATERAL } from '../../models/LATERAL';
import { Mams } from '../../models/Mams';
import { NomAvg } from '../../models/NomAvg';
import { SetFiled } from '../../models/SetFiled';
import { Shape } from '../../models/Shape';
import { SINGLE } from '../../models/SINGLE';
import { TRIANGULAR } from '../../models/TRIANGULAR';
import { CalcBILATERAL } from '../CalcBILATERAL';
import { CalcEDGE } from '../CalcEDGE';
import { CalcLATERAL } from '../CalcLATERAL';
import { CalcRECTANGULAR } from '../CalcRECTANGULAR';
import { CalcSINGLE } from '../CalcSINGLE';
import { CalcTRIANGULAR } from '../CalcTRIANGULAR';
import { DoRange } from '../DoRange';
import { GetAvDev } from '../GetAvDev';
import { GetAverage } from '../GetAverage';
import { GetCU } from '../GetCU';
import { GetDU } from '../GetDU';
import { GetMams } from '../GetMams';
import { GetMaxMinEmissions } from '../getMaxMinEmissions';
import { GetNomAvg } from '../GetNomAvg';
import { SelectionGraph } from '../Graph/SelectionGraph';
import { SetFieldPic } from '../SetFieldPic';
import { SetFieldRainAtSize } from '../SetFieldRainAtSize';
import { SetLabels } from '../SetLabels';
// import { SetPOSITION } from '../SetPOSITION';
// import { SetShadowField } from '../SetShadow/SetShadowField';
import { StoreFieldValues } from '../StoreFieldValues';

export function Calculate(
  Placement: number,
  cbEDCEmitter: cbEDCEmitter,
  EdgeType: number,
  _Shadows: boolean,
  unitSettings: Unit,
  txtLateralSpacing: string,
  txtEmitterSpacing: string,
  txtBedWidth: number,
  lblBedWidthUnit: string,
  dEDGEPOSITIONX: number,
  dEDGEPOSITIONY: number,
  BILATERAL_TRIANGULAR_LAYOUT: number,
  TRIANGULAR_Y_LAYOUT: number,
  LATERAL_LAYOUT: number,
  lastplacement: number,
  range: boolean,
  Graph: boolean,
  mouseClickPositionY1: number,
  mouseClickPositionY2: number,
) {
  if (!cbEDCEmitter.SelectedItem) {
    return;
  }
  let TimeNow = 60;
  let Laterals = -1;
  let RECTANGULAR_LAYOUT = 0;
  let X_EDGE_LAYOUT = 2;
  let Y_EDGE_LAYOUT = 1;
  let CORNER_EDGE_LAYOUT = 3;
  let EDGE_LAYOUT = 5;
  let maxrange = 0;
  let FIELDRES = 40;
  let xlength = 0;
  let Ylength = 0;
  let Xint = 0;
  let Yint = 0;
  let SLOPE = 0;
  let Kx = 0;
  let Ky = 0;
  let TUBENUM = 51;
  let MinChange = false;
  let FieldRainAt = null;
  let tempdrainat = null;
  let erecord = cbEDCEmitter.SelectedItem as Emitter;
  let min = 100000;
  let max = 0;
  let EffectivePlaces = 0;
  let EdgeOfFieldX = 0;
  let EdgeOfFieldY = 0;
  let XM = 0;
  let YM = 0;
  let fieldintervalX;
  let fieldintervalY;
  let CalcREC: Shape;
  let RectangularRes;
  let CalcTRIAN: TRIANGULAR;
  let resTRIANGULAR;
  let calcLATERAl: LATERAL;
  let resLATERAL;
  let CalcSIN: SINGLE;
  let SingleResult;
  let CalcBIL: Shape;
  let resBilateral;
  // let resultSetPOSITION;
  let GetMs: Mams;
  let ResultMams;
  let CalcED: EDGE;
  let ResultCalcEdge;
  let AverageType: Average;
  let NomAvgType: NomAvg;
  let TheMin;
  let TheMax;
  let dRange;
  let KEdgeY;
  let KEdgeX;
  let XMAMTEROT = 0;
  let YMAMTEROT = 0;
  let dXPOSITION1 = 0;
  let dYPOSITION1 = 0;
  let SINGLE_LAYOUT = 3;
  let BILATERAL_LAYOUT = 4;
  let MOVING_LATERAL_LAYOUT = 9;

  // let StoreX = parseFloat(txtLateralSpacing);
  // let StoreY = parseFloat(txtEmitterSpacing);
  let dXPOSITION = parseFloat(txtLateralSpacing);
  if (isNaN(dXPOSITION)) {
    return;
  }
  let dYPOSITION = parseFloat(txtEmitterSpacing);
  if (isNaN(dYPOSITION)) {
    return;
  }

  dXPOSITION = ConvertUnit(
    parseFloat(txtLateralSpacing),
    unitSettings.lateralSpacing,
    METRIC_DEFAULTS.LateralSpacing,
    null,
  );
  // dYPOSITION = parseFloat(txtEmitterSpacing);
  dYPOSITION = ConvertUnit(
    parseFloat(txtEmitterSpacing),
    unitSettings.emitterSpacing,
    METRIC_DEFAULTS.EmitterSpacing,
    null,
  );

  // dXPOSITION = parseFloat(txtLateralSpacing);
  // dYPOSITION = parseFloat(txtEmitterSpacing);
  if (erecord == null) {
    return;
  }
  let MinMaxResult = GetMaxMinEmissions(erecord, EffectivePlaces, TUBENUM, max, min);
  max = MinMaxResult.max;
  //8.25
  EffectivePlaces = MinMaxResult.EffectivePlaces;

  let fieldpic = SetFieldPic(dXPOSITION, dYPOSITION);
  let SetField: SetFiled = {
    Xint: Xint,
    Yint: Yint,
    SLOPE: SLOPE,
    xlength: xlength,
    Ylength: Ylength,
    dXPOSITION: dXPOSITION,
    dYPOSITION: dYPOSITION,
    Placement: Placement,
    RECTANGULAR_LAYOUT: RECTANGULAR_LAYOUT,
    TRIANGULAR_Y_LAYOUT: TRIANGULAR_Y_LAYOUT,
    LATERAL_LAYOUT: LATERAL_LAYOUT,
    MOVING_LATERAL_LAYOUT: MOVING_LATERAL_LAYOUT,
    SINGLE_LAYOUT: SINGLE_LAYOUT,
    BILATERAL_LAYOUT: BILATERAL_LAYOUT,
    BILATERAL_TRIANGULAR_LAYOUT: BILATERAL_TRIANGULAR_LAYOUT,
    XMAMTEROT: XMAMTEROT,
    YMAMTEROT: YMAMTEROT,
  };
  let Arraysrelut = SetFieldRainAtSize(SetField);
  FieldRainAt = Arraysrelut?.FieldRainAt;
  tempdrainat = Arraysrelut?.tempdrainat;
  fieldintervalX = Arraysrelut?.fieldintervalX;
  fieldintervalY = Arraysrelut?.fieldintervalY;
  XMAMTEROT = Arraysrelut?.XMAMTEROT ?? 0;
  YMAMTEROT = Arraysrelut?.YMAMTEROT ?? 0;
  Xint = Arraysrelut?.Xint ?? 0;
  Yint = Arraysrelut?.Yint ?? 0;

  let txtSprinklerCount = String(10000 / dXPOSITION / dYPOSITION);
  dRange = EffectivePlaces * erecord.Basedis;
  Kx = parseInt(String(dRange / dXPOSITION));
  Ky = parseInt(String(dRange / dYPOSITION));
  if (Laterals > 0) {
    Ky = Laterals;
    Kx = Laterals;
  }
  switch (Placement) {
    case RECTANGULAR_LAYOUT:
      CalcREC = {
        Yint: Yint,
        Xint: Xint,
        Kx: Kx,
        Ky: Ky,
        min: min,
        max: max,
        MinChange: MinChange,
        XMAMTEROT: XMAMTEROT,
        YMAMTEROT: YMAMTEROT,
        fieldintervalX: fieldintervalX,
        fieldintervalY: fieldintervalY,
        dRange: dRange,
        cbEDCEmitter: cbEDCEmitter,
        TUBENUM: TUBENUM,
        FieldRainAt: FieldRainAt,
      };

      RectangularRes = CalcRECTANGULAR(CalcREC);
      FieldRainAt = RectangularRes.FieldRainAt;
      min = RectangularRes.min;
      max = RectangularRes.max;
      MinChange = RectangularRes.MinChange;
      break;
    case TRIANGULAR_Y_LAYOUT:
      CalcTRIAN = {
        CommonTypes: {
          Yint: Yint,
          Xint: Xint,
          Kx: Kx,
          Ky: Ky,
          min: min,
          max: max,
          MinChange: MinChange,
          XMAMTEROT: XMAMTEROT,
          YMAMTEROT: YMAMTEROT,
          fieldintervalX: fieldintervalX,
          fieldintervalY: fieldintervalY,
          dRange: dRange,
          cbEDCEmitter: cbEDCEmitter,
          TUBENUM: TUBENUM,
          FieldRainAt: FieldRainAt,
        },
        SLOPE: Arraysrelut?.SLOPE!,
        tempdrainat: tempdrainat,
      };
      resTRIANGULAR = CalcTRIANGULAR(CalcTRIAN);
      FieldRainAt = resTRIANGULAR.FieldRainAt;
      tempdrainat = resTRIANGULAR.tempdrainat;
      min = resTRIANGULAR.min;
      max = resTRIANGULAR.max;
      MinChange = resTRIANGULAR.MinChange;
      break;
    case LATERAL_LAYOUT:
      calcLATERAl = {
        Common: {
          Yint: Yint,
          Xint: Xint,
          Kx: Kx,
          Ky: Ky,
          min: min,
          max: max,
          MinChange: MinChange,
          XMAMTEROT: XMAMTEROT,
          YMAMTEROT: YMAMTEROT,
          fieldintervalX: fieldintervalX,
          fieldintervalY: fieldintervalY,
          dRange: dRange,
          cbEDCEmitter: cbEDCEmitter,
          TUBENUM: TUBENUM,
          FieldRainAt: FieldRainAt,
        },
        txtBedWidth,
        unitSettings,
        lblBedWidthUnit,
        dXPOSITION: dXPOSITION,
        tempdrainat: tempdrainat,
      };

      resLATERAL = CalcLATERAL(calcLATERAl);

      FieldRainAt = resLATERAL.FieldRainAt;
      tempdrainat = resLATERAL.tempdrainat;
      min = resLATERAL.min;
      max = resLATERAL.max;
      MinChange = resLATERAL.MinChange;
      break;
    case SINGLE_LAYOUT:
      CalcSIN = {
        xlength: xlength,
        Ylength: Ylength,
        fieldintervalX: fieldintervalX,
        fieldintervalY: fieldintervalY,
        FIELDRES: FIELDRES,
        min: min,
        max: max,
        cbEDCEmitter: cbEDCEmitter,
        MinChange: MinChange,
        maxrange: maxrange,
        TUBENUM: TUBENUM,
        FieldRainAt: FieldRainAt,
      };
      SingleResult = CalcSINGLE(CalcSIN);
      FieldRainAt = SingleResult.FieldRainAt;
      dRange = SingleResult.dRange;
      min = SingleResult.min;
      max = SingleResult.max;
      break;

    case BILATERAL_LAYOUT:
      CalcBIL = {
        Yint: Xint,
        Xint: Yint,
        Kx: Kx,
        Ky: Ky,
        min: min,
        max: max,
        MinChange: MinChange,
        XMAMTEROT: XMAMTEROT,
        YMAMTEROT: YMAMTEROT,
        fieldintervalX: fieldintervalX,
        fieldintervalY: fieldintervalY,
        dRange: dRange,
        cbEDCEmitter: cbEDCEmitter,
        TUBENUM: TUBENUM,
        FieldRainAt: FieldRainAt,
      };
      resBilateral = CalcBILATERAL(CalcBIL);
      FieldRainAt = resBilateral.FieldRainAt;
      max = resBilateral.max;
      min = resBilateral.min;
      break;
    case EDGE_LAYOUT:
      if (dXPOSITION1 == 0) {
        dXPOSITION1 = dXPOSITION;
        dYPOSITION1 = dYPOSITION;
      }
      GetMs = {
        Xint: Xint,
        Yint: Yint,
        SLOPE: SLOPE,
        dXPOSITION: dXPOSITION,
        dYPOSITION: dYPOSITION,
        FIELDRES: FIELDRES,
        lastplacement: lastplacement,
        dXPOSITION1: dXPOSITION1,
        dYPOSITION1: dYPOSITION1,
        XMAMTEROT: XMAMTEROT,
        YMAMTEROT: YMAMTEROT,
        TRIANGULAR_Y_LAYOUT: TRIANGULAR_Y_LAYOUT,
        BILATERAL_TRIANGULAR_LAYOUT: BILATERAL_TRIANGULAR_LAYOUT,
      };
      ResultMams = GetMams(GetMs);
      XMAMTEROT = ResultMams.XMAMTEROT;
      YMAMTEROT = ResultMams.YMAMTEROT;
      Xint = Math.floor(ResultMams.Xint);
      Yint = Math.floor(ResultMams.Yint);

      FieldRainAt = Array(Xint + 1);
      for (let k = 0; k < FieldRainAt.length; k++) {
        FieldRainAt[k] = Array(Yint + 1);
      }

      fieldintervalX = dXPOSITION / Xint;

      fieldintervalY = dYPOSITION / Yint;
      //XM=16
      //YM=8
      switch (EdgeType) {
        case X_EDGE_LAYOUT:
          XM = Math.round((parseFloat(String(Xint)) * dEDGEPOSITIONX) / dXPOSITION);
          YM = Yint;
          break;
        case Y_EDGE_LAYOUT:
          XM = Xint;
          YM = Math.round((parseFloat(String(Yint)) * dEDGEPOSITIONY) / dYPOSITION);
          break;
        case CORNER_EDGE_LAYOUT:
          XM = Math.round((parseFloat(String(Xint)) * dEDGEPOSITIONX) / dXPOSITION);
          YM = Math.round((parseFloat(String(Yint)) * dEDGEPOSITIONY) / dYPOSITION);
          break;
      }

      Kx = parseInt(String(dRange / dXPOSITION1));
      Ky = parseInt(String(dRange / dYPOSITION1));

      if (lastplacement == BILATERAL_LAYOUT || lastplacement == BILATERAL_TRIANGULAR_LAYOUT) {
        Kx = 0;
      }
      if (EdgeType == X_EDGE_LAYOUT) {
        KEdgeY = Ky + 1;
        KEdgeX = 0;
      } else if (EdgeType == Y_EDGE_LAYOUT) {
        KEdgeY = 0;
        KEdgeX = Kx + 1;
      } else if (EdgeType == CORNER_EDGE_LAYOUT) {
        KEdgeY = 0;
        KEdgeX = 0;
      }
      CalcED = {
        Common: {
          Yint: Yint,
          Xint: Xint,
          Kx: Kx,
          Ky: Ky,
          min: min,
          max: max,
          MinChange: MinChange,
          XMAMTEROT: XMAMTEROT,
          YMAMTEROT: parseInt(YMAMTEROT.toString()),
          fieldintervalX: fieldintervalX,
          fieldintervalY: fieldintervalY,
          dRange: dRange,
          cbEDCEmitter: cbEDCEmitter,
          TUBENUM: TUBENUM,
          FieldRainAt: FieldRainAt,
        },
        EgdeT: {
          KEdgeX: KEdgeX,
          KEdgeY: KEdgeY,
          XM: XM,
          YM: YM,
          EdgeType: EdgeType,
          lastplacement: lastplacement,
          RECTANGULAR_LAYOUT: RECTANGULAR_LAYOUT,
          BILATERAL_LAYOUT: BILATERAL_LAYOUT,
          Y_EDGE_LAYOUT: Y_EDGE_LAYOUT,
          X_EDGE_LAYOUT: X_EDGE_LAYOUT,
          BILATERAL_TRIANGULAR_LAYOUT: BILATERAL_TRIANGULAR_LAYOUT,
          TRIANGULAR_Y_LAYOUT: TRIANGULAR_Y_LAYOUT,
        },
        tempdrainat: tempdrainat,
      };
      ResultCalcEdge = CalcEDGE(CalcED);
      FieldRainAt = ResultCalcEdge.FieldRainAt;
      max = ResultCalcEdge.max;
      min = ResultCalcEdge.min;
      MinChange = ResultCalcEdge.MinChange;
      tempdrainat = ResultCalcEdge.tempdrainat;

      break;
    default:
      break;
  }

  if (!MinChange) {
    min = 0;
  }

  let StoreValues = StoreFieldValues(
    FieldRainAt,
    Placement,
    TRIANGULAR_Y_LAYOUT,
    BILATERAL_TRIANGULAR_LAYOUT,
    LATERAL_LAYOUT,
    tempdrainat,
  );

  let PointsEffected = 0;
  let LostQuantity = 0;

  // const setShadow: Shadow = {
  //   Kx: Kx,
  //   Ky: Ky,
  //   min: min,
  //   max: max,
  //   KEdgeY: KEdgeY,
  //   KEdgeX: KEdgeX,
  //   growthDistanceFrm: growthDistanceFrm,
  //   dXPOSITION: dXPOSITION,
  //   dYPOSITION: dYPOSITION,
  //   FieldRainAt: FieldRainAt,
  //   Placement: Placement,
  //   lastplacement: lastplacement,
  //   tempdrainat: tempdrainat,
  //   dRange: dRange,
  //   EDGE_LAYOUT: EDGE_LAYOUT,
  //   dEDGEPOSITIONX: dEDGEPOSITIONX,
  //   fieldpic: fieldpic,
  //   PlantsField: PlantsField,
  //   dXPOSITION1: dXPOSITION1,
  //   dYPOSITION1: dYPOSITION1,
  //   // EmitterCY: EmitterCY,
  //   // fieldintervalX: fieldintervalX,
  //   // fieldintervalY: fieldintervalY,
  //   // XMAMTEROT: XMAMTEROT,
  //   // YMAMTEROT: YMAMTEROT,
  //   // cbEDCEmitter: cbEDCEmitter,
  //   // TUBENUM: TUBENUM,
  //   TRIANGULAR_Y_LAYOUT: TRIANGULAR_Y_LAYOUT,
  //   BILATERAL_TRIANGULAR_LAYOUT: BILATERAL_TRIANGULAR_LAYOUT,
  //   LATERAL_LAYOUT: LATERAL_LAYOUT,
  //   RECTANGULAR_LAYOUT: RECTANGULAR_LAYOUT,
  //   BILATERAL_LAYOUT: BILATERAL_LAYOUT,
  // };
  // if (_Shadows) {
  //   SetShadowField(setShadow);
  // } else {
  //   _Shadows = false;
  // }

  if (Placement == EDGE_LAYOUT) {
    if (EdgeType == X_EDGE_LAYOUT) {
      EdgeOfFieldX = XM;
      EdgeOfFieldY = Yint;
    } else if (EdgeType == Y_EDGE_LAYOUT) {
      EdgeOfFieldX = Xint;
      EdgeOfFieldY = YM;
    } else if (EdgeType == CORNER_EDGE_LAYOUT) {
      EdgeOfFieldX = XM;
      EdgeOfFieldY = YM;
    }
  } else {
    EdgeOfFieldX = XMAMTEROT;
    EdgeOfFieldY = YMAMTEROT;
  }

  TheMin = min;
  TheMax = Math_round(max, 2);

  let Average1 = 0;
  AverageType = {
    FieldEdgeX: EdgeOfFieldX,
    FieldEdgeY: EdgeOfFieldY,
    Average1: Average1,
    PointsEffectedas: PointsEffected,
    LostQuantity: LostQuantity,
    FieldRainAt: FieldRainAt,
    _Shadows: _Shadows,
    Placement: Placement,
    TRIANGULAR_Y_LAYOUT: TRIANGULAR_Y_LAYOUT,
    BILATERAL_TRIANGULAR_LAYOUT: BILATERAL_TRIANGULAR_LAYOUT,
    LATERAL_LAYOUT: LATERAL_LAYOUT,
  };
  let getAverage = GetAverage(AverageType);

  Average1 = getAverage.functionReturnValue;
  let ActuallCounter = getAverage.ActuallCounter;

  NomAvgType = {
    cbEDCEmitter: cbEDCEmitter,
    Placement: Placement,
    dRange: dRange,
    TimeNow: TimeNow,
    dXPOSITION: dXPOSITION,
    dYPOSITION: dYPOSITION,
    SINGLE_LAYOUT: SINGLE_LAYOUT,
  };

  let NomAvg = GetNomAvg(NomAvgType);

  let avGDEV = GetAvDev(Average1, EdgeOfFieldX, EdgeOfFieldY, FieldRainAt, ActuallCounter);

  let TheDu = GetDU(Average1, EdgeOfFieldX, EdgeOfFieldY, FieldRainAt, ActuallCounter) as string | number;

  let Silence = 0;
  let TheCu = GetCU(avGDEV, Average1, Silence);

  let resSetLabels = SetLabels(TheDu, min, unitSettings, max, NomAvg, TheCu);
  TheDu = resSetLabels.txtDu;
  TheCu = resSetLabels.txtCu;
  // debugger;
  // let txtMinimum = ConvertUnit(resSetLabels.mintxt, METRIC_DEFAULTS.AppnDepth, unitSettings.AppnRate, null);
  // let txtMaximum = ConvertUnit(resSetLabels.maxtxt, METRIC_DEFAULTS.AppnDepth, unitSettings.AppnRate, null);
  // let txtAverage = ConvertUnit(resSetLabels.txtAverage, METRIC_DEFAULTS.AppnRate, unitSettings.AppnRate, null);

  let txtMinimum = resSetLabels.mintxt;
  let txtMaximum = resSetLabels.maxtxt;
  let txtAverage = resSetLabels.txtAverage;
  let ranges;
  if (range) {
    ranges = DoRange(
      Placement,
      EdgeType,
      lastplacement,
      dRange,
      dXPOSITION,
      dYPOSITION,
      dXPOSITION1,
      dYPOSITION1,
      fieldpic,
    );
  }
  let GraphPoint;
  if (Graph) {
    GraphPoint = SelectionGraph(
      fieldpic,
      XMAMTEROT,
      YMAMTEROT,
      FieldRainAt,
      Placement,
      tempdrainat,

      mouseClickPositionY1,
      mouseClickPositionY2,
    );
  }
  let res = {
    fieldpic: fieldpic,
    FieldRainAt: FieldRainAt,
    tempdrainat: tempdrainat,
    TheMin: TheMin,
    TheMax: TheMax,
    txtAverage: txtAverage,
    txtMaximum: txtMaximum,
    txtMinimum: txtMinimum,
    TheDu: TheDu,
    TheCu: TheCu,
    txtSprinklerCount: txtSprinklerCount,
    dXPOSITION: dXPOSITION,
    dYPOSITION: dYPOSITION,
    ranges: ranges,
    GraphPoint: GraphPoint,
    StoreValues: StoreValues,
  };

  return res;
}
