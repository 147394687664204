// import { PowerOutputForm } from '../types';

import { Math_round } from 'shared/lib/calculation/mathRound';
import { Unit } from 'shared/models';

type Values = {
  voltage: number;
  current: number;
  powerFactor: number;
  efficiency: number;
};

export const calculatePowerUnit = (totalFlowUnit: string) => {
  if (totalFlowUnit === 'gph' || totalFlowUnit === 'gpm') {
    return '(Hp)';
  } else {
    return '(Kw)';
  }
};

export const calculatePowerOutput = (values: Values, units: Unit) => {
  let powerConv;

  if (units.totalFlow === 'gph' || units.totalFlow === 'gpm') {
    powerConv = 1.0 / 1000.0;
  } else {
    powerConv = 1.341 / 1000.0;
  }

  const dNumPhase = 3.0;
  const power =
    (((values.efficiency / 100.0) * values.powerFactor) / 100.0) *
    values.voltage *
    values.current *
    Math.sqrt(dNumPhase) *
    powerConv;

  return { power: Math_round(power, 2) };
};
