import { METRIC_DEFAULTS } from 'shared/constants';
import { Unit } from 'shared/models';
import { Math_round } from '../mathRound';
import { ConvertUnit } from '../unitConverter';
import { Irrigation } from './irrigation';

export function irrigationCalculate(
  inputData: Partial<Irrigation>,
  unitSettings: any,
  // showReport: boolean,
) {
  const validationResult = inputDataValidation(inputData);
  // eslint-disable-next-line no-prototype-builtins
  if (validationResult!.hasOwnProperty('error')) {
    return validationResult;
  }
  let result = {};
  //  var reportResult = {};
  let emitterFlow = calcEmitterFlow(inputData.ZoneEmitterFlow!, unitSettings);
  let emitterSpacing = calEmitterSpacing(inputData.ZoneEmitterSpacing!, unitSettings);
  let lateralSpacing = calcLateralSpacing(inputData.ZoneLateralSpacing!, unitSettings);
  let avgPeakConsumptionRate = calcAvgPeakConsumptionRate(inputData.AvgPeakConsumption!, unitSettings);
  let lateralPerRow = inputData.ZoneLateralsPerRow;
  let avgIrrigationRate = 0;
  let maxIrrigationTime = inputData.MaxIrrigationTimePerDay;
  let irrigationArea = calcIrrigationArea(inputData.IrrigationArea!, unitSettings);
  let sourceFlowRate = calcSourceFlowRate(inputData.SourceFlowRate!, unitSettings);

  avgIrrigationRate = (emitterFlow / emitterSpacing / lateralSpacing) * lateralPerRow!;
  let timeOfIrrigation = avgPeakConsumptionRate / avgIrrigationRate;
  let operationPerCycle = Number.parseInt(Math.floor(maxIrrigationTime! / timeOfIrrigation!).toString());

  let avgDischarge = calcZoneAvgDischarge((irrigationArea * 10 * avgIrrigationRate) / operationPerCycle, unitSettings);

  let totalTime = timeOfIrrigation * operationPerCycle;

  if (operationPerCycle <= 0) {
    return { data: inputData, error: 'msgRetry' };
  }

  let maxIrrigationTimePerCycle = timeOfIrrigation * operationPerCycle;

  result = {
    ...result,
    TotalIrrigationTime: Math_round(totalTime, 3),
  };
  if (maxIrrigationTimePerCycle > maxIrrigationTime!) {
    return { data: inputData, error: 'msgTimeExceeds' };
  }

  result = {
    ...result,
    ApplicationRate: calcApplicationRate(avgIrrigationRate, unitSettings),
  };
  result = {
    ...result,
    ZoneOpTime: Math_round(timeOfIrrigation, 3),
  };
  result = {
    ...result,
    NumberOfOperations: operationPerCycle,
  };
  result = {
    ...result,
    ZoneAvgDischarge: avgDischarge,
  };
  result = {
    ...result,
    TotalIrrigationTime: Math_round(maxIrrigationTimePerCycle, 3),
  };
  let usage = (avgDischarge / sourceFlowRate) * 100;

  result = {
    ...result,
    WellUtilization: Math_round(usage, 3),
  };

  // if (showReport) {
  //   let cat1 = '';
  //   let cat2 = '';
  //   let cat3 = '';
  //   cat1 = inputData.MainlinePipe1!.toString();
  //   cat2 = inputData.MainlinePipe1!.toString();
  //   cat3 = inputData.MainlinePipe1!.toString();
  //   let vel1 = 0;
  //   let vel2 = 0;
  //   let vel3 = 0;
  //   vel1 = velocity1Calc(inputData.MainlineVelocity1!, unitSettings);
  //   vel2 = velocity1Calc(inputData.MainlineVelocity2!, unitSettings);
  //   vel3 = velocity1Calc(inputData.MainlineVelocity3!, unitSettings);

  //   reportResult = { ...reportResult, cat1: cat1 };
  //   reportResult = { ...reportResult, cat2: cat2 };
  //   reportResult = { ...reportResult, cat3: cat3 };
  //   reportResult = { ...reportResult, length1: Length1 };
  //   reportResult = { ...reportResult, length2: Length2 };
  //   reportResult = { ...reportResult, length3: Length3 };
  //   reportResult = { ...reportResult, dia1: Dia1 };
  //   reportResult = { ...reportResult, dia2: Dia2 };
  //   reportResult = { ...reportResult, dia3: Dia3 };
  //   reportResult = { ...reportResult, velocity1: vel1 };
  //   reportResult = { ...reportResult, velocity2: vel2 };
  //   reportResult = { ...reportResult, velocity3: vel3 };
  //   reportResult = { ...reportResult, MainlineFlow: MainlineFlow };
  //   reportResult = {
  //     ...reportResult,
  //     maxVelocity: maxVelocityCalc(inputData.MainlineMaxVelocity!, unitSettings),
  //   };
  //   reportResult = { ...reportResult, PipesHeadloss: PipesHeadloss };

  //   result = { ...result, reportResult: reportResult };
  // }
  return result;
}

export function calcEmitterFlow(emitterFlow: number, unitSettings: Unit) {
  let emitterFlowRes = ConvertUnit(emitterFlow, unitSettings.flow, METRIC_DEFAULTS.Flow, 'Flow');
  return Math_round(emitterFlowRes, 3);
}

export function calEmitterSpacing(emitterSpacing: number, unitSettings: Unit) {
  let emitterSpacingRes = ConvertUnit(emitterSpacing, unitSettings.totalFlow, METRIC_DEFAULTS.TotalFlow, null);
  return Math_round(emitterSpacingRes, 3);
}

export function calcLateralSpacing(valvePressure: number, unitSettings: Unit) {
  let valvePressureRes = ConvertUnit(valvePressure, unitSettings.lateralSpacing, METRIC_DEFAULTS.LateralSpacing, null);
  return Math_round(valvePressureRes, 3);
}

export function calcApplicationRate(avgIrrigationRate: number, unitSettings: Unit) {
  let avgIrrigationRateRes = ConvertUnit(avgIrrigationRate, METRIC_DEFAULTS.AppnRate, unitSettings.appnRate, null);
  return Math_round(avgIrrigationRateRes, 3);
}

export function calcAvgPeakConsumptionRate(avgPeakConsumptionRate: number, unitSettings: Unit) {
  let avgPeakConsumptionRateRes = ConvertUnit(
    avgPeakConsumptionRate,
    unitSettings.pressure,
    METRIC_DEFAULTS.Pressure,
    null,
  );
  return Math_round(avgPeakConsumptionRateRes, 3);
}

export function calcIrrigationArea(irrigationArea: number, unitSettings: Unit) {
  let irrigationAreaRes = ConvertUnit(irrigationArea, unitSettings.appnRate, METRIC_DEFAULTS.AppnRate, null);
  return Math_round(irrigationAreaRes, 3);
}

export function calcSourceFlowRate(sourceFlowRate: number, unitSettings: Unit) {
  let sourceFlowRateRes = ConvertUnit(sourceFlowRate, unitSettings.totalFlow, METRIC_DEFAULTS.TotalFlow, null);
  return Math_round(sourceFlowRateRes, 3);
}

export function calcZoneAvgDischarge(avgDischarge: number, unitSettings: Unit) {
  let avgDischargeRes = ConvertUnit(avgDischarge, METRIC_DEFAULTS.TotalFlow, unitSettings.totalFlow, null);
  return Math_round(avgDischargeRes, 3);
}

function inputDataValidation(inputData: Partial<Irrigation>) {
  if (inputData.ZoneEmitterSpacing! <= 0) {
    return { data: inputData, error: 'msgInvalidEmitterSpacing' };
  }
  if (inputData.ZoneLateralSpacing! <= 0) {
    return { data: inputData, error: 'msgInvalidLateralSpacing' };
  }
  if (inputData.ZoneLateralsPerRow! <= 0) {
    return { data: inputData, error: 'InvalidLateralsPerRow' };
  }
  if (inputData.IrrigationArea! <= 0) {
    return { data: inputData, error: 'msgInvalidArea' };
  }
  if (inputData.SourceFlowRate! <= 0) {
    return { data: inputData, error: 'msgInvalidSourceFlow' };
  }
  if (inputData.AvgPeakConsumption! <= 0) {
    return { data: inputData, error: 'msgInvalidPeakRate' };
  }
  return inputData;
}
