export function SetFieldPic(dXPOSITION: number, dYPOSITION: number) {
  const fieldpic = {
    width: 0,
    height: 0,
  };

  if (dXPOSITION == dYPOSITION) {
    fieldpic.width = 420;
    fieldpic.height = 420;
  } else if (dXPOSITION > dYPOSITION) {
    fieldpic.width = 420;
    fieldpic.height = Math.trunc(420 * (dYPOSITION / dXPOSITION) + 0.6);
  } else if (dXPOSITION < dYPOSITION) {
    fieldpic.width = Math.trunc(420 * (dXPOSITION / dYPOSITION) + 0.6);
    fieldpic.height = 420;
  }
  const result = {
    width: fieldpic.width,
    height: fieldpic.height,
  };
  return result;
}
