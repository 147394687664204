import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { customAxios } from 'shared/api';
import { MasterGroup, MasterGroupsResponse } from 'shared/models';

interface UserState {
  loading: boolean;
  masterGroups: MasterGroup[];
}

const initialState: UserState = {
  loading: true,
  masterGroups: [],
};

export const getMasterGroupsThunk = createAsyncThunk('getMasterGroups', async (_, thunkAPI) => {
  try {
    const response = await customAxios.get<MasterGroupsResponse>('be/HtCatalog/GetMASTERGROUPS');
    return response.data;
  } catch (e) {
    if (e instanceof Error) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
});

const masterGroupsSlice = createSlice({
  name: 'masterGroups',
  initialState,
  reducers: {
    resetMasterGroups: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMasterGroupsThunk.pending.type, (state) => {
        state.loading = true;
      })
      .addCase(getMasterGroupsThunk.fulfilled.type, (state, action: PayloadAction<MasterGroupsResponse>) => {
        state.masterGroups = action.payload.data.rootResults;
        state.loading = false;
      })
      .addCase(getMasterGroupsThunk.rejected.type, (state) => {
        state.loading = false;
      });
  },
});

export const { resetMasterGroups } = masterGroupsSlice.actions;

export const masterGroupsReducer = masterGroupsSlice.reducer;
