import { SelectChangeEvent } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { customAxios } from 'shared/api';
import { useAppSelector } from 'shared/hooks';
import { CatalogItem, CatalogsResponse, GroupsResponse } from 'shared/models';

const ALL = 'All';

export const useEmitters = ({
  handleEmitterDataChange,
  region,
}: {
  region: string;
  handleEmitterDataChange: (
    value: Partial<{
      masterGroup: string;
      group: string;
      emitter: string;
    }>
  ) => void;
}) => {
  const { emitterGroups, emitters: defaultEmitters } = useAppSelector((state) => state.emitters);
  const { projectData } = useAppSelector((state) => state.projectData);

  const [groupTypes, setGroupTypes] = useState(emitterGroups);
  const [emitters, setEmitters] = useState<CatalogItem[]>(defaultEmitters);

  const [groupsLoading, setGroupsLoading] = useState(false);
  const [emittersLoading, setEmittersLoading] = useState(false);

  const [masterGroupId, setMasterGroupId] = useState(projectData.emitterMasterGroup);
  const [groupTypeId, setGroupTypeId] = useState(projectData.emitterGroup);

  const [nominalFlow, setNominalFlow] = useState<string | number>(ALL);
  const [spacing, setSpacing] = useState<string | number>(ALL);
  const [emitter, setEmitter] = useState<CatalogItem>(
    () => emitters.find((item) => item.catlog === projectData.emitterCatalog) as CatalogItem
  );

  useEffect(() => {
    handleEmitterDataChange({ emitter: emitter?.catlog });
  }, [emitter]);

  useEffect(() => {
    handleEmitterDataChange({ group: groupTypeId });
  }, [groupTypeId]);

  useEffect(() => {
    handleEmitterDataChange({ masterGroup: masterGroupId });
  }, [masterGroupId]);

  useEffect(() => {
    updateEmitters(groupTypeId, region);
  }, [region]);

  const updateEmitters = async (group: string, region: string) => {
    setEmittersLoading(true);

    try {
      const emittersResponse = await customAxios.get<CatalogsResponse>(
        'be/HtCatalog/GetCatalogPerDiaClassFlowSpacing',
        {
          params: {
            group,
            region,
          },
        }
      );

      setEmitters(emittersResponse.data.data);
      setEmitter(emittersResponse.data.data[0] ?? {});
    } catch (e) {
      setEmitters([]);
      setEmitter({} as CatalogItem);
      console.error(e);
    }

    setEmittersLoading(false);
  };

  const onMasterGroupChange = async (e: SelectChangeEvent<unknown>) => {
    setMasterGroupId(e.target.value as string);
    resetFilters();

    setGroupsLoading(true);
    const groupTypesResponse = await customAxios.get<GroupsResponse>('be/HtCatalog/GetGroupTypes', {
      params: { masterGroup: e.target.value },
    });

    setGroupTypes(groupTypesResponse.data.data.rootResults);
    setGroupTypeId(groupTypesResponse.data.data.rootResults[0]?.groups);
    setGroupsLoading(false);

    await updateEmitters(groupTypesResponse.data.data.rootResults[0]?.groups, region);
  };
  const onGroupTypeChange = async (e: SelectChangeEvent<unknown>) => {
    setGroupTypeId(e.target.value as string);
    resetFilters();

    await updateEmitters(e.target.value as string, region);
  };
  const onEmitterChange = (e: any, item: CatalogItem) => {
    setEmitter(item);
  };

  const onNominalFlowChange = (e: SelectChangeEvent<unknown>) => {
    setNominalFlow(e.target.value as string);
  };
  const onSpacingChange = (e: SelectChangeEvent<unknown>) => {
    setSpacing(e.target.value as string);
  };

  const nominalFlowFilter = (item: CatalogItem) => {
    // if (projectData.Region === 'USA' && item.AltQnom !== null) {
    //   return emitterNominalFlow ? item.AltQnom === emitterNominalFlow : true;
    // }
    return nominalFlow != 'All' ? item.qnom === nominalFlow : true;
  };
  const spacingFilter = (item: CatalogItem) => {
    // if (projectData.Region === 'USA' && item.AltSpacing !== null) {
    //   return emitterSpacing ? item.AltSpacing === emitterSpacing : true;
    // }
    return spacing != 'All' ? item.spacing === spacing : true;
  };

  const resetFilters = () => {
    setNominalFlow(ALL);
    setSpacing(ALL);
  };

  const emittersFiltered = useMemo(() => {
    const result = emitters.filter((item) => nominalFlowFilter(item) && spacingFilter(item));

    if (!result.find((item) => item.catlog === emitter?.catlog)) {
      setEmitter(result[0]);
    }

    return result;
  }, [nominalFlow, spacing, emitters]);

  const nominalFlows = useMemo(() => {
    setNominalFlow(ALL);
    setSpacing(ALL);

    return [ALL, ...new Set(emitters.map((item) => item.qnom))];
  }, [emitters]);

  const spacings = useMemo(() => {
    setSpacing(ALL);

    return [ALL, ...new Set(emitters.filter((item) => nominalFlowFilter(item)).map((item) => item.spacing))];
  }, [nominalFlow, emitters]);

  return {
    masterGroupId,
    onMasterGroupChange,
    onGroupTypeChange,
    nominalFlow,
    onNominalFlowChange,
    nominalFlows,
    spacing,
    onSpacingChange,
    spacings,
    emitter,
    onEmitterChange,
    emittersFiltered,
    groupTypes,
    groupsLoading,
    emittersLoading,
    groupTypeId,
  };
};
