import { TRIANGULAR } from '../models/TRIANGULAR';
import { v_at } from './v_at';

export function CalcTRIANGULAR(CalcTRIAN: TRIANGULAR) {
  let y = 0;
  let X = 0;
  let temp = 0;
  let i = 0;
  let j = 0;
  let FieldRainAt = CalcTRIAN.CommonTypes.FieldRainAt;
  let tempdrainat = CalcTRIAN.tempdrainat;

  if (CalcTRIAN.CommonTypes.Ky % 2 == 1) {
    CalcTRIAN.CommonTypes.Ky = CalcTRIAN.CommonTypes.Ky + 1;
  }
  for (y = 0; y <= CalcTRIAN.CommonTypes.Yint; y++) {
    for (X = 0; X <= CalcTRIAN.CommonTypes.Xint; X++) {
      if (X != 0) {
        const res = y / X;
        temp = parseInt(res.toString());
      } else {
        temp = -1;
      }
      CalcTRIAN.CommonTypes.FieldRainAt[X][y] = 0;
      for (i = -CalcTRIAN.CommonTypes.Kx; i <= CalcTRIAN.CommonTypes.Kx + 1; i++) {
        for (j = -CalcTRIAN.CommonTypes.Ky; j <= CalcTRIAN.CommonTypes.Ky + 1; j++) {
          if (i != 0 && j != 0) {
            const res =
              FieldRainAt[X][y] +
              Math.fround(
                v_at(
                  Math.floor(Math.abs(i) * CalcTRIAN.CommonTypes.XMAMTEROT - (i / Math.abs(i)) * X),
                  Math.floor(
                    Math.abs(
                      j * CalcTRIAN.CommonTypes.YMAMTEROT + (Math.abs(i % 2) * CalcTRIAN.CommonTypes.YMAMTEROT) / 2
                    ) -
                      (j / Math.abs(j)) * y
                  ),
                  CalcTRIAN.CommonTypes.fieldintervalX,
                  CalcTRIAN.CommonTypes.fieldintervalY,
                  CalcTRIAN.CommonTypes.dRange,
                  CalcTRIAN.CommonTypes.cbEDCEmitter,
                  CalcTRIAN.CommonTypes.TUBENUM
                )
              );

            FieldRainAt[X][y] = res;
          } else if (i == 0 && j != 0) {
            const res =
              FieldRainAt[X][y] +
              Math.fround(
                v_at(
                  X,
                  Math.floor(Math.abs(j) * CalcTRIAN.CommonTypes.YMAMTEROT - (j / Math.abs(j)) * y),
                  CalcTRIAN.CommonTypes.fieldintervalX,
                  CalcTRIAN.CommonTypes.fieldintervalY,
                  CalcTRIAN.CommonTypes.dRange,
                  CalcTRIAN.CommonTypes.cbEDCEmitter,
                  CalcTRIAN.CommonTypes.TUBENUM
                )
              );
            FieldRainAt[X][y] = res;
          } else if (i != 0 && j == 0) {
            const res =
              FieldRainAt[X][y] +
              Math.fround(
                v_at(
                  Math.floor(Math.abs(i) * CalcTRIAN.CommonTypes.XMAMTEROT - (i / Math.abs(i)) * X),
                  Math.floor(Math.abs((Math.abs(i % 2) * CalcTRIAN.CommonTypes.YMAMTEROT) / 2 - y)),
                  CalcTRIAN.CommonTypes.fieldintervalX,
                  CalcTRIAN.CommonTypes.fieldintervalY,
                  CalcTRIAN.CommonTypes.dRange,
                  CalcTRIAN.CommonTypes.cbEDCEmitter,
                  CalcTRIAN.CommonTypes.TUBENUM
                )
              );

            FieldRainAt[X][y] = res;
          } else if (i == 0 && j == 0) {
            const res =
              FieldRainAt[X][y] +
              Math.fround(
                v_at(
                  X,
                  y,
                  CalcTRIAN.CommonTypes.fieldintervalX,
                  CalcTRIAN.CommonTypes.fieldintervalY,
                  CalcTRIAN.CommonTypes.dRange,
                  CalcTRIAN.CommonTypes.cbEDCEmitter,
                  CalcTRIAN.CommonTypes.TUBENUM
                )
              );
            FieldRainAt[X][y] = res;
          }
        }
      }

      if (temp >= CalcTRIAN.SLOPE || X == 0) {
        temp = FieldRainAt[X][y];
        FieldRainAt[X][CalcTRIAN.CommonTypes.YMAMTEROT - y] = temp;
        tempdrainat[X][y] = temp;
        tempdrainat[X][CalcTRIAN.CommonTypes.YMAMTEROT - y] = temp;
        if (temp > CalcTRIAN.CommonTypes.max) {
          CalcTRIAN.CommonTypes.max = temp;
        } else if (temp < CalcTRIAN.CommonTypes.min) {
          CalcTRIAN.CommonTypes.min = temp;
          CalcTRIAN.CommonTypes.MinChange = true;
        }
      } else {
        temp = FieldRainAt[X][y];
        tempdrainat[X][y] = temp;
        tempdrainat[X][CalcTRIAN.CommonTypes.YMAMTEROT - y] = temp;
        FieldRainAt[X][y] = -1;
        FieldRainAt[X][CalcTRIAN.CommonTypes.YMAMTEROT - y] = -1;
      }
    }
  }
  let res = {
    FieldRainAt: FieldRainAt,
    tempdrainat: tempdrainat,
    max: CalcTRIAN.CommonTypes.max,
    min: CalcTRIAN.CommonTypes.min,
    MinChange: CalcTRIAN.CommonTypes.MinChange,
  };
  return res;
}
