import { Math_round } from '../../mathRound';
import { Emitter } from '../models/Emitter';

export function GetMaxMinEmissions(
  emitter: Emitter,
  EffectivePlaces: number,
  TUBENUM: number,
  min: number,
  max: number
) {
  let i = TUBENUM;
  while (i >= 0 && Math_round(Number(GetEmission(emitter, i)), 2) == 0) {
    i = i - 1;
  }

  if (i < 0) {
    // All emission values are 0, return early.
    return { min: 0, max: 0, EffectivePlaces: 0 };
  }

  EffectivePlaces = i;
  let TempMax = Number(GetEmission(emitter, i));
  let TempMin = Number(GetEmission(emitter, i));
  let snull = 0;

  while (i >= 0) {
    snull = Number(GetEmission(emitter, i));
    if (TempMax < snull) TempMax = snull;
    if (TempMin > snull && snull != 0) TempMin = snull;
    i = i - 1;
  }
  min = TempMin;
  max = TempMax;

  return {
    min: min,
    max: max,
    EffectivePlaces,
  };
}

export function GetEmission(emitter: Emitter, index: number) {
  const key = `place${index}` as keyof Emitter;
  return Number(emitter[key]) || 0;
}
