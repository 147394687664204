import { METRIC_DEFAULTS } from 'shared/constants';
import { Unit } from 'shared/models';
import { ConvertUnit } from '../../../unitConverter';
import { Color } from './ArrayColor';

export function DoLegend(stepd: number, TheMax: any, TheMin: any, unitSettings: Unit) {
  let STANDART_LEGEND = 0;
  let LegendIndex = 0;
  let EVEN_LEGEND = 1;
  let CUSTOM_LEGEND = 2;
  let FactorE = 1;
  let stepi = 0;
  let dValMin = 0;
  let dValMax = 0;
  let i = 0;
  let maximum = TheMax;
  let minimum = TheMin;
  let maximumi = maximum;
  if (maximumi == 0) maximumi = 1;
  let minimumi = minimum;
  let legend = [];

  switch (LegendIndex) {
    case STANDART_LEGEND:
      stepi = parseInt(String((maximumi - minimumi) / 8));
      if (stepi == 0) {
        if (maximumi - minimumi > 1) {
          stepi = 1;
          stepd = 1;
        } else {
          stepd = (maximum - minimum) / 8;
          legend[0] = { color: '#D2D228', gap: '0', percent: 0 };

          for (i = 1; i <= 8; i++) {
            dValMin = minimum + (i - 1) * stepd;
            dValMax = minimum + i * stepd;
            dValMin = ConvertUnit(dValMin, METRIC_DEFAULTS.AppnRate, unitSettings.appnRate, null);
            dValMax = ConvertUnit(dValMax, METRIC_DEFAULTS.AppnRate, unitSettings.appnRate, null);
            if (Color[i] != null) {
              legend.push({ color: Color[i], gap: dValMin + ' - ' + dValMax, percent: 0 });
            }
          }
          {
            let _val = minimumi + 8 * stepi;
            _val = ConvertUnit(_val, METRIC_DEFAULTS.AppnRate, unitSettings.appnRate, null);
            let _max = ConvertUnit(maximumi, METRIC_DEFAULTS.AppnRate, unitSettings.appnRate, null);

            if (minimumi + 8 * stepi >= maximumi) {
              legend.push({ color: '#009600', gap: _val + '+', percent: 0 });
            } else {
              legend.push({ color: '#009600', gap: _val + ' - ' + _max, percent: 0 });
            }
            stepi = parseInt(String(stepd + 0.5 * FactorE));
          }
          break;
        }
      } else {
        stepd = stepi;
      }
      if (stepi < 1 * FactorE) {
        stepi = parseInt(String(1 * FactorE));
      }
      legend[0] = { color: '#D2D228', gap: '0', percent: 0 };
      for (i = 1; i <= 8; i++) {
        dValMin = minimumi + (i - 1) * stepi;
        dValMax = minimumi + i * stepi;
        dValMin = ConvertUnit(dValMin, METRIC_DEFAULTS.AppnRate, unitSettings.appnRate, null);
        dValMax = ConvertUnit(dValMax, METRIC_DEFAULTS.AppnRate, unitSettings.appnRate, null);

        if (Color[i] != null) {
          legend.push({ color: Color[i], gap: dValMin + ' - ' + dValMax, percent: 0 });
        }
      }
    {
      let val = minimumi + 8 * stepi;
      val = ConvertUnit(val, METRIC_DEFAULTS.AppnRate, unitSettings.appnRate, null);
      let maxVal = ConvertUnit(maximumi, METRIC_DEFAULTS.AppnRate, unitSettings.appnRate, null);

      if (minimumi + 8 * stepi >= maximumi) {
        legend.push({ color: '#009600', gap: val + '+', percent: 0 });
      } else {
        legend.push({ color: '#009600', gap: val + ' - ' + maxVal, percent: 0 });
      }
    }

      break;

    case EVEN_LEGEND:
      stepd = (maximum - minimum) / 8;
      for (i = 1; i <= 8; i++) {
        dValMin = minimum + (i - 1) * stepd;
        dValMax = minimum + i * stepd;
        dValMin = ConvertUnit(dValMin, METRIC_DEFAULTS.AppnRate, unitSettings.appnRate, null);
        dValMax = ConvertUnit(dValMax, METRIC_DEFAULTS.AppnRate, unitSettings.appnRate, null);
        if (Color[i] != null) {
          legend.push({ color: Color[i], gap: dValMin + ' - ' + dValMax, percent: 0 });
        }
      }
    {
      let _val = minimumi + 8 * stepi;
      _val = ConvertUnit(_val, METRIC_DEFAULTS.AppnRate, unitSettings.appnRate, null);
      let _max = ConvertUnit(maximumi, METRIC_DEFAULTS.AppnRate, unitSettings.appnRate, null);

      if (minimumi + 8 * stepi >= maximumi) {
        legend.push({ color: '#009600', gap: _val + '+', percent: 0 });
      } else {
        legend.push({ color: '#009600', gap: _val + ' - ' + _max, percent: 0 });
      }
      stepi = parseInt(String(stepd + 0.5 * FactorE));
    }

      break;
    case CUSTOM_LEGEND:
      for (i = 1; i <= 8; i++) {
        dValMin = minimum + (i - 1) * stepd;
        dValMax = minimum + i * stepd;
        dValMin = ConvertUnit(dValMin, METRIC_DEFAULTS.AppnRate, unitSettings.appnRate, null);
        dValMax = ConvertUnit(dValMax, METRIC_DEFAULTS.AppnRate, unitSettings.appnRate, null);
        if (Color[i] != null) {
          legend.push({ color: Color[i], gap: dValMin + ' - ' + dValMax, percent: 0 });
        }
      }
    {
      let _val = minimumi + 8 * stepi;
      _val = ConvertUnit(_val, METRIC_DEFAULTS.AppnRate, unitSettings.appnRate, null);
      let _max = ConvertUnit(maximumi, METRIC_DEFAULTS.AppnRate, unitSettings.appnRate, null);

      if (minimumi + 8 * stepi >= maximumi) {
        legend.push(_val + '+');
      } else {
        legend.push(_val + ' - ' + _max);
      }
      stepi = parseInt(String(stepd + 0.5 * FactorE));
    }
      stepi = stepd + 0.5 * FactorE;
      break;
  }
  let result = {
    legend: legend,
    stepd: stepd,
  };
  return result;
}
