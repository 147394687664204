import { Button as MuiButton, ButtonProps as MuiButtonProps, useMediaQuery } from '@mui/material';
import React from 'react';
import { CalculateIcon, ShowReportIcon } from 'shared/assets';

interface Props extends MuiButtonProps {
  iconType?: 'default' | 'calculation' | 'show-report';
}

const ICONS_DICTIONARY = {
  default: null,
  calculation: <CalculateIcon width={20} height={20} />,
  'show-report': <ShowReportIcon width={20} height={20} />,
};

export const Button: React.FC<Props> = ({ children, iconType = 'default', ...props }) => {
  const isTablet = useMediaQuery('(max-width:850px)');

  const startIcon = ICONS_DICTIONARY[iconType];

  return (
    <MuiButton
      startIcon={startIcon}
      fullWidth
      variant="contained"
      {...props}
      sx={{
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.25)',
        borderRadius: '9px',
        fontSize: isTablet ? '14px' : '17px',
        lineHeight: isTablet ? '14px' : '17px',
        textTransform: 'capitalize',
        height: '39px',
        overflow: 'hidden',
        ...props.sx,
      }}
    >
      {children}
    </MuiButton>
  );
};
