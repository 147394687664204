import { TextField, TextFieldProps } from '@mui/material';
import { forwardRef } from 'react';

export const TechDataInput = forwardRef<unknown, TextFieldProps>((props, ref) => {
  return (
    <TextField
      {...props}
      type="number"
      title=""
      sx={{ '& fieldset': { border: 'none' }, width: 90 }}
      InputProps={{
        inputProps: {
          min: 0,
          style: {
            padding: '0',

            cursor: 'pointer',
            textAlign: 'right',
          },
        },
      }}
      inputRef={ref}
    />
  );
});
