import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { setEDCStateToolBar } from 'shared/slices';
import { Modal } from 'shared/ui';
import { BarChart, XAxis, YAxis, Tooltip, Legend, Bar } from 'recharts';
import { Box } from '@mui/system';

export const EDCSectionGraph = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    edcToolBar: { showSectionGraph },
    calcResult: { GraphPoint },
  } = useAppSelector((st) => st.edcState);

  const toggle = () => dispatch(setEDCStateToolBar({ showSectionGraph: !showSectionGraph }));

  const data = useMemo(() => {
    if (GraphPoint) {
      return GraphPoint.map((item, index) => ({
        name: index,
        series: item.toFixed(2),
      })).filter((item) => item);
    }
  }, [GraphPoint]);

  return (
    <Modal
      maxWidth="sm"
      title="Section Graph"
      open={showSectionGraph}
      onClose={toggle}
      PaperProps={{
        sx: { position: 'absolute', top: 10, left: 10, m: 0 },
        style: { pointerEvents: 'auto' },
      }}
      hideBackdrop
      disableEnforceFocus
      style={{ pointerEvents: 'none' }}
    >
      <Box sx={{ overflowX: 'auto', overflowY: 'hidden' }}>
        <BarChart width={550} height={300} data={data ?? []}>
          <XAxis dataKey="name" />
          <YAxis width={25} />
          <Tooltip />
          <Legend />
          <Bar dataKey="series" fill="#428d4d" />
        </BarChart>
      </Box>
    </Modal>
  );
};
