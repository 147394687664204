import { Box, MenuItem, SelectChangeEvent, Button, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { UNITS } from 'shared/constants';
import { Row, Input, Select } from 'shared/ui';

type UnitsModel = {
  pressure: string | number;
  flowPerLength: string | number;
  flow: string | number;
  totalFlow: string | number;
  length: string | number;
  velocity: string | number;
  pipeDiameter: string | number;
  emitterSpacing: string | number;
  lateralSpacing: string | number;
  area: string | number;
  appnDepth: string | number;
  appnRate: string | number;
};

export interface ViewProps {
  onSaveClick: () => void;
  toggle: () => void;
  onMetricDefaultsClick: () => void;
  onUSDefaultsClick: () => void;
  handleConvertUnitChange: (e: SelectChangeEvent<unknown>) => void;
  handleValuesChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleUnitChange: (e: SelectChangeEvent<unknown>) => void;
  unitValues: UnitsModel;
  values: UnitsModel;
  convertUnitValues: UnitsModel;
  convertedValues: UnitsModel;
}

export const DesktopView: FC<ViewProps> = ({
  onSaveClick,
  toggle,
  onMetricDefaultsClick,
  onUSDefaultsClick,
  unitValues,
  convertUnitValues,
  convertedValues,
  handleConvertUnitChange,
  handleValuesChange,
  handleUnitChange,
  values,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Box mb={1}>
        <Box display="flex" columnGap={1} mb={1}>
          <Button sx={{ width: '50%' }} variant="outlined" onClick={onMetricDefaultsClick}>
            {t('metricDef')}
          </Button>

          <Button sx={{ width: '50%' }} variant="outlined" onClick={onUSDefaultsClick}>
            {t('usDef')}
          </Button>
        </Box>

        <Row alignItems="flex-end">
          <Row alignItems="flex-end" width="100%">
            <Box mr={1} flexGrow={1}>
              <Select
                label={`${t('pressure')}`}
                name="pressure"
                value={unitValues.pressure}
                onChange={handleUnitChange}
              >
                {UNITS.Pressure.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box flexBasis={'28%'}>
              <Input type="number" name="pressure" value={values.pressure} onChange={handleValuesChange} />
            </Box>
            <Box mx={1}>
              <Typography align="center" sx={{ lineHeight: 2.5 }}>
                =
              </Typography>
            </Box>
          </Row>
          <Row width="100%">
            <Box mr={1} flexGrow={1}>
              <Select name="pressure" value={convertUnitValues.pressure} onChange={handleConvertUnitChange}>
                {UNITS.Pressure.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box flexBasis={'28%'}>
              <Input disabled isResultBox type="number" name="pressure" value={convertedValues.pressure} />
            </Box>
          </Row>
        </Row>

        <Row alignItems="flex-end">
          <Row alignItems="flex-end" width="100%">
            <Box mr={1} flexGrow={1}>
              <Select
                label={`${t('flowPerLength')}`}
                name="flowPerLength"
                value={unitValues.flowPerLength}
                onChange={handleUnitChange}
              >
                {UNITS.FlowPerLength.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box flexBasis={'28%'}>
              <Input type="number" name="flowPerLength" value={values.flowPerLength} onChange={handleValuesChange} />
            </Box>
            <Box mx={1}>
              <Typography align="center" sx={{ lineHeight: 2.5 }}>
                =
              </Typography>
            </Box>
          </Row>
          <Row width="100%">
            <Box mr={1} flexGrow={1}>
              <Select name="flowPerLength" value={convertUnitValues.flowPerLength} onChange={handleConvertUnitChange}>
                {UNITS.FlowPerLength.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box flexBasis={'28%'}>
              <Input disabled isResultBox type="number" name="flowPerLength" value={convertedValues.flowPerLength} />
            </Box>
          </Row>
        </Row>

        <Row alignItems="flex-end">
          <Row alignItems="flex-end" width="100%">
            <Box mr={1} flexGrow={1}>
              <Select label={`${t('emitterFlow')}`} name="flow" value={unitValues.flow} onChange={handleUnitChange}>
                {UNITS.Flow.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box flexBasis={'28%'}>
              <Input type="number" name="flow" value={values.flow} onChange={handleValuesChange} />
            </Box>
            <Box mx={1}>
              <Typography align="center" sx={{ lineHeight: 2.5 }}>
                =
              </Typography>
            </Box>
          </Row>
          <Row width="100%">
            <Box mr={1} flexGrow={1}>
              <Select name="flow" value={convertUnitValues.flow} onChange={handleConvertUnitChange}>
                {UNITS.Flow.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box flexBasis={'28%'}>
              <Input disabled isResultBox type="number" name="flow" value={convertedValues.flow} />
            </Box>
          </Row>
        </Row>

        <Row alignItems="flex-end">
          <Row alignItems="flex-end" width="100%">
            <Box mr={1} flexGrow={1}>
              <Select
                label={`${t('lateralFlow')}`}
                name="totalFlow"
                value={unitValues.totalFlow}
                onChange={handleUnitChange}
              >
                {UNITS.TotalFlow.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box flexBasis={'28%'}>
              <Input type="number" name="totalFlow" value={values.totalFlow} onChange={handleValuesChange} />
            </Box>
            <Box mx={1}>
              <Typography align="center" sx={{ lineHeight: 2.5 }}>
                =
              </Typography>
            </Box>
          </Row>
          <Row width="100%">
            <Box mr={1} flexGrow={1}>
              <Select name="totalFlow" value={convertUnitValues.totalFlow} onChange={handleConvertUnitChange}>
                {UNITS.TotalFlow.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box flexBasis={'28%'}>
              <Input disabled isResultBox type="number" name="totalFlow" value={convertedValues.totalFlow} />
            </Box>
          </Row>
        </Row>

        <Row alignItems="flex-end">
          <Row alignItems="flex-end" width="100%">
            <Box mr={1} flexGrow={1}>
              <Select label={`${t('length')}`} name="length" value={unitValues.length} onChange={handleUnitChange}>
                {UNITS.Length.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box flexBasis={'28%'}>
              <Input type="number" name="length" value={values.length} onChange={handleValuesChange} />
            </Box>
            <Box mx={1}>
              <Typography align="center" sx={{ lineHeight: 2.5 }}>
                =
              </Typography>
            </Box>
          </Row>
          <Row width="100%">
            <Box mr={1} flexGrow={1}>
              <Select name="length" value={convertUnitValues.length} onChange={handleConvertUnitChange}>
                {UNITS.Length.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box flexBasis={'28%'}>
              <Input disabled isResultBox type="number" name="length" value={convertedValues.length} />
            </Box>
          </Row>
        </Row>

        <Row alignItems="flex-end">
          <Row alignItems="flex-end" width="100%">
            <Box mr={1} flexGrow={1}>
              <Select
                label={`${t('velocity')}`}
                name="velocity"
                value={unitValues.velocity}
                onChange={handleUnitChange}
              >
                {UNITS.Velocity.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box flexBasis={'28%'}>
              <Input type="number" name="velocity" value={values.velocity} onChange={handleValuesChange} />
            </Box>
            <Box mx={1}>
              <Typography align="center" sx={{ lineHeight: 2.5 }}>
                =
              </Typography>
            </Box>
          </Row>
          <Row width="100%">
            <Box mr={1} flexGrow={1}>
              <Select name="velocity" value={convertUnitValues.velocity} onChange={handleConvertUnitChange}>
                {UNITS.Velocity.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box flexBasis={'28%'}>
              <Input disabled isResultBox type="number" name="velocity" value={convertedValues.velocity} />
            </Box>
          </Row>
        </Row>

        <Row alignItems="flex-end">
          <Row alignItems="flex-end" width="100%">
            <Box mr={1} flexGrow={1}>
              <Select
                label={`${t('pipeDia')}`}
                name="pipeDiameter"
                value={unitValues.pipeDiameter}
                onChange={handleUnitChange}
              >
                {UNITS.PipeDiameter.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box flexBasis={'28%'}>
              <Input type="number" name="pipeDiameter" value={values.pipeDiameter} onChange={handleValuesChange} />
            </Box>
            <Box mx={1}>
              <Typography align="center" sx={{ lineHeight: 2.5 }}>
                =
              </Typography>
            </Box>
          </Row>
          <Row width="100%">
            <Box mr={1} flexGrow={1}>
              <Select name="pipeDiameter" value={convertUnitValues.pipeDiameter} onChange={handleConvertUnitChange}>
                {UNITS.PipeDiameter.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box flexBasis={'28%'}>
              <Input disabled isResultBox type="number" name="pipeDiameter" value={convertedValues.pipeDiameter} />
            </Box>
          </Row>
        </Row>

        <Row alignItems="flex-end">
          <Row alignItems="flex-end" width="100%">
            <Box mr={1} flexGrow={1}>
              <Select
                label={`${t('emitterSpacing')}`}
                name="emitterSpacing"
                value={unitValues.emitterSpacing}
                onChange={handleUnitChange}
              >
                {UNITS.EmitterSpacing.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box flexBasis={'28%'}>
              <Input type="number" name="emitterSpacing" value={values.emitterSpacing} onChange={handleValuesChange} />
            </Box>
            <Box mx={1}>
              <Typography align="center" sx={{ lineHeight: 2.5 }}>
                =
              </Typography>
            </Box>
          </Row>
          <Row width="100%">
            <Box mr={1} flexGrow={1}>
              <Select name="emitterSpacing" value={convertUnitValues.emitterSpacing} onChange={handleConvertUnitChange}>
                {UNITS.EmitterSpacing.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box flexBasis={'28%'}>
              <Input disabled isResultBox type="number" name="emitterSpacing" value={convertedValues.emitterSpacing} />
            </Box>
          </Row>
        </Row>

        <Row alignItems="flex-end">
          <Row alignItems="flex-end" width="100%">
            <Box mr={1} flexGrow={1}>
              <Select
                label={`${t('lateralSpacing')}`}
                name="lateralSpacing"
                value={unitValues.lateralSpacing}
                onChange={handleUnitChange}
              >
                {UNITS.LateralSpacing.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box flexBasis={'28%'}>
              <Input type="number" name="lateralSpacing" value={values.lateralSpacing} onChange={handleValuesChange} />
            </Box>
            <Box mx={1}>
              <Typography align="center" sx={{ lineHeight: 2.5 }}>
                =
              </Typography>
            </Box>
          </Row>
          <Row width="100%">
            <Box mr={1} flexGrow={1}>
              <Select name="lateralSpacing" value={convertUnitValues.lateralSpacing} onChange={handleConvertUnitChange}>
                {UNITS.LateralSpacing.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box flexBasis={'28%'}>
              <Input disabled isResultBox type="number" name="lateralSpacing" value={convertedValues.lateralSpacing} />
            </Box>
          </Row>
        </Row>

        <Row alignItems="flex-end">
          <Row alignItems="flex-end" width="100%">
            <Box mr={1} flexGrow={1}>
              <Select label={`${t('area')}`} name="area" value={unitValues.area} onChange={handleUnitChange}>
                {UNITS.Area.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box flexBasis={'28%'}>
              <Input type="number" name="area" value={values.area} onChange={handleValuesChange} />
            </Box>
            <Box mx={1}>
              <Typography align="center" sx={{ lineHeight: 2.5 }}>
                =
              </Typography>
            </Box>
          </Row>
          <Row width="100%">
            <Box mr={1} flexGrow={1}>
              <Select name="area" value={convertUnitValues.area} onChange={handleConvertUnitChange}>
                {UNITS.Area.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box flexBasis={'28%'}>
              <Input disabled isResultBox type="number" name="area" value={convertedValues.area} />
            </Box>
          </Row>
        </Row>

        <Row alignItems="flex-end">
          <Row alignItems="flex-end" width="100%">
            <Box mr={1} flexGrow={1}>
              <Select
                label={`${t('appnDepth')}`}
                name="appnDepth"
                value={unitValues.appnDepth}
                onChange={handleUnitChange}
              >
                {UNITS.AppnDepth.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box flexBasis={'28%'}>
              <Input type="number" name="appnDepth" value={values.appnDepth} onChange={handleValuesChange} />
            </Box>
            <Box mx={1}>
              <Typography align="center" sx={{ lineHeight: 2.5 }}>
                =
              </Typography>
            </Box>
          </Row>
          <Row width="100%">
            <Box mr={1} flexGrow={1}>
              <Select name="appnDepth" value={convertUnitValues.appnDepth} onChange={handleConvertUnitChange}>
                {UNITS.AppnDepth.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box flexBasis={'28%'}>
              <Input disabled isResultBox type="number" name="appnDepth" value={convertedValues.appnDepth} />
            </Box>
          </Row>
        </Row>

        <Row alignItems="flex-end">
          <Row alignItems="flex-end" width="100%">
            <Box mr={1} flexGrow={1}>
              <Select
                label={`${t('appnRate')}`}
                name="appnRate"
                value={unitValues.appnRate}
                onChange={handleUnitChange}
              >
                {UNITS.AppnRate.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box flexBasis={'28%'}>
              <Input type="number" name="appnRate" value={values.appnRate} onChange={handleValuesChange} />
            </Box>
            <Box mx={1}>
              <Typography align="center" sx={{ lineHeight: 2.5 }}>
                =
              </Typography>
            </Box>
          </Row>
          <Row width="100%">
            <Box mr={1} flexGrow={1}>
              <Select name="appnRate" value={convertUnitValues.appnRate} onChange={handleConvertUnitChange}>
                {UNITS.AppnRate.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box flexBasis={'28%'}>
              <Input disabled isResultBox type="number" name="appnRate" value={convertedValues.appnRate} />
            </Box>
          </Row>
        </Row>
      </Box>
      <Box display="flex" columnGap={1} mt={2}>
        <Button onClick={onSaveClick} variant="contained" sx={{ flexBasis: '50%' }}>
          {t('save')}
        </Button>

        <Button onClick={toggle} variant="outlined" sx={{ flexBasis: '50%' }}>
          {t('cancel')}
        </Button>
      </Box>
    </>
  );
};
