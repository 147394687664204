import { Box, Divider, MenuItem } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { setEDCCategoryFilter } from 'shared/slices';
import { EDCSelectCategory, Select } from 'shared/ui';
import { EmittersEnamesItem } from '../../shared/models';
import { useTranslation } from 'react-i18next';

interface Props {
  emitterFamilies: EmittersEnamesItem[];
  value: EmittersEnamesItem;
  onChange: (value: string) => Promise<void>;
  loading: boolean;
}

export const EDCProductNameSelect: FC<Props> = ({ onChange, emitterFamilies, value, loading }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { categoryFilter } = useAppSelector((st) => st.edcState);

  const filters = useMemo(() => [...new Set(emitterFamilies.map((ef) => ef.subTypeName))], [emitterFamilies]);

  useEffect(() => {
    const categoryFilterDefaultValue = Object.fromEntries(filters.map((filter) => [filter, true]));
    dispatch(setEDCCategoryFilter(categoryFilterDefaultValue));
  }, [filters, dispatch]);

  const activeCategories = useMemo(
    () =>
      Object.entries(categoryFilter)
        .filter(([, value]) => value)
        .map(([key]) => key),
    [categoryFilter]
  );

  const products = useMemo(
    () => emitterFamilies.filter((ef) => activeCategories.includes(ef.subTypeName)).sort(),
    [emitterFamilies, activeCategories]
  );

  const isEmitterFamilyAvailable = products.includes(value);
  const [open, setOpen] = useState(false);

  const handleChange = async (selectedGroupId: string) => {
    await onChange(selectedGroupId);
    setOpen(false);
  };

  return (
    <Select
      label={`${t('subtype')}`}
      value={isEmitterFamilyAvailable ? value.groupId : ''}
      loading={loading}
      disabled={loading}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      paperMaxHeight="500px"
    >
      {!loading &&
        filters.map((filter) => (
          <EDCSelectCategory
            key={filter}
            checked={categoryFilter[filter]}
            handleChange={(e) => dispatch(setEDCCategoryFilter({ [filter]: e.target.checked }))}
            label={filter}
          />
        ))}
      <Divider />
      <Box sx={{ height: '280px', overflow: 'auto' }}>
        {products.map((item) => (
          <MenuItem onClick={() => handleChange(item.groupId)} key={item.groupId} value={item.groupId}>
            {item.groupName}
          </MenuItem>
        ))}
      </Box>
      {products.map((item) => (
        <MenuItem key={item.groupId} value={item.groupId} sx={{ display: 'none' }}>
          {item.groupName}
        </MenuItem>
      ))}
    </Select>
  );
};
