import { SetFiled } from '../models/SetFiled';

export function SetFieldRainAtSize(SetField: SetFiled) {
  let fieldintervalX;
  let fieldintervalY;
  let FieldRainAt;
  let tempdrainat;

  let FIELDRES = 40;
  let res;
  switch (SetField.Placement) {
    case SetField.RECTANGULAR_LAYOUT:
      if (SetField.dXPOSITION == SetField.dYPOSITION) {
        SetField.XMAMTEROT = FIELDRES;
        SetField.YMAMTEROT = FIELDRES;
        SetField.Xint = FIELDRES / 2;
        SetField.Yint = FIELDRES / 2;
      } else if (SetField.dXPOSITION > SetField.dYPOSITION) {
        SetField.XMAMTEROT = FIELDRES;
        SetField.YMAMTEROT = Math.trunc(
          Math.floor((SetField.dYPOSITION / SetField.dXPOSITION) * FIELDRES + 0.5),
        );
        SetField.Xint = FIELDRES / 2;
        SetField.Yint = Math.trunc(Math.floor(SetField.YMAMTEROT / 2 + 0.5));
      } else if (SetField.dXPOSITION < SetField.dYPOSITION) {
        SetField.YMAMTEROT = FIELDRES;
        SetField.XMAMTEROT = Math.trunc(
          Math.floor((SetField.dXPOSITION / SetField.dYPOSITION) * FIELDRES + 0.5),
        );
        SetField.Xint = Math.trunc(Math.floor(SetField.XMAMTEROT / 2 + 0.5));
        SetField.Yint = FIELDRES / 2;
      }
      FieldRainAt = new Array(SetField.XMAMTEROT + 1);
      for (let k = 0; k < FieldRainAt.length; k++) {
        FieldRainAt[k] = new Array(SetField.YMAMTEROT + 1);
      }
      fieldintervalX = SetField.dXPOSITION / SetField.XMAMTEROT;
      fieldintervalY = SetField.dYPOSITION / SetField.YMAMTEROT;
      res = {
        FieldRainAt: FieldRainAt,
        fieldintervalX: fieldintervalX,
        fieldintervalY: fieldintervalY,
        Xint: SetField.Xint,
        Yint: SetField.Yint,
        YMAMTEROT: SetField.YMAMTEROT,
        XMAMTEROT: SetField.XMAMTEROT,
      };
      return res;
    case SetField.TRIANGULAR_Y_LAYOUT:
      if (SetField.dXPOSITION == SetField.dYPOSITION) {
        SetField.XMAMTEROT = FIELDRES;
        SetField.YMAMTEROT = FIELDRES;
        SetField.Xint = FIELDRES;
        SetField.Yint = FIELDRES / 2;
        SetField.SLOPE = 0.5;
      } else if (SetField.dXPOSITION > SetField.dYPOSITION) {
        SetField.XMAMTEROT = FIELDRES;
        SetField.YMAMTEROT = Math.trunc(
          Math.floor((SetField.dYPOSITION / SetField.dXPOSITION) * FIELDRES + 0.5),
        );
        SetField.Xint = FIELDRES;
        SetField.Yint = Math.trunc(Math.floor(SetField.YMAMTEROT / 2 + 0.5));
        SetField.SLOPE = SetField.Yint / FIELDRES;
      } else if (SetField.dXPOSITION < SetField.dYPOSITION) {
        SetField.YMAMTEROT = FIELDRES;
        SetField.XMAMTEROT = Math.trunc(
          Math.floor((SetField.dXPOSITION / SetField.dYPOSITION) * FIELDRES + 0.5),
        );
        SetField.Xint = SetField.XMAMTEROT;
        SetField.Yint = FIELDRES / 2;
        SetField.SLOPE = SetField.dYPOSITION / (2 * SetField.dXPOSITION);
      }
      FieldRainAt = Array(SetField.XMAMTEROT + 1);
      tempdrainat = Array(SetField.XMAMTEROT + 1);
      for (let k = 0; k < FieldRainAt.length; k++) {
        FieldRainAt[k] = Array(SetField.YMAMTEROT + 1);
        tempdrainat[k] = Array(SetField.YMAMTEROT + 1);
      }
      fieldintervalX = SetField.dXPOSITION / SetField.XMAMTEROT;
      fieldintervalY = SetField.dYPOSITION / SetField.YMAMTEROT;
      res = {
        FieldRainAt: FieldRainAt,
        tempdrainat: tempdrainat,
        fieldintervalX: fieldintervalX,
        fieldintervalY: fieldintervalY,
        Xint: SetField.Xint,
        Yint: SetField.Yint,
        SLOPE: SetField.SLOPE,
        YMAMTEROT: SetField.YMAMTEROT,
        XMAMTEROT: SetField.XMAMTEROT,
      };
      return res;
    case SetField.LATERAL_LAYOUT:
      if (SetField.dXPOSITION == SetField.dYPOSITION) {
        SetField.XMAMTEROT = FIELDRES;
        SetField.YMAMTEROT = FIELDRES;
        SetField.Xint = FIELDRES / 2;
        SetField.Yint = FIELDRES / 2;
      } else if (SetField.dXPOSITION > SetField.dYPOSITION) {
        SetField.XMAMTEROT = FIELDRES;
        SetField.YMAMTEROT = Math.trunc(
          Math.floor((SetField.dYPOSITION / SetField.dXPOSITION) * FIELDRES + 0.5),
        );
        SetField.Xint = FIELDRES / 2;
        SetField.Yint = Math.trunc(Math.floor(SetField.YMAMTEROT / 2 + 0.5));
      } else if (SetField.dXPOSITION < SetField.dYPOSITION) {
        //y>x
        SetField.YMAMTEROT = FIELDRES;
        SetField.XMAMTEROT = Math.trunc(
          Math.floor((SetField.dXPOSITION / SetField.dYPOSITION) * FIELDRES + 0.5),
        );
        SetField.Xint = Math.trunc(Math.floor(SetField.XMAMTEROT / 2 + 0.5));
        SetField.Yint = FIELDRES / 2;
      }
      FieldRainAt = Array(SetField.XMAMTEROT + 1);
      tempdrainat = Array(SetField.XMAMTEROT + 1);

      for (let k = 0; k < FieldRainAt.length; k++) {
        FieldRainAt[k] = Array(SetField.YMAMTEROT + 1);
        tempdrainat[k] = Array(SetField.YMAMTEROT + 1);
      }
      fieldintervalX = SetField.dXPOSITION / SetField.XMAMTEROT;
      fieldintervalY = SetField.dYPOSITION / SetField.YMAMTEROT;
      res = {
        FieldRainAt: FieldRainAt,
        tempdrainat: tempdrainat,
        fieldintervalX: fieldintervalX,
        fieldintervalY: fieldintervalY,
        Xint: SetField.Xint,
        Yint: SetField.Yint,
        YMAMTEROT: SetField.YMAMTEROT,
        XMAMTEROT: SetField.XMAMTEROT,
      };
      return res;

    case SetField.MOVING_LATERAL_LAYOUT:
      if (SetField.dXPOSITION == SetField.dYPOSITION) {
        //cube case
        SetField.XMAMTEROT = FIELDRES;
        SetField.YMAMTEROT = FIELDRES;
        SetField.Xint = FIELDRES / 2;
        SetField.Yint = FIELDRES / 2;
      } else if (SetField.dXPOSITION > SetField.dYPOSITION) {
        //x>y
        SetField.XMAMTEROT = FIELDRES;
        SetField.YMAMTEROT = parseInt(
          String(
            Math.floor((SetField.dYPOSITION / SetField.dXPOSITION) * FIELDRES + 0.5),
          ),
        );
        SetField.Xint = FIELDRES / 2;
        SetField.Yint = parseInt(String(Math.floor(SetField.YMAMTEROT / 2 + 0.5)));
      } else if (SetField.dXPOSITION < SetField.dYPOSITION) {
        //y>xSetField
        SetField.YMAMTEROT = FIELDRES;
        SetField.XMAMTEROT = parseInt(
          String(
            Math.floor((SetField.dXPOSITION / SetField.dYPOSITION) * FIELDRES + 0.5),
          ),
        );
        SetField.Xint = parseInt(String(Math.floor(SetField.XMAMTEROT / 2 + 0.5)));
        SetField.Yint = FIELDRES / 2;
      }
      FieldRainAt = Array(SetField.XMAMTEROT + 1);
      tempdrainat = Array(SetField.XMAMTEROT + 1);

      for (let k = 0; k < FieldRainAt.length; k++) {
        FieldRainAt[k] = Array(SetField.YMAMTEROT + 1);
        tempdrainat[k] = Array(SetField.YMAMTEROT + 1);
        for (let c = 0; c < FieldRainAt[k].length; c++) {
          FieldRainAt[k][c] = 0;
          tempdrainat[k][c] = 0;
        }
      }
      fieldintervalX = SetField.dXPOSITION / SetField.XMAMTEROT;
      fieldintervalY = SetField.dYPOSITION / SetField.YMAMTEROT;
      res = {
        FieldRainAt: FieldRainAt,
        tempdrainat: tempdrainat,
        fieldintervalX: fieldintervalX,
        fieldintervalY: fieldintervalY,
        Xint: SetField.Xint,
        Yint: SetField.Yint,
      };
      return res;
    case SetField.SINGLE_LAYOUT:
      SetField.XMAMTEROT = FIELDRES;
      SetField.YMAMTEROT = FIELDRES;
      fieldintervalX = SetField.xlength / FIELDRES;
      fieldintervalY = SetField.Ylength / FIELDRES;
      FieldRainAt = Array(SetField.XMAMTEROT + 1);
      for (let k = 0; k < FieldRainAt.length; k++) {
        FieldRainAt[k] = Array(SetField.YMAMTEROT + 1);
      }

      res = {
        fieldintervalX: fieldintervalX,
        fieldintervalY: fieldintervalY,
        FieldRainAt: FieldRainAt,
        XMAMTEROT: SetField.XMAMTEROT,
        YMAMTEROT: SetField.YMAMTEROT,
        Xint: SetField.Xint,
        Yint: SetField.Yint,
      };
      return res;
    case SetField.BILATERAL_LAYOUT:
      if (SetField.dXPOSITION == SetField.dYPOSITION) {
        //cube case
        SetField.XMAMTEROT = FIELDRES;
        SetField.YMAMTEROT = FIELDRES;
        SetField.Yint = FIELDRES / 2;
        SetField.Xint = FIELDRES / 2;
      } else if (SetField.dXPOSITION > SetField.dYPOSITION) {
        //x>y
        SetField.XMAMTEROT = FIELDRES;
        SetField.YMAMTEROT = parseInt(
          String(
            Math.floor((SetField.dYPOSITION / SetField.dXPOSITION) * FIELDRES + 0.5),
          ),
        );
        SetField.Yint = parseInt(String(Math.floor(SetField.YMAMTEROT / 2 + 0.5)));
        SetField.Xint = FIELDRES / 2;
      } else if (SetField.dXPOSITION < SetField.dYPOSITION) {
        //y>x
        SetField.YMAMTEROT = FIELDRES;
        SetField.XMAMTEROT = parseInt(
          String(
            Math.floor((SetField.dXPOSITION / SetField.dYPOSITION) * FIELDRES + 0.5),
          ),
        );
        SetField.Yint = FIELDRES / 2;
        SetField.Xint = parseInt(String(Math.floor(SetField.XMAMTEROT / 2 + 0.5)));
      }
      FieldRainAt = Array(SetField.XMAMTEROT + 1);

      for (let k = 0; k < FieldRainAt.length; k++) {
        FieldRainAt[k] = Array(SetField.YMAMTEROT + 1);
        for (let c = 0; c < FieldRainAt[k].length; c++) {
          FieldRainAt[k][c] = 0;
        }
      }
      fieldintervalX = SetField.dXPOSITION / SetField.XMAMTEROT;
      fieldintervalY = SetField.dYPOSITION / SetField.YMAMTEROT;

      res = {
        FieldRainAt: FieldRainAt,
        fieldintervalX: fieldintervalX,
        fieldintervalY: fieldintervalY,
        Xint: SetField.Xint,
        Yint: SetField.Yint,
      };
      return res;
    case SetField.BILATERAL_TRIANGULAR_LAYOUT:
      if (SetField.dXPOSITION == SetField.dYPOSITION) {
        //cube case
        SetField.XMAMTEROT = FIELDRES;
        SetField.YMAMTEROT = FIELDRES;
        SetField.Yint = FIELDRES / 2;
        SetField.Xint = FIELDRES;
        SetField.SLOPE = 0.5;
      } else if (SetField.dXPOSITION > SetField.dYPOSITION) {
        //x>y
        SetField.XMAMTEROT = FIELDRES;
        SetField.YMAMTEROT = parseInt(
          String(
            Math.floor((SetField.dYPOSITION / SetField.dXPOSITION) * FIELDRES + 0.5),
          ),
        );
        SetField.Yint = Math.floor(SetField.YMAMTEROT / 2 + 0.5);
        SetField.Xint = FIELDRES;
        SetField.SLOPE = SetField.Yint / FIELDRES;
      } else if (SetField.dXPOSITION < SetField.dYPOSITION) {
        //y>x
        SetField.YMAMTEROT = FIELDRES;
        SetField.XMAMTEROT = parseInt(
          String(
            Math.floor((SetField.dXPOSITION / SetField.dYPOSITION) * FIELDRES + 0.5),
          ),
        );
        SetField.Yint = FIELDRES / 2;
        SetField.Xint = SetField.XMAMTEROT;
        SetField.SLOPE = SetField.dYPOSITION / (2 * SetField.dXPOSITION);
      }
      FieldRainAt = Array(SetField.XMAMTEROT + 1);
      tempdrainat = Array(SetField.XMAMTEROT + 1);

      for (let k = 0; k < FieldRainAt.length; k++) {
        FieldRainAt[k] = Array(SetField.YMAMTEROT + 1);
        tempdrainat[k] = Array(SetField.YMAMTEROT + 1);
        for (let c = 0; c < FieldRainAt[k].length; c++) {
          FieldRainAt[k][c] = 0;
          tempdrainat[k][c] = 0;
        }
      }
      fieldintervalX = SetField.dXPOSITION / SetField.XMAMTEROT;
      fieldintervalY = SetField.dYPOSITION / SetField.YMAMTEROT;

      res = {
        FieldRainAt: FieldRainAt,
        tempdrainat: tempdrainat,
        fieldintervalX: fieldintervalX,
        fieldintervalY: fieldintervalY,
        Xint: SetField.Xint,
        Yint: SetField.Yint,
        XMAMTEROT: SetField.XMAMTEROT,
        YMAMTEROT: SetField.YMAMTEROT,
      };
      return res;
  }
}
