import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { customAxios } from 'shared/api';
import { DeleteProjectBody, DeleteProjectResponse, Project, ProjectsResponse } from 'shared/models';

interface UserState {
  loading: boolean;
  projects: Project[];
  currentProject: Project;
  isProjectsError: boolean;
}

const initialState: UserState = {
  loading: true,
  isProjectsError: false,
  projects: [],
  currentProject: {} as Project,
};

export const getProjectsThunk = createAsyncThunk('getProjects', async (_, thunkAPI) => {
  try {
    const response = await customAxios.get<ProjectsResponse>(`be/Ht/ht-projects`);
    return response.data;
  } catch (e) {
    if (e instanceof Error) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
});

export const deleteProjectThunk = createAsyncThunk('deleteProject', async (body: DeleteProjectBody, thunkAPI) => {
  try {
    const response = await customAxios.delete<DeleteProjectResponse>(`be/Ht/${body.projectId}`);

    if (response.data) {
      thunkAPI.dispatch(projectsSlice.actions.deleteProject(body.projectId));
    }
    return response.data;
  } catch (e) {
    if (e instanceof Error) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
});

const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setCurrentProject(state, action: PayloadAction<Project>) {
      state.currentProject = action.payload;
    },
    deleteProject(state, action: PayloadAction<number>) {
      state.projects = state.projects.filter((item) => item.projectID !== action.payload);
    },
    resetProjects: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProjectsThunk.pending.type, (state) => {
        state.loading = true;
        state.isProjectsError = false;
      })
      .addCase(getProjectsThunk.fulfilled.type, (state, action: PayloadAction<ProjectsResponse>) => {
        state.projects = action.payload.data;
        state.currentProject = action.payload.data[0] ?? {};
        state.loading = false;
        state.isProjectsError = false;
      })
      .addCase(getProjectsThunk.rejected.type, (state) => {
        state.loading = false;
        state.isProjectsError = true;
      });
  },
});

export const { setCurrentProject, resetProjects } = projectsSlice.actions;

export const projectsReducer = projectsSlice.reducer;
