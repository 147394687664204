export function TriangularCondition(
  Placement: number,
  TRIANGULAR_Y_LAYOUT: number,
  BILATERAL_TRIANGULAR_LAYOUT: number,
) {
  let functionReturnValue = false;

  functionReturnValue = false;

  if (Placement == TRIANGULAR_Y_LAYOUT || Placement == BILATERAL_TRIANGULAR_LAYOUT) {
    functionReturnValue = true;
  }
  return functionReturnValue;
}
