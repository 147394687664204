import { Box, Button, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { PlantsImage, PlantsStaggered } from 'shared/assets';
import { useAppDispatch, useAppSelector, useEDCCalculate } from 'shared/hooks';
import { formattedInputValue } from 'shared/lib';
import { setEDCStatePlantsValues, setEDCStateToolBar } from 'shared/slices';
import { Input, Modal } from 'shared/ui';

export const EDCPlants = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { units } = useAppSelector((state) => state.units);
  const {
    edcToolBar: { showPlants, displayPlants },
    edcPlantsValues: {
      staggeredRows,
      betweenPlants,
      betweenRows,
      centerPlantsBetweenLaterals,
      inRows,
      plantDiameter,
      offRows,
    },
  } = useAppSelector((st) => st.edcState);

  const calculateEDC = useEDCCalculate();

  const togglePlants = () => dispatch(setEDCStateToolBar({ showPlants: !showPlants }));

  const toggleDisplayPlants = () => dispatch(setEDCStateToolBar({ displayPlants: !displayPlants }));

  const onStaggeredRowsChange = () => {
    dispatch(setEDCStatePlantsValues({ staggeredRows: !staggeredRows }));
  };
  const onCenterPlantsBetweenLateralsChange = () => {
    dispatch(setEDCStatePlantsValues({ centerPlantsBetweenLaterals: !centerPlantsBetweenLaterals }));
  };
  const onBetweenPlantsChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setEDCStatePlantsValues({ betweenPlants: +formattedInputValue(e) }));
  };
  const onBetweenRowsChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setEDCStatePlantsValues({ betweenRows: +formattedInputValue(e) }));
  };
  const onInRowsChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setEDCStatePlantsValues({ inRows: +formattedInputValue(e) }));
  };
  const onOffRowsChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setEDCStatePlantsValues({ offRows: +formattedInputValue(e) }));
  };
  const onPlantDiameterChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setEDCStatePlantsValues({ plantDiameter: +formattedInputValue(e) }));
  };

  const onSubmitPlants = () => {
    calculateEDC();
    togglePlants();
    toggleDisplayPlants();
  };

  return (
    <Modal maxWidth="sm" title="Plants" open={showPlants} onClose={togglePlants}>
      <Grid item container xs={12}>
        <Grid item xs={2.5} display={{ xs: 'none', sm: 'block' }}>
          <Box component="img" src={staggeredRows ? PlantsStaggered : PlantsImage} alt="Plants" />
        </Grid>

        <Grid container item xs={9.5} mt={1} columnSpacing={1}>
          <Grid item xs={6}>
            <Input
              label={`${t('Space Between Plants')} (${units.length})`}
              value={betweenPlants}
              onChange={onBetweenPlantsChange}
              name="BetweenPlants"
              type="number"
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" align="center" mt={1.5}>
              {t('Distance in measures from top-left emitter')}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Input
              label={`${t('Space Between Rows')}  (${units.length})`}
              name="BetweenRows"
              value={betweenRows}
              onChange={onBetweenRowsChange}
              type="number"
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              disabled={centerPlantsBetweenLaterals}
              isResultBox={centerPlantsBetweenLaterals}
              name="InRows"
              value={inRows}
              onChange={onInRowsChange}
              label={`${t('In Rows')} (${units.length})`}
              type="number"
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              label={`${t('Plant Diameter')} (${units.length})`}
              name="PlantDiameter"
              value={plantDiameter}
              onChange={onPlantDiameterChange}
              type="number"
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              disabled={centerPlantsBetweenLaterals}
              isResultBox={centerPlantsBetweenLaterals}
              name="OffRows"
              value={offRows}
              onChange={onOffRowsChange}
              label={`${t('Off Rows')} (${units.length})`}
              type="number"
            />
          </Grid>

          <Grid item xs={6}>
            <FormControlLabel
              name="StaggeredRows"
              control={<Checkbox checked={staggeredRows} onChange={onStaggeredRowsChange} />}
              label={`${t('Staggered Rows')}`}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              name="CenterPlantsBetweenLaterals"
              control={
                <Checkbox checked={centerPlantsBetweenLaterals} onChange={onCenterPlantsBetweenLateralsChange} />
              }
              label={`${t('Center Plants Between Laterals')}`}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid container mt={1} columnSpacing={2} rowGap={1}>
        <Grid item sm xs={12}>
          <Button onClick={onSubmitPlants} fullWidth variant="contained">
            {t('ok')}
          </Button>
        </Grid>
        <Grid item sm xs={12}>
          <Button onClick={togglePlants} color="primary" fullWidth variant="outlined">
            {t('cancel')}
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};
