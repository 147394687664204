import { Ellipse } from './HandleFiled/Ellipse';

export function DoRange(
  Placement: number,
  EdgeType: number,
  lastplacement: number,
  dRange: number,
  dXPOSITION: number,
  dYPOSITION: number,
  dXPOSITION1: number,
  dYPOSITION1: number,
  fieldpic: {
    width: number;
    height: number;
  },
) {
  let Kx = 0;
  let Ky = 0;
  let i = 0;
  let j = 0;
  let KEdgeY = 0;
  let KEdgeX = 0;
  let X_EDGE_LAYOUT = 1;
  let Y_EDGE_LAYOUT = 2;
  let CORNER_EDGE_LAYOUT = 3;
  let BILATERAL_LAYOUT = 4;
  let EDGE_LAYOUT = 5;
  let BILATERAL_TRIANGULAR_LAYOUT = 6;
  let RECTANGULAR_LAYOUT = 0;
  let TRIANGULAR_Y_LAYOUT = 1;
  let LATERAL_LAYOUT = 2;
  let SINGLE_LAYOUT = 3;
  let MOVING_LATERAL_LAYOUT = 9;

  if (Placement != EDGE_LAYOUT) {
    Kx = Math.floor(dRange / dXPOSITION);

    Ky = Math.floor(dRange / dYPOSITION);
  } else {
    Kx = Math.floor(dRange / dXPOSITION);

    Ky = Math.floor(dRange / dYPOSITION);
  }
  if (EdgeType == X_EDGE_LAYOUT) {
    KEdgeY = Ky + 1;
    if (lastplacement == BILATERAL_LAYOUT || lastplacement == BILATERAL_TRIANGULAR_LAYOUT)
      Kx = 1;
    KEdgeX = 0;
  } else if (EdgeType == Y_EDGE_LAYOUT) {
    KEdgeY = 0;
    if (lastplacement == BILATERAL_LAYOUT || lastplacement == BILATERAL_TRIANGULAR_LAYOUT)
      Kx = 0;
    KEdgeX = Kx + 1;
  } else if (EdgeType == CORNER_EDGE_LAYOUT) {
    KEdgeY = 0;
    if (lastplacement == BILATERAL_LAYOUT || lastplacement == BILATERAL_TRIANGULAR_LAYOUT)
      Kx = 0;
    KEdgeX = 0;
  }

  let width = fieldpic.width;
  let height = fieldpic.height;
  let range = [];
  switch (Placement) {
    case RECTANGULAR_LAYOUT:
      for (i = -Kx; i <= Kx + 1; i++) {
        for (j = -Ky; j <= Ky + 1; j++) {
          const e: Ellipse = {
            Tag: 'Range',
            Stroke: 'FF0000',
            width: (2 * dRange * width) / dXPOSITION,
            height: ((dRange * height) / dYPOSITION) * 2,
            left: i * width - (dRange * width) / dXPOSITION,
            top: j * height - (dRange * height) / dYPOSITION,
          };
          range.push(e);
        }
      }

      break;
    case TRIANGULAR_Y_LAYOUT:
      for (i = -Kx; i <= Kx + 1; i++) {
        for (j = -Ky; j <= Ky + 1; j++) {
          if (i % 2 == 0) {
            const e: Ellipse = {
              Tag: 'Range',
              Stroke: 'FF0000',
              width: (2 * dRange * width) / dXPOSITION,
              height: (2 * dRange * height) / dYPOSITION,
              left: i * width - (dRange * width) / dXPOSITION,
              top: j * height - (dRange * height) / dYPOSITION,
            };
            range.push(e);
          } else if (j % 2 > 0) {
            const e: Ellipse = {
              Tag: 'Range',
              Stroke: 'FF0000',
              width: (2 * dRange * width) / dXPOSITION,
              height: ((dRange * height) / dYPOSITION) * 2,
              left: i * width - (dRange * width) / dXPOSITION,
              top: (j * height) / 2 - (dRange * height) / dYPOSITION,
            };
            range.push(e);
          }
        }
      }

      break;
    case LATERAL_LAYOUT:
      for (j = -Ky; j <= Ky + 1; j++) {
        //picOptions.Circle (dXPOSITION / 2, j * dYPOSITION), dRange, QBColor(4) 'RED
        //g.DrawEllipse(Pens.Red, width - width / dYPOSITION / 2, height - j * height / dYPOSITION, dRange * 2, dRange * 2);
        //, dRange, QBColor(4) 'RED   ' + RelRange
      }

      break;
    case MOVING_LATERAL_LAYOUT:
      break;
    //Ky = CInt(dYPOSITION / MovingEmitterDistance) * CInt(dRange / dYPOSITION)
    //For i = -Kx To Kx + 1
    //For j = -Ky To Ky + YPOSITION / MovingEmitterDistance
    //picOptions.Circle (i * dXPOSITION, j * MovingEmitterDistance), dRange, QBColor(4)  'RED   ' + RelRange
    //Next
    //Next

    case SINGLE_LAYOUT:
      //picOptions.Circle (dXPOSITION / 2, dYPOSITION / 2), dRange, QBColor(4)
      //g.DrawEllipse(Pens.Red, width - Convert.ToSingle((width / dXPOSITION) / 2), Convert.ToSingle(height - (height / dYPOSITION) / 2), dRange * 2, dRange * 2);
      //, dRange, QBColor(4) 'RED   ' + RelRange
      break;
    case BILATERAL_LAYOUT:
      //Bi-linear
      for (i = 0; i <= 1; i++) {
        for (j = -Ky; j <= Ky + 1; j++) {
          //picOptions.Circle (i * dXPOSITION, j * dYPOSITION), dRange, QBColor(4) 'RED
          //g.DrawEllipse(Pens.Red, i * width - width / dXPOSITION, j * height - height / dYPOSITION, dRange * 2, dRange * 2);
          //, dRange, QBColor(4) 'RED   ' + RelRange
        }
      }

      break;
    case BILATERAL_TRIANGULAR_LAYOUT:
      for (j = -Ky; j <= Ky + 1; j++) {
        //i=0
        //picOptions.Circle (0, j * dYPOSITION), dRange, QBColor(4) 'RED
        //g.DrawEllipse(Pens.Red, 0, j * height / dYPOSITION, dRange * 2, dRange * 2) ', dRange, QBColor(4) 'RED   ' + RelRange
        //i=1
        //picOptions.Circle (dXPOSITION, j * dYPOSITION - dYPOSITION / 2), dRange, QBColor(4) 'RED
        //g.DrawEllipse(Pens.Red, width / dXPOSITION, j * height / dYPOSITION - (height / dYPOSITION) / 2, dRange * 2, dRange * 2) ', dRange, QBColor(4) 'RED   ' + RelRange
      }

      break;
    case EDGE_LAYOUT:
      if (lastplacement == RECTANGULAR_LAYOUT) {
        for (i = -Kx; i <= KEdgeX; i++) {
          //Ky + 1
          for (j = -Ky; j <= KEdgeY; j++) {
            //picOptions.Circle (i * dXPOSITION1, j * dYPOSITION1), dRange, QBColor(4) 'RED
            //g.DrawEllipse(Pens.Red, i * width / dXPOSITION, j * height / dYPOSITION, dRange * 2, dRange * 2) ', dRange, QBColor(4) 'RED   ' + RelRange
          }
        }
      } else if (lastplacement == TRIANGULAR_Y_LAYOUT) {
        for (i = -Kx; i <= KEdgeX; i++) {
          for (j = -Ky; j <= KEdgeY; j++) {
            if (i % 2 == 0) {
              //picOptions.Circle (i * dXPOSITION1, j * dYPOSITION1), dRange, QBColor(4)
              //g.DrawEllipse(Pens.Red, i * width / dXPOSITION1, j * height / dYPOSITION1, dRange * 2, dRange * 2) ', dRange, QBColor(4) 'RED   ' + RelRange
            } else {
              if (j == 0) {
                if (EdgeType == Y_EDGE_LAYOUT || EdgeType == X_EDGE_LAYOUT) {
                  //picOptions.Circle (i * dXPOSITION1, j * dYPOSITION1 - dYPOSITION1 / 2), dRange, QBColor(4)
                }
              } else {
                //picOptions.Circle (i * dXPOSITION1, j * dYPOSITION1 - dYPOSITION1 / 2), dRange, QBColor(4)
              }
            }
          }
        }
        //Bi-lateral calc in edge
      } else if (lastplacement == BILATERAL_LAYOUT) {
        for (i = 0; i <= KEdgeX; i++) {
          //Ky + 1
          for (j = -Ky; j <= KEdgeY; j++) {
            //picOptions.Circle (i * dXPOSITION1, j * dYPOSITION1), dRange, QBColor(4) 'RED
          }
        }
        //Bi-lateral calc in edge
      } else if (lastplacement == BILATERAL_TRIANGULAR_LAYOUT) {
        for (i = 0; i <= KEdgeX; i++) {
          //Ky + 1
          for (j = -Ky; j <= KEdgeY; j++) {
            if (i % 2 == 0) {
              //picOptions.Circle (i * dXPOSITION1, j * dYPOSITION1), dRange, QBColor(4)
            } else {
              if (!(j == 0 && EdgeType == X_EDGE_LAYOUT)) {
                //picOptions.Circle (i * dXPOSITION1, j * dYPOSITION1 - dYPOSITION1 / 2), dRange, QBColor(4)
              }
            }
          }
        }
      }
      break;
  }
  return range;
}
