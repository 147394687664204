import React, { useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector, useEDCCalculate } from 'shared/hooks';
import { setEDCStateValue } from 'shared/slices';

interface CanvasGraphProps {
  width: number;
  height: number;
}

export const CanvasGraph: React.FC<CanvasGraphProps> = ({ width, height }) => {
  const dispatch = useAppDispatch();
  const calculateEDC = useEDCCalculate();

  const {
    edcValues: { mouseClickPositionY1, mouseClickPositionY2 },
  } = useAppSelector((st) => st.edcState);

  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    const arcRadius = 5;

    const x1 = 0;
    const x2 = canvas.width;

    drawArc(x1 + arcRadius, mouseClickPositionY1 || canvas.height / 2, arcRadius, ctx);
    drawArc(x2 - arcRadius, mouseClickPositionY2 || canvas.height / 2, arcRadius, ctx);
    ctx.beginPath();
    ctx.moveTo(x1 + arcRadius, mouseClickPositionY1 || canvas.height / 2);
    ctx.lineTo(x2 - arcRadius, mouseClickPositionY2 || canvas.height / 2);
    ctx.strokeStyle = 'red';
    ctx.lineWidth = 2;

    ctx.stroke();
  }, [height, width, mouseClickPositionY1, mouseClickPositionY2]);

  function printMousePosition(event: any) {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const rect = canvas.getBoundingClientRect();

    if (event.clientX - rect.left > canvas.offsetWidth / 2) {
      dispatch(setEDCStateValue({ mouseClickPositionY2: Math.round(event.clientY - rect.top) }));
    } else {
      dispatch(setEDCStateValue({ mouseClickPositionY1: Math.round(event.clientY - rect.top) }));
    }
    calculateEDC();
  }

  function drawArc(x: number, y: number, radius: number, canvas: CanvasRenderingContext2D) {
    canvas.fillStyle = 'red';
    canvas.beginPath();
    canvas.arc(x, y, radius, 0, 2 * Math.PI);
    canvas.fill();
    canvas.closePath();
  }

  return (
    <canvas
      style={{
        position: 'absolute',
        top: '0%',
        left: '0%',
        zIndex: 100,
      }}
      width={width}
      height={height}
      onClick={printMousePosition}
      ref={canvasRef}
    />
  );
};
