import { Box, Button } from '@mui/material';
import { getProjectsThunk, saveProjectAsThunk, toggleSaveAsProject } from 'shared/slices';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector, useToast } from 'shared/hooks';
import { Input, Modal } from 'shared/ui';
import { getConvertedProjectDataForSave } from 'shared/lib';

export const SaveAsProject = () => {
  const { t } = useTranslation();
  const { showError, showSuccess } = useToast();
  const dispatch = useAppDispatch();
  const { openSaveAsProject } = useAppSelector((st) => st.modals);
  const { units } = useAppSelector((st) => st.units);
  const { projectData } = useAppSelector((st) => st.projectData);

  const [name, setName] = useState('');

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value);

  const toggle = () => dispatch(toggleSaveAsProject());

  const onSaveClick = async () => {
    const userId = localStorage.getItem('ht-user-id');
    if (!userId) return;

    const result = await dispatch(
      saveProjectAsThunk({
        projectData: getConvertedProjectDataForSave({ projectData: { ...projectData, id: 0 }, sourceUnits: units }),
        projectName: name,
      }),
    ).unwrap();

    if (result?.data) showSuccess(t('projectSaved'));
    if (!result?.data) showError(t('SomethingWentWrong'));

    await dispatch(getProjectsThunk());

    toggle();
  };

  return (
    <Modal maxWidth='xs' title={t('saveAs')} open={openSaveAsProject} onClose={toggle}>
      <Box>
        <Box mb={2}>
          <Input type='text' value={name} onChange={onNameChange} fullWidth autoFocus label={`${t('projectName')}`} />
        </Box>
        <Box display='flex' columnGap={1}>
          <Button onClick={onSaveClick} variant='contained' sx={{ flexBasis: '50%' }}>
            {t('save')}
          </Button>

          <Button onClick={toggle} variant='outlined' sx={{ flexBasis: '50%' }}>
            {t('cancel')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
