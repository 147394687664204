import { Fab } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ShepherdTourContext } from 'react-shepherd';
import NavigationIcon from '@mui/icons-material/Navigation';

export const ShepherdButton = () => {
  const { t } = useTranslation();

  const tour = useContext(ShepherdTourContext);

  return (
    <Fab variant="extended" color="primary" sx={{ position: 'fixed', bottom: 16, right: 16 }} onClick={tour?.start}>
      <NavigationIcon sx={{ mr: 1 }} />
      {t('interactiveGuide')}
    </Fab>
  );
};
