import { Mams } from '../models/Mams';

export function GetMams(GetMs: Mams) {
  if (GetMs.dXPOSITION == GetMs.dYPOSITION) {
    GetMs.Yint = GetMs.FIELDRES;
    GetMs.Xint = GetMs.FIELDRES;
    if (
      GetMs.lastplacement == GetMs.TRIANGULAR_Y_LAYOUT ||
      GetMs.lastplacement == GetMs.BILATERAL_TRIANGULAR_LAYOUT
    ) {
      //F
      GetMs.SLOPE = 0.5;
      //triangular
    }
    //cube case
    if (GetMs.dXPOSITION1 == GetMs.dXPOSITION && GetMs.dYPOSITION1 == GetMs.dYPOSITION) {
      GetMs.XMAMTEROT = GetMs.Xint;
      GetMs.YMAMTEROT = GetMs.Yint;
    } else if (
      GetMs.dXPOSITION1 < GetMs.dXPOSITION &&
      GetMs.dYPOSITION1 == GetMs.dYPOSITION
    ) {
      GetMs.XMAMTEROT = (GetMs.Xint * GetMs.dXPOSITION1) / GetMs.dXPOSITION;
      GetMs.YMAMTEROT = GetMs.Yint;
    } else if (
      GetMs.dXPOSITION1 == GetMs.dXPOSITION &&
      GetMs.dYPOSITION1 > GetMs.dYPOSITION
    ) {
      GetMs.XMAMTEROT = GetMs.Xint;
      GetMs.YMAMTEROT = (GetMs.Yint * GetMs.dYPOSITION1) / GetMs.dYPOSITION;
    } else if (
      GetMs.dXPOSITION1 == GetMs.dXPOSITION &&
      GetMs.dYPOSITION1 < GetMs.dYPOSITION
    ) {
      GetMs.XMAMTEROT = GetMs.Xint;
      GetMs.YMAMTEROT = (GetMs.Yint * GetMs.dYPOSITION) / GetMs.dYPOSITION1;
    } else if (
      GetMs.dXPOSITION1 > GetMs.dXPOSITION &&
      GetMs.dYPOSITION1 == GetMs.dYPOSITION
    ) {
      GetMs.XMAMTEROT = (GetMs.Xint * GetMs.dXPOSITION1) / GetMs.dXPOSITION;
      GetMs.YMAMTEROT = GetMs.Yint;
    }

    //x>y
  } else if (GetMs.dXPOSITION > GetMs.dYPOSITION) {
    GetMs.Xint = GetMs.FIELDRES;
    GetMs.Yint = (GetMs.FIELDRES * GetMs.dYPOSITION) / GetMs.dXPOSITION;
    //Int(GetMs.YMAMTEROT / 2 + .5)
    if (
      GetMs.lastplacement == GetMs.TRIANGULAR_Y_LAYOUT ||
      GetMs.lastplacement == GetMs.BILATERAL_TRIANGULAR_LAYOUT
    ) {
      GetMs.SLOPE = GetMs.Yint / GetMs.FIELDRES;
      //triangular
    }
    //cube case
    if (GetMs.dXPOSITION1 == GetMs.dXPOSITION && GetMs.dYPOSITION1 == GetMs.dYPOSITION) {
      GetMs.XMAMTEROT = GetMs.Xint;
      GetMs.YMAMTEROT = GetMs.Yint;
    } else if (
      GetMs.dXPOSITION1 < GetMs.dXPOSITION &&
      GetMs.dYPOSITION1 == GetMs.dYPOSITION
    ) {
      GetMs.XMAMTEROT = (GetMs.Xint * GetMs.dXPOSITION1) / GetMs.dXPOSITION;
      GetMs.YMAMTEROT = GetMs.Yint;
    } else if (
      GetMs.dXPOSITION1 == GetMs.dXPOSITION &&
      GetMs.dYPOSITION1 > GetMs.dYPOSITION
    ) {
      GetMs.XMAMTEROT = GetMs.Xint;
      GetMs.YMAMTEROT = (GetMs.Yint * GetMs.dYPOSITION1) / GetMs.dYPOSITION;
    } else if (
      GetMs.dXPOSITION1 == GetMs.dXPOSITION &&
      GetMs.dYPOSITION1 < GetMs.dYPOSITION
    ) {
      GetMs.XMAMTEROT = GetMs.Xint;
      GetMs.YMAMTEROT = (GetMs.Yint * GetMs.dYPOSITION) / GetMs.dYPOSITION1;
    } else if (
      GetMs.dXPOSITION1 > GetMs.dXPOSITION &&
      GetMs.dYPOSITION1 == GetMs.dYPOSITION
    ) {
      GetMs.XMAMTEROT = (GetMs.Xint * GetMs.dXPOSITION1) / GetMs.dXPOSITION;
      GetMs.YMAMTEROT = GetMs.Yint;
    }

    //y>x
  } else if (GetMs.dXPOSITION < GetMs.dYPOSITION) {
    GetMs.Yint = GetMs.FIELDRES;
    /// 2
    GetMs.Xint = (GetMs.FIELDRES * GetMs.dXPOSITION) / GetMs.dYPOSITION;
    //GetMs.XMAMTEROT
    if (
      GetMs.lastplacement == GetMs.TRIANGULAR_Y_LAYOUT ||
      GetMs.lastplacement == GetMs.BILATERAL_TRIANGULAR_LAYOUT
    ) {
      GetMs.SLOPE = GetMs.dYPOSITION / (2 * GetMs.dXPOSITION);
      //triangular
    }
    //cube case
    if (GetMs.dXPOSITION1 == GetMs.dXPOSITION && GetMs.dYPOSITION1 == GetMs.dYPOSITION) {
      GetMs.XMAMTEROT = GetMs.Xint;
      GetMs.YMAMTEROT = GetMs.Yint;
    } else if (
      GetMs.dXPOSITION1 < GetMs.dXPOSITION &&
      GetMs.dYPOSITION1 == GetMs.dYPOSITION
    ) {
      GetMs.XMAMTEROT = (GetMs.Xint * GetMs.dXPOSITION1) / GetMs.dXPOSITION;
      GetMs.YMAMTEROT = GetMs.Yint;
    } else if (
      GetMs.dXPOSITION1 == GetMs.dXPOSITION &&
      GetMs.dYPOSITION1 > GetMs.dYPOSITION
    ) {
      GetMs.XMAMTEROT = GetMs.Xint;
      GetMs.YMAMTEROT = (GetMs.Yint * GetMs.dYPOSITION1) / GetMs.dYPOSITION;
    } else if (
      GetMs.dXPOSITION1 == GetMs.dXPOSITION &&
      GetMs.dYPOSITION1 < GetMs.dYPOSITION
    ) {
      GetMs.XMAMTEROT = GetMs.Xint;
      GetMs.YMAMTEROT = (GetMs.Yint * GetMs.dYPOSITION1) / GetMs.dYPOSITION;
    } else if (
      GetMs.dXPOSITION1 > GetMs.dXPOSITION &&
      GetMs.dYPOSITION1 == GetMs.dYPOSITION
    ) {
      GetMs.XMAMTEROT = (GetMs.Xint * GetMs.dXPOSITION1) / GetMs.dXPOSITION;
      GetMs.YMAMTEROT = GetMs.Yint;
    }
  }
  let res = {
    XMAMTEROT: GetMs.XMAMTEROT,
    YMAMTEROT: GetMs.YMAMTEROT,
    Yint: GetMs.Yint,
    Xint: GetMs.Xint,
  };
  return res;
}
