import { Line } from './Line';

export function DoEdge(
  EdgeType: number,
  X_EDGE_LAYOUT: number,
  dEDGEPOSITIONX: number,
  dEDGEPOSITIONY: number,
  dXPOSITION: number,
  dYPOSITION: number,
  fieldpic: {
    width: number;
    height: number;
  },
  Y_EDGE_LAYOUT: number,
  CORNER_EDGE_LAYOUT: number,
) {
  let result = [];
  let brush = '#00FFFF';
  if (EdgeType == X_EDGE_LAYOUT) {
    let l: Line = {
      X1: (fieldpic.width * dEDGEPOSITIONX) / dXPOSITION,
      Y1: 0,
      Stroke: brush,
      StrokeThickness: 3.0,
      X2: (fieldpic.width * dEDGEPOSITIONX) / dXPOSITION,
      Y2: fieldpic.height,
      Tag: 'Edge',
    };

    let l1: Line = {
      X1: 0,
      Y1: 0,
      Stroke: '',
      StrokeThickness: 0,
      X2: 0,
      Y2: 0,
      Tag: '',
    };
    result.push(l, l1);
  } else if (EdgeType == Y_EDGE_LAYOUT) {
    let l: Line = {
      X1: 0,
      Y1: (fieldpic.height * dEDGEPOSITIONY) / dYPOSITION,
      Stroke: brush,
      StrokeThickness: 3.0,
      X2: fieldpic.width,
      Y2: (fieldpic.height * dEDGEPOSITIONY) / dYPOSITION,
      Tag: 'Edge',
    };
    let l1: Line = {
      X1: 0,
      Y1: 0,
      Stroke: '',
      StrokeThickness: 0,
      X2: 0,
      Y2: 0,
      Tag: '',
    };
    result.push(l, l1);
  } else if (EdgeType == CORNER_EDGE_LAYOUT) {
    let l1: Line = {
      X1: (fieldpic.width * dEDGEPOSITIONX) / dXPOSITION,
      Y1: 0,
      Stroke: brush,
      StrokeThickness: 3.0,
      X2: (fieldpic.width * dEDGEPOSITIONX) / dXPOSITION,
      Y2: (fieldpic.height * dEDGEPOSITIONY) / dYPOSITION,
      Tag: 'Edge',
    };

    let l2: Line = {
      X1: 0,
      Y1: (fieldpic.height * dEDGEPOSITIONY) / dYPOSITION,
      Stroke: brush,
      StrokeThickness: 3.0,
      X2: (fieldpic.width * dEDGEPOSITIONX) / dXPOSITION,
      Y2: (fieldpic.height * dEDGEPOSITIONY) / dYPOSITION,
      Tag: 'Edge',
    };

    result.push(l1, l2);
  }
  return result;
}
