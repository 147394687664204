import { UpdateConnectingLine } from './UpdateConnectingLine';
import { UpdateGraph } from './UpdateGraph';

export const SelectionGraph = (
  fieldpic: { width: number; height: number },
  XMAMTEROT: number,
  YMAMTEROT: number,
  FieldRainAt: Array<Array<number>>,
  Placement: number,
  tempdrainat: Array<Array<number>>,
  mouseClickPositionY1: number,
  mouseClickPositionY2: number,
) => {
  const ConnectingLine = UpdateConnectingLine(
    mouseClickPositionY1,
    mouseClickPositionY2,
    fieldpic,
  );
  const result = UpdateGraph(
    XMAMTEROT,
    YMAMTEROT,
    fieldpic,
    FieldRainAt,
    Placement,
    tempdrainat,
    ConnectingLine,
  );
  return result;
};
