export const splitTextIntoLines = (text: string | number, font: any, fontSize: number, maxWidth: number): string[] => {
  const textStr = text.toString();
  if (!textStr.includes(' ')) return [textStr];

  const words = textStr.split(' ')
  const lines: string[] = [];
  let currentLine = '';

  words.forEach((word) => {
    const testLine = currentLine ? `${currentLine} ${word}` : word;
    const testWidth = font.widthOfTextAtSize(testLine, fontSize);

    if (testWidth < maxWidth) {
      currentLine = testLine;
    } else {
      lines.push(currentLine);
      currentLine = word;
    }
  });

  if (currentLine) {
    lines.push(currentLine);
  }

  return lines;
};
