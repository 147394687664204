import { SelectChangeEvent, useMediaQuery } from '@mui/material';
import {
  saveUnitsThunk,
  setEDCStateValue,
  setLateralStateValue,
  setMainlineStateValue,
  setProjectValue,
  setSubmainStateValue,
  setSystemSummaryStateValue,
  setUnitsValue,
  toggleUnits,
} from 'shared/slices';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { METRIC_DEFAULTS, US_DEFAULTS } from 'shared/constants';
import { useAppDispatch, useAppSelector, useToast } from 'shared/hooks';
import { Math_round } from 'shared/lib/calculation/mathRound';
import { ConvertUnit } from 'shared/lib/calculation/unitConverter';
import { Modal } from 'shared/ui';
import {
  formattedInputValue,
  getConvertedEDCInputValues,
  getConvertedEDCResultValues,
  getConvertedEDCTableValues,
  getConvertedLateralCalculations,
  getConvertedMainlineValues,
  getConvertedProjectValues,
  getConvertedSubmainValues,
  getConvertedSystemSummaryValues,
} from 'shared/lib';
import { DesktopView } from './DesktopView';
import { MobileView } from './MobileView';

const convertUnit = (value: number, source: string, destination: string, unitType: string | null = null) =>
  Math_round(ConvertUnit(value, source, destination, unitType), 6);

export const Units = () => {
  const { t } = useTranslation();
  const { showError, showSuccess } = useToast();
  const dispatch = useAppDispatch();
  const { openUnits } = useAppSelector((st) => st.modals);
  const { units } = useAppSelector((state) => state.units);
  const { projectData } = useAppSelector((state) => state.projectData);
  const { lateralValues } = useAppSelector((state) => state.lateralState);
  const { submainValues } = useAppSelector((state) => state.submainState);
  const { mainlineValues } = useAppSelector((state) => state.mainlineState);
  const { systemSummaryValues } = useAppSelector((state) => state.systemSummaryState);
  const { edcValues } = useAppSelector((state) => state.edcState);
  const isMobile = useMediaQuery('(max-width:550px)');

  const [unitValues, setUnitValues] = useState({
    pressure: units.pressure,
    flowPerLength: units.flowPerLength,
    flow: units.flow,
    totalFlow: units.totalFlow,
    length: units.length,
    velocity: units.velocity,
    pipeDiameter: units.pipeDiameter,
    emitterSpacing: units.emitterSpacing,
    lateralSpacing: units.lateralSpacing,
    area: units.area,
    appnDepth: units.appnDepth,
    appnRate: units.appnRate,
  });

  const [convertUnitValues, setConvertUnitValues] = useState({
    pressure: units.pressure,
    flowPerLength: units.flowPerLength,
    flow: units.flow,
    totalFlow: units.totalFlow,
    length: units.length,
    velocity: units.velocity,
    pipeDiameter: units.pipeDiameter,
    emitterSpacing: units.emitterSpacing,
    lateralSpacing: units.lateralSpacing,
    area: units.area,
    appnDepth: units.appnDepth,
    appnRate: units.appnRate,
  });

  const [values, setValues] = useState({
    pressure: 1,
    flowPerLength: 1,
    flow: 1,
    totalFlow: 1,
    length: 1,
    velocity: 1,
    pipeDiameter: 1,
    emitterSpacing: 1,
    lateralSpacing: 1,
    area: 1,
    appnDepth: 1,
    appnRate: 1,
  });

  const [convertedValues, setConvertedValues] = useState({
    pressure: 1,
    flowPerLength: 1,
    flow: 1,
    totalFlow: 1,
    length: 1,
    velocity: 1,
    pipeDiameter: 1,
    emitterSpacing: 1,
    lateralSpacing: 1,
    area: 1,
    appnDepth: 1,
    appnRate: 1,
  });

  const handleValuesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputName = e.target.name;
    const inputValue = +e.target.value;

    const sourceKey = inputName as keyof typeof unitValues;
    const sourceUnit = unitValues[sourceKey];
    const destinationKey = inputName as keyof typeof convertUnitValues;
    const destinationUnit = convertUnitValues[destinationKey];

    const convertedKey = inputName as keyof typeof convertedValues;
    const convertedValue = Math_round(ConvertUnit(inputValue, sourceUnit, destinationUnit, null), 6);

    setValues((prev) => ({ ...prev, [e.target.name]: formattedInputValue(e) }));
    setConvertedValues((prev) => ({ ...prev, [convertedKey]: convertedValue }));
  };

  const handleUnitChange = (e: SelectChangeEvent<unknown>) => {
    const inputName = e.target.name;
    const inputValue = e.target.value as string;

    const valueKey = inputName as keyof typeof values;
    const value = values[valueKey];
    const destinationKey = inputName as keyof typeof convertUnitValues;
    const destinationUnit = convertUnitValues[destinationKey];

    const convertedKey = inputName as keyof typeof convertedValues;
    const convertedValue = Math_round(ConvertUnit(value, inputValue, destinationUnit, null), 6);

    setUnitValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    setConvertedValues((prev) => ({ ...prev, [convertedKey]: convertedValue }));
  };

  const handleConvertUnitChange = (e: SelectChangeEvent<unknown>) => {
    const inputName = e.target.name;
    const inputValue = e.target.value as string;

    const valueKey = inputName as keyof typeof values;
    const value = values[valueKey];
    const sourceKey = inputName as keyof typeof unitValues;
    const sourceUnit = unitValues[sourceKey];

    const convertedKey = inputName as keyof typeof convertedValues;
    const convertedValue = Math_round(ConvertUnit(value, sourceUnit, inputValue, null), 6);

    setConvertUnitValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    setConvertedValues((prev) => ({ ...prev, [convertedKey]: convertedValue }));
  };

  const toggle = () => dispatch(toggleUnits());

  const onMetricDefaultsClick = () => {
    setUnitValues({
      pressure: METRIC_DEFAULTS.Pressure,
      flowPerLength: METRIC_DEFAULTS.FlowPerLength,
      flow: METRIC_DEFAULTS.Flow,
      totalFlow: METRIC_DEFAULTS.TotalFlow,
      length: METRIC_DEFAULTS.Length,
      velocity: METRIC_DEFAULTS.Velocity,
      pipeDiameter: METRIC_DEFAULTS.PipeDiameter,
      emitterSpacing: METRIC_DEFAULTS.EmitterSpacing,
      lateralSpacing: METRIC_DEFAULTS.LateralSpacing,
      area: METRIC_DEFAULTS.Area,
      appnDepth: METRIC_DEFAULTS.AppnDepth,
      appnRate: METRIC_DEFAULTS.AppnRate,
    });
    const convertedPressure = convertUnit(values.pressure, METRIC_DEFAULTS.Pressure, convertUnitValues.pressure);
    const convertedFlowPerLength = convertUnit(
      values.flowPerLength,
      METRIC_DEFAULTS.FlowPerLength,
      convertUnitValues.flowPerLength
    );
    const convertedFlow = convertUnit(values.flow, METRIC_DEFAULTS.Flow, convertUnitValues.flow);
    const convertedTotalFlow = convertUnit(values.totalFlow, METRIC_DEFAULTS.TotalFlow, convertUnitValues.totalFlow);
    const convertedLength = convertUnit(values.length, METRIC_DEFAULTS.Length, convertUnitValues.length);
    const convertedVelocity = convertUnit(values.velocity, METRIC_DEFAULTS.Velocity, convertUnitValues.velocity);
    const convertedPipeDiameter = convertUnit(
      values.pipeDiameter,
      METRIC_DEFAULTS.PipeDiameter,
      convertUnitValues.pipeDiameter
    );
    const convertedEmitterSpacing = convertUnit(
      values.emitterSpacing,
      METRIC_DEFAULTS.EmitterSpacing,
      convertUnitValues.emitterSpacing
    );
    const convertedLateralSpacing = convertUnit(
      values.lateralSpacing,
      METRIC_DEFAULTS.LateralSpacing,
      convertUnitValues.lateralSpacing
    );
    const convertedArea = convertUnit(values.area, METRIC_DEFAULTS.Area, convertUnitValues.area);
    const convertedAppnDepth = convertUnit(values.appnDepth, METRIC_DEFAULTS.AppnDepth, convertUnitValues.appnDepth);
    const convertedAppnRate = convertUnit(values.appnRate, METRIC_DEFAULTS.AppnRate, convertUnitValues.appnRate);

    setConvertedValues({
      pressure: convertedPressure,
      flowPerLength: convertedFlowPerLength,
      flow: convertedFlow,
      totalFlow: convertedTotalFlow,
      length: convertedLength,
      velocity: convertedVelocity,
      pipeDiameter: convertedPipeDiameter,
      emitterSpacing: convertedEmitterSpacing,
      lateralSpacing: convertedLateralSpacing,
      area: convertedArea,
      appnDepth: convertedAppnDepth,
      appnRate: convertedAppnRate,
    });
  };

  const onUSDefaultsClick = () => {
    setUnitValues({
      pressure: US_DEFAULTS.Pressure,
      flowPerLength: US_DEFAULTS.FlowPerLength,
      flow: US_DEFAULTS.Flow,
      totalFlow: US_DEFAULTS.TotalFlow,
      length: US_DEFAULTS.Length,
      velocity: US_DEFAULTS.Velocity,
      pipeDiameter: US_DEFAULTS.PipeDiameter,
      emitterSpacing: US_DEFAULTS.EmitterSpacing,
      lateralSpacing: US_DEFAULTS.LateralSpacing,
      area: US_DEFAULTS.Area,
      appnDepth: US_DEFAULTS.AppnDepth,
      appnRate: US_DEFAULTS.AppnRate,
    });

    const convertedPressure = convertUnit(values.pressure, US_DEFAULTS.Pressure, convertUnitValues.pressure);
    const convertedFlowPerLength = convertUnit(
      values.flowPerLength,
      US_DEFAULTS.FlowPerLength,
      convertUnitValues.flowPerLength
    );
    const convertedFlow = convertUnit(values.flow, US_DEFAULTS.Flow, convertUnitValues.flow);
    const convertedTotalFlow = convertUnit(values.totalFlow, US_DEFAULTS.TotalFlow, convertUnitValues.totalFlow);
    const convertedLength = convertUnit(values.length, US_DEFAULTS.Length, convertUnitValues.length);
    const convertedVelocity = convertUnit(values.velocity, US_DEFAULTS.Velocity, convertUnitValues.velocity);
    const convertedPipeDiameter = convertUnit(
      values.pipeDiameter,
      US_DEFAULTS.PipeDiameter,
      convertUnitValues.pipeDiameter
    );
    const convertedEmitterSpacing = convertUnit(
      values.emitterSpacing,
      US_DEFAULTS.EmitterSpacing,
      convertUnitValues.emitterSpacing
    );
    const convertedLateralSpacing = convertUnit(
      values.lateralSpacing,
      US_DEFAULTS.LateralSpacing,
      convertUnitValues.lateralSpacing
    );
    const convertedArea = convertUnit(values.area, US_DEFAULTS.Area, convertUnitValues.area);
    const convertedAppnDepth = convertUnit(values.appnDepth, US_DEFAULTS.AppnDepth, convertUnitValues.appnDepth);
    const convertedAppnRate = convertUnit(values.appnRate, US_DEFAULTS.AppnRate, convertUnitValues.appnRate);

    setConvertedValues({
      pressure: convertedPressure,
      flowPerLength: convertedFlowPerLength,
      flow: convertedFlow,
      totalFlow: convertedTotalFlow,
      length: convertedLength,
      velocity: convertedVelocity,
      pipeDiameter: convertedPipeDiameter,
      emitterSpacing: convertedEmitterSpacing,
      lateralSpacing: convertedLateralSpacing,
      area: convertedArea,
      appnDepth: convertedAppnDepth,
      appnRate: convertedAppnRate,
    });
  };

  const onSaveClick = async () => {
    const unitsObj = {
      pressure: unitValues.pressure,
      flowPerLength: unitValues.flowPerLength,
      flow: unitValues.flow,
      totalFlow: unitValues.totalFlow,
      length: unitValues.length,
      velocity: unitValues.velocity,
      pipeDiameter: unitValues.pipeDiameter,
      emitterSpacing: unitValues.emitterSpacing,
      lateralSpacing: unitValues.lateralSpacing,
      area: unitValues.area,
      appnDepth: unitValues.appnDepth,
      appnRate: unitValues.appnRate,
    };
    dispatch(setUnitsValue(unitsObj));
    dispatch(setProjectValue(getConvertedProjectValues({ projectData, sourceUnits: units, destUnits: unitsObj })));
    dispatch(
      setLateralStateValue(getConvertedLateralCalculations({ lateralValues, sourceUnits: units, destUnits: unitsObj }))
    );
    dispatch(
      setSubmainStateValue(getConvertedSubmainValues({ submainValues, sourceUnits: units, destUnits: unitsObj }))
    );
    dispatch(
      setMainlineStateValue(getConvertedMainlineValues({ mainlineValues, sourceUnits: units, destUnits: unitsObj }))
    );
    dispatch(
      setSystemSummaryStateValue(
        getConvertedSystemSummaryValues({ systemSummaryValues, sourceUnits: units, destUnits: unitsObj })
      )
    );

    const edcTableValues = getConvertedEDCTableValues({ edcValues, sourceUnits: units, destUnits: unitsObj });
    const edcResultValues = getConvertedEDCResultValues({ edcValues, sourceUnits: units, destUnits: unitsObj });
    const edcInputValues = getConvertedEDCInputValues({ edcValues, sourceUnits: units, destUnits: unitsObj });

    dispatch(setEDCStateValue({ ...edcTableValues, ...edcResultValues, ...edcInputValues }));

    const userId = localStorage.getItem('ht-user-id');

    if (!userId) return;

    const result = await dispatch(
      saveUnitsThunk({ id: userId, htUserUnits: { ...unitsObj, userID: userId } })
    ).unwrap();

    if (result && Object.keys(result?.data).length) showSuccess(t('unitsSaved'));
    if (result && !Object.keys(result?.data).length) showError(t('SomethingWentWrong'));

    toggle();
  };

  return (
    <Modal margin={1} title={t('units')} open={openUnits} onClose={toggle}>
      {isMobile ? (
        <MobileView
          convertUnitValues={convertUnitValues}
          convertedValues={convertedValues}
          handleConvertUnitChange={handleConvertUnitChange}
          handleUnitChange={handleUnitChange}
          handleValuesChange={handleValuesChange}
          onMetricDefaultsClick={onMetricDefaultsClick}
          onSaveClick={onSaveClick}
          onUSDefaultsClick={onUSDefaultsClick}
          toggle={toggle}
          unitValues={unitValues}
          values={values}
        />
      ) : (
        <DesktopView
          convertUnitValues={convertUnitValues}
          convertedValues={convertedValues}
          handleConvertUnitChange={handleConvertUnitChange}
          handleUnitChange={handleUnitChange}
          handleValuesChange={handleValuesChange}
          onMetricDefaultsClick={onMetricDefaultsClick}
          onSaveClick={onSaveClick}
          onUSDefaultsClick={onUSDefaultsClick}
          toggle={toggle}
          unitValues={unitValues}
          values={values}
        />
      )}
    </Modal>
  );
};
