import {
  Box,
  CircularProgress,
  InputAdornment,
  Select as MuiSelect,
  SelectProps,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { forwardRef } from 'react';

type Props = SelectProps & {
  label?: string;
  count?: number;
  tooltip?: string;
  loading?: boolean;
  labelInput?: JSX.Element;
  paperMaxHeight?: string;
};

export const Select = forwardRef<unknown, Props>(
  ({ label, count, tooltip, loading, labelInput, paperMaxHeight, ...props }, ref) => {
    const { palette } = useTheme();

    return (
      <Box sx={{ width: '100%' }}>
        {label && (
          <Box display="flex" justifyContent="space-between">
            <Tooltip title={tooltip ?? ''} placement="top">
              <Typography
                noWrap
                sx={{
                  fontSize: '11px',
                  // textTransform: 'uppercase',
                  color: palette.secondary.main,
                  marginBottom: '3px',
                }}
              >
                {label}
              </Typography>
            </Tooltip>
            {labelInput}
          </Box>
        )}
        <Box display="flex" alignItems="center">
          {count && (
            <Typography
              sx={{
                fontSize: '13px',
                textTransform: 'uppercase',
                color: palette.info.dark,
                fontWeight: 500,
                mr: 1,
              }}
            >
              {`${count}:`}
            </Typography>
          )}
          <MuiSelect
            {...props}
            fullWidth
            sx={{
              background: palette.info.main,
              height: 34,
              fontSize: 15,
              borderRadius: '9px',
            }}
            endAdornment={
              loading ? (
                <InputAdornment sx={{ mr: 3 }} position="end">
                  <CircularProgress color="inherit" size={20} />
                </InputAdornment>
              ) : null
            }
            MenuProps={{ PaperProps: { sx: { maxHeight: paperMaxHeight ?? '40%' } } }}
            inputRef={ref}
          >
            {props.children}
          </MuiSelect>
        </Box>
      </Box>
    );
  }
);
