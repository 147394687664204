import { TextField, TextFieldProps, useTheme } from '@mui/material';
import { forwardRef } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';

export const SearchBar = forwardRef<unknown, TextFieldProps>((props, ref) => {
  const { palette } = useTheme();
  const { t } = useTranslation();

  return (
    <TextField
      inputRef={ref}
      color="primary"
      placeholder={`${t('search')}...`}
      fullWidth
      InputProps={{
        sx: {
          height: '40px',
          borderRadius: '9px',
          borderColor: palette.error.main,
        },
        endAdornment: <SearchIcon sx={{ color: 'primary.main' }} />,
      }}
      sx={{
        background: palette.info.main,
        borderRadius: '9px',
      }}
      {...props}
    />
  );
});
