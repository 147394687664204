import { rotateLeft } from './rotateLeft';
import { rotateRight } from './rotateRight';

export const getTransformedArray = (array: any[][], placement: number, edge: number) => {
  if (placement === 0) {
    switch (edge) {
      case 1: {
        return rotateLeft(array);
      }
      case 2: {
        return rotateRight(array);
      }
      case 0:
        return rotateRight(array);
      case 3:
      default:
        return array;
    }
  } else if (placement === 1) {
    switch (edge) {
      case 1: {
        return rotateLeft(array);
      }
      case 0:
      case 2: {
        return rotateRight(array);
      }
      case 3:
      default:
        return array;
    }
  } else if (placement === 2) {
    switch (edge) {
      case 2:
      case 0: {
        return rotateRight(array);
      }
      case 1: {
        return rotateLeft(array);
      }
      case 3:
      default:
        return array;
    }
  } else {
    switch (edge) {
      case 1: {
        return rotateLeft(array);
      }
      case 2: {
        return rotateRight(array);
      }
      case 3:
      case 0:
      default:
        return array;
    }
  }
};
