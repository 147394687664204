import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { plantingDiagram } from "shared/assets";
import selectProductBubble from '../assets/select-product-bubble.png'

export const useEDC = () => {

  const [isMac, setIsMac] = useState<boolean>(false)

  const {t} = useTranslation()

  useEffect(() => {
    setIsMac(window.innerWidth >= 1400);
  }, []);

const EDC_1: any = useMemo(() => ({
  id: 'edc-1',
  attachTo: {
    element: '#edc-select-product',
    on: 'right-top',
  },
  scrollTo: true,
  text: [
    `
    <div style="width: 581px; overflow-wrap: break-word">
      <strong class="shepherd-text">${t('edc1-1')}</strong>
      <ol>
        <li class="shepherd-text">${t('edc1-2')}</li>
        <li class="shepherd-text">${t('edc1-3')} <strong>${t('edc1-4')}</strong> ${t('edc1-5')}</strong></li>
      </ol>
    </div>
    `,
  ],
  buttons: [
    {
      classes: 'btn btn-info',
      text: 'Finish',
      type: 'cancel',
    },
    {
      classes: 'btn btn-success',
      text: 'Next',
      type: 'next',
    },
  ],
  modalOverlayOpeningPadding: 3,
  useModalOverlay: true,
  canClickTarget: false,
}), [t]);

const EDC_2: any = useMemo(() => ({
  id: 'edc-2',
  attachTo: {
    element: '#edc-buttons',
    on: 'right-top',
  },
  scrollTo: true,
  text: [
    `
    <div style="width: 581px; overflow-wrap: break-word">
      <p class="shepherd-text"><strong>${t('edc2-1')}</strong> ${t('edc2-2')}</p> 
      <p class="shepherd-text"><strong>${t('edc2-3')}</strong> ${t('edc2-4')}</p>
    </div>
    `,
  ],
  buttons: [
    {
      classes: 'btn btn-info',
      text: 'Back',
      type: 'back',
    },
    {
      classes: 'btn btn-success',
      text: 'Next',
      type: 'next',
    },
  ],
  modalOverlayOpeningPadding: 3,
  useModalOverlay: true,
  canClickTarget: false,
}), [t]);

const EDC_3: any = useMemo(() => ({
  id: 'edc-3',
  attachTo: {
    element: '#edc-diagram',
    on: 'right-top',
  },
  scrollTo: true,
  text: [
    `
    <div style="width: 561px; overflow-wrap: break-word">
      <p class="shepherd-text">${t('edc3-1')}</p> 
    </div>
    `,
  ],
  buttons: [
    {
      classes: 'btn btn-info',
      text: 'Back',
      type: 'back',
    },
    {
      classes: 'btn btn-success',
      text: 'Next',
      type: 'next',
    },
  ],
  modalOverlayOpeningPadding: 3,
  useModalOverlay: true,
  canClickTarget: false,
}), [t]);

const EDC_4: any = useMemo(() => ({
  id: 'edc-4',
  attachTo: {
    element: '#edc-planting-diagram',
    on: 'top-right',
  },
  scrollTo: true,
  text: [
    `
    <div style="width: ${isMac ? '400px' : '694px'}; height: ${isMac ? '374px' : '620px'}; overflow-wrap: break-word">
      <img style="height: ${isMac ? '370px' : '568px'};" src='${plantingDiagram}' />
    </div>
    `,
  ],
  buttons: [
    {
      classes: 'btn btn-info',
      text: 'Back',
      type: 'back',
    },
    {
      classes: 'btn btn-success',
      text: 'Next',
      type: 'next',
    },
  ],
  modalOverlayOpeningPadding: 3,
  useModalOverlay: true,
  canClickTarget: false,
}), [t]);

// const EDC_5: any = useMemo(() => ({
//   id: 'edc-5',
//   attachTo: {
//     element: '#edc-planting-diagram',
//     on: 'right-top',
//   },
//   scrollTo: true,
//   text: [
//     `
//     <div style="width: 581px; height: 361px; overflow-wrap: break-word">
//       <p class="shepherd-text">${t('edc5-1')}</p>
//       <p class="shepherd-text">${t('edc5-2')}</p>
//       <p class="shepherd-text">${t('edc5-3')}</p>
//       <p class="shepherd-text">${t('edc5-4')}</p>
//     </div>
//     `,
//   ],
//   buttons: [
//     {
//       classes: 'btn btn-info',
//       text: 'Back',
//       type: 'back',
//     },
//     {
//       classes: 'btn btn-success',
//       text: 'Next',
//       type: 'next',
//     },
//   ],
//   modalOverlayOpeningPadding: 3,
//   useModalOverlay: true,
//   canClickTarget: false,
// }), [t]);

const EDC_6: any = useMemo(() => ({
  id: 'edc-6',
  attachTo: {
    element: '#edc-positioning',
    on: 'right-top',
  },
  scrollTo: true,
  text: [
    `
    <div style="width: ${isMac ? '400px' : '745px'}; height: ${isMac ? '284px' : '536px'}; overflow: auto; overflow-wrap: break-word">
      <img style="width: ${isMac ? '280px' : '745px'}" src='${selectProductBubble}' />
      <p class="shepherd-text">${t('edc5-1')}</p>
      <p class="shepherd-text">${t('edc5-2')}</p>
      <p class="shepherd-text">${t('edc5-3')}</p>
      <p class="shepherd-text">${t('edc5-4')}</p>
    </div>
    `,
  ],
  buttons: [
    {
      classes: 'btn btn-info',
      text: 'Back',
      type: 'back',
    },
    {
      classes: 'btn btn-success',
      text: 'Next',
      type: 'next',
    },
  ],
  modalOverlayOpeningPadding: 3,
  useModalOverlay: true,
  canClickTarget: false,
}), [t]);

const EDC_7: any = useMemo(() => ({
  id: 'edc-7',
  attachTo: {
    element: '#edc-positioning-2',
    on: 'right-top',
  },
  scrollTo: true,
  text: [
    `
    <div style="width: 581px; height: 261px; overflow-wrap: break-word">
      <strong class='shepherd-text'>${t('edc7-1')}</strong>
      <p class='shepherd-text'>${t('edc7-1-1')}</p>
    </div>
    `,
  ],
  buttons: [
    {
      classes: 'btn btn-info',
      text: 'Back',
      type: 'back',
    },
    {
      classes: 'btn btn-success',
      text: 'Next',
      type: 'next',
    },
  ],
  modalOverlayOpeningPadding: 3,
  useModalOverlay: true,
  canClickTarget: false,
}), [t]);

const EDC_8: any = useMemo(() => ({
  id: 'edc-8',
  attachTo: {
    element: '#edc-results',
    on: 'right-top',
  },
  scrollTo: true,
  text: [
    `
    <div style="width: 581px; height: 136px; overflow-wrap: break-word">
      <p class='shepherd-text'>${t('edc8-1')}</p>
    </div>
    `,
  ],
  buttons: [
    {
      classes: 'btn btn-info',
      text: 'Back',
      type: 'back',
    },
    {
      classes: 'btn btn-success',
      text: 'Next',
      type: 'next',
    },
  ],
  modalOverlayOpeningPadding: 3,
  useModalOverlay: true,
  canClickTarget: false,
}), [t]);

const EDC_9: any = useMemo(() => ({
  id: 'edc-9',
  attachTo: {
    element: '#edc-numbers',
    on: 'left',
  },
  scrollTo: true,
  text: [
    `
    <div style="width: 581px; height: 131px; overflow-wrap: break-word">
      <p class='shepherd-text'>${t('edc9-1')}</p>
    </div>
    `,
  ],
  buttons: [
    {
      classes: 'btn btn-info',
      text: 'Back',
      type: 'back',
    },
    {
      classes: 'btn btn-success',
      text: 'Next',
      type: 'next',
    },
  ],
  modalOverlayOpeningPadding: 3,
  useModalOverlay: true,
  canClickTarget: false,
}), [t]);

const EDC_10: any = useMemo(() => ({
  id: 'edc-10',
  attachTo: {
    element: '#edc-range',
    on: 'left',
  },
  scrollTo: true,
  text: [
    `
    <div style="width: 581px; height: 131px; overflow-wrap: break-word">
      <p class='shepherd-text'>${t('edc10-1')}</p>
    </div>
    `,
  ],
  buttons: [
    {
      classes: 'btn btn-info',
      text: 'Back',
      type: 'back',
    },
    {
      classes: 'btn btn-success',
      text: 'Next',
      type: 'next',
    },
  ],
  modalOverlayOpeningPadding: 3,
  useModalOverlay: true,
  canClickTarget: false,
}), [t]);

const EDC_11: any = useMemo(() => ({
  id: 'edc-11',
  attachTo: {
    element: '#edc-plants',
    on: 'left',
  },
  scrollTo: true,
  text: [
    `
    <div style="width: 581px; height: 131px; overflow-wrap: break-word">
      <p class='shepherd-text'>${t('edc11-1')}</p>
    </div>
    `,
  ],
  buttons: [
    {
      classes: 'btn btn-info',
      text: 'Back',
      type: 'back',
    },
    {
      classes: 'btn btn-success',
      text: 'Next',
      type: 'next',
    },
  ],
  modalOverlayOpeningPadding: 3,
  useModalOverlay: true,
  canClickTarget: false,
}), [t]);

const EDC_12: any = useMemo(() => ({
  id: 'edc-12',
  attachTo: {
    element: '#edc-section-graph',
    on: 'left',
  },
  scrollTo: true,
  text: [
    `
    <div style="width: 581px; height: 131px; overflow-wrap: break-word">
      <p class='shepherd-text'>${t('edc12-1')}</p>
    </div>
    `,
  ],
  buttons: [
    {
      classes: 'btn btn-info',
      text: 'Back',
      type: 'back',
    },
    {
      classes: 'btn btn-success',
      text: 'Next',
      type: 'next',
    },
  ],
  modalOverlayOpeningPadding: 3,
  useModalOverlay: true,
  canClickTarget: false,
}), [t]);

const EDC_13: any = useMemo(() => ({
  id: 'edc-13',
  attachTo: {
    element: '#edc-growth',
    on: 'left',
  },
  scrollTo: true,
  text: [
    `
    <div style="width: 581px; height: 131px; overflow-wrap: break-word">
      <p class='shepherd-text'>${t('edc13-1')}</p>
    </div>
    `,
  ],
  buttons: [
    {
      classes: 'btn btn-info',
      text: 'Back',
      type: 'back',
    },
    {
      classes: 'btn btn-success',
      text: 'Next',
      type: 'next',
    },
  ],
  modalOverlayOpeningPadding: 3,
  useModalOverlay: true,
  canClickTarget: false,
}), [t]);

const EDC_14: any = useMemo(() => ({
  id: 'edc-14',
  attachTo: {
    element: '#edc-zoom',
    on: 'left',
  },
  scrollTo: true,
  text: [
    `
    <div style="width: 581px; height: 131px; overflow-wrap: break-word">
      <p class='shepherd-text'>${t('edc14-1')}</p>
    </div>
    `,
  ],
  buttons: [
    {
      classes: 'btn btn-info',
      text: 'Back',
      type: 'back',
    },
    {
      classes: 'btn btn-success',
      text: 'Next',
      type: 'next',
    },
  ],
  modalOverlayOpeningPadding: 3,
  useModalOverlay: true,
  canClickTarget: false,
}), [t]);

const EDC_15: any = useMemo(() => ({
  id: 'edc-15',
  attachTo: {
    element: '#edc-timer',
    on: 'left',
  },
  scrollTo: true,
  text: [
    `
    <div style="width: 581px; height: 131px; overflow-wrap: break-word">
      <p class='shepherd-text'>${t('edc15-1')}</p>
    </div>
    `,
  ],
  buttons: [
    {
      classes: 'btn btn-info',
      text: 'Back',
      type: 'back',
    },
    {
      classes: 'btn btn-success',
      text: 'Next',
      type: 'next',
    },
  ],
  modalOverlayOpeningPadding: 3,
  useModalOverlay: true,
  canClickTarget: false,
}), [t]);

const EDC_16: any = useMemo(() => ({
  id: 'edc-16',
  attachTo: {
    element: '#edc-16',
    on: 'left',
  },
  scrollTo: true,
  text: [
    `
    <div style="width: 561px; overflow-wrap: break-word">
      <p class='shepherd-text'><strong>${t('edc7-1')}</strong></p>
      <p class='shepherd-text'>${t('edc7-2')}</p>
      <p class='shepherd-text'>${t('edc7-3')}</p>
      <p class='shepherd-text'>${t('edc7-4')}</p>
    </div>
    `,
  ],
  buttons: [
    {
      classes: 'btn btn-info',
      text: 'Back',
      type: 'back',
    },
    {
      classes: 'btn btn-success',
      text: 'Next',
      type: 'next',
    },
  ],
  modalOverlayOpeningPadding: 3,
  useModalOverlay: true,
  canClickTarget: false,
}), [t]);

const EDC_17: any = useMemo(() => ({
  id: 'edc-17',
  attachTo: {
    element: '#edc-17',
    on: 'left',
  },
  scrollTo: true,
  text: [
    `
    <div style="width: 561px; overflow-wrap: break-word">
      <p class='shepherd-text'><strong>${t('edc7-1')}</strong></p>
      <p class='shepherd-text'>${t('edc17-1')}</p>
      <ol>
        <li class='shepherd-text'>${t('edc17-2')}</li>
        <li class='shepherd-text'>${t('edc17-3')}</li>
      </ol>
      <p class='shepherd-text'>${t('edc17-4')}</p>
    </div>
    `,
  ],
  buttons: [
    {
      classes: 'btn btn-info',
      text: 'Back',
      type: 'back',
    },
    {
      classes: 'btn btn-success',
      text: 'Next',
      type: 'next',
    },
  ],
  modalOverlayOpeningPadding: 3,
  useModalOverlay: true,
  canClickTarget: false,
}), [t]);


return {
  EDC_1,
  EDC_2,
  EDC_3,
  EDC_4,
  EDC_6,
  EDC_7,
  EDC_8,
  EDC_9,
  EDC_10,
  EDC_11,
  EDC_12,
  EDC_13,
  EDC_14,
  EDC_15,
  EDC_16,
  EDC_17
}

}