import { useAppSelector } from 'shared/hooks';
import { Loader } from 'shared/ui';
import { EDC } from 'widgets/EDC';

export const EDCPage = () => {
  //TODO: ADD LOADED ONCE PARAMS!
  const projectsLoading = useAppSelector((state) => state.projects.loading);
  const masterGroupsLoading = useAppSelector((state) => state.masterGroups.loading);
  const unitsLoading = useAppSelector((state) => state.units.loading);
  const projectDataLoading = useAppSelector((state) => state.projectData.loading);
  const emitterFamiliesLoadedOnce = useAppSelector((state) => state.emitterFamilies.emitterFamiliesLoadedOnce);
  const comparisonEmitterFamiliesLoadedOnce = useAppSelector(
    (state) => state.emitterFamilies.comparisonEmitterFamiliesLoadedOnce
  );
  const emittersEDCLoadedOnce = useAppSelector((state) => state.emittersEDC.emittersEDCLoadedOnce);
  const comparisonEmittersEDCLoadedOnce = useAppSelector((state) => state.emittersEDC.comparisonEmittersEDCLoadedOnce);

  const loading =
    projectsLoading ||
    masterGroupsLoading ||
    unitsLoading ||
    projectDataLoading ||
    !comparisonEmitterFamiliesLoadedOnce ||
    !comparisonEmittersEDCLoadedOnce ||
    !emitterFamiliesLoadedOnce ||
    !emittersEDCLoadedOnce;

  return !loading ? <EDC /> : <Loader centeredAbsolute />;
};
