import { FC, useEffect } from 'react';
import { List, Box, Drawer, useMediaQuery } from '@mui/material';
import { BottomButtons, NavButton, NestedItemsButton, RegionBlock } from './components';
import { NAV_ITEMS, NESTED_ITEMS } from './constants';
import { useSideMenu } from 'shared/hooks';
import { useTranslation } from 'react-i18next';

export const SideMenu: FC = () => {
  const isLaptopL = useMediaQuery('(min-width:1200px)');

  const { sideMenuIsOpened, closeSideMenu } = useSideMenu();
  const { t } = useTranslation();

  useEffect(() => closeSideMenu(), [isLaptopL]);

  return (
    <Drawer
      open={sideMenuIsOpened}
      onClose={closeSideMenu}
      variant={isLaptopL ? 'permanent' : 'temporary'}
      anchor={isLaptopL ? 'left' : 'right'}
      sx={{ width: '185px', minWidth: isLaptopL ? 0 : '185px' }}
      PaperProps={{ sx: { backgroundColor: 'transparent', border: 'none' } }}
    >
      <List
        disablePadding
        sx={{
          mt: { xs: 0, lg: '44px' },
          pt: { xs: '10px', lg: 0 },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          minHeight: { xs: '100%', lg: 'calc(100% - 44px)' },
          width: '100%',
          // width: '185px',
          minWidth: '185px',
          bgcolor: 'primary.main',
          overflow: 'auto',
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#F5FBFF',
            borderRight: '1px solid green',
            borderColor: 'primary.main',
          },
          '&::-webkit-scrollbar': {
            width: '6px',
            backgroundColor: 'primary.main',
          },
        }}
      >
        <Box>
          {NAV_ITEMS.map((r) => (
            <NavButton key={r} route={r} />
          ))}

          {NESTED_ITEMS.map(({ title, items }) => (
            <NestedItemsButton key={title} title={`${t(title)}`} items={items} />
          ))}

          <RegionBlock />
        </Box>

        <BottomButtons />
      </List>
    </Drawer>
  );
};
