import { METRIC_DEFAULTS } from 'shared/constants';
import { Unit } from 'shared/models';
import { Math_round } from '../../mathRound';
import { ConvertUnit } from '../../unitConverter';

export function SetLabels(
  TheDu: number | string,
  min: number,
  unitSettings: Unit,
  max: number,
  NomAvg: number,
  TheCu: number,
) {
  let txtDu = Math_round(+TheDu, 2);

  let mintxt = Math_round(ConvertUnit(min, METRIC_DEFAULTS.AppnRate, unitSettings.appnRate, null), 2);
  let maxtxt = Math_round(ConvertUnit(max, METRIC_DEFAULTS.AppnRate, unitSettings.appnRate, null), 2);
  let txtAverage = Math_round(ConvertUnit(NomAvg, METRIC_DEFAULTS.AppnRate, unitSettings.appnRate, null), 2);

  let txtCu = Math_round(TheCu, 2);

  let res = {
    txtDu: txtDu,
    mintxt: mintxt,
    maxtxt: maxtxt,
    txtCu: txtCu,
    txtAverage: txtAverage,
  };
  return res;
}
