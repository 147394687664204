import { Navigate, Route, Routes } from 'react-router-dom';
import { ProtectedRoute } from 'features';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { AuthState, setUsernameFromLS } from 'shared/slices';
import { CommonLayout, MainLayout } from 'shared/ui';
import { Header, SideMenu } from 'widgets';
import { EDCPage } from './EDC';
import { LateralPage } from './Lateral';
import { LoginPage } from './Login';
import { MainlinePage } from './Mainline';
import { RegisterPage } from './Register';
import { SubmainPage } from './Submain';
import { RedirectedLoginPage } from './RedirectedLogin';
import { SystemSummaryPage } from './SystemSummary';
import { useEffect } from 'react';
import { ShepherdProvider } from 'processes/ShepherdTour';

const commonRoutes = [
  { path: '/login', element: <LoginPage /> },
  { path: '/*', element: <LoginPage />, replace: true },
  { path: '/redirected-login', element: <RedirectedLoginPage /> },
];

const registerRoute = { path: '/register', element: <RegisterPage /> };

const privateRoutes = [
  { path: '/lateral', element: <LateralPage /> },
  { path: '/mainline', element: <MainlinePage /> },
  { path: '/submain', element: <SubmainPage /> },
  { path: '/system-summary', element: <SystemSummaryPage /> },
  { path: '/edc', element: <EDCPage /> },
];

export const Routing = () => {
  const dispatch = useAppDispatch();
  const authState = useAppSelector((state) => state.auth.authState);

  const isAuthenticated = authState === AuthState.SUCCESS;

  useEffect(() => {
    dispatch(setUsernameFromLS());
  }, []);

  return (
    <ShepherdProvider>
      <Routes>
        <Route element={<CommonLayout />}>
          {commonRoutes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={
                route.replace ? <Navigate to={isAuthenticated ? '/lateral' : '/login'} replace /> : route.element
              }
            />
          ))}
        </Route>

        <Route path={registerRoute.path} element={registerRoute.element} />

        <Route element={<MainLayout header={<Header />} sidebar={<SideMenu />} />}>
          <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} />}>
            {privateRoutes.map((route) => (
              <Route key={route.path} path={route.path} element={route.element} />
            ))}
          </Route>
        </Route>
      </Routes>
    </ShepherdProvider>
  );
};
