import { Shape } from '../models/Shape';
import { v_at } from './v_at';

export function CalcRECTANGULAR(CalcREC: Shape) {
  let y = 0;
  let X = 0;
  let temp = 0;
  let i = 0;
  let j = 0;
  for (y = 0; y <= CalcREC.Yint; y++) {
    for (X = 0; X <= CalcREC.Xint; X++) {
      CalcREC.FieldRainAt[X][y] = 0;
      for (i = -CalcREC.Kx; i <= CalcREC.Kx + 1; i++) {
        for (j = -CalcREC.Ky; j <= CalcREC.Ky + 1; j++) {
          if (i != 0 && j != 0) {
            let res = v_at(
              Math.round(Math.abs(i) * CalcREC.XMAMTEROT - (i / Math.abs(i)) * X),
              Math.round(Math.abs(j) * CalcREC.YMAMTEROT - (j / Math.abs(j)) * y),
              CalcREC.fieldintervalX,
              CalcREC.fieldintervalY,
              CalcREC.dRange,
              CalcREC.cbEDCEmitter,
              CalcREC.TUBENUM,
            );
            CalcREC.FieldRainAt[X][y] = CalcREC.FieldRainAt[X][y] + res;
          } else if (i == 0 && j != 0) {
            let rer = v_at(
              X,
              Math.round(Math.abs(j) * CalcREC.YMAMTEROT - (j / Math.abs(j)) * y),
              CalcREC.fieldintervalX,
              CalcREC.fieldintervalY,
              CalcREC.dRange,
              CalcREC.cbEDCEmitter,
              CalcREC.TUBENUM,
            );
            CalcREC.FieldRainAt[X][y] = CalcREC.FieldRainAt[X][y] + rer;
          } else if (j == 0 && i != 0) {
            let res = v_at(
              Math.round(Math.abs(i) * CalcREC.XMAMTEROT - (i / Math.abs(i)) * X),
              y,
              CalcREC.fieldintervalX,
              CalcREC.fieldintervalY,
              CalcREC.dRange,
              CalcREC.cbEDCEmitter,
              CalcREC.TUBENUM,
            );
            CalcREC.FieldRainAt[X][y] = CalcREC.FieldRainAt[X][y] + res;
          } else if (j == 0 && i == 0) {
            let res = v_at(
              y,
              X,
              CalcREC.fieldintervalX,
              CalcREC.fieldintervalY,
              CalcREC.dRange,
              CalcREC.cbEDCEmitter,
              CalcREC.TUBENUM,
            );
            CalcREC.FieldRainAt[X][y] = CalcREC.FieldRainAt[X][y] + res;
          }
        }
      }
      temp = CalcREC.FieldRainAt[X][y];
      if (temp > CalcREC.max) {
        CalcREC.max = temp;
      } else if (temp < CalcREC.min) {
        CalcREC.min = temp;
        CalcREC.MinChange = true;
      }
      CalcREC.FieldRainAt[X][CalcREC.YMAMTEROT - y] = temp;
      CalcREC.FieldRainAt[CalcREC.XMAMTEROT - X][CalcREC.YMAMTEROT - y] = temp;
      CalcREC.FieldRainAt[CalcREC.XMAMTEROT - X][y] = temp;
    }
  }

  let res = {
    FieldRainAt: CalcREC.FieldRainAt,
    min: CalcREC.min,
    max: CalcREC.max,
    MinChange: CalcREC.MinChange,
  };
  return res;
}
