import { Stack, StackProps } from '@mui/material';
import { FC } from 'react';

export const Row: FC<StackProps> = ({ children, sx, direction, justifyContent, alignItems, ...props }) => {
  return (
    <Stack
      direction={direction ?? 'row'}
      alignItems={alignItems ?? 'center'}
      justifyContent={justifyContent ?? 'flex-start'}
      {...props}
      sx={{ ...sx }}
    >
      {children}
    </Stack>
  );
};
