export const rotateRight = (array: any[][] = []) => {
  const result: any[][] = [];
  array.forEach(function (a, i) {
    a.forEach(function (b, j, bb) {
      result[bb.length - j - 1] = result[bb.length - j - 1] || [];
      result[bb.length - j - 1][i] = b;
    });
  });
  return result;
};
