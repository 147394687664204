import { METRIC_DEFAULTS } from 'shared/constants';
import { Math_round } from 'shared/lib/calculation/mathRound';
import { ConvertUnit } from 'shared/lib/calculation/unitConverter';
import { Unit } from 'shared/models';

export type CalcHydraulicType = {
  NumberOfOutlets: number;
  OutletDistance: number;
  OutletFlow: number;
  Q: number;
  Dia: number;
  HW: number;
  Len: number;
  HL: number;
  kd: number;
  velocity: number;
  addlHeadloss: number;
  headlossCalc: string;
  calcType: string;
};

export type CalcResult = {
  AHL: number;
  HL: number;
  Q: number;
  kd: number;
  velocity: number;
  Dia: number;
  Len: number;
  error: string;
};

export const CalculateHydraulic = (values: CalcHydraulicType, unitSettings: Unit) => {
  let HeadlossFactorForHeadloss = 1;
  let result: Partial<CalcResult> = {};

  let NumberOfOutlets = 0.0;
  NumberOfOutlets = values.NumberOfOutlets;
  if (isNaN(NumberOfOutlets)) NumberOfOutlets = 0;

  let OutletDistance = 0.0;
  OutletDistance = values.OutletDistance;
  if (isNaN(OutletDistance)) OutletDistance = 0;

  let OutletFlow = 0.0;
  OutletFlow = values.OutletFlow;
  if (isNaN(OutletFlow)) OutletFlow = 0;

  let Q = values.Q;
  if (isNaN(Q)) Q = 0;

  let Dia = values.Dia;
  if (isNaN(Dia)) Dia = 0;

  let HW = values.HW;
  if (isNaN(HW)) HW = 0;

  let Len = values.Len;
  if (isNaN(Len)) Len = 0;

  let HL = values.HL;
  if (isNaN(HL)) HL = 0;

  let velocity = values.velocity;
  if (isNaN(velocity)) velocity = 0;

  Dia = ConvertUnit(Dia, unitSettings.pipeDiameter, METRIC_DEFAULTS.PipeDiameter, 'PipeDiameter');
  Len = ConvertUnit(Len, unitSettings.length, METRIC_DEFAULTS.Length, null);
  HL = ConvertUnit(HL, unitSettings.pressure, METRIC_DEFAULTS.Pressure, null);
  velocity = ConvertUnit(velocity, unitSettings.velocity, METRIC_DEFAULTS.Velocity, null);
  OutletFlow = ConvertUnit(OutletFlow, unitSettings.totalFlow, METRIC_DEFAULTS.TotalFlow, null);
  OutletDistance = ConvertUnit(OutletDistance, unitSettings.length, METRIC_DEFAULTS.Length, null);

  if (NumberOfOutlets > 0) {
    Q = NumberOfOutlets * OutletFlow;
    HeadlossFactorForHeadloss = 0.1538 / Math.pow(NumberOfOutlets, 2) + 0.5 / NumberOfOutlets + 0.35;
  }

  if (NumberOfOutlets > 0 && OutletDistance > 0) {
    Len = NumberOfOutlets * OutletDistance;
    result = {
      ...result,
      Len: Math_round(ConvertUnit(Len, METRIC_DEFAULTS.Length, unitSettings.length, null), 1),
    };
  }

  if (values.calcType === 'headloss') {
    if (values.headlossCalc == 'H.W') {
      if (values.calcType === 'headloss' && HW != 0) {
        HL = (((1.131 * Math.pow(10.0, 12.0) * Math.pow(Q / HW, 1.852)) / Math.pow(Dia, 4.87)) * Len) / 1000;
      }
    } else {
      if (Dia < 125) {
        HL = (8.38 * Math.pow(10, 7) * Math.pow(Q, 1.75) * Math.pow(Dia, -4.75) * Len) / 1000;
      } else {
        HL = (9.19 * Math.pow(10, 7) * Math.pow(Q, 1.83) * Math.pow(Dia, -4.83) * Len) / 1000;
      }
    }
    HL = HL * HeadlossFactorForHeadloss;
  }

  result = {
    ...result,
    HL: Math_round(ConvertUnit(HL, METRIC_DEFAULTS.Pressure, unitSettings.pressure, null), 3),
  };

  let realhl = 0;
  if (HL > 0) {
    realhl = HL / HeadlossFactorForHeadloss;
  }

  if (values.calcType === 'internalDia') {
    if (velocity > 0 && HL == 0) {
      Dia = Math.pow((Q / 3600 / velocity / 3.14159) * 4, 0.5) * 1000.0;
      if (values.headlossCalc === 'H.W') {
        if (HW != 0) {
          HL = (((1.131 * Math.pow(10.0, 12.0) * Math.pow(Q / HW, 1.852)) / Math.pow(Dia, 4.87)) * Len) / 1000;
        }
      } else {
        if (Dia < 125) {
          HL = (8.38 * Math.pow(10, 7) * Math.pow(Q, 1.75) * Math.pow(Dia, -4.75) * Len) / 1000;
        } else {
          HL = (9.19 * Math.pow(10, 7) * Math.pow(Q, 1.83) * Math.pow(Dia, -4.83) * Len) / 1000;
        }
      }
      HL = HL * HeadlossFactorForHeadloss;

      result = {
        ...result,
        HL: Math_round(ConvertUnit(HL, METRIC_DEFAULTS.Pressure, unitSettings.pressure, null), 3),
      };
    } else {
      if (values.headlossCalc == 'H.W' && HW != 0) {
        Dia = Math.pow((((1.131 * Math.pow(10.0, 12.0) * Math.pow(Q / HW, 1.852)) / realhl) * Len) / 1000, 1.0 / 4.87);
      } else {
        let dwheadloss = 0;
        dwheadloss = (8.38 * Math.pow(10, 7) * Math.pow(Q, 1.75) * Len) / 1000 / realhl;
        Dia = Math.pow(dwheadloss, 1 / 4.75);
      }
    }
    result = {
      ...result,
      Dia: Math_round(ConvertUnit(Dia, METRIC_DEFAULTS.PipeDiameter, unitSettings.pipeDiameter, 'PipeDiameter'), 3),
    };
  }
  if (values.calcType === 'length') {
    if (values.headlossCalc == 'H.W' && HW != 0) {
      Len = (realhl * 1000 * Math.pow(Dia, 4.87)) / (1.131 * Math.pow(10.0, 12.0) * Math.pow(Q / HW, 1.852));
    } else {
      if (Dia < 125) {
        Len = (realhl / (8.38 * Math.pow(10, 7) * Math.pow(Q, 1.75) * Math.pow(Dia, -4.75))) * 1000;
      } else {
        Len = (realhl / (9.19 * Math.pow(10, 7) * Math.pow(Q, 1.83) * Math.pow(Dia, -4.83))) * 1000;
      }
    }
    result = {
      ...result,
      Len: Math_round(ConvertUnit(Len, METRIC_DEFAULTS.Length, unitSettings.length, null), 1),
    };
  }
  if (values.calcType === 'totalFlow') {
    if (velocity > 0 && HL == 0) {
      Q = velocity * 3600 * ((3.14159 * Math.pow(Dia / 1000.0, 2.0)) / 4.0);
    } else {
      if (values.headlossCalc == 'H.W' && HW != 0) {
        Q = Math.pow((((realhl * 1000) / Len) * Math.pow(Dia, 4.87)) / 1.131 / Math.pow(10.0, 12.0), 1.0 / 1.852) * HW;
      } else {
        let dwheadloss = 0;
        if (Dia < 125) {
          dwheadloss = realhl / ((8.38 * Math.pow(10, 7) * Math.pow(Dia, -4.75) * Len) / 1000);
          Q = Math.pow(dwheadloss, 1 / 1.75);
        } else {
          dwheadloss = realhl / ((9.19 * Math.pow(10, 7) * Math.pow(Dia, -4.83) * Len) / 1000);
          Q = Math.pow(dwheadloss, 1 / 1.83);
        }
      }
    }
  }
  const originalVelocity = Q / 3600 / ((3.14159 * Math.pow(Dia / 1000.0, 2.0)) / 4.0);

  velocity = Math_round(Q / 3600 / ((3.14159 * Math.pow(Dia / 1000.0, 2.0)) / 4.0), 3);

  result = {
    ...result,
    velocity: Math_round(ConvertUnit(velocity, METRIC_DEFAULTS.Velocity, unitSettings.velocity, null), 3),
    Q: Math_round(ConvertUnit(Q, METRIC_DEFAULTS.TotalFlow, unitSettings.totalFlow, null), 3),
  };
  let Kd = values.kd;
  if (isNaN(Kd)) {
    Kd = 0;
  }
  let AHL = 0.0;
  if (Kd != 0) {
    AHL = Math_round((Kd * originalVelocity * originalVelocity) / 2 / 9.81, 3);
    result = {
      ...result,
      AHL: Math_round(ConvertUnit(AHL, METRIC_DEFAULTS.Pressure, unitSettings.pressure, null), 3),
    };
  }

  if (isNaN(AHL)) {
    AHL = 0;
  }

  if (isNaN(velocity)) {
    velocity = 0;
  }

  if (AHL != 0) {
    AHL = ConvertUnit(AHL, unitSettings.pressure, METRIC_DEFAULTS.Pressure, null);
    result = {
      ...result,
      kd: Math_round((AHL * 9.81 * 2) / (velocity * velocity), 3),
    };
  }

  return result;
};
