export const NAV_ITEMS = ['/lateral', '/submain', '/mainline', '/system-summary', '/edc'];

export const enum TOGGLE_ACTION {
  TOGGLE_HYDRAULIC_CALC = 'toggleHydraulicCalculator',
  TOGGLE_PUMP_CALC = 'togglePumpCalculator',
  TOGGLE_ELECTRIC_CALC = 'toggleElectricCalculator',
  TOGGLE_INJECTION_RATE_CALC = 'toggleInjectionRateCalculator',
  TOGGLE_PRESSURE_FLOW_CALC = 'togglePressureFlowCalculator',
  TOGGLE_TEMPLATE = 'toggleTemplate',
  TOGGLE_NEW_PROJECT = 'toggleNewProject',
  TOGGLE_OPEN_PROJECT = 'toggleOpenProject',
  TOGGLE_SAVE_AS_PROJECT = 'toggleSaveAsProject',
  TOGGLE_REGIONS = 'toggleRegions',
  TOGGLE_UNITS = 'toggleUnits',
  TOGGLE_LANGUAGE = 'toggleLanguage',
}

export const enum ACTION_TYPE {
  MODAL,
  UTIL,
}

export type NestedItem = {
  subTitle: string;
  action: TOGGLE_ACTION;
  type: ACTION_TYPE;
};

export type NestedItemType = {
  title: string;
  items: NestedItem[];
};

export const NESTED_ITEMS: NestedItemType[] = [
  {
    title: 'tools',
    items: [
      { subTitle: 'Hydraulic Calculator', action: TOGGLE_ACTION.TOGGLE_HYDRAULIC_CALC, type: ACTION_TYPE.MODAL },
      { subTitle: 'Pump Calculator', action: TOGGLE_ACTION.TOGGLE_PUMP_CALC, type: ACTION_TYPE.MODAL },
      { subTitle: 'Electric calculator', action: TOGGLE_ACTION.TOGGLE_ELECTRIC_CALC, type: ACTION_TYPE.MODAL },
      { subTitle: 'Injection Rate', action: TOGGLE_ACTION.TOGGLE_INJECTION_RATE_CALC, type: ACTION_TYPE.MODAL },
      { subTitle: 'Pressure VS Flow', action: TOGGLE_ACTION.TOGGLE_PRESSURE_FLOW_CALC, type: ACTION_TYPE.MODAL },
    ],
  },
  {
    title: 'projects',
    items: [
      { subTitle: 'Template', action: TOGGLE_ACTION.TOGGLE_TEMPLATE, type: ACTION_TYPE.MODAL },
      { subTitle: 'New', action: TOGGLE_ACTION.TOGGLE_NEW_PROJECT, type: ACTION_TYPE.MODAL },
      { subTitle: 'Open', action: TOGGLE_ACTION.TOGGLE_OPEN_PROJECT, type: ACTION_TYPE.MODAL },
      { subTitle: 'Save', action: TOGGLE_ACTION.TOGGLE_SAVE_AS_PROJECT, type: ACTION_TYPE.UTIL },
      { subTitle: 'Save As', action: TOGGLE_ACTION.TOGGLE_SAVE_AS_PROJECT, type: ACTION_TYPE.MODAL },
    ],
  },
  {
    title: 'settings',
    items: [
      { subTitle: 'Units', action: TOGGLE_ACTION.TOGGLE_UNITS, type: ACTION_TYPE.MODAL },
      { subTitle: 'Language', action: TOGGLE_ACTION.TOGGLE_LANGUAGE, type: ACTION_TYPE.MODAL },
    ],
  },
];
