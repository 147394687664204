import { useContext } from 'react';
import { ToastContext } from 'shared/contexts';

export const useToast = () => {
  const { showSuccess, showError, showInfo, showWarning } = useContext(ToastContext);

  return {
    showSuccess,
    showError,
    showInfo,
    showWarning,
  };
};
