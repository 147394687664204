import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { customAxios } from 'shared/api';
import {
  ProjectData,
  ProjectDataBody,
  ProjectDataResponse,
  SaveProjectAsBody,
  SaveProjectAsResponse,
  SaveProjectBody,
  SaveProjectResponse,
} from 'shared/models';

interface UserState {
  loading: boolean;
  projectData: ProjectData;
}

const initialState: UserState = {
  loading: true,
  projectData: {} as ProjectData,
};

export const getProjectDataThunk = createAsyncThunk('getProjectData', async (params: ProjectDataBody, thunkAPI) => {
  try {
    const response = await customAxios.get<ProjectDataResponse>(
      `be/Ht/ht-project-data/${params.projectId}`,
    );
    return response.data;
  } catch (e) {
    if (e instanceof Error) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
});

export const saveProjectThunk = createAsyncThunk('saveProjectData', async (body: SaveProjectBody, thunkAPI) => {
  try {
    const response = await customAxios.post<SaveProjectResponse>(
      'be/Ht/ht-save-project',
      body,
    );

    return response.data;
  } catch (e) {
    if (e instanceof Error) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
});

export const saveProjectAsThunk = createAsyncThunk('saveProjectDataAs', async (body: SaveProjectAsBody, thunkAPI) => {
  try {
    const response = await customAxios.post<SaveProjectAsResponse>(
      'be/Ht/ht-save-as-project',
      body,
    );

    return response.data;
  } catch (e) {
    if (e instanceof Error) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
});

const projectDataSlice = createSlice({
  name: 'projectData',
  initialState,
  reducers: {
    setProjectValue: (state, action: PayloadAction<Partial<ProjectData>>) => {
      state.projectData = { ...state.projectData, ...action.payload };
    },
    resetProjectData: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProjectDataThunk.pending.type, (state) => {
        state.loading = true;
      })
      .addCase(getProjectDataThunk.fulfilled.type, (state, action: PayloadAction<ProjectDataResponse>) => {
        state.projectData = action.payload.data ?? {};
        state.loading = false;
      })
      .addCase(getProjectDataThunk.rejected.type, (state) => {
        state.loading = false;
      });
  },
});

export const {
  resetProjectData,

  setProjectValue,
} = projectDataSlice.actions;

export const projectDataReducer = projectDataSlice.reducer;
