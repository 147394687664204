import { Box, Checkbox, Grid, useMediaQuery } from '@mui/material';
import { t } from 'i18next';
import { ChangeEvent, FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { input_ico } from 'shared/assets';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { formattedInputValue } from 'shared/lib';
import { setProjectValue, setSubmainErrorValue, setSubmainStateValue } from 'shared/slices';
import { Accordion, IconLabel, Input, PaperBox } from 'shared/ui';

interface Props {
  onMaxVelocityChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onNumberOfRowsChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onTotalLengthChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onLateralSpacingChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const InputBlockContent: FC<Props> = ({
                                               onMaxVelocityChange,
                                               onNumberOfRowsChange,
                                               onTotalLengthChange,
                                               onLateralSpacingChange,
                                             }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { units } = useAppSelector((state) => state.units);
  const { projectData } = useAppSelector((st) => st.projectData);
  const { submainValues, submainErrors } = useAppSelector((st) => st.submainState);
  const isTablet = useMediaQuery('(max-width:850px)');

  const changeFirstLateralDistance = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setProjectValue({ firstLateralDistance: formattedInputValue(e) }));
  };
  const changeTotalFlow = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setProjectValue({ totalFlow: formattedInputValue(e) }));
  };

  const onIsSubmainLengthChange = () => {
    dispatch(setSubmainStateValue({ isSubmainLength: !submainValues.isSubmainLength }));
  };

  const changeManifoldAllowableHeadloss = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setSubmainStateValue({ allowedHeadloss: formattedInputValue(e) }));
  };

  //VALIDATIONS

  useEffect(() => {
    if (+projectData.manifoldSpacing <= 0) {
      dispatch(setSubmainErrorValue({ spacing: true }));
    } else {
      dispatch(setSubmainErrorValue({ spacing: false }));
    }
  }, [projectData.manifoldSpacing]);
  useEffect(() => {
    if (+projectData.totalRows <= 0) {
      dispatch(setSubmainErrorValue({ numberOfRows: true }));
    } else {
      dispatch(setSubmainErrorValue({ numberOfRows: false }));
    }
  }, [projectData.totalRows]);

  useEffect(() => {
    if (+projectData.totalManifoldLength <= 0) {
      dispatch(setSubmainErrorValue({ totalSubmainLength: true }));
    } else {
      dispatch(setSubmainErrorValue({ totalSubmainLength: false }));
    }
  }, [projectData.totalManifoldLength]);

  return (
    <>
      <Grid item container xs={12} columnSpacing={isTablet ? 1 : 3} rowSpacing={1}>
        <Grid item xs={isTablet ? 4 : 3}>
          <Input
            label={`${t('allowableHL')} (${units.pressure})`}
            // value={projectData.ManifoldAllowableHeadloss}
            value={submainValues.allowedHeadloss}
            onChange={changeManifoldAllowableHeadloss}
          />
        </Grid>

        <Grid item xs={isTablet ? 4 : 3}>
          <Input
            label={`${t('maxVelocity')} (${units.velocity})`}
            value={projectData.manifoldMaximumVelocity}
            onChange={onMaxVelocityChange}
          />
        </Grid>

        <Grid item xs={isTablet ? 4 : 3}>
          <Input
            label={`${t('lateralSpacing')} (${units.lateralSpacing})`}
            value={projectData.manifoldSpacing}
            onChange={onLateralSpacingChange}
            isErrorBox={submainErrors.spacing}
          />
        </Grid>
        {!isTablet && <Grid item xs={3}></Grid>}

        <Grid item xs={isTablet ? 6 : 3}>
          <Input
            label={`${t('totalSubmainLen')} (${units.length})`}
            isResultBox={!submainValues.isSubmainLength}
            value={projectData.totalManifoldLength}
            onChange={onTotalLengthChange}
            isErrorBox={submainErrors.totalSubmainLength}
            labelInput={
              <Checkbox
                checked={submainValues.isSubmainLength}
                onChange={onIsSubmainLengthChange}
                size='small'
                sx={{ p: '0 5px 0 0' }}
              />
            }
          />
        </Grid>

        <Grid item xs={isTablet ? 6 : 3}>
          <Input
            label={`${t('noOfRows')}`}
            isResultBox={submainValues.isSubmainLength}
            value={projectData.totalRows}
            onChange={onNumberOfRowsChange}
            isErrorBox={submainErrors.numberOfRows}
            labelInput={
              <Checkbox
                checked={!submainValues.isSubmainLength}
                onChange={onIsSubmainLengthChange}
                size='small'
                sx={{ p: '0 5px 0 0' }}
              />
            }
          />
        </Grid>

        <Grid item xs={isTablet ? 6 : 3}>
          <Input
            label={`${t('disTo1stLateral')} (${units.length})`}
            value={projectData.firstLateralDistance}
            onChange={changeFirstLateralDistance}
          />
        </Grid>
        <Grid item xs={isTablet ? 6 : 3}>
          <Input
            label={`${t('flow')} (${units.totalFlow})`}
            value={projectData.totalFlow}
            onChange={changeTotalFlow}
            isResultBox
          />
        </Grid>
      </Grid>
    </>
  );
};

export const InputBlock: FC<Props> = ({
                                        onMaxVelocityChange,
                                        onNumberOfRowsChange,
                                        onTotalLengthChange,
                                        onLateralSpacingChange,
                                      }) => {
  const isTablet = useMediaQuery('(max-width:850px)');

  return (
    <Box pt='10px'>
      {isTablet ? (
        <Accordion defaultExpanded header={<IconLabel iconSrc={input_ico}>{t('input')}</IconLabel>}>
          <InputBlockContent
            onMaxVelocityChange={onMaxVelocityChange}
            onNumberOfRowsChange={onNumberOfRowsChange}
            onTotalLengthChange={onTotalLengthChange}
            onLateralSpacingChange={onLateralSpacingChange}
          />
        </Accordion>
      ) : (
        <>
          <IconLabel iconSrc={input_ico}>{t('input')}</IconLabel>

          <PaperBox>
            <InputBlockContent
              onMaxVelocityChange={onMaxVelocityChange}
              onNumberOfRowsChange={onNumberOfRowsChange}
              onTotalLengthChange={onTotalLengthChange}
              onLateralSpacingChange={onLateralSpacingChange}
            />
          </PaperBox>
        </>
      )}
    </Box>
  );
};
