import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'shared/hooks';
import { parseSlopes } from 'shared/lib';
import { Math_round } from 'shared/lib/calculation/mathRound';

export const useDefineLengthReportData = () => {
  const { t } = useTranslation();
  const { units } = useAppSelector((st) => st.units);

  const { username } = useAppSelector((st) => st.auth);
  const { projectData } = useAppSelector((st) => st.projectData);
  const { lateralValues } = useAppSelector((st) => st.lateralState);

  const infoTable = {
    data: [
      [t('preparedFor'), t('by'), t('_date')],
      [
        `${projectData.tradingName ?? ''} ${projectData.growerName ?? ''}`,
        username,
        new Date(Date.now()).toLocaleString(),
      ],
    ],
  };

  const firstTable = useMemo(() => {
    return {
      data: [
        [
          `${t('inletPr')} (${units.pressure})`,
          `${t('internalDia')} (${units.pipeDiameter})`,
          `${t('slope')} (%)`,
          `${t('pMin')} (${units.pressure})`,
          `${t('pMax')} (${units.pressure})`,
          `${t('nominalPr')} (${units.pressure})`,
          `${t('nominalFlow')} (${units.flow})`,
          `${t('flushingVelocity')} (${units.velocity})`,
          `${t('eu')} (%)`,
          `${t('Du')} (%)`,
          `${t('Qmin/Qmax')}`,
        ],
        [
          projectData.lateralInletPressure,
          projectData.lateralInletDia,
          parseSlopes(projectData.lateralSlopes ?? '')[0]?.slope ?? 0,
          lateralValues.resultPMin,
          lateralValues.resultPMax,
          projectData.emitterNominalFlow,
          projectData.emitterNominalPressure,
          projectData.flushingVelocity ?? 0,
          lateralValues.resultEU,
          Math_round((lateralValues.resultDU as number) * 100, 3),
          Math_round(lateralValues.resultQMinQMax as number, 3),
        ],
      ],
    };
  }, [units, projectData, lateralValues]);

  const secondTable = useMemo(() => {
    return {
      data: [
        [
          `${t('length')} (${units.length})`,
          `${t('inletPr')} (${units.pressure})`,
          `${t('lateralFlow')} (${units.totalFlow})`,
          `${t('emitterFlow')} (${units.flow})`,
          `${t('velocity')} (${units.velocity})`,
          `${t('headloss')} (${units.pressure})`,
          `${t('totalHeadloss')} (${units.pressure})`,
        ],
        ...lateralValues.resultReportArray.map((item) => [
          item.length,
          item.inletPressure,
          item.lateralFlow,
          item.averageFlow,
          item.velocity,
          item.headloss,
          item.totalHeadloss.toString(),
        ]),
      ],
    };
  }, [units, lateralValues]);

  const pdfTableData = [firstTable, secondTable];

  return {
    infoTable,
    pdfTableData,
  };
};
