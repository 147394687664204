import { Box, Grid, useMediaQuery } from '@mui/material';
import { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { input_ico } from 'shared/assets';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { formattedInputValue } from 'shared/lib';
import { setMainlineStateValue } from 'shared/slices';
import { Accordion, IconLabel, Input, PaperBox } from 'shared/ui';

interface Props {
  onMaxVelocityChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onFlowChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const InputBlockContent: FC<Props> = ({ onMaxVelocityChange, onFlowChange }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { units } = useAppSelector((state) => state.units);
  const { mainlineValues } = useAppSelector((st) => st.mainlineState);
  const isMobile = useMediaQuery('(max-width:550px)');

  const changeMainlineAllowedHeadloss = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setMainlineStateValue({ allowableHeadloss: formattedInputValue(e) }));
  };
  const changeMainlinePumpHeadloss = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setMainlineStateValue({ pumpHeadloss: formattedInputValue(e) }));
  };
  const changeMainlineValveHeadloss = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setMainlineStateValue({ valveHeadloss: formattedInputValue(e) }));
  };
  const changeMainlineLength = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setMainlineStateValue({ length: formattedInputValue(e) }));
  };
  return (
    <>
      <Grid item container xs={12} columnSpacing={isMobile ? 1 : 3} rowSpacing={1}>
        <Grid item xs={isMobile ? 6 : 4}>
          <Input
            label={`${t('allowableHL')} (${units.pressure})`}
            // value={projectData.MainlineAllowedHeadloss}
            value={mainlineValues.allowableHeadloss}
            onChange={changeMainlineAllowedHeadloss}
          />
        </Grid>

        <Grid item xs={isMobile ? 6 : 4}>
          <Input
            label={`${t('pumpHeadloss')} (${units.pressure})`}
            value={mainlineValues.pumpHeadloss}
            // value={projectData.MainlinePumpHeadloss}
            onChange={changeMainlinePumpHeadloss}
          />
        </Grid>

        <Grid item xs={isMobile ? 6 : 4}>
          <Input
            label={`${t('valveHeadloss')} (${units.pressure})`}
            value={mainlineValues.valveHeadloss}
            // value={projectData.MainlineValveHeadloss}
            onChange={changeMainlineValveHeadloss}
          />
        </Grid>

        <Grid item xs={isMobile ? 6 : 4}>
          <Input
            label={`${t('length')} (${units.length})`}
            // value={projectData.MainlineLength}
            value={mainlineValues.length}
            onChange={changeMainlineLength}
          />
        </Grid>

        <Grid item xs={isMobile ? 6 : 4}>
          <Input
            label={`${t('maxVelocity')} (${units.velocity})`}
            // value={projectData.MainlineMaxVelocity}
            value={mainlineValues.maxVelocity}
            onChange={onMaxVelocityChange}
          />
        </Grid>

        <Grid item xs={isMobile ? 6 : 4}>
          <Input
            label={`${t('flow')} (${units.totalFlow})`}
            // value={projectData.MainlineFlow}
            value={mainlineValues.flow}
            onChange={onFlowChange}
          />
        </Grid>
      </Grid>
    </>
  );
};
export const InputBlock: FC<Props> = ({ onMaxVelocityChange, onFlowChange }) => {
  const isTablet = useMediaQuery('(max-width:850px)');
  const { t } = useTranslation();

  return (
    <Box mt='10px'>
      {isTablet ? (
        <Accordion defaultExpanded header={<IconLabel iconSrc={input_ico}>{t('input')}</IconLabel>}>
          <InputBlockContent onMaxVelocityChange={onMaxVelocityChange} onFlowChange={onFlowChange} />
        </Accordion>
      ) : (
        <PaperBox>
          <InputBlockContent onMaxVelocityChange={onMaxVelocityChange} onFlowChange={onFlowChange} />
        </PaperBox>
      )}
    </Box>
  );
};
