import { Box, TextField, TextFieldProps, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { forwardRef } from 'react';
import { Button } from '../Button';
import { useTranslation } from 'react-i18next';
import { Icon } from '../Icon';
import { reset } from 'shared/assets';

type Props = TextFieldProps & {
  label?: string;
  isResultBox?: boolean;
  isNotifyBox?: boolean;
  isErrorBox?: boolean;
  errorText?: string;
  isResetInlet?: boolean;
  onResetClick?: () => void;
  labelInput?: JSX.Element;
  additionalInput?: JSX.Element;
  labelInputPosition?: 'left' | 'right';
  tooltip?: string;
};

export const Input = forwardRef<unknown, Props>(
  (
    {
      label,
      isResetInlet,
      isResultBox,
      isErrorBox,
      isNotifyBox,
      onResetClick,
      labelInput,
      additionalInput,
      labelInputPosition = 'left',
      errorText,
      tooltip,
      ...props
    },
    ref
  ) => {
    const { palette } = useTheme();
    const { t } = useTranslation();
    const isMobile = useMediaQuery('(max-width:600px)');

    return (
      <Box position="relative">
        {label && (
          <Box display="flex" alignItems="flex-end">
            {labelInputPosition === 'left' && labelInput}
            <Tooltip title={tooltip ?? ''} placement="top">
              <Box display="flex" justifyContent="space-between" width="100%">
                <Typography
                  noWrap
                  sx={{
                    // ...(labelInput && { lineHeight: '11px' }),
                    fontSize: '11px',
                    // textTransform: 'uppercase',
                    color: palette.secondary.main,
                    marginBottom: '3px',
                  }}
                >
                  {label}
                </Typography>
                {errorText && isErrorBox && (
                  <Typography
                    noWrap
                    sx={{
                      // ...(labelInput && { lineHeight: '11px' }),
                      fontSize: '11px',
                      color: palette.error.main,
                      marginBottom: '3px',
                    }}
                  >
                    {errorText}
                  </Typography>
                )}
              </Box>
            </Tooltip>
            {labelInputPosition === 'right' && labelInput}
          </Box>
        )}
        <TextField
          fullWidth
          type="number"
          sx={{
            background: palette.info.main,
            borderRadius: '9px',
            ...(isNotifyBox && {
              background: '#ECEFF1',
              '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                WebkitAppearance: 'none',
              },
            }),
            ...(isResultBox && {
              background: '#ECEFF1',
              '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                WebkitAppearance: 'none',
              },
              '& .MuiOutlinedInput-root': { '& > fieldset': { borderColor: '#000000 !important' } },
            }),
            ...(isErrorBox && {
              background: palette.error.light,
              '& .MuiOutlinedInput-root': { '& > fieldset': { border: `1px solid ${palette.error.main}` } },
            }),
          }}
          inputProps={{
            min: 0,
            step: 1,
            ...(isResultBox && { style: { WebkitTextFillColor: '#000000' } }),
            ...(isErrorBox && { style: { WebkitTextFillColor: palette.error.main } }),
          }}
          InputProps={{
            sx: {
              fontSize: '15px',
              height: '34px',
              borderRadius: '9px',
              borderColor: palette.error.main,
              ...(isResetInlet && { pr: '0px' }),
            },
            ...(isResultBox && {
              readOnly: true,
            }),
            ...(isResetInlet && {
              endAdornment: (
                <Button
                  onClick={onResetClick}
                  variant="outlined"
                  sx={{
                    ml: 1,
                    display: 'flex',
                    fontSize: '11px',
                    height: '34px',
                    minWidth: '30px',
                    width: isMobile ? '42px' : '72px',
                    borderRadius: '9px',
                    boxShadow: 'none',
                    letterSpacing: '0.01em',
                    textTransform: 'uppercase',
                    fontWeight: 400,
                    p: 0,
                  }}
                >
                  {isMobile ? <Icon src={reset} /> : t('reset')}
                </Button>
              ),
            }),
          }}
          inputRef={ref}
          color="primary"
          {...props}
          placeholder={props.placeholder ?? '_'}
          onFocus={(e) => {
            !isResultBox && e.target.select();
          }}
        />
        {additionalInput}
      </Box>
    );
  }
);
