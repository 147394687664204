import { Box, Grid, Stack, useMediaQuery } from '@mui/material';
import { IconLabel, Row, ShepherdBlock } from 'shared/ui';
import { useTranslation } from 'react-i18next';
import { irrigation_ico, select_product } from '../../shared/assets';
import { IrrigationRateDiagram, PositioningBlock, ResultsBlock, SelectProductBlock, SpacingBlock } from './components';

export const EDC = () => {
  const { t } = useTranslation();
  const isTablet = useMediaQuery('(max-width:850px)');

  return (
    <Row
      direction={{ xs: 'column', xl: 'row' }}
      alignItems="flex-start"
      height={{ xs: 'fit-content', xl: 'calc(100vh - 60px)' }}
    >
      <Stack width={{ xs: '100%', xl: '45%' }} height="100%" justifyContent="space-between">
        <ShepherdBlock id="edc-select-product">
          <Box mb={1}>
            {!isTablet && <IconLabel iconSrc={select_product}>{t('selectProduct')}</IconLabel>}
            <SelectProductBlock />
          </Box>
        </ShepherdBlock>

        <Box>
          <Grid item container xs={12} columnSpacing={2} rowSpacing={1}>
            <Grid item xs={isTablet ? 12 : 6}>
              <SpacingBlock />
            </Grid>
            <Grid item xs={isTablet ? 12 : 6}>
              <ShepherdBlock id="edc-positioning">
                <ShepherdBlock id="edc-positioning-2">
                  <PositioningBlock />
                </ShepherdBlock>
              </ShepherdBlock>
            </Grid>
          </Grid>
        </Box>

        <ShepherdBlock id="edc-results">
          <ResultsBlock />
        </ShepherdBlock>
      </Stack>
      <Stack
        width={{ xs: '100%', xl: '55%' }}
        height={{ xs: '800px', xl: '100%' }}
        ml={{ xs: 0, xl: '15px' }}
        mt={{ xs: '10px', xl: 0 }}
      >
        {!isTablet && <IconLabel iconSrc={irrigation_ico}>{t('irrigationRateDiagram')}</IconLabel>}

        <IrrigationRateDiagram />
      </Stack>
    </Row>
  );
};
