import { Color } from './ArrayColor';
import { DoEdge } from './DoEdge';
import { DrawGrowth } from './DrawGrowth';
import { Ellipse } from './Ellipse';
import { LateralCondition } from './LateralCondition';
import { TriangularCondition } from './TriangularCondition';

export function CalcColorField(
  XM: number,
  YM: number,
  TotalPoints: number,
  minimum: any,
  FieldRainAt: any,
  tempdrainat: any,
  Placement: number,
  stepd: number,
  TRIANGULAR_Y_LAYOUT: number,
  BILATERAL_TRIANGULAR_LAYOUT: number,
  LATERAL_LAYOUT: number,
  EdgeType: number,
  dEDGEPOSITIONX: number,
  dEDGEPOSITIONY: number,
  dXPOSITION: number,
  fieldpic: {
    width: number;
    height: number;
  },
  _ShowGrowth: boolean,
  dYPOSITION: number,
  _Shadows: boolean,
  BetweenPlants: number,
  BetweenRows: number,
  PlantDiameter: number,
  StaggeredRows: boolean,
  GroupPlantRows: boolean,
  RowsinGroup: number,
  BetweenGroups: number,
  InRows: number,
  OffRows: number
) {
  let X;
  let y;
  let COLORI = 0;
  let POINTSIZE = 10;
  let TotalPoint = TotalPoints;
  let EDGE_LAYOUT = 5;
  let X_EDGE_LAYOUT = 1;
  let Y_EDGE_LAYOUT = 2;
  let CORNER_EDGE_LAYOUT = 3;

  let cfrac: Array<number> = Array(11);
  for (let c = 0; c < cfrac.length; c++) {
    cfrac[c] = 0;
  }

  let colors = Array(41);
  for (let k = 0; k < FieldRainAt.length; k++) {
    colors[k] = Array(FieldRainAt[0].length);
  }
  if (TriangularCondition(Placement, TRIANGULAR_Y_LAYOUT, BILATERAL_TRIANGULAR_LAYOUT)) {
    for (X = 0; X < FieldRainAt.length; X++) {
      for (y = 0; y < FieldRainAt[X].length; y++) {
        if (tempdrainat[X][y] == -1) {
          const e: Ellipse = {
            color: '#000',
            width: POINTSIZE * 1.25,
            height: POINTSIZE * 1.25,
            value: FieldRainAt[X][y],
          } as Ellipse;

          colors[X][y].push(e);
          if (X <= XM && y <= YM) {
            cfrac[COLORI] = cfrac[COLORI] + 1;
          }
        } else if (tempdrainat[X][y] == 0) {
          COLORI = 0;
          const e: Ellipse = {
            color: '#000',
            width: POINTSIZE * 1.25,
            height: POINTSIZE * 1.25,
            value: FieldRainAt[X][y],
            text: 0,
          } as Ellipse;

          colors[X][y] = e;

          if (X <= XM && y <= YM) {
            cfrac[COLORI] = cfrac[COLORI] + 1;
            TotalPoint = TotalPoint + 1;
          }
        } else {
          COLORI = Math.round((tempdrainat[X][y] - minimum) / stepd + 1);

          if (COLORI <= 0) {
            COLORI = 1;
          }
          if (COLORI > 9) {
            COLORI = 9;
          }
          if (Color[COLORI] != null) {
            let br = Color[COLORI];
            const e: Ellipse = {
              color: br,
              width: POINTSIZE * 1.25,
              height: POINTSIZE * 1.25,
              value: FieldRainAt[X][y],
              text: 0,
            } as Ellipse;
            colors[X][y] = e;
          }
          if (X <= XM && y <= YM) {
            cfrac[COLORI] = cfrac[COLORI] + 1;
            TotalPoint = TotalPoint + 1;
          }
        }
      }
    }
  } else if (LateralCondition(Placement, LATERAL_LAYOUT)) {
    for (X = 1; X < FieldRainAt.length - 1; X++) {
      for (y = 1; y < FieldRainAt.length - 1; y++) {
        if (tempdrainat[X][y] == -1) {
          const e: Ellipse = {
            color: '#000',
            width: POINTSIZE * 1.25,
            height: POINTSIZE * 1.25,
            value: FieldRainAt[X][y],
            text: 0,
          } as Ellipse;

          colors[X][y] = e;

          if (X <= XM && y <= YM) {
            cfrac[-1] = cfrac[-1] + 1;
          }
        } else if (tempdrainat[X][y] == 0) {
          COLORI = 0;
          const e: Ellipse = {
            color: '#000',
            width: POINTSIZE * 1.25,
            height: POINTSIZE * 1.25,
            value: FieldRainAt[X][y],
            text: 0,
          } as Ellipse;

          colors[X][y] = e;

          if (X <= XM && y <= YM) {
            cfrac[COLORI] = cfrac[COLORI] + 1;
            TotalPoint = TotalPoint + 1;
          }
        } else {
          COLORI = Math.round((tempdrainat[X][y] - minimum) / stepd + 1);
          if (COLORI <= 0) {
            COLORI = 1;
          }
          if (COLORI > 9) {
            COLORI = 9;
          }
          if (Color[COLORI] != null) {
            let br = Color[COLORI];
            const e: Ellipse = {
              color: br,
              width: POINTSIZE * 1.25,
              height: POINTSIZE * 1.25,
              value: FieldRainAt[X][y],
              text: 0,
            } as Ellipse;
            colors[X][y] = e;
          }
          if (X <= XM && y <= YM) {
            cfrac[COLORI] = cfrac[COLORI] + 1;
            TotalPoint = TotalPoint + 1;
          }
        }
      }
    }
  } else {
    for (X = 0; X < FieldRainAt.length; X++) {
      for (y = 0; y < FieldRainAt[0].length; y++) {
        if (FieldRainAt[X][y] == -1) {
          const e: Ellipse = {
            color: '#000',
            width: POINTSIZE * 1.25,
            height: POINTSIZE * 1.25,
            text: 0,
            value: FieldRainAt[X][y],
          } as Ellipse;
          colors[X][y] = e;

          if (X <= XM && y <= YM) {
            cfrac[0] = cfrac[0] + 1;
          }
        } else if (FieldRainAt[X][y] == 0) {
          COLORI = 0;
          const e: Ellipse = {
            color: '#000',
            width: POINTSIZE * 1.25,
            height: POINTSIZE * 1.25,
            value: FieldRainAt[X][y],
            text: 0,
          } as Ellipse;
          colors[X][y] = e;

          if (X <= XM && y <= YM) {
            cfrac[COLORI] = cfrac[COLORI] + 1;
            TotalPoint = TotalPoint + 1;
          }
        } else {
          COLORI = Math.round((FieldRainAt[X][y] - minimum) / stepd + 1);
          if (COLORI <= 0) {
            COLORI = 1;
          }
          if (COLORI > 9) {
            COLORI = 9;
          }
          if (Color[COLORI] != null) {
            let br = Color[COLORI];
            const e: Ellipse = {
              color: br,
              width: POINTSIZE * 1.25,
              height: POINTSIZE * 1.25,
              value: FieldRainAt[X][y],
              text: 0,
            } as Ellipse;
            colors[X][y] = e;
          }
          if (X <= XM && y <= YM) {
            cfrac[COLORI] = cfrac[COLORI] + 1;
            TotalPoint = TotalPoint + 1;
          }
        }
      }
    }
  }
  let DrawGrowthResult;
  if (_ShowGrowth) {
    DrawGrowthResult = DrawGrowth(
      fieldpic,
      dXPOSITION,
      dYPOSITION,
      _Shadows,
      BetweenPlants,
      BetweenRows,
      PlantDiameter,
      StaggeredRows,
      GroupPlantRows,
      RowsinGroup,
      BetweenGroups,
      InRows,
      OffRows,
      Placement,
      EdgeType,
      dEDGEPOSITIONX,
      dEDGEPOSITIONY
    );
  }
  let line;
  if (Placement == EDGE_LAYOUT) {
    line = DoEdge(
      EdgeType,
      X_EDGE_LAYOUT,
      dEDGEPOSITIONX,
      dEDGEPOSITIONY,
      dXPOSITION,
      dYPOSITION,
      fieldpic,
      Y_EDGE_LAYOUT,
      CORNER_EDGE_LAYOUT
    );
  }

  let ELIPS = {
    value: colors as Array<Array<Ellipse>>,
    cfrac: cfrac,
    TotalPoints: TotalPoint,
    GrowthResult: DrawGrowthResult,
    EdgeLine: line,
  };
  return ELIPS;
}
