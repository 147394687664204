/* eslint-disable no-extend-native */
/* eslint-disable no-case-declarations */

function toFixed(x: any) {
  if (Math.abs(x) < 1.0) {
    var e = parseInt(x.toString().split('e-')[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = '0.' + new Array(e).join('0') + x.toString().substring(2);
    }
  } else {
    var e = parseInt(x.toString().split('+')[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += new Array(e + 1).join('0');
    }
  }
  return x;
}

export function toFixedEnumber(number: number) {
  if (number.toString().includes('e')) {
    const eNumber = toFixed(number);
    const [, res] = eNumber.split('.');
    if (res[6] !== '0') {
      return Number(number.toFixed(6));
    }
    return number;
  }
  return number;
}

export function CalculateHeadloss(
  isHW: number,
  HWcoef: number,
  TotalFlow: number,
  dia: number,
  length: number,
  kd: number,
  PRA: number,
  PRB: number,
  PRC: number
) {
  let F2 = 0.0;
  //var PRA = 0, PRB = 0, PRC = 0;
  switch (isHW) {
    case 0:
      F2 =
        (1.131 *
          Math.pow(10.0, 12.0) *
          //   Math.pow(parseInt((TotalFlow / HWcoef).toString()), 1.852)) /
          Math.pow(TotalFlow / HWcoef, 1.852)) /
        Math.pow(dia, 4.87);
      F2 = (F2 * length) / 1000;
      break;
    case 1:
      if (PRA != 0 && PRB != 0 && PRC != 0) {
        F2 = (((PRA * Math.pow(10.0, 7) * Math.pow(TotalFlow, PRB)) / Math.pow(dia, PRC)) * length) / 1000.0;
        //  F2 = toFixedEnumber(b);
      }
      break;
    case 2: {
      let Velocity = Math.pow(TotalFlow / 3600 / ((3.14159 * Math.pow(dia / 1000.0, 2.0)) / 4.0), 2.0) / 2.0 / 9.81;

      if (313 * Math.abs(TotalFlow / (dia / 1000)) <= 2000) {
        F2 = (64 / Math.abs((313 * TotalFlow * 1000) / dia)) * length * (Velocity / (dia / 1000));
      }

      if (313 * Math.abs(TotalFlow / (dia / 1000)) > 2000 && 313 * Math.abs(TotalFlow / (dia / 1000)) <= 4000) {
        F2 = 3.42e-5 * Math.pow(Math.abs((313 * TotalFlow * 1000) / dia), 0.85) * length * (Velocity / (dia / 1000));
      }
      if (313 * Math.abs(TotalFlow / (dia / 1000)) > 4000 && 313 * Math.abs(TotalFlow / (dia / 1000)) <= 100000) {
        F2 = (0.316 / Math.pow(Math.abs((313 * TotalFlow * 1000) / dia), 0.25)) * length * (Velocity / (dia / 1000));
      }

      if (313 * Math.abs(TotalFlow / (dia / 1000)) > 100000) {
        F2 = (0.13 / Math.pow(Math.abs((313 * TotalFlow * 1000) / dia), 0.172)) * length * (Velocity / (dia / 1000));
      }
      break;
    }
  }
  let v = (4.0 * TotalFlow) / (dia * dia * Math.PI * 36.0 * 0.0001);
  F2 += (kd * v * v) / 2 / 9.81;

  return F2;
}
