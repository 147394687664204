import { Box, Grid, Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { FC, useState, useEffect, useRef, useMemo } from 'react';
import { useAppSelector } from 'shared/hooks';
import { EmitterPng } from 'shared/assets';
import { getTransformedArray, getRange, getEmitterIconPositions } from 'shared/lib';
import { SCALE_COEFFICIENT } from 'shared/constants';
import { CanvasEdges, CanvasLines, CanvasPlants, CanvasRange } from 'shared/ui';
import { CanvasGraph } from './CanvasGraph';

export const Map: FC = () => {
  const {
    calcResult: { ELIPS, FieldRainAt, tempdrainat, ranges, GrowthResult, EdgeLine },
    edcValues: { edge, placement, lateralSpacing, emitterSpacing, scSquare, wettestSquare },
    edcToolBar: { showNumbers, showRange, showGrowth, showSectionGraph, displayPlants },
  } = useAppSelector((st) => st.edcState);

  const transformedEllipses = getTransformedArray(ELIPS, +placement, +edge);
  const transformedFieldAtRain = getTransformedArray(FieldRainAt, +placement, +edge);
  const transformedTempRainAt = getTransformedArray(tempdrainat ?? [], +placement, +edge);

  const mapContainerRef = useRef<HTMLDivElement>(null);
  const [legendWidth, setLegendWidth] = useState(0);
  const [legendHeight, setLegendHeight] = useState(0);

  useEffect(() => {
    setLegendWidth(mapContainerRef.current?.offsetWidth ?? 0);
    setLegendHeight(mapContainerRef.current?.offsetHeight ?? 0);
  }, [ELIPS]);

  const widthStep = legendWidth / SCALE_COEFFICIENT;
  const heightStep = legendHeight / SCALE_COEFFICIENT;

  const emitterIconPositions = getEmitterIconPositions({ edge, placement, widthStep, heightStep });

  const emitterSpacingArray = getRange(+emitterSpacing, 0, 5);
  const lateralSpacingArray = getRange(+lateralSpacing, 0, 5);

  const rangesData = useMemo(() => ranges ?? [], [ranges]);
  const edgeData = useMemo(() => EdgeLine ?? [], [EdgeLine]);

  return (
    <Grid
      item
      xs={12}
      container
      sx={{
        overflow: 'auto',
        p: 2,
        position: 'relative',
        width: 'fit-content',
        margin: '0 auto',
      }}
      alignItems="center"
    >
      <Grid item xs="auto" sx={{ p: 2, position: 'relative' }}>
        {emitterIconPositions?.map((item, idx) => (
          <img key={idx} src={EmitterPng} alt="" style={{ position: 'absolute', ...item }} />
        ))}

        <Box component="div" sx={{ position: 'relative', overflow: 'hidden' }} ref={mapContainerRef}>
          {transformedEllipses.map((ellipses, idxRow) => (
            <div key={idxRow} style={{ display: 'flex' }}>
              {ellipses.map((colors, idxEllipse) => (
                <div
                  key={idxEllipse}
                  style={{
                    borderRadius: '2px',
                    width: '10.24px',
                    height: '10.24px',
                    fontSize: '8px',
                    color: 'black',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontWeight: 'bold',
                    backgroundColor: colors.color,
                  }}
                >
                  {showNumbers && idxRow % 2 === 0 && idxEllipse % 2 === 0 && (
                    <span>
                      {tempdrainat?.length
                        ? transformedTempRainAt[idxRow][idxEllipse] > 0 &&
                          Math.ceil(transformedTempRainAt[idxRow][idxEllipse])
                        : transformedFieldAtRain[idxRow][idxEllipse] > 0 &&
                          Math.ceil(transformedFieldAtRain[idxRow][idxEllipse])}
                    </span>
                  )}
                </div>
              ))}
            </div>
          ))}

          {scSquare && (
            <div
              style={{
                position: 'absolute',
                top: scSquare.Y * 10.24,
                left: scSquare.X * 10.24,
                width: scSquare.side * 10.24,
                height: scSquare.side * 10.24,
                border: '2px solid red',
              }}
            />
          )}
          {wettestSquare && (
            <div
              style={{
                position: 'absolute',
                top: wettestSquare.Y * 10.24,
                left: wettestSquare.X * 10.24,
                width: wettestSquare.side * 10.24,
                height: wettestSquare.side * 10.24,
                border: '2px solid blue',
              }}
            />
          )}

          {showRange && rangesData.map((item, idx) => <CanvasRange key={idx} item={item} />)}

          {showSectionGraph && (
            <CanvasGraph
              width={mapContainerRef.current?.offsetWidth ?? 0}
              height={mapContainerRef.current?.offsetHeight ?? 0}
            />
          )}

          {edgeData.length ? (
            <CanvasEdges
              edge={edge}
              lines={edgeData}
              width={mapContainerRef.current?.offsetWidth ?? 0}
              height={mapContainerRef.current?.offsetHeight ?? 0}
            />
          ) : null}

          {displayPlants && GrowthResult?.resElip.length
            ? GrowthResult.resElip.map((item, idx) => <CanvasPlants key={idx} item={item} showGrowth={showGrowth} />)
            : null}

          {displayPlants && GrowthResult?.resLine.length ? (
            <CanvasLines
              lines={GrowthResult.resLine}
              width={mapContainerRef.current?.offsetWidth ?? 0}
              height={mapContainerRef.current?.offsetHeight ?? 0}
            />
          ) : null}
        </Box>
      </Grid>

      {emitterSpacingArray.map((item, idx) => (
        <Typography variant="caption" key={uuidv4()} sx={{ position: 'absolute', top: 30 + heightStep * idx, left: 0 }}>
          {item}
        </Typography>
      ))}
      {lateralSpacingArray.map((item, idx) => (
        <Typography variant="caption" key={uuidv4()} sx={{ position: 'absolute', top: 0, left: 35 + widthStep * idx }}>
          {item}
        </Typography>
      ))}
    </Grid>
  );
};
