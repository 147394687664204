import { FC } from 'react';
import { Box, RadioGroup, Radio, styled, FormControlLabelProps, FormControlLabel, useMediaQuery } from '@mui/material';
import { ShepherdBlock } from '../ShepherdBlock';
import { Row } from '../Row';

const SHEPHERD_ID_DICTIONARY = {
  'lateral-select-type': 'lateral-select-product-type',
  'lateral-calc-type': 'lateral-calculation-type-select',
  submain: 'submain-length',
};

const StyledRadio = styled(Radio)(({ theme }) => ({
  color: theme.palette.info.dark,
  px: '5px',
  ml: '15px',
  '&.Mui-checked': {
    color: theme.palette.info.main,
  },
}));

const StyledLabel: FC<FormControlLabelProps> = ({ value, checked, control, label, sx }) => {
  const isMobile = useMediaQuery('(max-width:400px)');

  return (
    <FormControlLabel
      value={value}
      control={control}
      label={label}
      checked={checked}
      sx={{
        display: 'flex',
        flexWrap: 'nowrap',
        minWidth: isMobile ? '40%' : '146px',

        borderRadius: '9px',
        margin: 0,
        marginBottom: '5px',
        justifyContent: isMobile ? 'flex-start' : 'center',
        padding: '0 10px',
        height: '33px',
        border: '1px solid grey',
        boxShadow: '0px 3px 5px 1px rgba(0, 0, 0, 0.3)',
        bgcolor: checked ? 'primary.main' : 'info.main',
        borderColor: checked ? 'primary.main' : 'info.dark',
        overflow: 'hidden',
        ...sx,
        '& .MuiTypography-root': {
          color: checked ? 'info.main' : 'info.dark',
          fontSize: '16px',
          lineHeight: '16px',
          fontWeight: 400,
          marginLeft: '9px',
          overflow: 'hidden',

          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        },
        span: {
          padding: '4px 0px',
        },
      }}
    />
  );
};

interface Props {
  values: string[];
  trueFlag: boolean;
  onChange: () => void;
  radioType?: keyof typeof SHEPHERD_ID_DICTIONARY;
}

export const RadioSwitcher: FC<Props> = ({ values, onChange, trueFlag, radioType = 'lateral-calc-type' }) => {
  const shepherdId = SHEPHERD_ID_DICTIONARY[radioType];

  return (
    <Box pb="2px">
      <RadioGroup onChange={onChange} row sx={{ flexWrap: 'nowrap' }}>
        <ShepherdBlock id={`${shepherdId}`}>
          <Row flexWrap="nowrap">
            <StyledLabel
              value={values[0]}
              label={values[0]}
              checked={trueFlag}
              control={<StyledRadio size="small" />}
              sx={{ mb: '5px', mr: '10px' }}
            />
            <StyledLabel
              value={values[1]}
              label={values[1]}
              checked={!trueFlag}
              control={<StyledRadio size="small" />}
            />
          </Row>
        </ShepherdBlock>
      </RadioGroup>
    </Box>
  );
};
