import { Shape } from '../models/Shape';
import { v_at } from './v_at';
export function CalcBILATERAL(CalcBIL: Shape) {
  let X = 0;
  let y = 0;
  let temp: number;
  let j = 0;
  let FieldRainAt = CalcBIL.FieldRainAt;

  for (X = 0; y <= CalcBIL.Yint; X++) {
    for (y = 0; y <= CalcBIL.Xint; y++) {
      for (j = -CalcBIL.Ky; j <= CalcBIL.Ky + 1; j++) {
        if (j != 0) {
          FieldRainAt[X][y] =
            FieldRainAt[X][y] +
            v_at(
              X,
              parseInt(String(Math.abs(j) * CalcBIL.YMAMTEROT - (j / Math.abs(j)) * y)),
              CalcBIL.fieldintervalX,
              CalcBIL.fieldintervalY,
              CalcBIL.dRange,
              CalcBIL.cbEDCEmitter,
              CalcBIL.TUBENUM,
            );
          FieldRainAt[X][y] =
            FieldRainAt[X][y] +
            v_at(
              CalcBIL.XMAMTEROT - X,
              parseInt(String(Math.abs(j) * CalcBIL.YMAMTEROT - (j / Math.abs(j)) * y)),
              CalcBIL.fieldintervalX,
              CalcBIL.fieldintervalY,
              CalcBIL.dRange,
              CalcBIL.cbEDCEmitter,
              CalcBIL.TUBENUM,
            );
        } else {
          FieldRainAt[X][y] =
            FieldRainAt[X][y] +
            v_at(
              X,
              y,
              CalcBIL.fieldintervalX,
              CalcBIL.fieldintervalY,
              CalcBIL.dRange,
              CalcBIL.cbEDCEmitter,
              CalcBIL.TUBENUM,
            ) +
            v_at(
              CalcBIL.XMAMTEROT - X,
              y,
              CalcBIL.fieldintervalX,
              CalcBIL.fieldintervalY,
              CalcBIL.dRange,
              CalcBIL.cbEDCEmitter,
              CalcBIL.TUBENUM,
            );
        }
      }

      temp = FieldRainAt[X][y];
      if (temp > CalcBIL.max) {
        CalcBIL.max = temp;
      } else if (temp < CalcBIL.min) {
        CalcBIL.min = temp;
        CalcBIL.MinChange = true;
      }

      FieldRainAt[X][y] = temp;
      FieldRainAt[CalcBIL.YMAMTEROT - X][CalcBIL.YMAMTEROT - y] = temp;
      FieldRainAt[CalcBIL.YMAMTEROT - X][y] = temp;
    }
  }
  let res = {
    FieldRainAt: FieldRainAt,
    max: CalcBIL.max,
    min: CalcBIL.min,
  };
  return res;
}
