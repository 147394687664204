import React, { useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

type LocationState = { sideMenuIsOpened: boolean };

export const useSideMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as LocationState;

  const sideMenuIsOpened = state?.sideMenuIsOpened;

  const setSideMenuIsOpened = useCallback(
    (open: boolean) => {
      if (open && !state?.sideMenuIsOpened) {
        navigate(location.pathname, { state: { sideMenuIsOpened: true } });
      } else if (!open && state?.sideMenuIsOpened) {
        navigate(-1);
      }
    },
    [navigate, location.pathname, state?.sideMenuIsOpened],
  );

  const toggleSideMenu = useCallback(
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setSideMenuIsOpened(open);
    },
    [setSideMenuIsOpened],
  );

  const closeSideMenu = useCallback(() => {
    setSideMenuIsOpened(false);
  }, [setSideMenuIsOpened]);

  return { sideMenuIsOpened, toggleSideMenu, closeSideMenu };
};
