import { Box, useMediaQuery } from '@mui/material';
import { ChangeEvent, FC } from 'react';
import { input_ico, main_line_bg, pipe_dia } from 'shared/assets';
import { IconLabel, RadioSwitcher, ResetButton, Row, ShepherdBlock } from 'shared/ui';
import {
  AdvancedOptionsBlock,
  DefineSlopesBlock,
  InputBlock,
  ResultsBlock,
  SelectProductBlock,
  SimulationBlock,
} from './components';
import { useAppDispatch, useAppSelector, useMainlineFilters } from 'shared/hooks';
import { setMainlineStateValue, setProjectValue } from 'shared/slices';
import { FilterMainlineCatalogs } from 'shared/lib/calculation/Mainline/FilterMainlineCatalogs';
import { MainControlBlock } from 'features';
import { useTranslation } from 'react-i18next';
import { formattedInputValue } from 'shared/lib';

export const Mainline: FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { mainlineValues } = useAppSelector((state) => state.mainlineState);
  // const { projectData } = useAppSelector((state) => state.projectData);
  const { units } = useAppSelector((state) => state.units);
  const isTablet = useMediaQuery('(max-width:850px)');

  const submainSwitchValues = [t('calculateLength'), t('mainLineSimulation')];

  const handleToggleCalcLength = () => dispatch(setMainlineStateValue({ isCalcLength: !mainlineValues.isCalcLength }));

  const {
    masterGroups,
    masterGroupId,
    onMasterGroupChange,
    mainlineSubtype,
    onGroupTypeChange,
    classType,
    onClassTypeChange,
    classTypes,
    mainlinePipe1,
    mainlinePipe2,
    mainlinePipe3,
    mainlinesFiltered,
    mainlinesLoading,
    mainlineGroupsLoading,
    onMainline1Change,
    onMainline2Change,
    onMainline3Change,
  } = useMainlineFilters();

  const onMaxVelocityChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setMainlineStateValue({ maxVelocity: formattedInputValue(e) }));

    if (classType === 'All') return;

    const currentPipes = FilterMainlineCatalogs({
      maxVelocity: +e.target.value,
      manifoldClass: classType.toString(),
      totalFlow: +mainlineValues.flow,
      cbManifoldPipe: mainlinesFiltered,
      units,
    });

    if (!currentPipes) return;

    dispatch(
      setProjectValue({
        mainlinePipe1: currentPipes.manifoldPipe1Selected,
        mainlinePipe2: currentPipes.manifoldPipe2Selected,
        mainlinePipe3: currentPipes.manifoldPipe3Selected,
      }),
    );
  };

  const onFlowChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setMainlineStateValue({ flow: formattedInputValue(e) }));

    if (classType === 'All') return;

    const currentPipes = FilterMainlineCatalogs({
      maxVelocity: +mainlineValues.maxVelocity,
      manifoldClass: classType.toString(),
      totalFlow: +e.target.value,
      cbManifoldPipe: mainlinesFiltered,
      units,
    });

    if (!currentPipes) return;
    dispatch(
      setProjectValue({
        mainlinePipe1: currentPipes.manifoldPipe1Selected,
        mainlinePipe2: currentPipes.manifoldPipe2Selected,
        mainlinePipe3: currentPipes.manifoldPipe3Selected,
      }),
    );
  };

  const onReset = () => {
    dispatch(
      setMainlineStateValue({
        pipesHeadloss: 0,
        pressureAtValve: 0,
        pumpPressure: 0,
        allowableHeadloss: 10,
        pumpHeadloss: 10,
        valveHeadloss: 5,
        length: 0,
        maxVelocity: 2.5,
        flow: 0,
      }),
    );
    dispatch(
      setProjectValue({
        mainlineHWCoef: 0,
        mainlineSlope: 0,
        lateralInletPressure: 0,
        mainlinePumpPressure: 0,
        mainlinePressureAtValve: 0,
        mainlinePipeHeadloss: 0,
        mainlinePipe1Length: 0,
        mainlinePipe2Length: 0,
        mainlinePipe3Length: 0,
      }),
    );
  };

  return (
    <Box>
      <Row direction={{ xs: 'column', xl: 'row' }} alignItems='flex-start' minHeight={isTablet ? 'auto' : '480px'}>
        <Box width={{ xs: '100%', xl: '45%' }}>
          <ShepherdBlock id='mainline-input'>
            <Row justifyContent={isTablet ? 'flex-end' : 'space-between'} alignItems='flex-start'>
              {!isTablet && <IconLabel iconSrc={input_ico}>{t('input')}</IconLabel>}

              <ResetButton onClick={onReset} />
            </Row>

            <InputBlock onMaxVelocityChange={onMaxVelocityChange} onFlowChange={onFlowChange} />
          </ShepherdBlock>
          <ShepherdBlock id='mainline-selected-product'>
            <SelectProductBlock
              masterGroups={masterGroups}
              masterGroupId={masterGroupId}
              onMasterGroupChange={onMasterGroupChange}
              mainlineSubtype={mainlineSubtype}
              onGroupTypeChange={onGroupTypeChange}
              classType={classType}
              onClassTypeChange={onClassTypeChange}
              classTypes={classTypes}
            />
          </ShepherdBlock>
        </Box>

        <Box width={{ xs: '100%', xl: '55%' }} mt={{ xs: '20px', xl: 0 }}>
          <Row alignItems='flex-start' direction={isTablet ? 'column-reverse' : 'row'}>
            <AdvancedOptionsBlock />
            <DefineSlopesBlock />
          </Row>
        </Box>
      </Row>

      <Box mt={{ xs: '10px', xl: '-65px' }}>
        <RadioSwitcher
          radioType='submain'
          values={submainSwitchValues}
          trueFlag={mainlineValues.isCalcLength}
          onChange={handleToggleCalcLength}
        />

        {!isTablet && <IconLabel iconSrc={pipe_dia}>{t('pipeDiameters')}</IconLabel>}

        <Row direction={{ xs: 'column', xl: 'row' }} alignItems='flex-start'>
          <Box width={{ xs: '100%', xl: '45%' }}>
            <ShepherdBlock id='submain-calc'>
              <SimulationBlock
                mainlinePipe1={mainlinePipe1}
                mainlinePipe2={mainlinePipe2}
                mainlinePipe3={mainlinePipe3}
                mainlinesFiltered={mainlinesFiltered}
                mainlinesLoading={mainlinesLoading}
                mainlineGroupsLoading={mainlineGroupsLoading}
                onMainline1Change={onMainline1Change}
                onMainline2Change={onMainline2Change}
                onMainline3Change={onMainline3Change}
              />
            </ShepherdBlock>
            <ShepherdBlock id='submain-result'>
              <ResultsBlock />
            </ShepherdBlock>
          </Box>

          {!isTablet && (
            <Box width={{ xs: '100%', xl: '55%' }}>
              <ShepherdBlock id='mainline-result-graphically'>
                <MainControlBlock bg={main_line_bg} />
              </ShepherdBlock>
            </Box>
          )}
        </Row>
      </Box>
    </Box>
  );
};
