import { Box, Grid, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { results_ico } from 'shared/assets';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { toggleSubmainReport } from 'shared/slices';
import { Accordion, Button, IconLabel, Input, PaperBox, ShepherdBlock } from 'shared/ui';

const ResultsContent = () => {
  const { t } = useTranslation();

  const { units } = useAppSelector((state) => state.units);
  const { submainValues } = useAppSelector((state) => state.submainState);
  const isTablet = useMediaQuery('(max-width:850px)');
  const isMobile = useMediaQuery('(max-width:550px)');

  return (
    <Grid item container xs={12} columnSpacing={isMobile ? 1 : 3} rowSpacing={1}>
      <Grid item xs={isTablet ? 6 : 3}>
        <Input label={`${t('valveOutletPressure')} (${units.pressure})`} isResultBox
               value={submainValues.valvePressure} />
      </Grid>
      <Grid item xs={isTablet ? 6 : 3}>
        <Input label={`${t('firstLateral')} (${units.pressure})`} isResultBox value={submainValues.firstLateral} />
      </Grid>
      <Grid item xs={isTablet ? 6 : 3}>
        <Input label={`${t('lastLateral')} (${units.pressure})`} isResultBox value={submainValues.lastLateral} />
      </Grid>
      <Grid item xs={isTablet ? 6 : 3}>
        <Input label={`${t('totalHeadloss')} (${units.pressure})`} isResultBox value={submainValues.totalHeadloss} />
      </Grid>
    </Grid>
  );
};

const ReportButton = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { submainValues } = useAppSelector((state) => state.submainState);
  const isTablet = useMediaQuery('(max-width:850px)');
  const isMobile = useMediaQuery('(max-width:550px)');

  const openSubmainReport = () => dispatch(toggleSubmainReport());

  return (
    <Grid item container xs={12} columnSpacing={3} mt={1}>
      <Grid item xs={isTablet ? 6 : 9}></Grid>
      <Grid item xs={isMobile ? 12 : isTablet ? 6 : 2.8}>
        <ShepherdBlock id='submain-result-report'>
          <Button iconType='show-report' onClick={openSubmainReport} disabled={!submainValues.valvePressure}>
            {t('showReport')}
          </Button>
        </ShepherdBlock>
      </Grid>
    </Grid>
  );
};

export const ResultsBlock = () => {
  const isTablet = useMediaQuery('(max-width:850px)');
  const isMobile = useMediaQuery('(max-width:550px)');

  const { t } = useTranslation();

  return (
    <Box mt={isTablet ? '10px' : '-20px'}>
      {isTablet ? (
        <Accordion defaultExpanded header={<IconLabel iconSrc={results_ico}>{t('results')}</IconLabel>}>
          <>
            <ResultsContent />
            {!isMobile && <ReportButton />}
          </>
        </Accordion>
      ) : (
        <>
          <IconLabel iconSrc={results_ico}>{t('results')}</IconLabel>

          <PaperBox>
            <ResultsContent />
          </PaperBox>
          <ReportButton />
        </>
      )}
    </Box>
  );
};
