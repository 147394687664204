import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type SystemSummaryValues = {
  avgIrrigationRate: number;
  numberOfOperations: number;
  maxTotalIrrigationTime: number;
  avgDischarge: number;
  waterSourceUsage: number;
  timeOfIrrigation: number;
  flowRate: string | number;
};

export type SystemSummaryErrors = {
  irrigationArea: boolean;
  numberOfLateral: boolean;
  lateralSpacing: boolean;
  avgPeak: boolean;
  waterSource: boolean;
  emitterSpacing: boolean;
  maxIrrigation: boolean;
  flowRate: boolean;
  sourceFlowRate: boolean;
  waterSourceUsage: boolean;
};

interface SystemSummary {
  systemSummaryValues: SystemSummaryValues;
  systemSummaryErrors: SystemSummaryErrors;
}

const initialState: SystemSummary = {
  systemSummaryValues: {
    avgIrrigationRate: 0,
    numberOfOperations: 0,
    maxTotalIrrigationTime: 0,
    avgDischarge: 0,
    waterSourceUsage: 0,
    timeOfIrrigation: 0,
    flowRate: 0,
  },
  systemSummaryErrors: {
    irrigationArea: false,
    numberOfLateral: false,
    lateralSpacing: false,
    emitterSpacing: false,
    avgPeak: false,
    waterSource: false,
    maxIrrigation: false,
    sourceFlowRate: false,
    flowRate: false,
    waterSourceUsage: false,
  },
};

const systemSummarySlice = createSlice({
  name: 'systemSummary',
  initialState,
  reducers: {
    setSystemSummaryStateValue: (state, action: PayloadAction<Partial<SystemSummaryValues>>) => {
      state.systemSummaryValues = { ...state.systemSummaryValues, ...action.payload };
    },
    setSystemSummaryStateError: (state, action: PayloadAction<Partial<SystemSummaryErrors>>) => {
      state.systemSummaryErrors = { ...state.systemSummaryErrors, ...action.payload };
    },
  },
});

export const { setSystemSummaryStateValue, setSystemSummaryStateError } = systemSummarySlice.actions;

export const systemSummaryReducer = systemSummarySlice.reducer;
