import { FC } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { input_ico, laterals_bg, select_product } from 'shared/assets';
import { IconLabel, RadioSwitcher, ResetButton, Row, ShepherdBlock } from 'shared/ui';
import { DefineSlopesBlock, EmitterBlock, InputBlock, LateralBlock, ResultsBlock, TechDataBlock } from './components';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { setLateralStateValue, setProjectValue } from 'shared/slices';
import { CatalogItem } from 'shared/models';
import { METRIC_DEFAULTS } from 'shared/constants';
import { Math_round } from 'shared/lib/calculation/mathRound';
import { ConvertUnit } from 'shared/lib/calculation/unitConverter';
import { getEmitterKD, getEmitterKDMaxLength } from 'shared/lib/lateralHelpers';
import { MainControlBlock } from 'features';
import { useTranslation } from 'react-i18next';

export const Lateral: FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { lateralValues } = useAppSelector((st) => st.lateralState);
  const { projectData } = useAppSelector((st) => st.projectData);
  const { units } = useAppSelector((st) => st.units);
  const { laterals } = useAppSelector((state) => state.laterals);
  const { emitters } = useAppSelector((state) => state.emitters);
  const isTablet = useMediaQuery('(max-width:850px)');
  const isMobile = useMediaQuery('(max-width:500px)');

  const integrateSwitchValues = [t('integrated'), t('onLine')];
  const lengthSwitchValues = [t('defineLength'), t('maxLength')];

  const handleToggleMaxLength = () => dispatch(setLateralStateValue({ isMaxLength: !lateralValues.isMaxLength }));

  const handleSetCurrentProductData = (isIntegrated: boolean) => {
    const currentLateral = laterals.find((item) => item.catlog === projectData.lateralCatalog) as CatalogItem;
    const currentEmitter = emitters.find((item) => item.catlog === projectData.emitterCatalog) as CatalogItem;

    if (isIntegrated) {
      dispatch(
        setProjectValue({
          lateralInletDia: Math_round(
            ConvertUnit(currentLateral.intrnl, METRIC_DEFAULTS.PipeDiameter, units.pipeDiameter, null),
            3
          ),
          emitterSpacing: Math_round(
            ConvertUnit(currentLateral.spacing, METRIC_DEFAULTS.LateralSpacing, units.lateralSpacing, null),
            3
          ),
          emitterPMin: Math_round(ConvertUnit(currentLateral.pMin, METRIC_DEFAULTS.Pressure, units.pressure, null), 3),
          emitterPMax: Math_round(ConvertUnit(currentLateral.pMax, METRIC_DEFAULTS.Pressure, units.pressure, null), 3),
          emitterA: currentLateral.emitterQa,
          emitterB: currentLateral.emitterQb,
          cvv: currentLateral.mManuFactCv,
          hwCoef: currentLateral.hwcof,
          lateralKd: isIntegrated
            ? currentLateral.kd
            : lateralValues.isMaxLength
              ? getEmitterKDMaxLength(currentEmitter, currentLateral)
              : getEmitterKD(currentEmitter, currentLateral),
        })
      );
    } else {
      dispatch(
        setProjectValue({
          lateralInletDia: Math_round(
            ConvertUnit(currentLateral.intrnl, METRIC_DEFAULTS.PipeDiameter, units.pipeDiameter, null),
            3
          ),
          emitterSpacing: Math_round(
            ConvertUnit(currentEmitter.spacing, METRIC_DEFAULTS.LateralSpacing, units.lateralSpacing, null),
            3
          ),
          emitterPMax: Math_round(ConvertUnit(currentEmitter.pMax, METRIC_DEFAULTS.Pressure, units.pressure, null), 3),
          emitterPMin: Math_round(ConvertUnit(currentEmitter.pMin, METRIC_DEFAULTS.Pressure, units.pressure, null), 3),
          emitterA: currentEmitter.emitterQa,
          emitterB: currentEmitter.emitterQb,
          lateralKd: isIntegrated
            ? currentLateral.kd
            : lateralValues.isMaxLength
              ? getEmitterKDMaxLength(currentEmitter, currentLateral)
              : getEmitterKD(currentEmitter, currentLateral),
          cvv: currentEmitter.mManuFactCv,
          hwCoef: currentEmitter.hwcof,
        })
      );
    }
  };

  const handleToggleIntegrated = () => {
    dispatch(setProjectValue({ integrated: !projectData.integrated }));
    handleSetCurrentProductData(!projectData.integrated);
  };

  const resetAllData = () => {
    dispatch(
      setProjectValue({
        lateralSlopes: '',
        lateralInletPressure: 0,
        lateralLength2: 0,
        flushingVelocity: 0,
      })
    );

    dispatch(
      setLateralStateValue({
        resultEU: '',
        resultMaxVelocity: '',
        resultTotalHeadloss: '',
        resultTotalFlow: '',
        resultDU: '',
        resultPMin: '',
        resultPMax: '',
        resultQMinQMax: '',
        resultReportArray: [],
        resultMaxLength: '',
        resultMaxLengthEU: '',
        resultMaxLengthMaxVelocity: '',
        resultMaxLengthTotalHeadloss: '',
        resultMaxLengthTotalFlow: '',
        resultMaxLengthDU: '',
        resultMaxLengthPMin: '',
        resultMaxLengthPMax: '',
        resultMaxLengthQMinQMax: '',
        resultMaxLengthReportArray: [],
      })
    );

    handleSetCurrentProductData(projectData.integrated);
  };

  return (
    <Box>
      <Row direction={{ xs: 'column', xl: 'row' }} alignItems="flex-start" minHeight={isTablet ? 'auto' : '480px'}>
        <Box width={{ xs: '100%', xl: '45%' }}>
          <Row
            justifyContent="space-between"
            alignItems={isMobile ? 'center' : 'flex-start'}
            pb={isMobile ? '10px' : 0}
            flexWrap="wrap"
          >
            <IconLabel iconSrc={select_product}>{t('selectProduct')}</IconLabel>

            <Row justifyContent="flex-end" flexGrow={1}>
              <ResetButton onClick={resetAllData} />
            </Row>
          </Row>
          <RadioSwitcher
            radioType="lateral-select-type"
            values={integrateSwitchValues}
            trueFlag={projectData.integrated}
            onChange={handleToggleIntegrated}
          />

          {!projectData.integrated && (
            <ShepherdBlock id="lateral-emitter-block">
              <EmitterBlock />
            </ShepherdBlock>
          )}

          <ShepherdBlock id="lateral-lateral-block">
            <LateralBlock />
          </ShepherdBlock>
        </Box>
        <Box width={{ xs: '100%', xl: '55%' }} mt={{ xs: '15px', xl: 0 }}>
          <Row alignItems="flex-start" direction={isTablet ? 'column-reverse' : 'row'}>
            <TechDataBlock />
            <DefineSlopesBlock />
          </Row>
        </Box>
      </Row>

      <Box mt={{ xs: '20px', xl: '-65px' }} pt={{ xl: 1 }}>
        <RadioSwitcher
          radioType="lateral-calc-type"
          values={lengthSwitchValues}
          trueFlag={!lateralValues.isMaxLength}
          onChange={handleToggleMaxLength}
        />
        {!isTablet && <IconLabel iconSrc={input_ico}>{t('input')}</IconLabel>}

        <Row direction={{ xs: 'column', xl: 'row' }} alignItems="flex-start">
          <Box width={{ xs: '100%', xl: '45%' }}>
            <InputBlock />
            <ShepherdBlock id="lateral-calculation-result-value">
              <ResultsBlock />
            </ShepherdBlock>
          </Box>

          {!isTablet && (
            <Box width={{ xs: '100%', xl: '55%' }}>
              <ShepherdBlock id="lateral-result-graphically">
                <MainControlBlock bg={laterals_bg} />
              </ShepherdBlock>
            </Box>
          )}
        </Row>
      </Box>
    </Box>
  );
};
