export const GetY = (X: number, X1: number, X2: number, Y1: number, Y2: number) => {
  let functionReturnValue = 0;
  if (X2 != X1) {
    if (X2 > X1) {
      functionReturnValue = (X * (Y2 - Y1) - Y2 * X1 + Y1 * X2) / (X2 - X1);
    } else {
      functionReturnValue = (X * (Y2 - Y1) + Y1 * X2 - Y2 * X1) / (X2 - X1);
    }
  } else {
    functionReturnValue = X1;
  }
  return Math.round(functionReturnValue);
};
