import { Box, Button, Stack, Typography, styled, useMediaQuery } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { AuthInput } from 'shared/ui';
import { LoginBody } from 'shared/models';
import { useAppDispatch, useDialog, useToast } from 'shared/hooks';
import { useTranslation } from 'react-i18next';
import { AccountNotActivatedModal, EndUserLicenseAcceptance, LoginHeader } from 'features';
import { loginThunk } from 'shared/slices';

const Text = styled(Typography)(() => ({
  fontSize: '11px',
  fontWeight: 400,
  margin: '20px 0 8px 20px',
  textDecoration: 'none',
}));

const btnStyles = {
  textTransform: 'capitalize',
  borderRadius: '8px',
  minWidth: '120px',
  height: '48px',
  fontSize: 18,
};

export const RedirectedLoginForm = () => {
  const { showError } = useToast();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:510px)');
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const [loginData, setLoginData] = useState({ username: '', password: '' });
  const handleLoginDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoginData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    const mergeData = JSON.parse(localStorage.getItem('mergeData') as string);
    mergeData && setLoginData({ username: mergeData?.email, password: '' });
  }, []);

  const { open: openAcceptance, toggle: toggleAcceptance } = useDialog();
  const { open: openActivation, toggle: toggleActivation } = useDialog();

  const statusCallbacks: { [key: number]: () => void } = useMemo(
    () => ({
      400: () => showError(t('wrong_login_credentials')),
      411: () => toggleAcceptance(),
      412: () => toggleActivation(),
    }),
    [loginData.username]
  );

  const loginBody: LoginBody = {
    user: loginData.username,
    password: loginData.password,
    rememberMe: false,
    isFromOldHT: true,
    isUserValidation: true,
    isNeedToMerge: true,
  };

  const handleLoginClick = async (cachedBody?: LoginBody) => {
    const body: LoginBody = cachedBody || loginBody;

    try {
      setLoading(true);

      await dispatch(loginThunk(body)).unwrap();

      navigate('/lateral');
    } catch (e: any) {
      console.log(e);

      const callback = statusCallbacks[e];

      if (callback) {
        callback();
      } else {
        showError(t('wrong_login_credentials'));
      }
    } finally {
      setLoading(false);
    }
  };

  const onCancelClick = () => {
    navigate(-1);
    localStorage.removeItem('mergeData');
  };

  return (
    <>
      <Stack alignItems="center" sx={{}}>
        <Box
          sx={{
            p: '40px 24px 20px 24px',
            width: isMobile ? '100%' : '480px',
            boxShadow: '0px -0.5px 4px 0px rgba(0, 0, 0, 0.25)',
            borderRadius: '16px',
          }}
        >
          <LoginHeader />

          <Stack mt={1} sx={{}}>
            <Box flexBasis="100%">
              <Text>{t('login')}</Text>
              <AuthInput
                name="username"
                value={loginData.username}
                onChange={handleLoginDataChange}
                required
                autoFocus
                placeholder={`${t('enter_email')}`}
                disabled
              />
            </Box>

            <Box flexBasis="100%" mb={2}>
              <Text>{t('password')}</Text>

              <AuthInput
                name="password"
                value={loginData.password}
                onChange={handleLoginDataChange}
                required
                // type="password"
                placeholder={`${t('enter_password')}`}
                password={'true'}
              />
            </Box>

            <Stack direction="row" justifyContent="flex-end" columnGap={3} mt={1}>
              <Box>
                <Button
                  onClick={onCancelClick}
                  variant="outlined"
                  sx={{
                    ...btnStyles,
                    color: 'black',
                    borderColor: 'black',
                  }}
                >
                  {t('cancel')}
                </Button>
              </Box>
              <Box>
                <LoadingButton
                  onClick={() => handleLoginClick()}
                  loading={loading}
                  fullWidth
                  variant="contained"
                  color="primary"
                  sx={{ ...btnStyles }}
                  disabled={!loginData.password}
                >
                  {t('login')}
                </LoadingButton>
              </Box>
            </Stack>
          </Stack>
        </Box>
      </Stack>

      <EndUserLicenseAcceptance
        open={openAcceptance}
        handleClose={toggleAcceptance}
        email={loginData.username}
        loginCallback={() => handleLoginClick(loginBody)}
      />

      <AccountNotActivatedModal open={openActivation} onClose={toggleActivation} email={loginData.username} />
    </>
  );
};
