import { FC } from 'react';
import { Box } from '@mui/system';
import { results_ico } from 'shared/assets';
import { Accordion, Button, IconLabel, Input, PaperBox, ShepherdBlock } from 'shared/ui';
import { Grid, useMediaQuery } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { setLateralStateValue, toggleEnterLengthValues, toggleLateralDefineLengthReport } from 'shared/slices';
import { useTranslation } from 'react-i18next';

const ResultsContent = () => {
  const isMobile = useMediaQuery('(max-width:550px)');
  const { t } = useTranslation();
  const { lateralValues, lateralErrors } = useAppSelector((st) => st.lateralState);
  const { units } = useAppSelector((st) => st.units);

  const {
    isMaxLength,
    resultDU,
    resultEU,
    resultMaxLength,
    resultMaxLengthDU,
    resultMaxLengthEU,
    resultMaxLengthMaxVelocity,
    resultMaxLengthPMax,
    resultMaxLengthPMin,
    resultMaxLengthTotalFlow,
    resultMaxLengthTotalHeadloss,
    resultMaxVelocity,
    resultPMax,
    resultPMin,
    resultTotalFlow,
    resultTotalHeadloss,
  } = lateralValues;

  const { maxLengthPMax, maxLengthPMin, maxLengthVelocity, pMax, pMin, velocity } = lateralErrors;

  return (
    <>
      <Grid item container xs={12} columnSpacing={isMobile ? 1 : 3} rowSpacing={1}>
        {isMaxLength && (
          <Grid item xs={isMobile ? 4 : 3}>
            <Input disabled label={`${t('maxLength')} (${units.length})`} isResultBox value={resultMaxLength} />
          </Grid>
        )}
        <Grid item xs={isMobile ? 4 : 3}>
          <Input
            type='text'
            disabled
            label={`${t('eu')} (%)`}
            isResultBox
            value={isMaxLength ? resultMaxLengthEU : resultEU}
          />
        </Grid>
        <Grid item xs={isMobile ? 4 : 3}>
          <Input
            type='text'
            disabled
            label={`${t('maxVelocity')} (${units.velocity})`}
            isResultBox
            value={isMaxLength ? resultMaxLengthMaxVelocity : resultMaxVelocity}
            isErrorBox={isMaxLength ? maxLengthVelocity : velocity}
          />
        </Grid>
        <Grid item xs={isMobile ? 4 : 3}>
          <Input
            type='text'
            disabled
            label={`${t('totalHeadloss')} (${units.pressure})`}
            isResultBox
            value={isMaxLength ? resultMaxLengthTotalHeadloss : resultTotalHeadloss}
          />
        </Grid>
        {!isMaxLength && (
          <Grid item xs={isMobile ? 4 : 3}>
            <Input
              type='text'
              disabled
              label={`${t('totalFlow')} (${units.totalFlow})`}
              isResultBox
              value={isMaxLength ? resultMaxLengthTotalFlow : resultTotalFlow}
            />
          </Grid>
        )}

        {isMaxLength && (
          <Grid item xs={isMobile ? 4 : 3}>
            <Input
              type='text'
              disabled
              label={`${t('totalFlow')} (${units.totalFlow})`}
              isResultBox
              value={isMaxLength ? resultMaxLengthTotalFlow : resultTotalFlow}
            />
          </Grid>
        )}
        <Grid item xs={isMobile ? 4 : 3}>
          <Input
            type='text'
            disabled
            label={`${t('du')}`}
            isResultBox
            value={isMaxLength ? resultMaxLengthDU : resultDU}
          />
        </Grid>
        <Grid item xs={isMobile ? 4 : 3}>
          <Input
            type='text'
            disabled
            label={`${t('pMin')} (${units.pressure})`}
            isResultBox
            value={isMaxLength ? resultMaxLengthPMin : resultPMin}
            isErrorBox={isMaxLength ? maxLengthPMin : pMin}
          />
        </Grid>
        <Grid item xs={isMobile ? 4 : 3}>
          <Input
            type='text'
            disabled
            label={`${t('pMax')} (${units.pressure})`}
            isResultBox
            value={isMaxLength ? resultMaxLengthPMax : resultPMax}
            isErrorBox={isMaxLength ? maxLengthPMax : pMax}
          />
        </Grid>
      </Grid>
    </>
  );
};

const ShowReportButton = () => {
  const isTablet = useMediaQuery('(max-width:850px)');
  const isMobile = useMediaQuery('(max-width:550px)');
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { lateralValues } = useAppSelector((st) => st.lateralState);
  const openLateralDefineLengthReport = () => dispatch(toggleLateralDefineLengthReport());
  const openEnterLengthValues = () => dispatch(toggleEnterLengthValues());

  const { isMaxLength } = lateralValues;

  return (
    <Grid item container xs={12} columnSpacing={3} justifyContent='flex-end' py={'10px'} px={isTablet ? 0 : '10px'}>
      <Grid item xs={isMobile ? 6 : 3}>
        {!isMaxLength ? (
          <ShepherdBlock id='lateral-report-button'>
            <Button iconType='show-report' onClick={openLateralDefineLengthReport} disabled={!lateralValues.resultDU}>
              {t('showReport')}
            </Button>
          </ShepherdBlock>
        ) : (
          <ShepherdBlock id='lateral-report-button'>
            <Button iconType='show-report' onClick={openEnterLengthValues} disabled={!lateralValues.resultMaxLength}>
              {t('showReport')}
            </Button>
          </ShepherdBlock>
        )}
      </Grid>
      {/* {!isMaxLength && (
      <Grid item xs={isMobile ? 6 : 3}>
        <Button iconType="show-report" onClick={openLateralDefineLengthReport} disabled={!lateralValues.resultDU}>
          {t('showReport')}
        </Button>
      </Grid>
    )}
    {isMaxLength && (
      <Grid item xs={isMobile ? 6 : 3}>
       
      </Grid>
    )} */}
    </Grid>
  );
};

export const ResultsBlock: FC = () => {
  const isTablet = useMediaQuery('(max-width:850px)');
  const isMobile = useMediaQuery('(max-width:550px)');
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { lateralValues } = useAppSelector((st) => st.lateralState);

  const onExpandedChange = () => {
    dispatch(setLateralStateValue({ expandedResultsAccordion: !lateralValues.expandedResultsAccordion }));
  };

  return (
    <Box pt='10px' width='100%'>
      {isTablet ? (
        <Accordion
          expanded={lateralValues.expandedResultsAccordion}
          onChange={onExpandedChange}
          header={<IconLabel iconSrc={results_ico}>{t('results')}</IconLabel>}
        >
          <>
            <ResultsContent />
            {!isMobile && <ShowReportButton />}
          </>
        </Accordion>
      ) : (
        <Box sx={{ mt: '-20px' }}>
          <IconLabel iconSrc={results_ico}>{t('results')}</IconLabel>

          <PaperBox>
            <ResultsContent />
          </PaperBox>
          <ShowReportButton />
        </Box>
      )}
    </Box>
  );
};
