import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { t } from 'i18next';
import { PropsWithChildren, useMemo, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { Math_round } from 'shared/lib/calculation/mathRound';
import { setEDCTablesGraph, setEDCTablesActiveData } from 'shared/slices';

interface TableRowHoveredProps extends PropsWithChildren {
  currentStep: number;
  data: { item: string; values: number[] };
}

const TableCellBorder: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <TableCell
      sx={{
        pt: 0,
        pb: 0,
        pr: 1,
        pl: 1,
        width: { xs: 'auto' },
      }}
    >
      {children}
    </TableCell>
  );
};

const TableCellNoBorder: React.FC<PropsWithChildren & { isHeader?: boolean }> = ({ children, isHeader }) => {
  return (
    <TableCell
      sx={{
        ...(isHeader && { background: '#E8F3EC' }),
        pt: 0,
        pb: 0,
        pr: 1,
        pl: 1,
        border: 'none',
        width: { xs: 'auto' },
      }}
    >
      {children}
    </TableCell>
  );
};

const TableRowHovered: React.FC<TableRowHoveredProps> = ({ children, data, currentStep }) => {
  const dispatch = useAppDispatch();
  const { tablesResult, tablesActiveData } = useAppSelector((state) => state.edcState);

  const selectedData = `${currentStep}${data.item}${data.values.toString()}`;

  const graphSteps = useMemo(() => tablesResult.map((item) => item.step), [tablesResult]);

  const generateGraphData = useCallback(
    (item: string, values: number[]) => {
      const graphData = {
        item,
        values: values.map((item, idx) => ({ step: graphSteps[idx], value: item })),
      };

      dispatch(setEDCTablesGraph(graphData));
    },
    [graphSteps]
  );

  const onRowClick = () => {
    generateGraphData(data.item, data.values);
    dispatch(setEDCTablesActiveData(selectedData));
  };

  return (
    <TableRow
      onClick={onRowClick}
      sx={{
        cursor: 'pointer',
        '&:hover': {
          ...(tablesActiveData !== selectedData && {
            background: '#E8F3EC',
          }),
        },
        ...(tablesActiveData === selectedData && {
          backgroundColor: '#428d4d',
          '& > td': { color: '#fff' },
        }),
      }}
    >
      {children}
    </TableRow>
  );
};

export const EDCTablesGrid = () => {
  const { tablesResult } = useAppSelector((state) => state.edcState);

  return (
    <TableContainer
      sx={{
        height: 300,
        '&::-webkit-scrollbar': {
          width: '7px',
          height: '7px',
        },
        '&::-webkit-scrollbar-track': {
          background: 'primary.main',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'primary.main',
          borderRadius: '13px',
        },
      }}
    >
      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            <TableCellNoBorder isHeader>{t('step')}</TableCellNoBorder>
            <TableCellNoBorder isHeader>{t('item')}</TableCellNoBorder>
            {tablesResult.map((item) => (
              <TableCellNoBorder key={item.step} isHeader>
                {item.step.toString()}
              </TableCellNoBorder>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tablesResult.map((item) =>
            Object.entries(item).map(([key, value], idx) =>
              key !== 'step' ? (
                <TableRowHovered
                  key={item.step * idx}
                  currentStep={item.step}
                  data={{
                    item: key,
                    values: value.map((item: number | string) => (typeof item === 'number' ? item : parseFloat(item))),
                  }}
                >
                  {idx !== 4 ? (
                    <>
                      <TableCellNoBorder>{idx === 1 && item.step}</TableCellNoBorder>
                      <TableCellNoBorder>{key}</TableCellNoBorder>
                      {key === 'cu' &&
                        value.map((item: number) => (
                          <TableCellNoBorder key={Math.random()}>{Math_round(item, 3)}</TableCellNoBorder>
                        ))}
                      {key === 'du' &&
                        value.map((item: number) => (
                          <TableCellNoBorder key={Math.random()}>{Math_round(item, 3)}</TableCellNoBorder>
                        ))}
                      {key === 'avg' &&
                        value.map((item: number) => (
                          <TableCellNoBorder key={Math.random()}>{Math_round(item, 3)}</TableCellNoBorder>
                        ))}
                    </>
                  ) : (
                    <>
                      <TableCellBorder />
                      <TableCellBorder>{key}</TableCellBorder>
                      {key === 'min' &&
                        value.map((item: number) => (
                          <TableCellBorder key={Math.random()}>{Math_round(item, 3)}</TableCellBorder>
                        ))}
                    </>
                  )}
                </TableRowHovered>
              ) : null
            )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
