import { GetX } from './GetX';
import { GetY } from './GetY';

export const UpdateGraph = (
  XMAMTEROT: number,
  YMAMTEROT: number,
  fieldpic: { width: number; height: number },
  FieldRainAt: Array<Array<number>>,
  Placement: number,
  tempdrainat: Array<Array<number>>,
  ConnectingLine: { X1: number; X2: number; Y1: number; Y2: number },
) => {
  let i = 0;
  let j = 0;
  let istep = 0;
  let LowV = 0;
  let HighV = 0;
  let itempx = 0;
  let itempy = 0;
  let DeltaX = 0;
  let DeltaY = 0;
  let aPoints;
  let TRIANGULAR_Y_LAYOUT = 1;
  let BILATERAL_TRIANGULAR_LAYOUT = 6;

  //ConnectingLine
  DeltaX = Math.abs(ConnectingLine.X1 - ConnectingLine.X2);
  DeltaY = Math.abs(ConnectingLine.Y1 - ConnectingLine.Y2);
  if (DeltaX >= DeltaY) {
    aPoints = new Array(Math.round((DeltaX * XMAMTEROT) / fieldpic.width + 0.5));
    istep = Math.round(fieldpic.width / XMAMTEROT);
    itempx = Math.round(fieldpic.width / XMAMTEROT);
    itempy = Math.round(fieldpic.height / YMAMTEROT);
    if (ConnectingLine.X1 <= ConnectingLine.X2) {
      LowV = ConnectingLine.X1;
      HighV = ConnectingLine.X2 - 15;
    } else {
      LowV = ConnectingLine.X2;
      HighV = ConnectingLine.X1;
    }
    for (i = LowV; i <= HighV; i += istep) {
      j = GetY(
        i,
        ConnectingLine.X1,
        ConnectingLine.X2,
        ConnectingLine.Y1,
        ConnectingLine.Y2,
      );

      if (j < 0) {
        j = 0;
      }
      if (j > fieldpic.height) {
        j = Math.round(fieldpic.height);
      }
      aPoints[Math.round((i - LowV) / istep)] =
        FieldRainAt[Math.round(i / itempx)][Math.round(j / itempy)];

      if (aPoints[Math.round((i - LowV) / istep)] == -1) {
        if (
          Placement == TRIANGULAR_Y_LAYOUT ||
          Placement == BILATERAL_TRIANGULAR_LAYOUT
        ) {
          if (tempdrainat[Math.round(i / itempx)][Math.round(j / itempy)] == -1) {
            aPoints[Math.round((i - LowV) / istep)] = 0;
          } else {
            aPoints[Math.round((i - LowV) / istep)] =
              tempdrainat[Math.round(i / itempx)][Math.round(j / itempy)];
          }
        } else {
          aPoints[Math.round((i - LowV) / istep)] = 0;
        }
      }
    }
    if (i < HighV) {
      i = HighV;
      //GoTo OneMore
    }

    return aPoints;
  } else {
    aPoints = new Array(
      (Math.abs(ConnectingLine.Y2 - ConnectingLine.Y1) * YMAMTEROT) / fieldpic.height +
        0.5,
    );
    //ReDim sPoints(Abs(Ball2.Top - Ball1.Top) * YMAMTEROT / fieldpic.height + .5)
    istep = Math.round(fieldpic.height / YMAMTEROT);
    itempx = Math.round(fieldpic.width / XMAMTEROT);
    itempy = Math.round(fieldpic.height / YMAMTEROT);
    //check for the right order for the loop
    if (ConnectingLine.Y1 <= ConnectingLine.Y2) {
      LowV = ConnectingLine.Y1;
      HighV = ConnectingLine.Y2;
    } else {
      LowV = ConnectingLine.Y2;
      HighV = ConnectingLine.Y1;
    }
    for (i = LowV; i <= HighV; i += istep) {
      j = GetX(
        i,
        ConnectingLine.X1,
        ConnectingLine.X2,
        ConnectingLine.Y1,
        ConnectingLine.Y2,
      );

      if (j < 0) j = 0;
      if (j > fieldpic.width) {
        j = Math.round(fieldpic.width);
      }
      aPoints[Math.round((i - LowV) / istep)] =
        FieldRainAt[Math.round(j / itempx)][Math.round(i / itempy)];
      if (aPoints[Math.round((i - LowV) / istep)] == -1) {
        if (
          Placement == TRIANGULAR_Y_LAYOUT ||
          Placement == BILATERAL_TRIANGULAR_LAYOUT
        ) {
          //xxx switced i,j
          if (tempdrainat[Math.round(j / itempx)][Math.round(i / itempy)] == -1) {
            aPoints[Math.round((i - LowV) / istep)] = 0;
          } else {
            aPoints[Math.round((i - LowV) / istep)] =
              tempdrainat[Math.round(j / itempx)][Math.round(i / itempy)];
            //xxx switced i,j
          }
        } else {
          aPoints[Math.round((i - LowV) / istep)] = 0;
        }
      }
    }
    return aPoints;
  }
};
