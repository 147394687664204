import { FC } from 'react';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

interface Props {
  amount: number;
}

export const AvailableInfo: FC<Props> = ({ amount }) => {
  const { t } = useTranslation();

  return (
    <Typography
      variant="h3"
      align="center"
      noWrap
      sx={{
        color: 'secondary.main',
        fontWeight: 600,
        fontSize: 18,
        lineHeight: '16px',
      }}
    >
      {t('available')}: {amount}
    </Typography>
  );
};
