import { SelectChangeEvent } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { customAxios } from 'shared/api';
import { useAppSelector } from 'shared/hooks';
import { CatalogItem, CatalogsResponse, GroupsResponse } from 'shared/models';

const ALL = 'All';

export const useMainlines = ({
  handleMainlineDataChange,
  region,
}: {
  region: string;
  handleMainlineDataChange: (
    value: Partial<{
      masterGroup: string;
      group: string;
      mainline: string;
    }>
  ) => void;
}) => {
  const { mainlineGroups, mainlines: defaultMainlines } = useAppSelector((state) => state.mainlines);
  const { projectData } = useAppSelector((state) => state.projectData);

  const [groupTypes, setGroupTypes] = useState(mainlineGroups);
  const [mainlines, setMainlines] = useState<CatalogItem[]>(defaultMainlines);

  const [groupsLoading, setGroupsLoading] = useState(false);
  const [mainlinesLoading, setMainlinesLoading] = useState(false);

  const [masterGroupId, setMasterGroupId] = useState(projectData.mainlineMasterGroup);
  const [groupTypeId, setGroupTypeId] = useState(projectData.mainlineGroup);
  const [classType, setClassType] = useState<string | number>(ALL);
  const [mainline, setMainline] = useState<CatalogItem>(
    () => mainlines.find((item) => item.catlog === projectData.mainlineCatalog) as CatalogItem
  );

  useEffect(() => {
    handleMainlineDataChange({ mainline: mainline?.catlog });
  }, [mainline]);

  useEffect(() => {
    handleMainlineDataChange({ group: groupTypeId });
  }, [groupTypeId]);

  useEffect(() => {
    handleMainlineDataChange({ masterGroup: masterGroupId });
  }, [masterGroupId]);

  useEffect(() => {
    updateMainlines(groupTypeId, region);
  }, [region]);

  const updateMainlines = async (group: string, region: string) => {
    setMainlinesLoading(true);
    try {
      const mainlinesResponse = await customAxios.get<CatalogsResponse>(
        'be/HtCatalog/GetCatalogPerDiaClassFlowSpacing',
        {
          params: {
            group,
            region,
          },
        }
      );

      setMainlines(mainlinesResponse.data.data);
      setMainline(mainlinesResponse.data.data[0] ?? {});
    } catch (e) {
      setMainlines([]);
      setMainline({} as CatalogItem);
      console.error(e);
    }
    setMainlinesLoading(false);
  };

  const onMasterGroupChange = async (e: SelectChangeEvent<unknown>) => {
    setMasterGroupId(e.target.value as string);
    resetFilters();

    setGroupsLoading(true);
    const groupTypesResponse = await customAxios.get<GroupsResponse>('be/HtCatalog/GetGroupTypes', {
      params: { masterGroup: e.target.value },
    });

    setGroupTypes(groupTypesResponse.data.data.rootResults);
    setGroupTypeId(groupTypesResponse.data.data.rootResults[0]?.groups);
    setGroupsLoading(false);

    await updateMainlines(groupTypesResponse.data.data.rootResults[0]?.groups, region);
  };
  const onGroupTypeChange = async (e: SelectChangeEvent<unknown>) => {
    setGroupTypeId(e.target.value as string);
    resetFilters();

    await updateMainlines(e.target.value as string, region);
  };
  const onMainlineChange = (e: any, item: CatalogItem) => {
    setMainline(item);
  };

  const onClassTypeChange = (e: SelectChangeEvent<unknown>) => {
    setClassType(e.target.value as string);
  };

  const classTypeFilter = (item: CatalogItem) => {
    // if (projectData.Region === 'USA' && item.AltClass !== null) {
    //   return mainlineClassType ? item.AltClass?.toString().trim() === mainlineClassType : true;
    // }

    return classType !== 'All' ? item.class?.toString().trim() === classType : true;
  };

  const resetFilters = () => {
    setClassType(ALL);
  };

  const mainlinesFiltered = useMemo(() => {
    const result = mainlines.filter((item) => classTypeFilter(item));

    if (!result.find((item) => item.catlog === mainline?.catlog)) {
      setMainline(result[0]);
    }

    return result;
  }, [classType, mainlines]);

  const classTypes = useMemo(() => {
    setClassType(ALL);

    return [ALL, ...new Set(mainlines.map((item) => item.class))];
  }, [mainlines]);

  return {
    masterGroupId,
    onMasterGroupChange,
    onGroupTypeChange,
    classType,
    onClassTypeChange,
    classTypes,
    mainline,
    onMainlineChange,
    mainlinesFiltered,
    groupTypes,
    groupsLoading,
    mainlinesLoading,
    groupTypeId,
  };
};
