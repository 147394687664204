import { Box, Grid, MenuItem, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { submain_ico } from 'shared/assets';
import { useAppSelector, useSubmainFilters } from 'shared/hooks';
import { AvailableInfo, IconLabel, PaperBox, ProductSelect, Select } from 'shared/ui';

export const SubmainBlock = () => {
  const { submainGroups, submainsLoading, submainGroupsLoading } = useAppSelector((state) => state.submains);
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width: 550px)');

  const {
    masterGroups,
    masterGroupId,
    onMasterGroupChange,
    submainSubtype,
    onGroupTypeChange,
    classType,
    classTypes,
    onClassTypeChange,
    submainProduct,
    submainsFiltered,
    onSubmainChange,
  } = useSubmainFilters();

  return (
    <Box mb={1}>
      <Box sx={{ mb: 1 }}>
        <IconLabel iconSrc={submain_ico}>{t('submain')}</IconLabel>
      </Box>
      <PaperBox>
        <Grid container columnSpacing={1}>
          <Grid item xs={4}>
            <Select label={`${t('type')}`} value={masterGroupId} onChange={onMasterGroupChange}>
              {masterGroups.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.mastergroupname}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={4}>
            <Select
              label={`${t('subtype')}`}
              value={submainSubtype}
              onChange={onGroupTypeChange}
              loading={submainGroupsLoading}
              disabled={submainGroupsLoading}
            >
              {submainGroups.map((item) => (
                <MenuItem key={item.groups} value={item.groups}>
                  {item.grouptypename}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={4}>
            <Select
              label={`${t('class')}`}
              value={classType}
              onChange={onClassTypeChange}
              disabled={submainGroupsLoading || submainsLoading}
            >
              {classTypes.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
        <Grid container columnSpacing={1} mt={1}>
          <Grid item xs={isMobile ? 12 : 8}>
            <ProductSelect
              label={`${t('selectedProduct')}`}
              value={submainProduct}
              onChange={onSubmainChange}
              options={submainsFiltered}
              loading={submainsLoading}
              disabled={submainsLoading || submainGroupsLoading}
              error={!submainProduct}
            />
          </Grid>
          <Grid item xs={isMobile ? 12 : 4} mt={1}>
            <AvailableInfo amount={submainsFiltered.length} />
          </Grid>
        </Grid>
      </PaperBox>
    </Box>
  );
};
