export const rotateLeft = (array: any[][] = []) => {
  const result: any[][] = [];
  array.forEach((a, i, aa) => {
    a.forEach((b, j) => {
      result[j] = result[j] || [];
      result[j][aa.length - i - 1] = b;
    });
  });
  return result;
};
