import { CircularProgress } from '@mui/material';
import { FC } from 'react';

interface Props {
  centeredAbsolute?: boolean;
}

export const Loader: FC<Props> = ({ centeredAbsolute }) => {
  return (
    <CircularProgress
      sx={{
        ...(centeredAbsolute && { position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }),
      }}
    />
  );
};
