import { Box, Grid, MenuItem, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { submain_ico } from 'shared/assets';
import { useAppSelector, useMainlineFilters } from 'shared/hooks';
import { AvailableInfo, IconLabel, PaperBox, ProductSelect, Select } from 'shared/ui';

export const MainlineBlock = () => {
  const { mainlineGroups, mainlinesLoading, mainlineGroupsLoading } = useAppSelector((state) => state.mainlines);
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width: 550px)');

  const {
    masterGroups,
    masterGroupId,
    onMasterGroupChange,
    mainlineSubtype,
    onGroupTypeChange,
    classType,
    classTypes,
    onClassTypeChange,
    mainlineProduct,
    mainlinesFiltered,
    onMainlineChange,
  } = useMainlineFilters();

  return (
    <Box mb={1}>
      <Box sx={{ mb: 1 }}>
        <IconLabel iconSrc={submain_ico}>{t('mainline')}</IconLabel>
      </Box>
      <PaperBox>
        <Grid container columnSpacing={1}>
          <Grid item xs={4}>
            <Select label={`${t('type')}`} value={masterGroupId} onChange={onMasterGroupChange}>
              {masterGroups.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.mastergroupname}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={4}>
            <Select
              label={`${t('subtype')}`}
              value={mainlineSubtype}
              onChange={onGroupTypeChange}
              loading={mainlineGroupsLoading}
              disabled={mainlineGroupsLoading}
            >
              {mainlineGroups.map((item) => (
                <MenuItem key={item.groups} value={item.groups}>
                  {item.grouptypename}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={4}>
            <Select
              label={`${t('class')}`}
              value={classType}
              onChange={onClassTypeChange}
              disabled={mainlineGroupsLoading || mainlinesLoading}
            >
              {classTypes.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
        <Grid container columnSpacing={1} mt={1}>
          <Grid item xs={isMobile ? 12 : 8}>
            <ProductSelect
              label={`${t('selectedProduct')}`}
              value={mainlineProduct}
              onChange={onMainlineChange}
              options={mainlinesFiltered}
              loading={mainlinesLoading}
              disabled={mainlinesLoading || mainlineGroupsLoading}
              error={!mainlineProduct}
            />
          </Grid>
          <Grid item xs={isMobile ? 12 : 4} mt={1}>
            <AvailableInfo amount={mainlinesFiltered.length} />
          </Grid>
        </Grid>
      </PaperBox>
    </Box>
  );
};
