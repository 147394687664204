import { FC } from 'react';
import { Box, BoxProps } from '@mui/material';

export const PaperBox: FC<BoxProps> = ({ children, sx, ...props }) => {
  return (
    <Box
      sx={{
        mt: '10px',
        p: '7px 10px 12px 10px',
        boxShadow: '0px 2px 10px 2px rgba(0, 0, 0, 0.2)',
        borderRadius: '10px',
        bgcolor: '#ffffff66',
        position: 'relative',
        ...sx,
      }}
      {...props}
    >
      {children}
    </Box>
  );
};
