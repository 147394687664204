import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'shared/hooks';

export const useSubmainReportData = () => {
  const { t } = useTranslation();
  const { units } = useAppSelector((st) => st.units);

  const { username } = useAppSelector((st) => st.auth);
  const { projectData } = useAppSelector((st) => st.projectData);
  const { submainValues } = useAppSelector((st) => st.submainState);

  const infoTable = {
    data: [
      [t('preparedFor'), t('by'), t('_date')],
      [
        `${projectData.tradingName ?? ''} ${projectData.growerName ?? ''}`,
        username,
        new Date(Date.now()).toLocaleString(),
      ],
    ],
  };

  const firstTable = useMemo(() => {
    return {
      data: [
        [
          t('catalog'),
          `${t('length')} (${units.length})`,
          `${t('dia')} (${units.pipeDiameter})`,
          `${t('velocity')} (${units.velocity})`,
        ],
        [
          projectData.manifoldPipe1,
          projectData.manifoldPipe1Length,
          projectData.manifoldPipe1Dia,
          submainValues.velocity1,
        ],
        [
          projectData.manifoldPipe1,
          projectData.manifoldPipe2Length,
          projectData.manifoldPipe2Dia,
          submainValues.velocity2,
        ],
        [
          projectData.manifoldPipe1,
          projectData.manifoldPipe3Length,
          projectData.manifoldPipe3Dia,
          submainValues.velocity3,
        ],
      ],
    };
  }, [projectData, submainValues]);

  const secondTable = useMemo(() => {
    return {
      data: [
        [`${t('pressureAtValve')} (${units.length})`, `${t('disTo1stLateral')} (${units.length})`],
        [submainValues.valvePressure, submainValues.firstLateral],
      ],
    };
  }, [units, submainValues]);

  const thirdTable = useMemo(() => {
    return {
      data: [
        [
          `${t('length')} (${units.length})`,
          `${t('flow')} (${units.totalFlow})`,
          `${t('velocity')} (${units.velocity})`,
          `${t('headloss')} (${units.length})`,
          `${t('Pressure')} (${units.pressure})`,
        ],
        ...submainValues.resultReportArray.map((item) => [
          item.len,
          item.flow,
          isNaN(item.vel) ? '' : item.vel,
          isNaN(item.headloss) ? '' : item.headloss,
          item.pressure,
        ]),
      ],
    };
  }, [units, submainValues]);

  const pdfTableData = [firstTable, secondTable, thirdTable];

  return {
    infoTable,
    pdfTableData,
  };
};
