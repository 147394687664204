import { EDGE } from '../models/EDGE';
import { v_at } from './v_at';
export function CalcEDGE(CalcED: EDGE) {
  let X = 0;
  let y = 0;
  let temp = 0;
  let i = 0;
  let j = 0;
  let FieldRainAt = CalcED.Common.FieldRainAt;
  let tempdrainat = CalcED.tempdrainat;
  let resMinMax;
  for (y = 0; y <= CalcED.Common.Yint; y++) {
    for (X = 0; X <= CalcED.Common.Xint; X++) {
      FieldRainAt[X][y] = 0.0;
      if (X == 0 && y == 33) {
      }
      for (i = -CalcED.Common.Kx; i <= CalcED.EgdeT.KEdgeX; i++) {
        for (j = -CalcED.Common.Ky; j <= CalcED.EgdeT.KEdgeY; j++) {
          if (i != 0 && j != 0) {
            const xxx = Math.abs(i) * CalcED.Common.XMAMTEROT - (i / Math.abs(i)) * X;
            const xx = Math.round(xxx);
            const yyy = Math.abs(j) * CalcED.Common.YMAMTEROT - (j / Math.abs(j)) * y;
            const yy = Math.round(yyy);
            var res = v_at(
              xx,
              yy,
              CalcED.Common.fieldintervalX,
              CalcED.Common.fieldintervalY,
              CalcED.Common.dRange,
              CalcED.Common.cbEDCEmitter,
              CalcED.Common.TUBENUM
            );
            FieldRainAt[X][y] = FieldRainAt[X][y] + res;
          } else if (i == 0 && j != 0) {
            // if(j == 2 && i == 0 && X == 0 && y == 33){
            //   debugger;
            // }
            const xx = X;
            const yyy = Math.abs(j) * CalcED.Common.YMAMTEROT - (j / Math.abs(j)) * y;
            const yy = Math.round(yyy);
            const res = v_at(
              xx,
              yy,
              CalcED.Common.fieldintervalX,
              CalcED.Common.fieldintervalY,
              CalcED.Common.dRange,
              CalcED.Common.cbEDCEmitter,
              CalcED.Common.TUBENUM
            );
            FieldRainAt[X][y] = FieldRainAt[X][y] + res;
          } else if (j == 0 && i != 0) {
            const xxx = Math.abs(i) * CalcED.Common.XMAMTEROT - (i / Math.abs(i)) * X;
            const xx = Math.round(xxx);
            const yy = y;
            var res = v_at(
              xx,
              yy,
              CalcED.Common.fieldintervalX,
              CalcED.Common.fieldintervalY,
              CalcED.Common.dRange,
              CalcED.Common.cbEDCEmitter,
              CalcED.Common.TUBENUM
            );
            FieldRainAt[X][y] = FieldRainAt[X][y] + res;

            //effect of emitters at x=0
          } else if (j == 0 && i == 0) {
            const xx = X;
            const yy = y;
            var res = v_at(
              xx,
              yy,
              CalcED.Common.fieldintervalX,
              CalcED.Common.fieldintervalY,
              CalcED.Common.dRange,
              CalcED.Common.cbEDCEmitter,
              CalcED.Common.TUBENUM
            );
            FieldRainAt[X][y] = FieldRainAt[X][y] + res;
          }
        }
        temp = FieldRainAt[X][y];
        resMinMax = SetMinMax(
          X,
          y,
          temp,
          CalcED.Common.min,
          CalcED.Common.max,
          CalcED.EgdeT.XM,
          CalcED.EgdeT.YM,
          CalcED.Common.MinChange
        );
      }
    }
  }

  let Edgeresult = {
    FieldRainAt: FieldRainAt,
    tempdrainat: tempdrainat,
    // @ts-ignore
    min: resMinMax.min,
    // @ts-ignore
    max: resMinMax.max,
    // @ts-ignore
    MinChange: resMinMax.MinChange,
  };
  return Edgeresult;
}

export function SetMinMax(
  X: number,
  y: number,
  temp: number,
  min: number,
  max: number,
  XM: number,
  YM: number,
  MinChange: boolean
) {
  if (X <= XM && y <= YM) {
    if (temp > max) {
      max = temp;
    } else if (temp < min) {
      min = temp;
      MinChange = true;
    }
  }
  let Setres = {
    max: max,
    min: min,
    MinChange: MinChange,
  };
  return Setres;
}
