import { Box, MenuItem, Table, TableBody, TableCell as Cell, TableContainer, useMediaQuery } from '@mui/material';
import { ChangeEvent, FC } from 'react';
import { tech_data_ico } from 'shared/assets';
import { Accordion, IconLabel, TableRow, TechDataInput, TechDataSelect } from 'shared/ui';
import { MANIFOLD_HEADLOSS_CALCULATION, SLOPE_DIRECTIONS } from 'shared/constants';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { setProjectValue } from 'shared/slices';
import { useTranslation } from 'react-i18next';
import { formattedInputValue } from 'shared/lib';

const AdvancedOptionsContent = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { projectData } = useAppSelector((st) => st.projectData);

  const changeMainlineHeadlossCalc = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setProjectValue({ mainlineHeadlossCalc: e.target.value }));
  };
  const changeMainlineHWCoef = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setProjectValue({ mainlineHWCoef: formattedInputValue(e) }));
  };
  const changeMainlineSlope = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setProjectValue({ mainlineSlope: formattedInputValue(e) }));
  };
  const changeMainlineSlopeDir = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setProjectValue({ mainlineSlopeDir: e.target.value }));
  };
  return (
    <TableContainer sx={{ mt: '15px' }}>
      <Table>
        <TableBody>
          <TableRow withBg>
            <Cell sx={{ width: '100%' }}>{`${t('equation')}`}</Cell>
            <Cell align='right'>
              <TechDataSelect value={projectData.mainlineHeadlossCalc} onChange={changeMainlineHeadlossCalc}>
                {MANIFOLD_HEADLOSS_CALCULATION.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TechDataSelect>
            </Cell>
          </TableRow>
          <TableRow>
            <Cell>{`${t('hwCoef')}`}</Cell>
            <Cell>
              <TechDataInput
                value={projectData.mainlineHWCoef}
                onChange={changeMainlineHWCoef}
                disabled={projectData.mainlineHeadlossCalc === 'D.W'}
              />
            </Cell>
          </TableRow>
          <TableRow>
            <Cell sx={{ width: '100%' }}>{`${t('slope')}`} (%)</Cell>
            <Cell>
              <TechDataInput value={projectData.mainlineSlope} onChange={changeMainlineSlope} />
            </Cell>
          </TableRow>
          <TableRow withBg>
            <Cell>{`${t('direction')}`}</Cell>
            <Cell align='right'>
              <TechDataSelect value={projectData.mainlineSlopeDir} onChange={changeMainlineSlopeDir}>
                {SLOPE_DIRECTIONS.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TechDataSelect>
            </Cell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const AdvancedOptionsBlock: FC = () => {
  const isTablet = useMediaQuery('(max-width:850px)');
  const { t } = useTranslation();

  return (
    <Box
      pt={isTablet ? '10px' : 0}
      width={isTablet ? '100%' : '40%'}
      px={isTablet ? 0 : '15px'}
      pl={{ xs: 0, xl: '15px' }}
    >
      {isTablet ? (
        <Accordion header={<IconLabel iconSrc={tech_data_ico}>{t('advancedOptions')}</IconLabel>}>
          <AdvancedOptionsContent />
        </Accordion>
      ) : (
        <>
          <IconLabel iconSrc={tech_data_ico}>{t('advancedOptions')}</IconLabel>

          <AdvancedOptionsContent />
        </>
      )}
    </Box>
  );
};
