import { Math_round } from '../../mathRound';
import { NomAvg } from '../models/NomAvg';
export function GetNomAvg(NomAvg: NomAvg) {
  let functionReturnValue = 0;
  let FactorGI = 1;
  let dr = NomAvg.cbEDCEmitter.SelectedItem;
  if (NomAvg.Placement == NomAvg.SINGLE_LAYOUT) {
    functionReturnValue = Math_round(
      (Math_round(dr.FlowRate, 2) / (NomAvg.dRange * NomAvg.dRange * 3.14159)) * (NomAvg.TimeNow / 60),
      1
    );
  } else {
    functionReturnValue =
      ((Math_round(dr.FlowRate, 2) * FactorGI) / (NomAvg.dXPOSITION * NomAvg.dYPOSITION)) * (NomAvg.TimeNow / 60);
  }
  return functionReturnValue;
}
