import { Button, ButtonProps } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { reset } from 'shared/assets';

export const ResetButton: FC<ButtonProps> = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <Button
      onClick={onClick}
      variant="outlined"
      sx={{
        display: 'flex',
        fontSize: '18px',
        borderRadius: '20px',
        height: '26px',
        letterSpacing: '0.01em',
        fontWeight: 400,
        padding: '6px 13px',
      }}
      startIcon={<img src={reset} />}
    >
      {t('resetAll')}
    </Button>
  );
};
