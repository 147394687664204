import { useAppSelector } from 'shared/hooks';
import { Loader } from 'shared/ui';
import { Lateral } from 'widgets';

export const LateralPage = () => {
  //TODO: ADD LOADED ONCE PARAMS!
  const projectsLoading = useAppSelector((state) => state.projects.loading);
  const masterGroupsLoading = useAppSelector((state) => state.masterGroups.loading);
  const unitsLoading = useAppSelector((state) => state.units.loading);
  const projectDataLoading = useAppSelector((state) => state.projectData.loading);
  const emittersLoadedOnce = useAppSelector((state) => state.emitters.emittersLoadedOnce);
  const lateralsLoadedOnce = useAppSelector((state) => state.laterals.lateralsLoadedOnce);

  const loading =
    projectsLoading ||
    masterGroupsLoading ||
    unitsLoading ||
    projectDataLoading ||
    !lateralsLoadedOnce ||
    !emittersLoadedOnce;

  return !loading ? <Lateral /> : <Loader centeredAbsolute />;
};
