import { Average } from '../models/Average';
export function GetAverage(Average: Average) {
  let functionReturnValue = 0;
  let X = 0;
  let y = 0;
  let SUM = 0;
  let TotalPoints = 0;
  let ActuallCounter = 0;

  for (y = 0; y <= Average.FieldEdgeY; y++) {
    for (X = 0; X <= Average.FieldEdgeX; X++) {
      if (Average?.FieldRainAt?.[X]?.[y] >= 0) {
        SUM = SUM + Average.FieldRainAt[X][y];
        ActuallCounter = ActuallCounter + 1;
      }
    }
  }

  TotalPoints = (Average.FieldEdgeX + 1) * (Average.FieldEdgeY + 1);
  if (Average._Shadows) {
    ActuallCounter = ActuallCounter + Average.PointsEffectedas;
  }
  if (ActuallCounter > TotalPoints) {
    ActuallCounter = parseInt(String(TotalPoints));
  }
  if (Average._Shadows) {
    SUM = SUM + Average.LostQuantity;
  }
  if (
    Average.Placement != Average.TRIANGULAR_Y_LAYOUT &&
    Average.Placement != Average.BILATERAL_TRIANGULAR_LAYOUT &&
    Average.Placement != Average.LATERAL_LAYOUT
  ) {
    functionReturnValue = SUM / TotalPoints;
  } else if (ActuallCounter > 0) {
    functionReturnValue = SUM / ActuallCounter;
  } else {
    functionReturnValue = 0;
  }
  if (ActuallCounter > 0) {
    Average.Average1 = SUM / ActuallCounter;
  }
  let result = {
    ActuallCounter: ActuallCounter,
    functionReturnValue: functionReturnValue,
  };
  return result;
}
