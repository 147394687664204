import { FC } from 'react';
import { styled, keyframes } from '@mui/system';
import Button, { ButtonProps } from '@mui/material/Button';

const snake = keyframes`
  0% {
    opacity: 1
  }  
  to {
    stroke-dashoffset: 370%;
    opacity: 0
  }
  `;

const AnimatedButton = styled(Button)(() => ({
  '--thickness': '0.3rem',
  '--roundness': '1.2rem',
  '--color': 'black',
  '--opacity': 0.6,

  WebkitTapHighlightColor: 'transparent',
  WebkitAppearance: 'button',
  cursor: 'pointer',
  display: 'block',
  fontWeight: 500,
  lineHeight: 1.5,
  margin: 0,
  position: 'relative',
  WebkitBackdropFilter: 'blur(100px)',
  backdropFilter: 'blur(100px)',
  textTransform: 'capitalize',
  borderRadius: '8px',
  minWidth: '120px',
  height: '48px',
  fontSize: 18,
  color: 'black',
  borderColor: 'black',

  '&:hover': {
    borderColor: 'black',
  },

  '&:active': {
    '--opacity': 0,
    background: 'hsla(0, 0%, 100%, 0.1)',
  },

  '&:disabled': {
    cursor: 'default',
  },

  '&:focusVisible': {
    outline: 'auto',
  },

  '&[hidden]': {
    display: 'none',
  },

  svg: {
    borderRadius: '2',
    display: 'block',
    filter: 'url(#glow)',
    height: '100%',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
  },

  rect: {
    fill: 'none',
    stroke: 'blue',
    strokeWidth: 2,
    rx: 7,
    strokeLinejoin: 'round',
    strokeDasharray: '165%',
    strokeDashoffset: 80,
    animation: `${snake} 1.5s linear 1`,
    height: '100%',
    opacity: 0,
    transition: 'opacity 0.9s',
    width: '100%',
  },

  // '& rect': {
  //   animationPlayState: 'infinite',
  // },
  // '&:hover rect': {
  //   animationPlayState: 'running',
  //   opacity: 'var(--opacity)',
  // },
}));

interface Props extends ButtonProps {
  animated?: boolean;
}

export const RegisterButton: FC<Props> = ({ children, onClick, animated }) => {
  return (
    <AnimatedButton variant="outlined" onClick={onClick}>
      <>{children}</>

      {animated && (
        <svg>
          <defs>
            <filter id="glow">
              <feGaussianBlur result="coloredBlur" stdDeviation="5" />
              <feMerge>
                <feMergeNode in="coloredBlur" />
                <feMergeNode in="coloredBlur" />
                <feMergeNode in="coloredBlur" />
                <feMergeNode in="SourceGraphic" />
              </feMerge>
            </filter>
          </defs>
          <rect />
        </svg>
      )}
    </AnimatedButton>
  );
};
