import { Math_round } from 'shared/lib/calculation/mathRound';
import { ConvertUnit } from 'shared/lib/calculation/unitConverter';
import { Unit } from 'shared/models';

type Values = {
  finalConcentration: number;
  tankSolutionConc: number;
  pumpRate: number;
  outletRate: number;
  calculationType: string;
};

export const calculateInjectionRate = (values: Values, units: Unit) => {
  const pumpRate = ConvertUnit(values.pumpRate, units?.totalFlow, 'lps', '');
  const finalConcentration = values.finalConcentration;

  if (values.calculationType === 'outletRate') {
    const tankConcentration = values.tankSolutionConc;
    const outletRate = (pumpRate * finalConcentration) / tankConcentration / 10000.0;
    const res = Math_round(ConvertUnit(outletRate, 'lps', units?.flow, ''), 3);
    return res;
  } else {
    const outletRate = ConvertUnit(values.outletRate, units?.flow, 'lps', '');
    const tankConcentration = (pumpRate * finalConcentration) / outletRate / 10000.0;
    const res = Math_round(tankConcentration, 3);
    return res;
  }
};
