import { Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { rivulisLogo } from 'shared/assets';

export const LoginHeader = () => {
  const { t } = useTranslation();

  return (
    <Stack direction={'row'} alignItems="center" justifyContent="space-between" flexWrap={'wrap'}>
      <Box width="171px">
        <Box
          component="img"
          alt="logotype"
          src={rivulisLogo}
          sx={{ width: '100%', display: 'block', height: '100%' }}
        />
      </Box>

      <Box>
        <Typography component="h1" sx={{ fontWeight: 600, fontSize: 32, color: 'rgba(88, 89, 91, 1)' }}>
          {t('Hydraulic Tool')}
        </Typography>
      </Box>
    </Stack>
  );
};
