import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FC } from 'react';
import { useAppSelector } from 'shared/hooks';
import { Row } from 'shared/ui';
import { mathRound } from 'shared/lib';

export const Gap: FC = () => {
  const { palette } = useTheme();
  const { gap } = useAppSelector((st) => st.edcState.calcResult);
  const isMobile = useMediaQuery('(max-width:450px)');

  return (
    <Stack
      height={{ xs: 'auto', sm: '180px' }}
      sx={{ borderTop: `1px solid ${palette.info.light}` }}
      alignItems="center"
    >
      <Stack
        sx={{ pt: 2, maxHeight: '100%', width: isMobile ? '80%' : '300px' }}
        flexWrap={{ xs: 'nowrap', sm: 'wrap' }}
      >
        {gap?.map((item) => (
          <Row key={item.gap} mb={1}>
            <Box component="div" mx={2} sx={{ width: 20, height: 20, backgroundColor: item.color }} />

            <Typography variant="body2">
              {item.gap.includes('+')
                ? `${parseInt(item.gap.split('+')[0])}+ (${mathRound(item.percent)}%)`
                : `${item.gap
                    .split(' - ')
                    .map((item) => mathRound(+item))
                    .join(' - ')} (${mathRound(item.percent)}%)`}
            </Typography>
          </Row>
        ))}
      </Stack>
    </Stack>
  );
};
