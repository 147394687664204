import { FC } from 'react';
import { BoxProps, Link, Typography } from '@mui/material';

const underlinedAnimation = {
  '.underlined, .underlined *, .underlined:after, .underlined:before, .underlined :after, .underlined :before': {
    border: '0 solid',
    boxSizing: 'border-box',
  },
  '.underlined': {
    WebkitTapHighlightColor: 'transparent',
    WebkitAppearance: 'button',
    backgroundColor: '#000',
    backgroundImage: 'none',
    color: 'blue',
    cursor: 'pointer',
    fontSize: '100%',
    lineHeight: 1.5,
    margin: 0,
    webkitMaskImage: '-webkit-radial-gradient(#000, blue)',
    padding: 0,
    background: 'none',
    boxSizing: 'border-box',
    WebkitClipPath: 'polygon(0 0,100% 0, 100% calc(100% + var(--thickness)),  0 calc(100% + var(--thickness)) )',
    clipPath: 'polygon( 0 0, 100% 0, 100% calc(100% + var(--thickness)), 0 calc(100% + var(--thickness)))',
    display: 'block',
    WebkitMaskImage: 'none',
    position: 'relative',
    '--thickness': '2px',
  },

  '.underlined:before': {
    background: 'blue',
    bottom: 'calc(var(--thickness) * -1)',
    content: '""',
    display: 'block',
    height: 'var(--thickness)',
    left: 0,
    pointerEvents: 'none',
    position: 'absolute',
    width: '100%',
    opacity: 0,
  },
  // '.underlined:hover:before ': {
  '.underlined:before ': {
    // WebkitAnimation: 'slide 0.5s ease infinite',
    // animation: 'slide 0.5s ease infinite',
    WebkitAnimation: 'slide 1.5s ease',
    animation: 'slide 1.5s ease',
  },
  '@-webkit-keyframes slide ': {
    '0%': {
      transform: 'translateX(0)',
      opacity: 1,
    },
    '50%': {
      transform: 'translateX(100%)',
    },
    '51%': {
      transform: ' translateX(-100%)',
    },
    '80%': {
      opacity: 1,
    },
    to: {
      transform: 'translateX(0%)',
    },
  },
  '@keyframes slide ': {
    '0%': {
      transform: 'translateX(0)',
      opacity: 1,
    },
    '50%': {
      transform: 'translateX(100%)',
    },
    '51%': {
      transform: ' translateX(-100%)',
    },
    '80%': {
      opacity: 1,
    },
    to: {
      transform: 'translateX(0%)',
    },
  },
};

interface Props extends BoxProps {
  animated?: boolean;
}

export const ForgotPasswordLink: FC<Props> = ({ children, onClick, animated }) => {
  return (
    <Typography onClick={onClick} variant="body2" sx={{ ...(animated && underlinedAnimation) }}>
      <Link color="primary" className="underlined" sx={{ cursor: 'pointer', color: 'blue', textDecoration: 'none' }}>
        {children}
      </Link>
    </Typography>
  );
};
