export let Color = [
  '#D2D228',
  '#C8FA14',
  '#AAFA32',
  '#82E61E',
  '#57fa7d',
  '#62FF62',
  '#00F000',
  '#00C800',
  '#009600',
  '#006400',
];
