import { Box } from '@mui/system';
import { RedirectedLoginForm } from 'widgets';

export const RedirectedLoginPage = () => {
  return (
    <Box>
      <RedirectedLoginForm />
    </Box>
  );
};
