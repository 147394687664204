import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'shared/hooks';

export const useMainlineReportData = () => {
  const { t } = useTranslation();
  const { units } = useAppSelector((st) => st.units);

  const { username } = useAppSelector((st) => st.auth);
  const { projectData } = useAppSelector((st) => st.projectData);
  const { mainlineValues } = useAppSelector((st) => st.mainlineState);

  const infoTable = {
    data: [
      [t('preparedFor'), t('by'), t('_date')],
      [
        `${projectData.tradingName ?? ''} ${projectData.growerName ?? ''}`,
        username,
        new Date(Date.now()).toLocaleString(),
      ],
    ],
  };

  const firstTable = useMemo(() => {
    return {
      data: [
        [
          t('catalog'),
          `${t('length')} (${units.length})`,
          `${t('dia')} (${units.pipeDiameter})`,
          `${t('veloctiy')} (${units.velocity})`,
        ],
        [
          projectData.mainlinePipe1,
          projectData.mainlinePipe1Length,
          projectData.mainlinePipe1Dia,
          mainlineValues.velocity1,
        ],
        [
          projectData.mainlinePipe2,
          projectData.mainlinePipe2Length,
          projectData.mainlinePipe2Dia,
          mainlineValues.velocity2,
        ],
        [
          projectData.mainlinePipe3,
          projectData.mainlinePipe3Length,
          projectData.mainlinePipe3Dia,
          mainlineValues.velocity3,
        ],
      ],
    };
  }, [units, projectData, mainlineValues]);

  const secondTable = useMemo(() => {
    return {
      data: [
        [t('totalFlow'), t('maxVelocity'), t('headloss')],
        [projectData.totalFlow ?? '', mainlineValues.maxVelocity ?? '', projectData.mainlinePipeHeadloss ?? ''],
      ],
    };
  }, [units, projectData, mainlineValues]);

  const pdfTableData = [firstTable, secondTable];

  return {
    infoTable,
    pdfTableData,
  };
};
