import { FC } from 'react';
import { Box, Container } from '@mui/system';
import { Outlet } from 'react-router';

export const CommonLayout: FC = () => {
  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#F5FBFF',
      }}
    >
      <Container component="main" maxWidth="sm">
        <Outlet />
      </Container>
    </Box>
  );
};
