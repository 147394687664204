import { FC, useEffect, useState } from 'react';
import { Button } from '../../shared/ui';
import { Box, CircularProgress, Dialog, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MailIcon from '@mui/icons-material/Mail';
import DoneIcon from '@mui/icons-material/Done';
import { useToast } from 'shared/hooks';
import { customAxios } from 'shared/api';
import { HydraulicToolAppType } from 'shared/constants';

interface Props {
  open: boolean;
  onClose: () => void;
  email: string;
}

export const AccountNotActivatedModal: FC<Props> = ({ open, onClose, email }) => {
  const { t } = useTranslation();
  const { showError, showSuccess } = useToast();

  const [isMailWasSent, setIsMailWasSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleResendClick = async () => {
    try {
      setIsLoading(true);

      await customAxios.post('/be/User/resend-invitation-by-email', {
        email,
        appType: HydraulicToolAppType,
      });

      setIsMailWasSent(true);
      showSuccess('Check your email');
    } catch (e: any) {
      console.log(e);
      showError(t('Something went wrong'));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => setIsMailWasSent(false), [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          padding: '48px 24px 40px',
          width: '324px',
          borderRadius: '16px',
          background: '#FFF',
          boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.16), 0px -1px 4px 0px rgba(0, 0, 0, 0.25)',
        },
      }}
    >
      <Stack sx={{ gap: '24px' }}>
        <Typography
          sx={{
            color: '#242731',
            textAlign: 'center',
            fontFamily: 'Roboto',
            fontSize: '18px',
            fontWeight: 600,
            lineHeight: '130%',
            letterSpacing: '0.18px',
          }}
        >
          {t('Account not activated')}
        </Typography>

        <Typography
          sx={{
            color: '#242731',
            fontFamily: 'Roboto',
            fontSize: '18px',
            lineHeight: '130%',
            letterSpacing: '0.18px',
          }}
        >
          {t('Check your mail box for account activation')}
        </Typography>

        <Button
          onClick={handleResendClick}
          sx={{
            m: '0 auto',
            width: 'auto',
            padding: '8px 16px',
            bgcolor: 'info.dark',
            color: 'black',
            '&:hover': { bgcolor: 'info.dark' },
          }}
        >
          <Box sx={{ mr: 1, mt: '2px' }}>
            {isLoading ? <CircularProgress size={18} /> : isMailWasSent ? <DoneIcon /> : <MailIcon />}
          </Box>

          {t('Resend Email')}
        </Button>
      </Stack>
    </Dialog>
  );
};
