export function GetAvDev(
  Average: number,
  FieldEdgeX: number,
  FieldEdgeY: number,
  FieldRainAt: Array<Array<number>>,
  ActuallCounter: number
) {
  let functionReturnValue = 0;
  let X = 0;
  let y = 0;
  let SUM = 0;
  let tmp = 0;

  for (y = 0; y <= FieldEdgeY; y++) {
    for (X = 0; X <= FieldEdgeX; X++) {
      if (FieldRainAt?.[X]?.[y] >= 0) {
        SUM = SUM + Math.abs(FieldRainAt?.[X]?.[y] - Average);
        tmp = tmp + 1;
      }
    }
  }
  if (ActuallCounter > 0) {
    functionReturnValue = SUM / ActuallCounter;
  } else {
    functionReturnValue = 0;
  }
  return functionReturnValue;
}
