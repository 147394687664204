import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { customAxios } from 'shared/api';
import { SaveUnitsBody, SaveUnitsResponse, Unit, UnitsResponse } from 'shared/models';

interface UserState {
  loading: boolean;
  units: Unit;
}

const initialState: UserState = {
  loading: true,
  units: {} as Unit,
};

export const getUnitsThunk = createAsyncThunk('getUnits', async (_, thunkAPI) => {
  try {
    const response = await customAxios.get<UnitsResponse>(
      `be/Ht/user-units`,
    );
    return response.data;
  } catch (e) {
    if (e instanceof Error) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
});

export const saveUnitsThunk = createAsyncThunk('saveUnits', async (body: SaveUnitsBody, thunkAPI) => {
  try {
    const response = await customAxios.put<SaveUnitsResponse>(
      'be/Ht/update-user-units',
      body,
    );
    return response.data;
  } catch (e) {
    if (e instanceof Error) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
});

const unitsSlice = createSlice({
  name: 'units',
  initialState,
  reducers: {
    setUnitsValue: (state, action: PayloadAction<Partial<Unit>>) => {
      state.units = { ...state.units, ...action.payload };
    },
    resetUnits: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUnitsThunk.pending.type, (state) => {
        state.loading = true;
      })
      .addCase(getUnitsThunk.fulfilled.type, (state, action: PayloadAction<UnitsResponse>) => {
        state.units = action.payload.data ?? {};
        state.loading = false;
      })
      .addCase(getUnitsThunk.rejected.type, (state) => {
        state.loading = false;
      });
  },
});

export const { resetUnits, setUnitsValue } = unitsSlice.actions;

export const unitsReducer = unitsSlice.reducer;
