/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import { CalculateLaterals2 } from '../calculateLaterals2';
import { Math_round } from '../mathRound';
import { ConvertUnit } from '../unitConverter';

import { LimitsRangeLaterals, range } from '../common/limitRange';
import { laterals as Laterals } from './laterals';
import { Unit } from 'shared/models';
import { METRIC_DEFAULTS } from 'shared/constants';

const arrayCopy = <T>(
  sourceArray: T[],
  sourceIndex: number,
  destinationArray: T[],
  destinationIndex: number,
  length: number,
): void => {
  // Check for invalid input
  if (
    !Array.isArray(sourceArray) ||
    !Array.isArray(destinationArray) ||
    sourceIndex < 0 ||
    destinationIndex < 0 ||
    length < 0
  ) {
    throw new Error('Invalid input');
  }

  // Perform the copy
  const sourceSlice = sourceArray.slice(sourceIndex, sourceIndex + length);
  destinationArray.splice(destinationIndex, length, ...sourceSlice);
};

export function LateralsValidationInput(laterals: Partial<Laterals>) {
  if (laterals.Length! <= 0) {
    laterals.Length = 0;
    return { data: laterals, error: 'msgInvalidLateralLen' };
  }
  if (laterals.spacing! <= 0) {
    laterals.spacing = 0;
    return { data: laterals, error: 'msgInvalidEmitterSpacing' };
  }
  if (laterals.pMin! <= 0) {
    laterals.pMin = 0;
    return { data: laterals, error: 'msgInvalidPmin' };
  }

  if (laterals.pMax! <= 0) {
    laterals.pMax = 0;
    return { data: laterals, error: 'msgInvalidPmax' };
  }

  if (laterals.pMax! < laterals.pMin!) {
    return { data: laterals, error: 'msgPmaxless' };
  }
  if (laterals.pInlet! > laterals.pMax!) {
    return { data: laterals, error: 'msgInvalidInletPr' };
  }
  return laterals;
}

// CatalogResult
export function CalculateLateralsConvert(laterals: Partial<Laterals>, unitSettings: Unit) {
  laterals.dia = ConvertUnit(laterals.dia!, unitSettings.pipeDiameter, METRIC_DEFAULTS.PipeDiameter, 'PipeDiameter');
  laterals.Length = ConvertUnit(laterals.Length!, unitSettings.length, METRIC_DEFAULTS.Length, null);
  laterals.spacing = ConvertUnit(laterals.spacing!, unitSettings.emitterSpacing, METRIC_DEFAULTS.EmitterSpacing, null);

  laterals.pInlet = ConvertUnit(laterals.pInlet!, unitSettings.pressure, METRIC_DEFAULTS.Pressure, null);
  laterals.pMin = ConvertUnit(laterals.pMin!, unitSettings.pressure, METRIC_DEFAULTS.Pressure, null);
  laterals.pMax = ConvertUnit(laterals.pMax!, unitSettings.pressure, METRIC_DEFAULTS.Pressure, null);
  laterals.flushVelocity = ConvertUnit(laterals.flushVelocity!, unitSettings.velocity!, METRIC_DEFAULTS.Velocity, null);
}

export function CalculateHeadloss(
  isHW: number,
  HWcoef: number,
  TotalFlow: number,
  dia: number,
  length: number,
  kd: number,
  PRA: number,
  PRB: number,
  PRC: number,
) {
  let F2 = 0.0;
  //var PRA = 0, PRB = 0, PRC = 0;
  switch (isHW) {
    case 0:
      F2 = (1.131 * Math.pow(10.0, 12.0) * Math.pow(TotalFlow / HWcoef, 1.852)) / Math.pow(dia, 4.87);
      console.log('F2 first', F2);
      F2 = (F2 * length) / 1000;
      console.log('F2 second', F2);

      break;
    case 1:
      if (PRA != 0 && PRB != 0 && PRC != 0) {
        F2 = (((PRA * Math.pow(10, 7) * Math.pow(TotalFlow, PRB)) / Math.pow(dia, PRC)) * length) / 1000;
      }
      break;
    case 2: {
      let Velocity = Math.pow(TotalFlow / 3600 / ((3.14159 * Math.pow(dia / 1000.0, 2.0)) / 4.0), 2.0) / 2.0 / 9.81;

      if (313 * Math.abs(TotalFlow / (dia / 1000)) <= 2000) {
        F2 = (64 / Math.abs((313 * TotalFlow * 1000) / dia)) * length * (Velocity / (dia / 1000));
      }

      if (313 * Math.abs(TotalFlow / (dia / 1000)) > 2000 && 313 * Math.abs(TotalFlow / (dia / 1000)) <= 4000) {
        F2 = 3.42e-5 * Math.pow(Math.abs((313 * TotalFlow * 1000) / dia), 0.85) * length * (Velocity / (dia / 1000));
      }
      if (313 * Math.abs(TotalFlow / (dia / 1000)) > 4000 && 313 * Math.abs(TotalFlow / (dia / 1000)) <= 100000) {
        F2 = (0.316 / Math.pow(Math.abs((313 * TotalFlow * 1000) / dia), 0.25)) * length * (Velocity / (dia / 1000));
      }

      if (313 * Math.abs(TotalFlow / (dia / 1000)) > 100000) {
        F2 = (0.13 / Math.pow(Math.abs((313 * TotalFlow * 1000) / dia), 0.172)) * length * (Velocity / (dia / 1000));
      }
      break;
    }
  }
  let v = (4.0 * TotalFlow) / (dia * dia * Math.PI * 36.0 * 0.0001);
  F2 += (kd * v * v) / 2 / 9.81;
  return F2;
}

export function CalculateLaterals2_V1(
  laterals: Partial<Laterals>,
  unitSettings: Unit,
  arrQ: number[],
  arrVelocity: number[],
  arrPressure: number[],
  arrEU: number[],
  arrHeadloss: number[],
) {
  return CalculateLaterals2(
    laterals.isHW!,
    laterals.HWCoeff!,
    laterals.dia!,
    laterals.A!,
    laterals.B!,
    laterals.Kd!,
    laterals.Length!,
    laterals.spacing!,
    laterals.slopes!,
    laterals.pInlet!,
    laterals.pMin!,
    laterals.pMax!,
    laterals.cvv!,
    laterals.calcType!,
    laterals.flushVelocity!,
    arrQ,
    arrVelocity,
    arrPressure,
    arrEU,
    arrHeadloss,
    laterals.PRA!,
    laterals.PRB!,
    laterals.PRC!,
    0,
    0,
    false,
    unitSettings,
  );
}

export function CalculateIsHwByHDCalc(laterals: Partial<Laterals>) {
  let isHW = 0;
  switch (laterals.HDCalc) {
    case 'H.W':
      isHW = 0;
      break;
    case 'D.W':
      isHW = 1;
      if (laterals.PRA == 0 || laterals.PRB == 0 || laterals.PRC == 0) {
        isHW = 2;
      }
      break;
    case 'D.W-RE':
      isHW = 2;
      break;
  }
  laterals.isHW = isHW;
}

export function CalculateLateralsV1(laterals: Partial<Laterals>, unitSettings: Unit, showReport: boolean) {
  let arrQ: number[] = [];
  let arrVelocity: number[] = [];
  let arrPressure: number[] = [];
  let arrEU: number[] = [];
  let arrHeadloss: number[] = [];
  let rangeResult: LimitsRangeLaterals = {};
  const validationResult = LateralsValidationInput(laterals);
  // eslint-disable-next-line no-prototype-builtins
  if (validationResult.hasOwnProperty('error')) {
    return validationResult;
  }
  CalculateLateralsConvert(laterals, unitSettings);
  CalculateIsHwByHDCalc(laterals);
  const retVal = CalculateLaterals2(
    laterals.isHW!,
    laterals.HWCoeff!,
    laterals.dia!,
    laterals.A!,
    laterals.B!,
    laterals.Kd!,
    laterals.Length!,
    laterals.spacing!,
    laterals.slopes!,
    laterals.pInlet!,
    laterals.pMin!,
    laterals.pMax!,
    laterals.cvv!,
    laterals.calcType!,
    laterals.flushVelocity!,
    arrQ,
    arrVelocity,
    arrPressure,
    arrEU,
    arrHeadloss,
    laterals.PRA!,
    laterals.PRB!,
    laterals.PRC!,
    0,
    0,
    false,
    unitSettings,
  );
  arrQ = retVal.arrQ;
  arrVelocity = retVal.arrVelocity;
  arrPressure = retVal.arrPressure;
  arrEU = retVal.arrEU;
  arrHeadloss = retVal.arrHeadloss;
  if (retVal.spacings <= 0) {
    return { error: 'msgRetry' };
  }
  let result = {};

  let QFlush =
    laterals.flushVelocity! * ((Math.PI * laterals.dia! * laterals.dia! * Math.pow(10, -6)) / 4) * 3600 * 1000;

  result = {
    ...result,
    totalHeadloss: totalHeadlossResult(unitSettings, arrHeadloss),
  };
  if (laterals.pInlet === 0) {
    result = {
      ...result,
      pInlet: pInletResult(laterals, unitSettings, arrPressure),
    };
  }
  let totalFlow = lateralTotalFlowResult(arrQ);
  const minPressure = PressureResult(unitSettings, arrPressure).minPressure;
  const maxPressure = PressureResult(unitSettings, arrPressure).maxPressure;

  if (maxPressure > laterals.pMax! || maxPressure < laterals.pMin!) {
    let range: range;

    rangeResult.ActpmaxRange = { range: 'over' };
    // TxtActpmax.Background = new SolidColorBrush(Colors.Red);
    // TxtActpmax.Foreground = new SolidColorBrush(Colors.White);
  } else {
    rangeResult.ActpmaxRange = { range: 'in' };

    // TxtActpmax.Background = txtLastLateralPressure.Background;
    // TxtActpmax.Foreground = new SolidColorBrush(Colors.Green);
  }

  // resullt = { ... result , overMinPressure: minPressure < pMin | minPressure > pMax }
  if (minPressure < laterals.pMin! || minPressure > laterals.pMax!) {
    rangeResult.ActpminRange = { range: 'over' };
    // TxtActpmin.Background = new SolidColorBrush(Colors.Red);
    // TxtActpmin.Foreground = new SolidColorBrush(Colors.White);
  } else {
    rangeResult.ActpminRange = { range: 'in' };

    // TxtActpmin.Background = txtLastLateralPressure.Background;
    // TxtActpmin.Foreground = new SolidColorBrush(Colors.Green);
  }
  let maxVelocity = calcMaxVelocity(unitSettings, arrVelocity);
  if (maxVelocity > 3.5) {
    rangeResult.LateralMaxVelocity = { range: 'over' };
    // txtLateralMaxVelocity.Background = new SolidColorBrush(Colors.Red);
    // txtLateralMaxVelocity.Foreground = new SolidColorBrush(Colors.White);
  } else {
    rangeResult.LateralMaxVelocity = { range: 'in' };

    // txtLateralMaxVelocity.Background = txtLastLateralPressure.Background;
    // txtLateralMaxVelocity.Foreground = txtLastLateralPressure.Foreground;
  }

  const du = LateralMaxDu(totalFlow, arrQ);

  result = {
    ...result,
    lateralMaxDu: Math_round(du, 3),
    lateralMaxReportDu: du,
  };

  totalFlow = (totalFlow + QFlush) / 1000;

  result = {
    ...result,
    totalFlow: Math_round(ConvertUnit(totalFlow, METRIC_DEFAULTS.TotalFlow, unitSettings.totalFlow, null), 3),
  };
  result = { ...result, Flow1: totalFlow1(unitSettings, totalFlow, QFlush) };
  result = { ...result, Flow2: totalFlow1(unitSettings, totalFlow, QFlush) };
  result = {
    ...result,
    pmin: minPressure,
  };
  result = {
    ...result,
    pmax: maxPressure,
  };
  result = { ...result, EU2: Math_round(arrEU[arrEU.length - 1], 3) };
  result = {
    ...result,
    MaxVelocity: calcMaxVelocity(unitSettings, arrVelocity),
  };
  if (showReport) {
    let lateralLastReportResult = {};
    lateralLastReportResult = {
      ...lateralLastReportResult,
      inletPressure: inletPressure(unitSettings, arrPressure),
    };
    lateralLastReportResult = {
      ...lateralLastReportResult,
      headloss: headloss(unitSettings, arrHeadloss),
    };
    lateralLastReportResult = {
      ...lateralLastReportResult,
      //UnitConverter.ConvertUnit(totalHeadloss, unitSettings.pressureMetric, unitSettings.pressure, null).ToString("F3");
      totalHeadloss: totalHeadlossResult(unitSettings, arrHeadloss),
    };
    lateralLastReportResult = {
      ...lateralLastReportResult,
      lateralFlow: lateralFlow(unitSettings, arrQ),
    };
    lateralLastReportResult = {
      ...lateralLastReportResult,
      velocity: velocity(unitSettings, arrVelocity),
    };
    lateralLastReportResult = {
      ...lateralLastReportResult,
      length: 0,
    };

    lateralLastReportResult = {
      ...lateralLastReportResult,
      qMinQMax: qMinQMax(unitSettings, arrQ),
    };

    let lateralReportResult = getLateralReportResult(
      laterals,
      unitSettings,
      QFlush,
      arrQ,
      arrHeadloss,
      arrVelocity,
      arrPressure,
      lateralLastReportResult,
    );
    lateralLastReportResult = {
      ...lateralLastReportResult,
      lateralReportResult: lateralReportResult.reverse(),
    };
    result = { ...result, lateralLastReportResult };
  }
  result = { ...result, rangeResult: rangeResult };
  return result;
}

export function lateralTotalFlowResult(arrQ: number[]) {
  let flow = 0;
  let totalFlow = 0;
  for (const flow_loopVariable of arrQ) {
    flow = flow_loopVariable;
    totalFlow += flow;
  }
  return totalFlow;
}

export function pInletResult(laterals: Partial<Laterals>, unitSettings: Unit, arrPressure: number[]) {
  let pInlet = arrPressure[arrPressure.length - 1];
  laterals.pInlet = ConvertUnit(pInlet, 'm', unitSettings.pressure, null);
  return Math_round(laterals.pInlet!, 3);
}

export function LateralMaxDu(totalFlow: number, arrQ: number[]) {
  let count = 0;
  count = arrQ.length;
  const aveFlow = totalFlow / count;
  const lstFlow: number[] = [];
  for (let i = 0; i < count - 1; i++) {
    lstFlow.push(arrQ[i]);
  }
  lstFlow.sort((a, b) => a - b);
  let totalQuarterFlow = 0;
  for (let i = 0; i <= Math.floor(count / 4 - 1); i++) {
    totalQuarterFlow += lstFlow[i];
  }
  let du = 0.0;

  if (count < 4) {
    du = 100;
  }
  let minFlow = totalQuarterFlow / parseInt((count / 4).toString());
  du = minFlow / aveFlow;
  return du;
}

export function totalFlow1(unitSettings: Unit, totalFlow: number, qflush: number) {
  let totalFlowTemp = 0;
  totalFlowTemp = (totalFlow + qflush) / 1000;

  let totalFlowTempRes = ConvertUnit(totalFlowTemp, METRIC_DEFAULTS.TotalFlow, unitSettings.pressure, null);
  return Math_round(totalFlowTempRes, 3);
}

export function PressureResult(unitSettings: Unit, arrPressure: number[]) {
  let count = arrPressure.length;
  let minPressure = Number.MAX_VALUE;
  let maxPressure = Number.MIN_VALUE;
  for (let i = 0; i <= count - 1; i++) {
    if (minPressure > arrPressure[i]) {
      minPressure = arrPressure[i];
    }
    if (maxPressure < arrPressure[i]) {
      maxPressure = arrPressure[i];
    }
  }

  let maxPressureRes = ConvertUnit(maxPressure, METRIC_DEFAULTS.Pressure, unitSettings.pressure, null);

  let minPressureRes = ConvertUnit(minPressure, METRIC_DEFAULTS.Pressure, unitSettings.pressure, null);
  return {
    maxPressure: Math_round(maxPressureRes, 3),
    minPressure: Math_round(minPressureRes, 3),
  };
}

export function calcMaxVelocity(unitSettings: Unit, arrVelocity: number[]) {
  arrVelocity.sort((a, b) => a - b);
  let maxVelocity = arrVelocity[arrVelocity.length - 1];
  if (maxVelocity > 3.5) {
    // txtLateralMaxVelocity.style['background'] = 'red';
    // txtLateralMaxVelocity.style['color'] = 'white';
  } else {
    // txtLateralMaxVelocity.style['background'] =
    // txtLastLateralPressure.style['background'];
    // txtLateralMaxVelocity.style['color'] = txtLastLateralPressure.style['color'];
  }
  let maxVelocityRes = ConvertUnit(maxVelocity, METRIC_DEFAULTS.Velocity, unitSettings.velocity, null);
  return Math_round(maxVelocityRes, 3);
}

export function Flow(unitSettings: Unit, arrVelocity: number[]) {
  arrVelocity.sort();
  let maxVelocity = arrVelocity[arrVelocity.length - 1];
  if (maxVelocity > 3.5) {
    // txtLateralMaxVelocity.style['background'] = 'red';
    // txtLateralMaxVelocity.style['color'] = 'white';
  } else {
    // txtLateralMaxVelocity.style['background'] =
    // txtLastLateralPressure.style['background'];
    // txtLateralMaxVelocity.style['color'] = txtLastLateralPressure.style['color'];
  }
  let maxVelocityRes = ConvertUnit(maxVelocity, METRIC_DEFAULTS.Velocity, unitSettings.velocity, null);
  return Math_round(maxVelocityRes, 3);
}

export function inletPressure(unitSettings: Unit, arrPressure: number[]) {
  let InletPressure = arrPressure[arrPressure.length - 1];

  let inletPressureRes = ConvertUnit(InletPressure, METRIC_DEFAULTS.Pressure, unitSettings.pressure, null);
  return Math_round(inletPressureRes, 3);
}

export function headloss(unitSettings: Unit, arrHeadloss: number[]) {
  let headloss = arrHeadloss[arrHeadloss.length - 1];

  let headlossRes = ConvertUnit(headloss, METRIC_DEFAULTS.Pressure, unitSettings.pressure, null);
  return Math_round(headlossRes, 3);
}

//totalHeadloss(totalHeadlossResult(unitSettings, arrHeadloss), unitSettings),
export function totalHeadloss(headloss: number, unitSettings: Unit) {
  let headlossRes = ConvertUnit(headloss, METRIC_DEFAULTS.Pressure, unitSettings.pressure, null);
  return Math_round(headlossRes, 3);
}

export function totalHeadlossResult(unitSettings: Unit, arrHeadloss: number[]) {
  let totalHeadloss = 0;
  for (let i = 0; i <= arrHeadloss.length - 2; i++) {
    totalHeadloss += arrHeadloss[i];
  }

  let totalHeadlossRes = ConvertUnit(totalHeadloss, METRIC_DEFAULTS.Pressure, unitSettings.pressure, null);

  return Math_round(totalHeadlossRes, 3);
}

export function lateralFlow(unitSettings: Unit, arrQ: number[]) {
  let qMin = Number.MAX_VALUE;
  let qMax = Number.MIN_VALUE;
  let totalLateralFlow = 0;
  for (let j = 0; j < arrQ.length; j++) {
    totalLateralFlow += arrQ[j];
    if (arrQ[j] < qMin) qMin = arrQ[j];

    if (arrQ[j] > qMax) qMax = arrQ[j];
  }
  let lateralFlow = ConvertUnit(totalLateralFlow, METRIC_DEFAULTS.TotalFlow, unitSettings.totalFlow, null);
  return Math_round(lateralFlow / 1000, 3);
}

export function qMinQMax(unitSettings: Unit, arrQ: number[]) {
  let qMin = Number.MAX_VALUE;
  let qMax = Number.MIN_VALUE;
  let totalLateralFlow = 0;
  for (let j = 0; j < arrQ.length; j++) {
    totalLateralFlow += arrQ[j];
    if (arrQ[j] < qMin) qMin = arrQ[j];

    if (arrQ[j] > qMax) qMax = arrQ[j];
  }

  return qMin / qMax;
}

export function velocity(unitSettings: Unit, arrVelocity: number[]) {
  let velocity = ConvertUnit(
    arrVelocity[arrVelocity.length - 1],
    METRIC_DEFAULTS.Velocity,
    unitSettings.velocity,
    null,
  );
  return Math_round(velocity, 3);
}

export function getLateralReportResult(
  laterals: Partial<Laterals>,
  unitSettings: Unit,
  qFlush: number,
  arrQ: number[],
  arrHeadloss: number[],
  arrVelocity: number[],
  arrPressure: number[],
  lastRow: any,
) {
  const lateralReportResult = [];

  for (let i = 0; i < arrQ.length; i++) {
    const element = arrQ[i];

    let result = {};
    const lateralFlow = Math_round(
      ConvertUnit(element / 1000, METRIC_DEFAULTS.TotalFlow, unitSettings.totalFlow, null),
      3,
    );
    result = { ...result, lateralFlow: lateralFlow };
    let num = laterals.Length! - i * laterals.spacing!;
    const length = Math_round(ConvertUnit(num, METRIC_DEFAULTS.Length, unitSettings.length, null), 3);
    result = { ...result, length: length };
    const _arrFlow = [...arrQ.slice(0, i + 1)];
    _arrFlow.sort((a, b) => a - b);
    // Array.Copy(arrHeadloss, 0, arrTempHL, 0, i);
    const arrHeadlossCopy: number[] = [];
    arrayCopy(arrHeadloss, 0, arrHeadlossCopy, 0, i);
    arrHeadlossCopy.sort((a, b) => a - b);
    let totalHL = 0;

    for (let k = 0; k < arrHeadlossCopy.length; k++) {
      totalHL += arrHeadlossCopy[k];
    }

    const totalHeadloss = Math_round(ConvertUnit(totalHL, METRIC_DEFAULTS.Pressure, unitSettings.pressure, null), 3);

    result = { ...result, totalHeadloss: totalHeadloss };
    if (i > 0) {
      const velocity = Math_round(
        ConvertUnit(arrVelocity[i - 1], METRIC_DEFAULTS.Velocity, unitSettings.velocity, null),
        3,
      );
      result = { ...result, velocity: velocity };
    }
    if (i > 0) {
      const headloss = Math_round(
        ConvertUnit(arrHeadloss[i - 1], METRIC_DEFAULTS.Pressure, unitSettings.pressure, null),
        3,
      );
      result = { ...result, headloss: headloss };
    }
    const arrPr = [...arrPressure];
    // arrPr.sort();
    const inletPressure = Math_round(ConvertUnit(arrPr[i], METRIC_DEFAULTS.Pressure, unitSettings.pressure, null), 3);
    result = { ...result, inletPressure: inletPressure };

    let flowTotal = 0;
    for (let j = 0; j <= i; j++) {
      flowTotal += _arrFlow[j];
    }
    flowTotal += qFlush;
    const lateralFlowQlow = Math_round(
      ConvertUnit(flowTotal / 1000, METRIC_DEFAULTS.TotalFlow, unitSettings.totalFlow, null),
      3,
    );
    result = { ...result, lateralFlow: lateralFlowQlow };

    const averageFlow = Math_round(ConvertUnit(arrQ[i], METRIC_DEFAULTS.TotalFlow, unitSettings.totalFlow, null), 3);
    result = { ...result, averageFlow: averageFlow };

    lateralReportResult.push(result);
  }

  lateralReportResult.push({
    lateralFlow: lastRow.lateralFlow,
    averageFlow: 0,
    inletPressure: lastRow.inletPressure,
    length: lastRow.length,
    headloss: lastRow.headloss,
    totalHeadloss: lastRow.totalHeadloss,
    velocity: lastRow.velocity,
  });

  return lateralReportResult;
}
