import {
  Grid,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead as MUIHead,
  TableRow,
  Typography,
} from '@mui/material';
import { toggleMainlineReport } from 'shared/slices';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { Button, Modal } from 'shared/ui';
import { useMainlineReportData } from './hooks';
import { createPDF } from 'shared/lib';

const TableHead = styled(MUIHead)(({ theme }) => ({
  th: {
    color: theme.palette.primary.main,
    backgroundColor: '#F5FBFF',
  },
}));
const scrollStyles = {
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'primary.main',
  },
  '&::-webkit-scrollbar': {
    width: '6px',
    height: '6px',
    backgroundColor: '#F5FBFF',
  },
};

export const MainlineReport = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { openMainlineReport } = useAppSelector((st) => st.modals);
  const { units } = useAppSelector((st) => st.units);
  const { username } = useAppSelector((st) => st.auth);
  const { projectData } = useAppSelector((st) => st.projectData);
  const { mainlines } = useAppSelector((st) => st.mainlines);
  const { mainlineValues } = useAppSelector((st) => st.mainlineState);

  const toggle = () => dispatch(toggleMainlineReport());

  const { infoTable, pdfTableData } = useMainlineReportData();

  const handleDownloadPDF = () => {
    createPDF({
      type: 'mainline',
      title: 'Mainline Report',
      infoData: infoTable,
      tables: pdfTableData,
      reportName: 'Mainline Report',
      t,
    });
  };

  return (
    <Modal maxWidth="lg" title="Mainline Report" open={openMainlineReport} onClose={toggle}>
      <Grid container rowGap={1}>
        <Grid item xs={12}>
          <Button onClick={handleDownloadPDF} sx={{ width: '200px' }}>
            {t('export')}
          </Button>
        </Grid>

        <Grid item xs={12}>
          <TableContainer sx={{ ...scrollStyles }}>
            <Table sx={{ width: '500px' }}>
              <TableHead>
                <TableRow>
                  <TableCell>{t('preparedFor')}</TableCell>
                  <TableCell>{t('by')}</TableCell>
                  <TableCell>{t('_date')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{`${projectData.tradingName ?? ''} ${projectData.growerName ?? ''}`}</TableCell>
                  <TableCell>{username}</TableCell>
                  <TableCell>{new Date(Date.now()).toLocaleString()}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body2">{t('mainlineRep')}</Typography>
        </Grid>

        <Grid item xs={12} maxWidth="100%">
          <TableContainer sx={{ ...scrollStyles }}>
            <Table>
              <TableHead sx={{ color: 'primary.main' }}>
                <TableRow>
                  <TableCell>{t('catalog')}</TableCell>
                  <TableCell>{`${t('length')} (${units.length})`}</TableCell>
                  <TableCell>{`${t('dia')} (${units.pipeDiameter})`}</TableCell>
                  <TableCell>{`${t('velocity')} (${units.velocity})`}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {projectData.mainlinePipe1Length ? (
                  <TableRow>
                    <TableCell>
                      {mainlines.find((item) => item.catlog === projectData.mainlinePipe1)?.catlogDesc}
                    </TableCell>
                    <TableCell>{projectData.mainlinePipe1Length}</TableCell>
                    <TableCell>{projectData.mainlinePipe1Dia}</TableCell>
                    <TableCell>{mainlineValues.velocity1}</TableCell>
                  </TableRow>
                ) : null}
                {projectData.mainlinePipe2Length ? (
                  <TableRow>
                    <TableCell>
                      {mainlines.find((item) => item.catlog === projectData.mainlinePipe2)?.catlogDesc}
                    </TableCell>
                    <TableCell>{projectData.mainlinePipe2Length}</TableCell>
                    <TableCell>{projectData.mainlinePipe2Dia}</TableCell>
                    <TableCell>{mainlineValues.velocity2}</TableCell>
                  </TableRow>
                ) : null}
                {projectData.mainlinePipe3Length ? (
                  <TableRow>
                    <TableCell>
                      {mainlines.find((item) => item.catlog === projectData.mainlinePipe3)?.catlogDesc}
                    </TableCell>
                    <TableCell>{projectData.mainlinePipe3Length}</TableCell>
                    <TableCell>{projectData.mainlinePipe3Dia}</TableCell>
                    <TableCell>{mainlineValues.velocity3}</TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={12}>
          <TableContainer
            sx={{
              maxHeight: 430,
              overflow: 'auto',
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'primary.main',
              },
              '&::-webkit-scrollbar': {
                width: '6px',
                height: '6px',
                backgroundColor: '#F5FBFF',
              },
            }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>{`${t('totalFlow')} (${units.totalFlow})`}</TableCell>
                  <TableCell>{`${t('maxVelocity')} (${units.velocity})`}</TableCell>
                  <TableCell>{`${t('headloss')} (${units.length})`}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell size="small">{projectData.totalFlow}</TableCell>
                  <TableCell size="small">{mainlineValues.maxVelocity}</TableCell>
                  <TableCell size="small">{projectData.mainlinePipeHeadloss}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <Typography variant="body2">{t('repDisclaimer')}</Typography>
        </Grid>
      </Grid>
    </Modal>
  );
};
