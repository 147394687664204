import { LoadingButton } from '@mui/lab';
import { Autocomplete, TextField, Stack, Typography, FormControlLabel, Checkbox, Link } from '@mui/material';
import { Box } from '@mui/system';
import { EmailAlreadyRegisteredModal, EndUserLicense, SuccessfulRegister } from 'features';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { customAxios } from 'shared/api';
import { REGISTRATION_COUNTRIES, REGISTRATION_OCCUPATION } from 'shared/constants';
import { useToast, useDialog } from 'shared/hooks';
import { CountryData, detectCountryData } from 'shared/lib';
import { RegisterInput, Button, InputPhone } from 'shared/ui';

export const telRegexp = /[^a-zA-Z0-9]*[0-9]{3}[^a-zA-Z0-9]*[0-9]{3}[^a-zA-Z0-9]*[0-9]{4}$/;
export const emailRegexp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const RegisterForm = () => {
  const { open: openSuccessfulRegister, toggle: toggleSuccessfulRegister } = useDialog();
  const [searchParams] = useSearchParams();

  const { recreating, oldUserName } = Object.fromEntries([...searchParams]);

  const { t } = useTranslation();
  const { showError } = useToast();
  const navigate = useNavigate();

  const [countryData, setCountryData] = useState<CountryData>();

  const [registerData, setRegisterData] = useState({
    name: '',
    email: '',
    organization: '',
    phone: '',
    occupation: '',
    country: '',
    receiveUpdates: false,
  });
  const [errorState, setErrorState] = useState({
    email: false,
    name: false,
    phone: false,
    country: false,
  });

  const { open, toggle } = useDialog();
  const [loading, setLoading] = useState(false);
  const [isEmailCheckLoading, setIsEmailCheckLoading] = useState(false);

  const [isEmailAlreadyRegisteredModal, setIsEmailAlreadyRegisteredModal] = useState(false);
  const [isEmailExist, setIsEmailExist] = useState(false);

  const body = {
    ...(oldUserName && { oldUserName }),
    email: registerData.email,
    organizationName: registerData.organization,
    country: registerData.country,
    occupation: registerData.occupation,
    userName: registerData.name,
    phoneNumber: registerData.phone,
    receiveUpdates: registerData.receiveUpdates,
    roles: [],
  };

  const isAlreadyRegisteredMessage = !oldUserName && isEmailExist;
  const isFillMissingDataMessage = !!oldUserName && isEmailExist;

  useEffect(() => {
    async function getCountryCode() {
      const countryData = await detectCountryData();

      setCountryData(countryData);
    }

    !registerData.phone && getCountryCode();

    localStorage.removeItem('mergeData');
  }, []);

  const handleRegisterDataChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRegisterData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    if (e.target.name === 'email') {
      setIsEmailExist(false);
    }
  };

  const handleReceiveUpdatesChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRegisterData((prev) => ({ ...prev, receiveUpdates: e.target.checked }));
  };

  const handleAutocompleteChange = (field: string, value: string) => {
    setRegisterData((prev) => ({ ...prev, [field]: value }));
  };

  const validateEmail = () => {
    if (!emailRegexp.test(registerData.email)) {
      setErrorState((prev) => ({ ...prev, email: true }));
      return true;
    } else {
      setErrorState((prev) => ({ ...prev, email: false }));
      return false;
    }
  };

  const validatePhone = () => {
    if (!telRegexp.test(registerData.phone.replaceAll(' ', ''))) {
      setErrorState((prev) => ({ ...prev, phone: true }));
      return true;
    } else {
      setErrorState((prev) => ({ ...prev, phone: false }));
      return false;
    }
  };

  const validateName = () => {
    if (!registerData.name) {
      setErrorState((prev) => ({ ...prev, name: true }));
      return true;
    } else {
      setErrorState((prev) => ({ ...prev, name: false }));
      return false;
    }
  };

  const validateCountry = () => {
    if (!registerData.country) {
      setErrorState((prev) => ({ ...prev, country: true }));
      return true;
    } else {
      setErrorState((prev) => ({ ...prev, country: false }));
      return false;
    }
  };
  const validateForm = () => {
    const emailNotValid = validateEmail();
    const phoneNotValid = validatePhone();
    const nameNotValid = validateName();
    const countryNotValid = validateCountry();

    return emailNotValid || phoneNotValid || nameNotValid || countryNotValid;
  };

  const checkEmailExisting = async () => {
    try {
      const response = await customAxios.get(`/be/Ht/ht-user-already-exist/?userName=${registerData.email}`);

      const userInfo: {
        isExist: boolean;
        name: string;
        phone: string;
        country: string;
        occupation: string;
        organization: string;
      } = response.data?.data;

      if (!userInfo) return false;
      return userInfo;
    } catch (e) {
      showError('Something went wrong.');
      return false;
    }
  };

  const onBlurEmailExistingCheck = async () => {
    const emailNotValid = validateEmail();
    if (emailNotValid) return;

    try {
      setIsEmailCheckLoading(true);
      setIsEmailExist(false);

      const userInfo = await checkEmailExisting();
      if (!userInfo) return;

      if (userInfo.isExist) {
        setIsEmailExist(true);

        if (oldUserName) {
          setRegisterData((prev) => ({ ...prev, ...userInfo }));
          setErrorState((prev) => ({ ...prev, phone: false, name: false }));
        }
      }
    } catch (e) {
      showError('Something went wrong.');
    } finally {
      setIsEmailCheckLoading(false);
    }
  };

  const handleSubmitClick = async () => {
    if (isEmailCheckLoading) return;
    if (isAlreadyRegisteredMessage) return;

    const isNotValid = validateForm();
    if (isNotValid) return;

    try {
      setLoading(true);

      if (recreating) {
        await customAxios.post('/be/User/recreate-ht-user', body);
        toggleSuccessfulRegister();

        localStorage.setItem('ht-redirected-email', registerData.email);
        handleBackClick();
      } else {
        if (isEmailExist) {
          setIsEmailAlreadyRegisteredModal(true);
          return;
        }

        await customAxios.post('/be/User/create-ht-user', body);
        toggleSuccessfulRegister();
      }
    } catch (e) {
      showError('Something went wrong.');
    } finally {
      setLoading(false);
    }
  };

  const handleBackClick = () => {
    navigate('/');
  };

  const onPhoneChange = (phone: string) => {
    setRegisterData((prev) => ({ ...prev, phone: phone }));
  };

  const handleSuccessfulRegister = () => {
    toggleSuccessfulRegister();
    navigate('/');
  };

  return (
    <>
      <Box sx={{ width: '100%', height: '100%' }}>
        <Box sx={{ height: '113px', width: '100%', backgroundColor: '#1D8742', mb: '70px' }} />
        <Stack
          sx={{
            padding: '0 64px',
            gap: '48px',
            alignContent: 'center',
            maxWidth: '847px',
            width: '100%',
            m: '0 auto',
          }}
        >
          <Typography
            sx={{
              textAlign: 'center',
              fontFamily: 'Roboto',
              fontSize: '35px',
              lineHeight: '130%',
              letterSpacing: '0.35px',
            }}
          >
            {t('welcome_to_rivulis_ht')}
            <br />
            {t('please_fill_the_form_below')}
          </Typography>
          <Box display={'flex'}>
            <Stack sx={{ flexBasis: '50%', gap: '32px', pr: '32px', borderRight: '1px solid #D4D4D4' }}>
              <Box>
                <RegisterInput
                  value={registerData.email}
                  onChange={handleRegisterDataChange}
                  onBlur={onBlurEmailExistingCheck}
                  name="email"
                  required
                  error={errorState.email}
                  label={`${t('email')}*`}
                  loading={isEmailCheckLoading}
                />

                {errorState.email && (
                  <Typography
                    sx={{
                      mt: '8px',
                      color: '#F00',
                      fontFamily: 'Roboto',
                      fontSize: '18px',
                      lineHeight: '130%',
                      letterSpacing: '0.18px',
                    }}
                  >
                    Wrong e-mail
                  </Typography>
                )}
                {isAlreadyRegisteredMessage && (
                  <Typography
                    sx={{
                      mt: '8px',
                      color: '#F00',
                      fontFamily: 'Roboto',
                      fontSize: '18px',
                      lineHeight: '130%',
                      letterSpacing: '0.18px',
                    }}
                  >
                    {t('email_already_registered_please_go_back_to_the')}
                    <Link color="primary" href="/login" sx={{ color: 'blue', ml: '8px' }}>
                      {t('login_page')}
                    </Link>
                  </Typography>
                )}

                {isFillMissingDataMessage && (
                  <Typography
                    sx={{
                      mt: '8px',
                      color: 'primary.main',
                      fontFamily: 'Roboto',
                      fontSize: '18px',
                      lineHeight: '130%',
                      letterSpacing: '0.18px',
                    }}
                  >
                    {t('Email exists in the system fill in missing data')}
                  </Typography>
                )}
              </Box>

              <Box>
                <InputPhone
                  onBlur={validatePhone}
                  value={registerData.phone}
                  onChange={onPhoneChange}
                  error={errorState.phone}
                  countryCode={countryData?.countryCode}
                  disabled={isFillMissingDataMessage}
                />
                {errorState.phone && (
                  <Typography
                    sx={{
                      mt: '8px',
                      color: '#F00',
                      fontFamily: 'Roboto',
                      fontSize: '18px',
                      lineHeight: '130%',
                      letterSpacing: '0.18px',
                    }}
                  >
                    Wrong phone
                  </Typography>
                )}
              </Box>
              <RegisterInput
                value={registerData.organization}
                onChange={handleRegisterDataChange}
                name="organization"
                label={`${t('organization')}`}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ py: 0 }}
                    value={registerData.receiveUpdates}
                    checked={registerData.receiveUpdates}
                    onChange={handleReceiveUpdatesChange}
                  />
                }
                label={
                  <Typography
                    sx={{
                      color: '#1D8742',
                      fontFamily: 'Roboto',
                      fontSize: '18px',
                      fontWeight: 700,
                    }}
                  >
                    {t('receiveUpdates')}
                  </Typography>
                }
              />
            </Stack>
            <Stack sx={{ flexBasis: '50%', gap: '32px', pl: '32px' }}>
              <Box>
                <RegisterInput
                  value={registerData.name}
                  onBlur={validateName}
                  onChange={handleRegisterDataChange}
                  error={errorState.name}
                  name="name"
                  required
                  label={`${t('name')}*`}
                  disabled={isFillMissingDataMessage}
                />
                {errorState.name && (
                  <Typography
                    sx={{
                      mt: '8px',
                      color: '#F00',
                      fontFamily: 'Roboto',
                      fontSize: '18px',
                      lineHeight: '130%',
                      letterSpacing: '0.18px',
                    }}
                  >
                    Wrong name
                  </Typography>
                )}
              </Box>

              <Box>
                <Typography
                  noWrap
                  sx={{
                    color: '#242731',
                    fontFamily: 'Roboto',
                    fontSize: '18px',
                    lineHeight: '130%',
                    letterSpacing: '0.18px',
                    mb: '8px',
                  }}
                >
                  {t('country')}*
                </Typography>
                <Autocomplete
                  size="small"
                  sx={{
                    fieldset: {
                      borderColor: errorState.country ? '#F00' : 'rgba(177, 177, 177, 1)',
                      borderRadius: '8px',
                    },
                    '& .MuiInputBase-input': {
                      height: '27px',
                      py: 0,
                    },
                  }}
                  disablePortal
                  onBlur={validateCountry}
                  options={REGISTRATION_COUNTRIES}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField {...params} required label={''} InputLabelProps={{ shrink: true }} />
                  )}
                  value={registerData.country}
                  onChange={(_, value) => {
                    handleAutocompleteChange('country', value ?? '');
                  }}
                />
                {errorState.country && (
                  <Typography
                    sx={{
                      mt: '8px',
                      color: '#F00',
                      fontFamily: 'Roboto',
                      fontSize: '18px',
                      lineHeight: '130%',
                      letterSpacing: '0.18px',
                    }}
                  >
                    Wrong country
                  </Typography>
                )}
              </Box>
              <Box>
                <Typography
                  noWrap
                  sx={{
                    color: '#242731',
                    fontFamily: 'Roboto',
                    fontSize: '18px',
                    lineHeight: '130%',
                    letterSpacing: '0.18px',
                    mb: '8px',
                  }}
                >
                  {t('occupation')}
                </Typography>
                <Autocomplete
                  size="small"
                  disablePortal
                  sx={{
                    '& .MuiInputBase-input': {
                      height: '27px',
                      py: 0,
                    },
                    fieldset: { borderRadius: '8px' },
                  }}
                  options={REGISTRATION_OCCUPATION}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => <TextField {...params} label={''} InputLabelProps={{ shrink: true }} />}
                  value={registerData.occupation}
                  onChange={(_, value) => {
                    handleAutocompleteChange('occupation', value ?? '');
                  }}
                />
              </Box>
            </Stack>
          </Box>

          {/* <Typography
            sx={{
              color: '#1E1E1E',
              textAlign: 'center',
              fontFamily: 'Roboto',
              fontSize: '25px',
              lineHeight: '180%',
              letterSpacing: '0.5px',
            }}
          >
            {t('by_submitting_you_accept_the')}
            <Link sx={{ cursor: 'pointer', mx: 1 }}>{t('terms_and_conditions')}</Link>
            {t('interactiveSubmainCalc1-9')}
            <Link onClick={toggle} sx={{ cursor: 'pointer', mx: 1 }}>
              {t('end_user_license_agreement_0')}
            </Link>
          </Typography> */}

          <Box sx={{ display: 'flex', gap: '24px', justifyContent: 'flex-end' }}>
            <Button
              onClick={handleBackClick}
              sx={{
                width: '120px',
                height: '48px',
                padding: '0px 32px',
                color: '#242731',
                textAlign: 'center',
                fontFamily: 'Roboto',
                fontSize: '18px',
                fontWeight: 600,
                lineHeight: '130%',
                letterSpacing: '0.18px',
                background: 'transparent',
              }}
            >
              {t('cancel')}
            </Button>
            <LoadingButton
              sx={{
                color: '#FFF',
                textAlign: 'center',
                fontFamily: 'Roboto',
                fontSize: '18px',
                fontWeight: 600,
                lineHeight: '130%',
                letterSpacing: '0.18px',
                width: '120px',
                height: '48px',
                padding: '0px 24px',
                borderRadius: '8px',
                textTransform: 'none',
              }}
              onClick={handleSubmitClick}
              loading={loading}
              variant="contained"
              color="primary"
            >
              {t('submit')}
            </LoadingButton>
          </Box>
        </Stack>
      </Box>

      <SuccessfulRegister
        open={openSuccessfulRegister}
        handleClose={handleSuccessfulRegister}
        backToLogin={() => navigate('/login')}
      />

      <EmailAlreadyRegisteredModal
        show={isEmailAlreadyRegisteredModal}
        onClose={() => setIsEmailAlreadyRegisteredModal(false)}
        mergeData={{ ...body, oldUserName }}
      />

      <EndUserLicense open={open} onAccept={toggle} onDoNotAccept={toggle} />
    </>
  );
};
