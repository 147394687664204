export function CalculateEU(
  arrFlow: number[],
  count: number,
  totalFlow: number,
  calcType: string,
  cvv: number,
) {
  if (arrFlow.length <= 0 || count <= 0) {
    return 0.0;
  }
  let aveFlow = totalFlow / count;
  let lstFlow = [];
  for (let i = 0; i <= count - 1; i++) {
    lstFlow.push(arrFlow[i]);
  }
  var sortedlstFlow = lstFlow.sort((a, b) => a - b);

  switch (calcType) {
    case 'Classic': {
      //Classic
      //  eu = 100 * (1 - 1.27 * cvv) * (minFlow / avgFlow)
      var dvd = sortedlstFlow[0] / aveFlow;
      let res = 100 * (1 - 1.27 * cvv) * dvd;
      return res;
    }
    case 'Quarter': {
      //Quarter
      let totalQuarterFlow = 0.0;
      for (let i = 0; i <= count / 4 - 1; i++) {
        totalQuarterFlow += arrFlow[i];
      }

      if (count < 4) {
        return 100;
      }

      let minFlow = totalQuarterFlow / (count / 4);
      return 100 * ((minFlow / aveFlow) * (1 - 1.27 * cvv));
    }
    case 'Standard': {
      //Standard Dev.
      let variance = 0.0;
      for (let i = 0; i <= count - 1; i++) {
        variance += Math.pow(aveFlow - arrFlow[i], 2);
      }

      let sd = Math.sqrt(variance / count);
      return 100 * (1 - sd / aveFlow);
    }
  }
  return 0;
}
