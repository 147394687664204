import { FC } from 'react';
import { Box, IconButton, Stack, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import Filter9PlusIcon from '@mui/icons-material/Filter9Plus';
import TimerIcon from '@mui/icons-material/Timer';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ParkIcon from '@mui/icons-material/Park';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { setEDCStateToolBar } from 'shared/slices';
import { useTranslation } from 'react-i18next';
import { ShepherdBlock } from 'shared/ui';
import { RangeIcon } from 'shared/assets';

export const Tools: FC = () => {
  const dispatch = useAppDispatch();
  const { palette } = useTheme();
  const isSmall = useMediaQuery('(max-width:600px)');

  const { t } = useTranslation();
  const {
    edcToolBar: { showNumbers, showRange, showPlants, showSectionGraph, displayPlants, showZoom },
  } = useAppSelector((st) => st.edcState);

  const toggleNumbers = () => dispatch(setEDCStateToolBar({ showNumbers: !showNumbers }));
  const toggleRange = () => dispatch(setEDCStateToolBar({ showRange: !showRange }));
  const togglePlants = () => dispatch(setEDCStateToolBar({ showPlants: !showPlants }));
  const toggleSectionGraph = () => dispatch(setEDCStateToolBar({ showSectionGraph: !showSectionGraph }));
  // const toggleGrowth = () => dispatch(setEDCStateToolBar({ showGrowth: !showGrowth }));
  const toggleZoom = () => dispatch(setEDCStateToolBar({ showZoom: !showZoom }));
  // const toggleTimer = () => dispatch(setEDCStateToolBar({ showTimer: !showTimer }));
  const toggleDisplayPlants = () => dispatch(setEDCStateToolBar({ displayPlants: !displayPlants }));

  return (
    <Stack
      alignItems="center"
      justifyContent={isSmall ? 'center' : 'flex-start'}
      ml={isSmall ? 0 : -1}
      flexGrow={1}
      direction={isSmall ? 'row' : 'column'}
      sx={{
        borderRight: isSmall ? 'none' : `1px solid ${palette.info.light}`,
        borderBottom: isSmall ? `1px solid ${palette.info.light}` : 'none',

        width: isSmall ? '100%' : '65px',
        pt: isSmall ? 0 : '30px',
        mb: isSmall ? 2 : 0,
        minHeight: isSmall ? 'auto' : '100%',
        overflowX: 'auto',
        overflowY: 'hidden',
      }}
    >
      <Box>
        <ShepherdBlock id="edc-numbers">
          <Tooltip title={t('numbers')} placement="left">
            <IconButton onClick={toggleNumbers}>
              <Filter9PlusIcon />
            </IconButton>
          </Tooltip>
        </ShepherdBlock>
      </Box>
      <Box>
        <ShepherdBlock id="edc-range">
          <Tooltip title={t('range')} placement="left">
            <IconButton onClick={toggleRange}>
              <RangeIcon />
            </IconButton>
          </Tooltip>
        </ShepherdBlock>
      </Box>
      <Box>
        <ShepherdBlock id="edc-plants">
          <Tooltip title={t('plants')} placement="left">
            <IconButton
              onClick={displayPlants ? toggleDisplayPlants : togglePlants}
              sx={{ ...(displayPlants && { background: 'rgba(0, 0, 0, 0.08)' }) }}
            >
              <ParkIcon />
            </IconButton>
          </Tooltip>
        </ShepherdBlock>
      </Box>
      <Box>
        <ShepherdBlock id="edc-section-graph">
          <Tooltip title={t('sectionGraph')} placement="left">
            <IconButton onClick={toggleSectionGraph}>
              <EqualizerIcon />
            </IconButton>
          </Tooltip>
        </ShepherdBlock>
      </Box>
      <Box>
        <ShepherdBlock id="edc-growth">
          <Tooltip /*title={t('growth')}*/ title="Coming soon" placement="left">
            <IconButton
              //onClick={toggleGrowth}
              sx={{ opacity: 0.5 }}
              disableFocusRipple
              disableRipple
              disableTouchRipple
            >
              <TrendingUpIcon />
            </IconButton>
          </Tooltip>
        </ShepherdBlock>
      </Box>
      <Box>
        <ShepherdBlock id="edc-zoom">
          <Tooltip title={t('zoom')} placement="left">
            <IconButton onClick={toggleZoom}>
              <ZoomInIcon />
            </IconButton>
          </Tooltip>
        </ShepherdBlock>
      </Box>
      <Box>
        <ShepherdBlock id="edc-timer">
          <Tooltip /*title={t('timer')}*/ title="Coming soon" placement="left">
            <IconButton
              //onClick={toggleTimer}
              sx={{ opacity: 0.5 }}
              disableFocusRipple
              disableRipple
              disableTouchRipple
            >
              <TimerIcon />
            </IconButton>
          </Tooltip>
        </ShepherdBlock>
      </Box>
    </Stack>
  );
};
