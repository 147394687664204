import React, { useEffect, useRef } from 'react';
import { RangeItem } from 'shared/lib/calculation/models';

interface CanvasRangeProps {
  item: RangeItem;
  key: number;
}

export const CanvasRange: React.FC<CanvasRangeProps> = ({ item }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    canvas.width = item.height;
    canvas.height = item.width;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;

    ctx.strokeStyle = '#FF0000';
    ctx.beginPath();
    ctx.arc(centerX, centerY, canvas.height / 2, 0, 2 * Math.PI);

    ctx.stroke();
  }, [item]);

  return (
    <canvas
      style={{
        position: 'absolute',
        top: item.top,
        left: item.left,
      }}
      ref={canvasRef}
    />
  );
};
