import { FC } from 'react';
import { TableRow as MuiTableRow, TableRowProps as MuiTableRowProps } from '@mui/material';

interface TableRowProps extends MuiTableRowProps {
  withBg?: boolean;
}

export const TableRow: FC<TableRowProps> = ({ withBg, children }) => {
  return (
    <MuiTableRow
      sx={{
        td: {
          fontSize: '15px',
          border: 0,
          p: '3px 12px',
          '&:first-of-type': { borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px' },
          '&:last-of-type': { borderTopRightRadius: '10px', borderBottomRightRadius: '10px' },
        },
        ...(withBg && { backgroundColor: '#DAE9E2' }),
      }}
    >
      {children}
    </MuiTableRow>
  );
};
