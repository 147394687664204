import {
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import FolderIcon from '@mui/icons-material/Folder';
import {
  deleteProjectThunk,
  getProjectDataThunk,
  getProjectsThunk,
  setCurrentProject,
  toggleOpenProject,
} from 'shared/slices';
import { useAppDispatch, useAppSelector, useToast } from 'shared/hooks';
import { Modal } from 'shared/ui';
import { SearchBar } from './components';
import { useMemo, useState } from 'react';
import { parseDate } from 'shared/lib';
import { Project } from 'shared/models';
import { useTranslation } from 'react-i18next';

export const OpenProject = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { showSuccess, showError } = useToast();
  const { openProject } = useAppSelector((st) => st.modals);
  const { projects, currentProject } = useAppSelector((state) => state.projects);

  const [searchValue, setSearchValue] = useState('');
  const [deleteProjectId, setDeleteProjectId] = useState(-1);

  const onSearchValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const filteredProjects = useMemo(
    () => projects.filter((project) => project.projectName.toLowerCase().includes(searchValue.toLowerCase())),
    [searchValue, projects],
  );

  const toggle = () => dispatch(toggleOpenProject());

  const handleItemClick = (project: Project) => {
    dispatch(setCurrentProject(project));
    dispatch(getProjectDataThunk({ projectId: project.projectID }));
    toggle();
  };

  const handleDeleteClick = async (projectId: number) => {
    if (!projectId) return;

    const userId = localStorage.getItem('ht-user-id');

    if (!userId) return;

    setDeleteProjectId(projectId);

    const result = await dispatch(deleteProjectThunk({ projectId })).unwrap();

    if (result?.data) showSuccess(t('projectDeleted'));
    if (!result?.data) showError(t('SomethingWentWrong'));

    setDeleteProjectId(-1);

    if (projectId === currentProject.projectID) {
      await dispatch(getProjectsThunk());
    }
  };

  return (
    <Modal title={t('openProject')} open={openProject} onClose={toggle}>
      <SearchBar value={searchValue} onChange={onSearchValueChange} sx={{ mb: 1 }} />
      <List
        sx={{
          height: '500px',
          overflow: 'auto',
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'primary.main',
          },
          '&::-webkit-scrollbar': {
            width: '6px',
            backgroundColor: '#F5FBFF',
          },
        }}
      >
        {filteredProjects.map((project) => (
          <ListItem
            onClick={() => handleItemClick(project)}
            disableGutters
            key={project.projectID}
            sx={{
              p: 0,
              cursor: 'pointer',
              '&:hover': {
                bgcolor: 'action.hover',
              },
            }}
          >
            <ListItemIcon sx={{ minWidth: 40, pl: 1 }}>
              <FolderIcon color='primary' />
            </ListItemIcon>
            <ListItemText
              sx={{ mr: '35px' }}
              primary={
                <Typography noWrap variant='subtitle2'>
                  {project.projectName}
                </Typography>
              }
              secondary={
                <Typography noWrap variant='body2'>{`Last save: ${
                  project.lastSave ? parseDate(project.lastSave) : new Date().toISOString().split('T')[0]
                }`}</Typography>
              }
            />
            <ListItemSecondaryAction>
              <IconButton
                onClick={() => handleDeleteClick(project.projectID)}
                disabled={deleteProjectId === project.projectID}
              >
                {deleteProjectId === project.projectID ? (
                  <CircularProgress size={23} />
                ) : (
                  <DeleteIcon color='primary' />
                )}
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Modal>
  );
};
