export const textFormatter = (template: string, args: string[] | number[]) => {
    let formatted = template;
  
    const regexp = new RegExp(/%\w+%/g);
    const matchResult = formatted.match(regexp);
    const inputData: {
      [value: string]: string | number;
    } = {};
  
    if (matchResult && matchResult.length > 0) {
      for (let i = 0; i < matchResult.length; i++) {
        formatted = formatted.replace(matchResult[i], `${args[i]}`);
        const newLocal = matchResult[i].replace(/%/gi, '');
        inputData[newLocal] = args[i];
      }
      return { output: formatted, input: inputData, template };
    }
    for (let i = 0; i < args.length; i++) {
      inputData[i + 1] = args[i];
    }
    for (let i = 0; i < args.length; i++) {
      const regexp = new RegExp(`\\{{${i + 1}\\}}`, 'gi');
      formatted = formatted.replace(regexp, `${args[i]}`);
    }
    return { output: formatted, input: inputData, template };
  };
  