import { METRIC_DEFAULTS } from 'shared/constants';
import { Unit } from 'shared/models';
import { CalculateEU } from './calculateEU';
import { CalculateHeadloss } from './calculateHeadloss';
import { Math_round } from './mathRound';
import { ConvertUnit } from './unitConverter';

export function CalculateLaterals(
  isHW: number,
  HWCoeff: number,
  dia: number,
  A: number,
  B: number,
  Kd: number,
  Length: number,
  spacing: number,
  slopes: any[],
  pInlet: number,
  pMin: number,
  pMax: number,
  cvv: number,
  calcType: string,
  flushVelocity: number,
  arrQ: number[],
  arrVelocity: number[],
  arrPressure: number[],
  arrEU: number[],
  arrHeadloss: number[],
  PRA: number,
  PRB: number,
  PRC: number,
  minEU: number,
  maxQDelta: number,
  bCheckLimit: boolean,
  unitSettings: Unit,
  MaxLengthCalcMethod: string,
) {
  PRA = Math.fround(PRA);
  PRB = Math.fround(PRB);
  PRC = Math.fround(PRC);
  let result = {};
  let originalpMin = pMin;
  // Flush velocity
  let QFlush = flushVelocity * ((Math.PI * dia * dia * Math.pow(10, -6)) / 4) * 3600 * 1000;
  let smaller = 0;
  let bigger = 0;
  //lateralFlow = averageFlow = minFlow = maxFlow = minPr = maxPr = eu = 0.0;

  let MaxQDelata = 0.0;
  // let redoPmin = pMin;
  MaxQDelata = maxQDelta;
  let iter = 0;
  let minPressure = Number.MAX_VALUE;
  let maxPressure = Number.MIN_VALUE;
  let spacings = 0;
  /*arrQ = [];
  arrVelocity = [];
  arrPressure = [];
  arrHeadloss = [];
  arrEU = [];*/
  let PreviousInletP = 0;
  let redo = false;
  let bSkipWhile = false;
  while (iter < 10) {
    //console.log(iter);
    let redoPinlet = false;
    if (MaxLengthCalcMethod === 'Minimum EU') {
      let res = Math.round(Length / spacing);
      spacings = parseInt(res.toString());
    } else {
      let res = Math.floor(Length / spacing);
      spacings = parseInt(res.toString());

    }

    let arrHeight = [];
    let arrQAcc = [];
    arrQ.length = 0;
    arrVelocity.length = 0;
    arrPressure.length = 0;
    arrHeadloss.length = 0;
    arrEU.length = 0;

    arrPressure[0] = pMin;
    let i = 0;
    let minFlow = Number.MAX_VALUE;
    let maxFlow = Number.MIN_VALUE;
    if (slopes.length > 0) {
      let slopeIndex = 0;
      let slope = slopes[0].Slope;
      if (slopes[0].SlopeDirection == 'Downhill') slope = slope * -1;
      let len = ConvertUnit(slopes[slopeIndex].Length, unitSettings.length, METRIC_DEFAULTS.Length, null);
      let totalLength = len;

      //totalLength = UnitConverter.ConvertUnit(totalLength, unitSettings.Length, unitSettings.LengthMetric, Nothing)
      for (i = 0; i <= spacings - 1; i++) {
        if (i * spacing >= totalLength) {
          if (slopeIndex < slopes.length - 1) {
            slopeIndex += 1;
            len = ConvertUnit(slopes[slopeIndex].Length, unitSettings.length, METRIC_DEFAULTS.Length, null);
            //len = UnitConverter.ConvertUnit(len, unitSettings.Length, unitSettings.LengthMetric, Nothing)
            totalLength += len;
            slope = slopes[slopeIndex].Slope;
            if (slopes[slopeIndex].SlopeDirection == 'Downhill') {
              slope = slope * -1;
            }
          } else {
            slope = 0;
          }
        }
        arrHeight[i] = (slope / 100.0) * spacing;
      }
    }

    arrQ[0] = arrQAcc[0] = A * Math.pow(arrPressure[0], B);
    arrEU[0] = 0;
    arrVelocity[0] = 0;
    arrHeadloss[0] = CalculateHeadloss(
      isHW,
      Math.round(HWCoeff),
      (arrQAcc[0] + QFlush) / 1000,
      dia,
      spacing,
      Kd,
      PRA,
      PRB,
      PRC,
    );
    let recalculate = false;
    minFlow = arrQ[0];
    let oldcaleu = 0;

    for (i = 1; i <= spacings - 1; i++) {
      let _arrHeight = arrHeight[i - 1] ? arrHeight[i - 1] : 0;
      let _arrPressure = arrPressure[i - 1] ? arrPressure[i - 1] : 0;
      let _arrHeadloss = arrHeadloss[i - 1] ? arrHeadloss[i - 1] : 0;
      arrQ[i] = A * Math.pow(_arrPressure + _arrHeadloss + _arrHeight, B);
      if (arrQ[i] < minFlow) {
        minFlow = arrQ[i];
      }
      if (arrQ[i] > maxFlow) {
        maxFlow = arrQ[i];
      }
      arrQAcc[i] = arrQAcc[i - 1] + arrQ[i];
      arrHeadloss[i] = CalculateHeadloss(
        isHW,
        Math.round(HWCoeff),
        (arrQAcc[i] + QFlush) / 1000,
        dia,
        spacing,
        Kd,
        PRA,
        PRB,
        PRC,
      );
      arrPressure[i] = _arrPressure + _arrHeadloss + _arrHeight;
      if (bCheckLimit == false) {
        if (redoPinlet == false) {
          if (redo == false && arrPressure[i] <= pMax && i > 1) {
            if (arrPressure[i] < originalpMin - 0.01 && arrPressure[0] < pMax) {
              pMin = arrPressure[0] + originalpMin - arrPressure[i];
              //  arrPressure(0) = 4
              i = 1;
              recalculate = true;
              iter = 1;
              break; // TODO: might not be correct. Was : Exit For
            }
          } else {
            redo = false;
          }
        }
      }
      if (arrPressure[i] == 0) {
        // showalert('pressure 0');
        return { error: 'pressure 0' };
      }
      if (minPressure > arrPressure[i]) {
        minPressure = arrPressure[i];
      }
      if (maxPressure < arrPressure[i]) {
        maxPressure = arrPressure[i];
      }
      if (pInlet == 0) {
        if (arrPressure[i] < arrPressure[i - 1] && arrPressure[i] + 0.01 < originalpMin) {
          recalculate = true;
          pMin = pMin + originalpMin - arrPressure[i];
          arrQ.length = 0;
          arrQAcc.length = 0;
          arrHeadloss.length = 0;
          arrVelocity.length = 0;
          minPressure = Number.MAX_VALUE;
          maxPressure = Number.MIN_VALUE;
          break; // TODO: might not be correct. Was : Exit For
        } else {
          recalculate = false;
        }
      } else {
        if (bCheckLimit) {
          let lastPressure = arrPressure[i];
          if (lastPressure <= pInlet) {
            if (Math.abs(lastPressure - pInlet) > 0.01) {
              PreviousInletP = lastPressure;
              redoPinlet = true;
              pMin += pInlet - lastPressure;
              recalculate = true;
              break; // TODO: might not be correct. Was : Exit For
            } else {
              redoPinlet = false;
              recalculate = false;
            }
          }
        }
      }
      arrVelocity[i] = (arrQAcc[i] / 1000 + QFlush / 1000) / 3600 / ((Math.PI * dia * dia * Math.pow(10, -6)) / 4);
      arrEU[i] = CalculateEU(arrQ, i + 1, arrQAcc[i], calcType, cvv);
      var qDelta = (1 - minFlow / maxFlow) * 100;
      if (bCheckLimit) {
        switch (MaxLengthCalcMethod) {
          case 'Minimum EU':
            if (B !== 0) {
              if ((oldcaleu > minEU && arrEU[i] < minEU) || (oldcaleu < minEU && arrEU[i] > minEU && oldcaleu != 0)) {
                //Added 01-05-14 - Start
                //if (oldcaleu > minEU && arrHeight[i] != 0)
                let round = 0;
                if (slopes.length > 0) {
                  round = Math.floor(i * spacing);
                } else {
                  round = Math.round(i * spacing);
                }

                return round;
              }
              oldcaleu = arrEU[i];
            } else {
              //Or arrVelocity(i) > 3 Then
              if (maxPressure - minPressure > pMax - originalpMin) {
                return Math.round(i * spacing);
              }
            }
            break;
          case 'Q(delta)':
            if (qDelta > maxQDelta + 2) {
              return Math.round(i * spacing);
            }
            break;
          case 'Pressure':
            if (pInlet == 0) {
              //Or arrVelocity(i) > 3 Then
              if (maxPressure - minPressure > pMax - originalpMin) {
                return Math.round(i * spacing);
              }
            } else {
              //Or arrVelocity(i) > 3 Then
              if (maxPressure - minPressure > pInlet - originalpMin) {
                return Math.round((i + 1) * spacing);
              }
            }
            break;
        }
      }
    }
    if (recalculate) {
      minPressure = Number.MAX_VALUE;
      maxPressure = Number.MIN_VALUE;
      continue;
    }
    minPressure = Number.MAX_VALUE;
    maxPressure = Number.MIN_VALUE;
    if (pInlet > 0) {
      if (bCheckLimit == false) {
        let lastPressure = arrPressure[i - 1];
        //Kiran : Problem here
        if (Math.abs(lastPressure - pInlet) > 0.01 && Math.abs(PreviousInletP - lastPressure) > 0.01) {
          PreviousInletP = lastPressure;
          redo = true;
          // pMin = redoPmin
          pMin += (pInlet - lastPressure) / 2;
          // redoPmin = pMin
          if (pMin < 0) {
            redo = false;
            pMin = originalpMin;
            // redoPmin = pMin;
            Length = spacing * (spacings - 1);
          }
        }
      } else {
        if (MaxLengthCalcMethod === 'Pressure') {
          var min = 999999;
          var max = -999999;
          for (var z = 0; z < arrPressure.length; z++) {
            if (arrPressure[z] < min) min = arrPressure[z];
            if (arrPressure[z] > max) max = arrPressure[z];
          }
          if (min < originalpMin - 0.001) {
            pMin += (originalpMin - min) / 3;
            Length = spacing * (spacings - 1);
            redo = true;
          }
        }
      }
    }
    if (redo == false) {
      let lstPressures = [];
      lstPressures = [...arrPressure];
      lstPressures.sort((a, b) => a - b);
      let min = lstPressures[0];
      let max = lstPressures[lstPressures.length - 1];
      let totalQuarterFlow = 0;
      let arrSortedQ = [0, ...arrQ];
      // arrSortedQ[0] = 0;
      // for (let x = 1; x < arrQ.length; x++) arrSortedQ[x] = arrQ[x - 1];
      // arrSortedQ = [...arrSortedQ, arrQ];
      arrSortedQ.sort((a, b) => a - b);
      bSkipWhile = false;
      let delta = 0.1;
      switch (MaxLengthCalcMethod) {
        case 'Minimum EU': {
          let avgFlow = arrQAcc[i - 1] / i;
          let eu = 0.0;

          switch (calcType) {
            case 'Classic': {
              //EuCalcType.Classic:
              eu = 100 * (1 - 1.27 * cvv) * (minFlow / avgFlow);

              break;
            }
            case 'Quarter': {
              //EuCalcType.Quarter:
              for (let k = 0; k <= i / 4 - 1; k++) {
                totalQuarterFlow += arrSortedQ[k];
              }

              if (i < 4) {
                eu = 100;
              }
              let minQuarterFlow = totalQuarterFlow / (i / 4);
              eu = 100 * ((minQuarterFlow / avgFlow) * (1 - 1.27 * cvv));

              break;
            }
            case 'Standard': {
              //EuCalcType.StandardDeviation:
              //Standard Dev.
              let variance = 0.0;
              for (let k = 0; k <= i - 1; k++) {
                variance += Math.pow(avgFlow - arrQ[k], 2);
              }

              let sd = Math.sqrt(variance / i);
              eu = 100 * (1 - sd / avgFlow);
              break;
            }
          }
          //mineu+delta
          if (minEU > 0 && eu > minEU) {
            Length = spacing * (spacings + 1);
            if (bigger == Length) {
              bSkipWhile = true;
              break; // TODO: might not be correct. Was : Exit While
            }
            bigger = Length;
            // mineu- delta
          } else if (minEU > 0 && eu < minEU) {
            //smalleratep=Int((minEU - eu) / 0.02)
            Length = spacing * (spacings - 1);
            if (smaller == Length) {
              bSkipWhile = true;
              break; // TODO: might not be correct. Was : Exit While
            }
            smaller = Length;
          } else {
            bSkipWhile = true;
            break; // TODO: might not be correct. Was : Exit While
          }

          if (Length <= 0) {
            // showalert('msgNoMaxLen');
            return { error: 'msgNoMaxLen' };
          }
          break;
        }
        case 'Q(delta)': {
          let minQ = arrSortedQ[1];
          let maxQ = arrSortedQ[spacings - 1];
          let QDelta = (1 - minQ / maxQ) * 100.0;
          if (QDelta != 0 && QDelta < MaxQDelata - delta) {
            if (bigger == 1) {
              Length = ConvertUnit(Length, METRIC_DEFAULTS.Length, unitSettings.length, null);
              result = { ...result, MaxLateralLength: Math_round(Length, 3) };
              bSkipWhile = true;
              break; // TODO: might not be correct. Was : Exit While
            } else {
              smaller = 1;
              bigger = 0;
            }
            Length = spacing * (spacings + 1);
          }
          if (QDelta != 0 && QDelta > MaxQDelata + delta) {
            if (smaller == 1) {
              Length = ConvertUnit(Length, METRIC_DEFAULTS.Length, unitSettings.length, null);
              result = { ...result, MaxLateralLength: Math_round(Length, 3) };
              bSkipWhile = true;
              break; // TODO: might not be correct. Was : Exit While
            } else {
              bigger = 1;
              smaller = 0;
            }
            Length = spacing * (spacings - 1);
          } else {
            Length = ConvertUnit(Length, METRIC_DEFAULTS.Length, unitSettings.length, null);
            result = { ...result, MaxLateralLength: Math_round(Length, 3) };
            bSkipWhile = true;
            break; // TODO: might not be correct. Was : Exit While
          }
          break;
        }
        case 'Pressure':
          for (var j = 1; j <= spacings - 1; j++) {
            if (arrPressure[j] < min || arrPressure[j] > max) {
              Length = spacing * j;
              break; // TODO: might not be correct. Was : Exit For
            }
          }

          Length = ConvertUnit(Length, METRIC_DEFAULTS.Length, unitSettings.length, null);
          result = { ...result, MaxLateralLength: Math_round(Length, 3) };
          bSkipWhile = true;
          break; // TODO: might not be correct. Was : Exit While
      }
    }
    if (bSkipWhile == true) {
      break;
    }
  }
  return Math.round(spacings * spacing);
}
