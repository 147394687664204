import { Button } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { Input, Modal } from 'shared/ui';
import { calculatePowerOutput, calculatePowerUnit } from './lib';
import { toggleElectricCalculator } from 'shared/slices';
import { formattedInputValue } from 'shared/lib';

export const ElectricCalculator = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { openElectricCalc } = useAppSelector((st) => st.modals);
  const { units } = useAppSelector((st) => st.units);

  const [values, setValues] = useState({ voltage: 240, current: 30, powerFactor: 80, efficiency: 70, power: 0 });

  const handleValuesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues((prev) => ({ ...prev, [e.target.name]: formattedInputValue(e) }));
  };

  const toggle = () => dispatch(toggleElectricCalculator());

  const handleCalculateClick = () => {
    const { current, efficiency, powerFactor, voltage } = values;

    const { power } = calculatePowerOutput({ current, efficiency, powerFactor, voltage }, units);

    setValues((prev) => ({ ...prev, power }));
  };

  return (
    <Modal maxWidth="xs" title={t('electricCalculator')} open={openElectricCalc} onClose={toggle}>
      <Box>
        <Input
          value={values.voltage}
          onChange={handleValuesChange}
          fullWidth
          type="number"
          name="voltage"
          label={`${t('voltage')} (Volts)`}
        />
        <Input
          value={values.current}
          onChange={handleValuesChange}
          fullWidth
          type="number"
          name="current"
          label={`${t('current')} (Amps)`}
        />
        <Input
          value={values.powerFactor}
          onChange={handleValuesChange}
          fullWidth
          type="number"
          name="powerFactor"
          label={`${t('pf')} (%)`}
        />
        <Input
          value={values.efficiency}
          onChange={handleValuesChange}
          fullWidth
          type="number"
          name="efficiency"
          label={`${t('efficiency')} (%)`}
        />
        <Input
          fullWidth
          value={values.power}
          name="power"
          label={`${t('power')} ${calculatePowerUnit(units.totalFlow)}`}
          isResultBox
        />
        <Box display="flex" columnGap={1} mt={2}>
          <Button onClick={handleCalculateClick} variant="contained" sx={{ flexBasis: '50%' }}>
            {t('calculate')}
          </Button>

          <Button onClick={toggle} variant="outlined" sx={{ flexBasis: '50%' }}>
            {t('cancel')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
