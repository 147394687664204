import { SetPlants } from './SetPlants';

export function SetRows(
  RowCount: number,
  Top: number,
  BetweenPlants: number,
  StaggeredRows: boolean,
  PlantDiameter: number,
  Left_Renamed: number,
  dYPOSITION: number,
) {
  let _with1 = [];
  for (let NewX = 0; NewX <= RowCount - 1; NewX++) {
    let RowLength = dYPOSITION;

    let Rows = {
      Top: Top,
      RowLength: RowLength,
      BetweenPlants: BetweenPlants,
      Left: Left_Renamed + NewX * BetweenPlants,
      Plants: SetPlants(
        StaggeredRows,
        Top,
        PlantDiameter,
        BetweenPlants,
        RowLength,
        Left_Renamed + NewX * BetweenPlants,
      ),
    };
    _with1.push(Rows);
  }
  return _with1;
}
