import React, { useEffect, useRef } from 'react';
import { Points } from 'shared/lib/calculation/models';

interface CanvasEdgesProps {
  lines: Points[];
  width: number;
  height: number;
  edge: number;
}

export const CanvasEdges: React.FC<CanvasEdgesProps> = ({ lines, width, height, edge }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    canvas.width = width;
    canvas.height = height;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.lineWidth = 3;

    lines.forEach((line) => {
      ctx.strokeStyle = '#0000FF';
      ctx.beginPath();

      ctx.moveTo(line.Y1, line.X1);
      ctx.lineTo(line.Y2, line.X2);

      ctx.stroke();
    });
  }, [lines, width, height]);

  return (
    <canvas
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 20,
      }}
      ref={canvasRef}
    />
  );
};
