import { Math_round } from 'shared/lib/calculation/mathRound';
import { ConvertUnit } from 'shared/lib/calculation/unitConverter';
import { Unit } from 'shared/models';

export const calculatePowerUnit = (totalFlowUnit: string) => {
  if (totalFlowUnit === 'm³/h' || totalFlowUnit === 'lph' || totalFlowUnit === 'lps' || totalFlowUnit === 'lpm') {
    return '(Kw)';
  } else {
    return '(Hp)';
  }
};

type Values = {
  flow: number;
  head: number;
  efficiency: number;
};

export const calculateWaterPower = (values: Values, units: Unit) => {
  let powerConv;

  if (
    units.totalFlow === 'm³/h' ||
    units.totalFlow === 'lph' ||
    units.totalFlow === 'lps' ||
    units.totalFlow === 'lpm'
  ) {
    powerConv = 1.0;
  } else {
    powerConv = 1.341;
  }

  const flow = ConvertUnit(values.flow, units.totalFlow, 'm³/h', '');
  const head = ConvertUnit(values.head, units.pressure, 'm', '');

  const efficiency = values.efficiency;

  const power = (flow * head * powerConv * 100.0) / efficiency / 368.0;

  return { power: Math_round(power, 3) };
};
