import { Box, useMediaQuery } from '@mui/material';
import { FC } from 'react';
import { lateral_input, pipe_dia, submain_bg } from 'shared/assets';
import { IconLabel, RadioSwitcher, ResetButton, Row, ShepherdBlock } from 'shared/ui';
import {
  AdvancedOptionsBlock,
  DefineSlopesBlock,
  InputBlock,
  LateralInputBlock,
  ResultsBlock,
  SelectProductBlock,
  SimulationBlock,
} from './components';
import { setProjectValue, setSubmainErrorValue, setSubmainStateValue } from 'shared/slices';
import { useAppDispatch, useAppSelector, useSubmainFilters } from 'shared/hooks';
import { FilterManifoldCatalogs } from 'shared/lib/calculation/ManifoldDesign/FilterManifoldCatalogs';
import { calculateNumOfRows, calculateTotalLength, formattedInputValue } from 'shared/lib';
import { Math_round } from 'shared/lib/calculation/mathRound';
import { ConvertUnit } from 'shared/lib/calculation/unitConverter';
import { MainControlBlock } from 'features';
import { useTranslation } from 'react-i18next';

export const Submain: FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { submainValues } = useAppSelector((state) => state.submainState);
  const { projectData } = useAppSelector((state) => state.projectData);
  const { units } = useAppSelector((state) => state.units);
  const isTablet = useMediaQuery('(max-width:850px)');

  const submainSwitchValues = [t('calculateLength'), t('submainSimulation')];

  const handleToggleCalcLength = () => dispatch(setSubmainStateValue({ isCalcLength: !submainValues.isCalcLength }));

  const {
    masterGroups,
    masterGroupId,
    onMasterGroupChange,
    submainSubtype,
    onGroupTypeChange,
    classType,
    onClassTypeChange,
    classTypes,
    submainPipe1,
    submainPipe2,
    submainPipe3,
    submainsFiltered,
    submainsLoading,
    submainGroupsLoading,
    onSubmain1Change,
    onSubmain2Change,
    onSubmain3Change,
  } = useSubmainFilters();

  const onMaxVelocityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setProjectValue({ manifoldMaximumVelocity: formattedInputValue(e) }));

    if (!classType) return;
    const currentPipes = FilterManifoldCatalogs({
      maxVelocity: +e.target.value,
      manifoldClass: classType.toString(),
      totalFlow: +projectData.totalFlow,
      cbManifoldPipe: submainsFiltered,
      units,
    });

    if (!currentPipes) return;

    dispatch(
      setProjectValue({
        manifoldPipe1: currentPipes.manifoldPipe1Selected,
        manifoldPipe2: currentPipes.manifoldPipe2Selected,
        manifoldPipe3: currentPipes.manifoldPipe3Selected,
      })
    );
  };

  const onNumberOfRowsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setProjectValue({ totalRows: formattedInputValue(e) }));
    const convertedSpacing = Math_round(
      ConvertUnit(+projectData.manifoldSpacing, units.lateralSpacing, units.length, null),
      3
    );
    const length = Math_round(+calculateTotalLength(+e.target.value, convertedSpacing), 3);

    dispatch(
      setProjectValue({
        totalManifoldLength: length,
        totalRows: formattedInputValue(e),
      })
    );
  };

  const onTotalLengthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setProjectValue({ totalManifoldLength: formattedInputValue(e) }));

    const convertedSpacing = Math_round(
      ConvertUnit(+projectData.manifoldSpacing, units.lateralSpacing, units.length, null),
      3
    );
    const numberOfRows = calculateNumOfRows(+e.target.value, convertedSpacing);

    dispatch(setProjectValue({ totalRows: +numberOfRows, totalManifoldLength: formattedInputValue(e) }));
  };

  const onLateralSpacingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setProjectValue({ manifoldSpacing: formattedInputValue(e) }));

    const spacing = ConvertUnit(+e.target.value, units.lateralSpacing, units.length, null);

    if (submainValues.isSubmainLength) {
      if (spacing > 0) {
        const numberOfRowsValue = Math.floor(+projectData.totalManifoldLength / spacing) + 1;
        dispatch(setProjectValue({ totalRows: numberOfRowsValue }));
      } else {
        dispatch(setProjectValue({ totalRows: 0 }));
      }
    } else {
      if (+projectData.totalRows > 0) {
        const totalLengthValue = Math_round((+projectData.totalRows - 1) * spacing, 3);
        dispatch(setProjectValue({ totalManifoldLength: totalLengthValue }));
      }
    }
  };

  const onReset = () => {
    dispatch(
      setSubmainStateValue({ firstLateral: 0, lastLateral: 0, totalHeadloss: 0, valvePressure: 0, allowedHeadloss: 0 })
    );
    dispatch(setSubmainErrorValue({ length1: false, length2: false, length3: false }));
    dispatch(
      setProjectValue({
        manifoldSlopes: '',
        flow1: 0,
        flow2: 0,
        totalFlow: 0,
        lateralInletPressure: 0,
        manifoldMaximumVelocity: 0,
        manifoldHWCoef: 0,
        submainClass: '',
        firstLateralDistance: 0,
        manifoldSpacing: 0,
        totalManifoldLength: 0,
        totalRows: 0,
        manifoldPipe1Length: 0,
        manifoldPipe2Length: 0,
        manifoldPipe3Length: 0,
        valvePressure: 0,
        manifoldHeadloss: 0,
      })
    );
  };

  return (
    <Box>
      <Row direction={{ xs: 'column', xl: 'row' }} alignItems="flex-start" minHeight={isTablet ? 'auto' : '480px'}>
        <Box width={{ xs: '100%', xl: '45%' }}>
          <ShepherdBlock id="submain-lateral-input">
            <Row justifyContent={isTablet ? 'flex-end' : 'space-between'} alignItems="flex-start">
              {!isTablet && <IconLabel iconSrc={lateral_input}>{t('lateralInput')}</IconLabel>}
              <ResetButton onClick={onReset} />
            </Row>
            <LateralInputBlock />
          </ShepherdBlock>
          <ShepherdBlock id="submain-input">
            <InputBlock
              onMaxVelocityChange={onMaxVelocityChange}
              onNumberOfRowsChange={onNumberOfRowsChange}
              onTotalLengthChange={onTotalLengthChange}
              onLateralSpacingChange={onLateralSpacingChange}
            />
          </ShepherdBlock>
          <ShepherdBlock id="submain-selected-product">
            <SelectProductBlock
              masterGroups={masterGroups}
              masterGroupId={masterGroupId}
              onMasterGroupChange={onMasterGroupChange}
              submainSubtype={submainSubtype}
              onGroupTypeChange={onGroupTypeChange}
              classType={classType}
              onClassTypeChange={onClassTypeChange}
              classTypes={classTypes}
            />
          </ShepherdBlock>
        </Box>
        <Box width={{ xs: '100%', xl: '55%' }} mt={{ xs: '20px', xl: 0 }}>
          <Row alignItems="flex-start" direction={isTablet ? 'column-reverse' : 'row'}>
            <AdvancedOptionsBlock />
            <DefineSlopesBlock />
          </Row>
        </Box>
      </Row>

      <Box mt={{ xs: '10px', xl: '-65px' }}>
        <RadioSwitcher
          radioType="submain"
          values={submainSwitchValues}
          trueFlag={submainValues.isCalcLength}
          onChange={handleToggleCalcLength}
        />
        {!isTablet && <IconLabel iconSrc={pipe_dia}>{t('pipeDiameters')}</IconLabel>}

        <Row direction={{ xs: 'column', xl: 'row' }} alignItems="flex-start">
          <Box width={{ xs: '100%', xl: '45%' }}>
            <ShepherdBlock id="submain-calc">
              <SimulationBlock
                onSubmain1Change={onSubmain1Change}
                onSubmain2Change={onSubmain2Change}
                onSubmain3Change={onSubmain3Change}
                submainGroupsLoading={submainGroupsLoading}
                submainPipe1={submainPipe1}
                submainPipe2={submainPipe2}
                submainPipe3={submainPipe3}
                submainsFiltered={submainsFiltered}
                submainsLoading={submainsLoading}
              />
            </ShepherdBlock>
            <ShepherdBlock id="submain-result">
              <ResultsBlock />
            </ShepherdBlock>
          </Box>

          {!isTablet && (
            <Box width={{ xs: '100%', xl: '55%' }}>
              <ShepherdBlock id="submain-result-graphically">
                <MainControlBlock bg={submain_bg} />
              </ShepherdBlock>
            </Box>
          )}
        </Row>
      </Box>
    </Box>
  );
};
