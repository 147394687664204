import { Box } from '@mui/material';
import { FC } from 'react';

interface Props {
  text: string;
  isActive: boolean;
  onClick: () => void;
}

export const EDCSwitchButton: FC<Props> = ({ isActive, onClick, text }) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        lineHeight: '16px',
        fontWeight: 600,
        fontSize: '27px',
        letterSpacing: '0.01em',
        border: `3px solid ${isActive ? '#1D8742' : '#B8B8B8'}`,
        color: isActive ? '#1D8742' : '#B8B8B8',
        borderRadius: '5px',
      }}
    >
      <span style={{ padding: '9px 7px' }}>{text}</span>
    </Box>
  );
};
