import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import '../../app/shepherd.css'

export const useLateral = () => {
  const [isMac, setIsMac] = useState<boolean>(false);

  const { t } = useTranslation();

  useEffect(() => {
    setIsMac(window.innerWidth >= 1400 && window.innerWidth < 1500);
  }, []);

  const LATERAL_1: any = useMemo(
    () => ({
      id: 'lateral-1',
      attachTo: {
        element: '#lateral-select-product-type',
        on: 'right-end',
      },
      scrollTo: true,
      text: [
        `<div style="width: 354px"><p class="shepherd-text"><strong>${t(
          'interactiveLateral1-1'
        )}</strong></p><p class="shepherd-text">${t('interactiveLateral1-2')}</p></div>`,
      ],
      buttons: [
        {
          classes: 'btn btn-info',
          text: 'Finish',
          type: 'cancel',
        },
        {
          classes: 'btn btn-success',
          text: 'Next',
          type: 'next',
        },
      ],
      modalOverlayOpeningPadding: 3,
      useModalOverlay: true,
      canClickTarget: false,
    }),
    [t]
  );

  const LATERAL_2: any = useMemo(
    () => ({
      id: 'lateral-2',
      attachTo: {
        element: '#lateral-calculation-type-select',
        on: `${isMac ? 'bottom' : 'right'}`,
      },
      scrollTo: true,
      text: [
        `
    <div style="width: ${isMac ? '344px' : '444px'}">
      <p class="shepherd-text">${t('interactiveLateral2-1')}<strong>${t('interactiveLateral2-2')}</strong> ${t(
          'interactiveLateral2-3'
        )}</p>

      <p class="shepherd-text">${t('interactiveLateral2-1')} <strong>${t('interactiveLateral2-4')}</strong> ${t(
          'interactiveLateral2-5'
        )}</p>
    </div>
    `,
      ],
      buttons: [
        {
          classes: 'btn btn-info',
          text: 'Back',
          type: 'back',
        },
        {
          classes: 'btn btn-success',
          text: 'Next',
          type: 'next',
        },
      ],
      modalOverlayOpeningPadding: 3,
      useModalOverlay: true,
      canClickTarget: false,
    }),
    [t]
  );

  const LATERAL_3: any = useMemo(
    () => ({
      id: 'lateral-3',
      attachTo: {
        element: '#lateral-calculation-button',
        on: 'right',
      },
      scrollTo: true,
      text: [
        `<div style="width: 291px">
    <p class="shepherd-text"><strong>${t('interactiveLateral3')}</strong></p>
    </div>
    `,
      ],
      buttons: [
        {
          classes: 'btn btn-info',
          text: 'Back',
          type: 'back',
        },
        {
          classes: 'btn btn-success',
          text: 'Next',
          type: 'next',
        },
      ],
      modalOverlayOpeningPadding: 4,
      useModalOverlay: true,
      canClickTarget: false,
    }),
    [t]
  );

  const LATERAL_4: any = useMemo(
    () => ({
      id: 'lateral-4',
      attachTo: {
        element: '#lateral-report-button',
        on: 'right',
      },
      scrollTo: true,
      text: [
        `
    <p class="shepherd-text">${t('interactiveLateral4-1')} <strong>${t('interactiveLateral4-2')}</strong> ${t(
          'interactiveLateral4-3'
        )}</p>
    `,
      ],
      buttons: [
        {
          classes: 'btn btn-info',
          text: 'Back',
          type: 'back',
        },
        {
          classes: 'btn btn-success',
          text: 'Next',
          type: 'next',
        },
      ],
      modalOverlayOpeningPadding: 4,
      useModalOverlay: true,
      canClickTarget: false,
    }),
    [t]
  );

  const LATERAL_5: any = useMemo(
    () => ({
      id: 'lateral-5',
      attachTo: {
        element: '#lateral-result-graphically',
        on: 'left',
      },
      scrollTo: true,
      text: [
        `
    <div style="width: 435px">
      <p class="shepherd-text">${t('interactiveLateral5')}</p>
    </div>
    `,
      ],
      buttons: [
        {
          classes: 'btn btn-info',
          text: 'Back',
          type: 'back',
        },
        {
          classes: 'btn btn-success',
          text: 'Next',
          type: 'next',
        },
      ],
      modalOverlayOpeningPadding: 4,
      useModalOverlay: true,
      canClickTarget: false,
    }),
    [t]
  );

  const LATERAL_INTEGRATED_1: any = useMemo(
    () => ({
      id: 'lateral-integrated-1',
      attachTo: {
        element: '#lateral-lateral-block',
        on: 'right',
      },
      scrollTo: true,
      text: [
        `<div style="width: 530px"><p class="shepherd-text">${t(
          'interactiveLateralIntegrated1-1'
        )}</p>  <p class="shepherd-text">${t('interactiveLateralIntegrated1-2')}</p> <p class="shepherd-text">${t(
          'interactiveLateralIntegrated1-3'
        )} <strong>${t('interactiveLateralIntegrated1-4')}</strong> ${t('interactiveLateralIntegrated1-5')}<p></div>`,
      ],
      buttons: [
        {
          classes: 'btn btn-info',
          text: 'Back',
          type: 'back',
        },
        {
          classes: 'btn btn-success',
          text: 'Next',
          type: 'next',
        },
      ],
      modalOverlayOpeningPadding: 4,
      useModalOverlay: true,
      canClickTarget: false,
    }),
    [t]
  );

  const LATERAL_INTEGRATED_2: any = useMemo(
    () => ({
      id: 'lateral-integrated-2',
      attachTo: {
        element: '#lateral-lateral-product-selected',
        on: 'right',
      },
      scrollTo: true,
      text: [
        `
    <div style="width: 554px">
      <p class="shepherd-text"><strong>${t('interactiveLateralIntegrated1-1')}</strong></p>

      <p class="shepherd-text">${t('interactiveLateralIntegrated2-1')} <strong>${t(
          'interactiveLateralIntegrated1-4'
        )}</strong> ${t('interactiveLateralIntegrated1-5')}</p>
      
      <p class="shepherd-text">${t('interactiveLateralIntegrated2-2')} <strong>${t(
          'interactiveLateralIntegrated2-3'
        )}</strong> ${t('interactiveLateralIntegrated2-4')}</p>
    </div>
    `,
      ],
      buttons: [
        {
          classes: 'btn btn-info',
          text: 'Back',
          type: 'back',
        },
        {
          classes: 'btn btn-success',
          text: 'Next',
          type: 'next',
        },
      ],
      modalOverlayOpeningPadding: 4,
      useModalOverlay: true,
      canClickTarget: false,
    }),
    [t]
  );

  const LATERAL_DEFINE_1: any = useMemo(
    () => ({
      id: 'lateral-define-1',
      attachTo: {
        element: '#lateral-calculation-input-value',
        on: 'right-end',
      },
      scrollTo: true,
      text: [
        `
    <div style="511px">
    <p class="shepherd-text"><strong>${t('interactiveLateralDefine1-1')}</strong></p>
    <p class="shepherd-text"><strong>${t('interactiveLateralDefine1-2')}</strong> - ${t(
          'interactiveLateralDefine1-3'
        )}</p>
    <p class="shepherd-text">${t('interactiveLateralDefine1-4')}</p>
    <p class="shepherd-text"><strong>${t('interactiveLateralDefine1-5')}</strong> ${t(
          'interactiveLateralDefine1-6'
        )}</p>
    <p class="shepherd-text"><strong>${t('interactiveLateralDefine1-7')} -</strong> ${t(
          'interactiveLateralDefine1-8'
        )}</p>
    <ul style="margin-bottom: 0px">
      <li class="shepherd-text">${t('interactiveLateralDefine1-9')}</li>
      <li class="shepherd-text">${t('interactiveLateralDefine1-10')}</li>
      <li class="shepherd-text">${t('interactiveLateralDefine1-11')}</li>
    </ul>
    </div>
    `,
      ],
      buttons: [
        {
          classes: 'btn btn-info',
          text: 'Back',
          type: 'back',
        },
        {
          classes: 'btn btn-success',
          text: 'Next',
          type: 'next',
        },
      ],
      modalOverlayOpeningPadding: 4,
      useModalOverlay: true,
      canClickTarget: false,
    }),
    [t]
  );

  const LATERAL_DEFINE_2: any = useMemo(
    () => ({
      id: 'lateral-define-2',
      attachTo: {
        element: '#lateral-calculation-result-value',
        on: 'right',
      },
      scrollTo: true,
      text: [
        `
    <div style="width: 354px">
    <p class="shepherd-text"><strong>${t('interactiveLateralDefine2-1')}</strong></p>

    <p class="shepherd-text">${t('interactiveLateralResultDefine')}</p>
  </div>    
`,
      ],
      buttons: [
        {
          classes: 'btn btn-info',
          text: 'Back',
          type: 'back',
        },
        {
          classes: 'btn btn-success',
          text: 'Next',
          type: 'next',
        },
      ],
      modalOverlayOpeningPadding: 4,
      useModalOverlay: true,
      canClickTarget: false,
    }),
    [t]
  );

  const LATERAL_ON_LINE_1: any = useMemo(
    () => ({
      id: 'lateral-on-line-1',
      attachTo: {
        element: '#lateral-emitter-block',
        on: 'right',
      },
      scrollTo: true,
      text: [
        `
    <div style="width: 549px">
      <p class="shepherd-text"><strong>${t('interactiveLateralOnLine1-1')}</strong></p>
      <ol>
      <li class="shepherd-text">${t('interactiveLateralOnLine1-2')}</li>
      <li class="shepherd-text">${t('interactiveLateralOnLine1-3')} <strong>${t(
          'interactiveLateralOnLine1-4'
        )}</strong></li>
      <li class="shepherd-text">${t('interactiveLateralOnLine1-4')} <strong>${t(
          'interactiveLateralIntegrated1-4'
        )}</strong> ${t('interactiveLateralIntegrated1-5')}</li>
      </ol>
    </div>
    `,
      ],
      buttons: [
        {
          classes: 'btn btn-info',
          text: 'Back',
          type: 'back',
        },
        {
          classes: 'btn btn-success',
          text: 'Next',
          type: 'next',
        },
      ],
      modalOverlayOpeningPadding: 4,
      useModalOverlay: true,
      canClickTarget: false,
    }),
    [t]
  );

  const LATERAL_ON_LINE_2: any = useMemo(
    () => ({
      id: 'lateral-on-line-2',
      attachTo: {
        element: '#lateral-emitter-product-selected',
        on: 'right',
      },
      scrollTo: true,
      text: [
        `
    <div style="width: 507px">
      <p class="shepherd-text"><strong>${t('interactiveLateralOnLine1-1')}</strong></p>
      <p class="shepherd-text">${t('interactiveLateralOnLine1-4')} <strong>${t(
          'interactiveLateralIntegrated1-4'
        )}</strong> ${t('interactiveLateralIntegrated1-5')}</p>
      <p class="shepherd-text">${t('interactiveLateralIntegrated2-2')} <strong>${t(
          'interactiveLateralIntegrated2-3'
        )}</strong> ${t('interactiveLateralIntegrated2-4')}</p>
    </div>
    `,
      ],
      buttons: [
        {
          classes: 'btn btn-info',
          text: 'Back',
          type: 'back',
        },
        {
          classes: 'btn btn-success',
          text: 'Next',
          type: 'next',
        },
      ],
      modalOverlayOpeningPadding: 4,
      useModalOverlay: true,
      canClickTarget: false,
    }),
    [t]
  );

  const LATERAL_ON_LINE_3: any = useMemo(
    () => ({
      id: 'lateral-on-line-3',
      attachTo: {
        element: '#lateral-lateral-block',
        on: 'right',
      },
      scrollTo: true,
      text: [
        `
  <div style="width: 506px">
  <p class="shepherd-text"><strong>${t('interactiveLateralIntegrated1-1')}</strong></p>
  <ol>
    <li class="shepherd-text">${t('interactiveLateralOnLine3-1')}</li>
    <li class="shepherd-text">${t('interactiveLateralOnLine1-4')} <strong>${t(
          'interactiveLateralIntegrated1-4'
        )}</strong> ${t('interactiveLateralIntegrated1-5')}</li>
  </ol>
  <p class="shepherd-text">${t('interactiveLateralOnLine3-2')} <strong>${t(
          'interactiveLateralIntegrated1-4'
        )}</strong> ${t('interactiveLateralIntegrated1-5')}</li>
  </div>
  `,
      ],
      buttons: [
        {
          classes: 'btn btn-info',
          text: 'Back',
          type: 'back',
        },
        {
          classes: 'btn btn-success',
          text: 'Next',
          type: 'next',
        },
      ],
      modalOverlayOpeningPadding: 4,
      useModalOverlay: true,
      canClickTarget: false,
    }),
    [t]
  );

  const LATERAL_MAX_1: any = useMemo(
    () => ({
      id: 'lateral-max-1',
      attachTo: {
        element: '#lateral-calculation-input-value',
        on: 'right-start',
      },
      scrollTo: true,
      text: [
        `
    <div style="width: 511px">
      <p class="shepherd-text"><strong>${t('interactiveLateralDefine1-1')}</strong></p>
      <p class="shepherd-text"><strong>${t('inletPr')}</strong> - ${t('interactiveLateralMax1-1')}</p>
      <p class="shepherd-text">${t('interactiveLateralMax1-2')}</p>
      <p class="shepherd-text">${t('interactiveLateralMax1-3')} <strong>${t('interactiveLateralMax1-4')}</strong> ${t(
          'interactiveLateralMax1-5'
        )}</p>
      <p class="shepherd-text"><strong>${t('interactiveLateralMax1-6')}</strong> ${t('interactiveLateralMax1-7')}</p>
    </div>
    `,
      ],
      buttons: [
        {
          classes: 'btn btn-info',
          text: 'Back',
          type: 'back',
        },
        {
          classes: 'btn btn-success',
          text: 'Next',
          type: 'next',
        },
      ],
      modalOverlayOpeningPadding: 4,
      useModalOverlay: true,
      canClickTarget: false,
    }),
    [t]
  );
  const LATERAL_MAX_2: any = useMemo(
    () => ({
      id: 'lateral-max-2',
      attachTo: {
        element: '#lateral-calculation-result-value',
        on: 'right',
      },
      scrollTo: true,
      text: [
        `
    <div style="354px">
    <p class="shepherd-text"><strong>${t('interactiveLateralDefine2-1')}</strong></p>

    <p class="shepherd-text"><strong>${t('interactiveLateralDefine2-2')}</strong> ${t('interactiveLateralDefine2-3')}<p>
</div>    
`,
      ],
      buttons: [
        {
          classes: 'btn btn-info',
          text: 'Back',
          type: 'back',
        },
        {
          classes: 'btn btn-success',
          text: 'Next',
          type: 'next',
        },
      ],
      modalOverlayOpeningPadding: 4,
      useModalOverlay: true,
      canClickTarget: false,
    }),
    [t]
  );

  return {
    LATERAL_1,
    LATERAL_2,
    LATERAL_3,
    LATERAL_4,
    LATERAL_5,
    LATERAL_DEFINE_1,
    LATERAL_DEFINE_2,
    LATERAL_INTEGRATED_1,
    LATERAL_INTEGRATED_2,
    LATERAL_MAX_1,
    LATERAL_MAX_2,
    LATERAL_ON_LINE_1,
    LATERAL_ON_LINE_2,
    LATERAL_ON_LINE_3,
  };
};
