import { ChangeEvent, FC, useEffect, useMemo } from 'react';
import { Box, Grid, MenuItem, SelectChangeEvent, useMediaQuery } from '@mui/material';
import { Accordion, Button, IconLabel, Input, PaperBox, Select, ShepherdBlock } from 'shared/ui';
import { useAppDispatch, useAppSelector, useToast } from 'shared/hooks';
import { MAX_LENGTH } from 'shared/constants';
import { setLateralErrorValue, setLateralStateValue, setProjectValue } from 'shared/slices';
import { formattedInputValue, mapSlopesForCalculation, scrollToResults } from 'shared/lib';
import { getEmitterKD, getEmitterKDMaxLength } from 'shared/lib/lateralHelpers';
import { CatalogItem } from 'shared/models';
import { Math_round } from 'shared/lib/calculation/mathRound';
import { CalculateLateralsV1 } from 'shared/lib/calculation/laterals/calculateLaterals';
import { LateralCalcResult, LateralLengthCalcResult } from 'shared/lib/calculation/models';
import { useTranslation } from 'react-i18next';
import { mainCalculate } from 'shared/lib/calculation/laterals/mainCalculateLaterals';
import { input_ico } from 'shared/assets';

const InputContent = () => {
  const dispatch = useAppDispatch();
  const { units } = useAppSelector((st) => st.units);
  const { projectData } = useAppSelector((st) => st.projectData);
  const { emitters } = useAppSelector((st) => st.emitters);
  const { laterals } = useAppSelector((st) => st.laterals);
  const isMobile = useMediaQuery('(max-width:550px)');
  const { lateralValues, lateralErrors } = useAppSelector((st) => st.lateralState);
  const { t } = useTranslation();

  const changeLateralLength = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setProjectValue({ lateralLength2: formattedInputValue(e) }));
  };
  const changeLateralInletPressure = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setProjectValue({ lateralInletPressure: formattedInputValue(e) }));
  };
  const changeFlushingVelocity = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setProjectValue({ flushingVelocity: formattedInputValue(e) }));
  };
  const changeUniformityType = (e: SelectChangeEvent<unknown>) => {
    dispatch(setLateralStateValue({ uniformityType: e.target.value as string }));
  };
  const changeMinimumVariation = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setLateralStateValue({ minimumVariation: formattedInputValue(e) }));
  };
  const changeMinimumEU = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setLateralStateValue({ minimumEu: formattedInputValue(e) }));
  };
  const resetInletPressure = () => {
    dispatch(setProjectValue({ lateralInletPressure: 0 }));
  };

  const currentProduct = useMemo(
    () =>
      projectData.integrated
        ? (laterals.find((item) => item.catlog === projectData.lateralCatalog) as CatalogItem)
        : (emitters.find((item) => item.catlog === projectData.emitterCatalog) as CatalogItem),
    [projectData.lateralCatalog, projectData.emitterCatalog]
  );

  const isDripTape = useMemo(
    () => projectData.integrated && projectData.lateralMasterGroup === '847f0edf-3712-4c1c-8afa-b9559e4fe29f',
    [projectData.integrated, projectData.lateralMasterGroup]
  );

  const isDripperOrSprinkler = useMemo(() => currentProduct?.emitterQb === 0, [currentProduct]);

  const isNotCompensated = useMemo(() => currentProduct?.emitterQb > 0, [currentProduct]);

  useEffect(() => {
    if (isDripTape) {
      dispatch(setLateralStateValue({ uniformityType: 'Minimum EU' }));
      return;
    }

    if (isDripperOrSprinkler) {
      dispatch(setLateralStateValue({ uniformityType: 'Pressure' }));
      return;
    }

    if (isNotCompensated) {
      dispatch(setLateralStateValue({ uniformityType: 'Q(delta)' }));
      return;
    }
  }, [currentProduct]);

  const SPRINKLER_MAX_LENGTH = MAX_LENGTH.filter((item) => item.value === 'Pressure');

  const MAX_LENGTH_OPTIONS = isDripperOrSprinkler ? SPRINKLER_MAX_LENGTH : MAX_LENGTH;

  const isUniformityTypeAble = MAX_LENGTH_OPTIONS.some((item) => item.value === lateralValues.uniformityType);

  return (
    <Grid item container xs={12} columnSpacing={isMobile ? 1 : 3}>
      <Grid item xs={isMobile ? 6 : 3}>
        <Input
          label={`${t('inletPr')} (${units.pressure})`}
          value={projectData.lateralInletPressure}
          onChange={changeLateralInletPressure}
          isResetInlet
          isErrorBox={lateralErrors.inletPressure}
          onResetClick={resetInletPressure}
        />
      </Grid>
      {!lateralValues.isMaxLength && (
        <Grid item xs={isMobile ? 6 : 3}>
          <Input
            label={`${t('length')} (${units.length})`}
            isErrorBox={lateralErrors.length}
            value={projectData.lateralLength2}
            onChange={changeLateralLength}
          />
        </Grid>
      )}

      <Grid item xs={isMobile ? 6 : 3}>
        <Input
          label={`${t('flushingVelocity')} (${units.velocity})`}
          value={projectData.flushingVelocity}
          onChange={changeFlushingVelocity}
        />
      </Grid>
      {lateralValues.isMaxLength ? (
        <>
          <Grid item xs={isMobile ? 6 : 3}>
            <Select
              label={`${t('uniformityType')}`}
              value={isUniformityTypeAble ? lateralValues.uniformityType : ''}
              onChange={changeUniformityType}
            >
              {MAX_LENGTH_OPTIONS.map(({ label, value }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          {lateralValues.uniformityType === 'Minimum EU' && (
            <Grid item xs={isMobile ? 6 : 3}>
              <Input label={`${t('minEU')}`} value={lateralValues.minimumEu} onChange={changeMinimumEU} />
            </Grid>
          )}
          {lateralValues.uniformityType === 'Q(delta)' && (
            <Grid item xs={isMobile ? 6 : 3}>
              <Input
                label={`${t('minimumVariation')}`}
                value={lateralValues.minimumVariation}
                onChange={changeMinimumVariation}
              />
            </Grid>
          )}
          {lateralValues.uniformityType === 'Pressure' && <Grid item xs={2.4}></Grid>}
        </>
      ) : (
        <Grid item xs={isMobile ? 6 : 3}></Grid>
      )}
    </Grid>
  );
};

const CalcButton = () => {
  const isTablet = useMediaQuery('(max-width:850px)');
  const isMobile = useMediaQuery('(max-width:550px)');
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { showError } = useToast();
  const { projectData } = useAppSelector((st) => st.projectData);
  const { units } = useAppSelector((st) => st.units);
  const { lateralValues } = useAppSelector((st) => st.lateralState);
  const { laterals } = useAppSelector((state) => state.laterals);
  const { emitters } = useAppSelector((state) => state.emitters);

  const onDefineLengthCalculate = () => {
    isMobile && scrollToResults();

    const currentProduct = projectData.integrated
      ? (laterals.find((item) => item.catlog === projectData.lateralCatalog) as CatalogItem)
      : (emitters.find((item) => item.catlog === projectData.emitterCatalog) as CatalogItem);

    const currentLateral = laterals.find((item) => item.catlog === projectData.lateralCatalog) as CatalogItem;
    const currentEmitter = emitters.find((item) => item.catlog === projectData.emitterCatalog) as CatalogItem;

    const values = {
      HDCalc: projectData.headlossCalc,
      dia: +projectData.lateralInletDia,
      spacing: +projectData.emitterSpacing,
      HWCoeff: lateralValues.isCustom ? +projectData.hwCoef : currentLateral.hwcof,
      A: lateralValues.isCustom ? Math_round(+projectData.emitterA, 3) : Math_round(currentProduct.emitterQa, 3),
      B: lateralValues.isCustom ? Math_round(+projectData.emitterB, 3) : Math_round(currentProduct.emitterQb, 3),
      Kd: lateralValues.isCustom
        ? +projectData.lateralKd
        : projectData.integrated
          ? currentProduct.kd
          : getEmitterKD(currentEmitter, currentLateral),
      cvv: lateralValues.isCustom ? +projectData.cvv : Math_round(currentLateral.mManuFactCv, 3),
      Length: +projectData.lateralLength2,
      pMin: +projectData.emitterPMin,
      pMax: +projectData.emitterPMax,
      pInlet: projectData.lateralInletPressure as number,
      PRA: currentLateral.pipeDha,
      PRB: currentLateral.pipeDhb,
      PRC: currentLateral.pipeDhc,
      slopes: mapSlopesForCalculation(projectData.lateralSlopes),
      calcType: projectData?.euType ?? 'Classic',
      flushVelocity: (projectData.flushingVelocity as number) ?? 0,
    };

    console.log('####: values', values);

    const result = CalculateLateralsV1(values, units, true) as LateralCalcResult;

    console.log('####: result', result);

    if (result.error) {
      showError(t(result.error));
      return;
    }

    dispatch(
      setLateralStateValue({
        resultDU: isNaN(result.lateralMaxDu) ? 0 : result.lateralMaxDu,
        resultMaxVelocity: isNaN(result.MaxVelocity) ? 0 : result.MaxVelocity,
        resultTotalHeadloss: isNaN(result.totalHeadloss) ? 0 : result.totalHeadloss,
        resultEU: isNaN(result.EU2) ? 0 : result.EU2,
        resultTotalFlow: isNaN(result.totalFlow) ? 0 : result.totalFlow,
        resultPMax: isNaN(result.pmax) ? 0 : result.pmax,
        resultPMin: isNaN(result.pmin) ? 0 : result.pmin,
        resultQMinQMax: isNaN(result.lateralLastReportResult.qMinQMax) ? 0 : result.lateralLastReportResult.qMinQMax,
        resultReportArray: result.lateralLastReportResult.lateralReportResult,
        expandedResultsAccordion: true,
      })
    );

    dispatch(
      setProjectValue({
        flow1: result.totalFlow,
        flow2: result.totalFlow,
        ...(result.pInlet && { lateralInletPressure: result.pInlet }),
      })
    );

    if (result.pmax > +projectData.emitterPMax) {
      dispatch(setLateralErrorValue({ pMax: true }));
    } else {
      dispatch(setLateralErrorValue({ pMax: false }));
    }

    if (result.pmin < +projectData.emitterPMin) {
      dispatch(setLateralErrorValue({ pMin: true }));
    } else {
      dispatch(setLateralErrorValue({ pMin: false }));
    }

    if (result.rangeResult?.LateralMaxVelocity.range === 'over') {
      dispatch(setLateralErrorValue({ velocity: true }));
    } else {
      dispatch(setLateralErrorValue({ velocity: false }));
    }
  };

  const onMaxLengthCalculate = () => {
    isMobile && scrollToResults();
    const currentProduct = projectData.integrated
      ? (laterals.find((item) => item.catlog === projectData.lateralCatalog) as CatalogItem)
      : (emitters.find((item) => item.catlog === projectData.emitterCatalog) as CatalogItem);

    const currentLateral = laterals.find((item) => item.catlog === projectData.lateralCatalog) as CatalogItem;
    const currentEmitter = emitters.find((item) => item.catlog === projectData.emitterCatalog) as CatalogItem;

    const values = {
      runLengthEnd: 0,
      runLengthIncrement: 0,
      runLengthStart: 0,
      HDCalc: projectData.headlossCalc,
      HWCoeff: lateralValues.isCustom ? +projectData.hwCoef : currentLateral.hwcof,
      A: lateralValues.isCustom ? Math_round(+projectData.emitterA, 3) : Math_round(currentProduct.emitterQa, 3),
      B: lateralValues.isCustom ? Math_round(+projectData.emitterB, 3) : Math_round(currentProduct.emitterQb, 3),
      Kd: lateralValues.isCustom
        ? +projectData.lateralKd
        : projectData.integrated
          ? currentProduct.kd
          : getEmitterKDMaxLength(currentEmitter, currentLateral),
      cvv: lateralValues.isCustom ? +projectData.cvv : Math_round(currentLateral.mManuFactCv, 3),
      dia: +projectData.lateralInletDia,
      spacing: +projectData.emitterSpacing,
      Length: +projectData.lateralLength2,
      pMin: +projectData.emitterPMin,
      pMax: +projectData.emitterPMax,
      pInlet: projectData.lateralInletPressure as number,
      inletPressure: projectData.lateralInletPressure as number,
      PRA: currentLateral.pipeDha,
      PRB: currentLateral.pipeDhb,
      PRC: currentLateral.pipeDhc,
      slopes: mapSlopesForCalculation(projectData.lateralSlopes),
      calcType: projectData?.euType ?? 'Classic',
      flushVelocity: (projectData.flushingVelocity as number) ?? 0,
      maxQDelta: lateralValues.minimumVariation as number,
      MaxLengthCalcMethod: lateralValues.uniformityType,
      minEU: lateralValues.minimumEu as number,
      MinimumEU: lateralValues.minimumEu as number,
    };

    const result = mainCalculate(values, units, false) as LateralLengthCalcResult;

    const reportValues = {
      ...values,
      runLengthStart: +projectData.emitterSpacing,
      runLengthEnd: result.MaxLateralLength,
      runLengthIncrement: +projectData.emitterSpacing,
    };

    console.log('####: values', reportValues);

    const reportResult = mainCalculate(reportValues, units, true) as LateralLengthCalcResult;

    console.log('####: result', reportResult);

    if (reportResult.error) {
      showError(t(reportResult.error));
      return;
    }

    dispatch(
      setLateralStateValue({
        maxLengthStart: +projectData.emitterSpacing,
        maxLengthEnd: result.MaxLateralLength,
        maxLengthIncrement: +projectData.emitterSpacing,
        resultMaxLengthDU: isNaN(reportResult.LateralMaxDu) ? 0 : reportResult.LateralMaxDu,
        resultMaxLengthEU: 0,
        resultMaxLengthMaxVelocity: isNaN(reportResult.LateralMaxVelocity) ? 0 : reportResult.LateralMaxVelocity,
        resultMaxLengthTotalHeadloss: isNaN(reportResult.TotalHeadloss) ? 0 : reportResult.TotalHeadloss,
        resultMaxLength: isNaN(reportResult.MaxLateralLength) ? 0 : reportResult.MaxLateralLength,
        resultMaxLengthTotalFlow: isNaN(reportResult.TotalFlow) ? 0 : reportResult.TotalFlow,
        resultMaxLengthPMax: isNaN(reportResult.Actpmax) ? 0 : reportResult.Actpmax,
        resultMaxLengthPMin: isNaN(reportResult.Actpmin) ? 0 : reportResult.Actpmin,
        resultMaxLengthQMinQMax: isNaN(reportResult.minByqMax) ? 0 : reportResult.minByqMax,
        resultMaxLengthReportArray: reportResult.sortedLateralMainReportResults,
        expandedResultsAccordion: true,
      })
    );

    dispatch(
      setProjectValue({
        flow1: reportResult.TotalFlow,
        flow2: reportResult.TotalFlow,
        ...(reportResult.SLInletPressure && { lateralInletPressure: reportResult.SLInletPressure }),
      })
    );

    if (reportResult.Actpmax > +projectData.emitterPMax) {
      dispatch(setLateralErrorValue({ maxLengthPMax: true }));
    } else {
      dispatch(setLateralErrorValue({ maxLengthPMax: false }));
    }

    if (reportResult.Actpmin < +projectData.emitterPMin) {
      dispatch(setLateralErrorValue({ maxLengthPMin: true }));
    } else {
      dispatch(setLateralErrorValue({ maxLengthPMin: false }));
    }
  };

  // VALIDATIONS

  useEffect(() => {
    if (+projectData.lateralLength2 <= 0) {
      dispatch(setLateralErrorValue({ length: true }));
    } else {
      dispatch(setLateralErrorValue({ length: false }));
    }
  }, [projectData.lateralLength2]);

  useEffect(() => {
    if (+projectData.emitterSpacing <= 0) {
      dispatch(setLateralErrorValue({ spacing: true }));
    } else {
      dispatch(setLateralErrorValue({ spacing: false }));
    }
  }, [projectData.emitterSpacing]);

  useEffect(() => {
    if (+projectData.lateralInletPressure > +projectData.emitterPMax) {
      dispatch(setLateralErrorValue({ inletPressure: true }));
    } else {
      dispatch(setLateralErrorValue({ inletPressure: false }));
    }
  }, [projectData.lateralInletPressure, projectData.emitterPMax]);

  return (
    <Grid item container xs={12} columnSpacing={3} justifyContent="flex-end" py={'10px'} px={isTablet ? 0 : '10px'}>
      <Grid item xs={isMobile ? 6 : 3}>
        <ShepherdBlock id="lateral-calculation-button">
          <Button
            iconType="calculation"
            onClick={lateralValues.isMaxLength ? onMaxLengthCalculate : onDefineLengthCalculate}
          >
            {t('calculate')}
          </Button>
        </ShepherdBlock>
      </Grid>
    </Grid>
  );
};

export const InputBlock: FC = () => {
  const isTablet = useMediaQuery('(max-width:850px)');
  const { t } = useTranslation();

  return (
    <Box width="100%">
      {isTablet ? (
        <Accordion defaultExpanded header={<IconLabel iconSrc={input_ico}>{t('input')}</IconLabel>}>
          <>
            <InputContent />
            <CalcButton />
          </>
        </Accordion>
      ) : (
        <Box sx={{ mt: '5px' }}>
          <ShepherdBlock id="lateral-calculation-input-value">
            <PaperBox>
              <InputContent />
            </PaperBox>
          </ShepherdBlock>
          <CalcButton />
        </Box>
      )}
    </Box>
  );
};
