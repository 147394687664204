import { useState, useCallback } from 'react';

export const useDialog = (show = false) => {
  const [open, setOpen] = useState(show);

  const toggle = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  return { open, toggle };
};
