import { useMemo } from 'react';
import { useAppSelector } from './useAppSelector';
import { compareSteps } from 'shared/lib';
import { useLateral } from 'shared/constants/shepherd-lateral';
import { useSubmain } from 'shared/constants/shepherd-submain';
import { useMainline } from 'shared/constants/shepherd-mainline';
import { useSysSum } from 'shared/constants/shepherd-system-summary';
import { useSideBar } from 'shared/constants/shepherd-sidebar';
import { useEDC } from 'shared/constants/shepherd-edc';

export const useGetShepherdSteps = () => {
  const { units } = useAppSelector((state) => state.units);
  const { projectData } = useAppSelector((st) => st.projectData);
  const { lateralValues } = useAppSelector((st) => st.lateralState);
  const { submainValues } = useAppSelector((state) => state.submainState);

  const {
    LATERAL_1,
    LATERAL_2,
    LATERAL_3,
    LATERAL_4,
    LATERAL_5,
    LATERAL_DEFINE_1,
    LATERAL_DEFINE_2,
    LATERAL_INTEGRATED_1,
    LATERAL_INTEGRATED_2,
    LATERAL_MAX_1,
    LATERAL_MAX_2,
    LATERAL_ON_LINE_1,
    LATERAL_ON_LINE_2,
    LATERAL_ON_LINE_3,
  } = useLateral();
  const {
    SUBMAIN_1,
    SUBMAIN_2,
    SUBMAIN_3,
    SUBMAIN_4,
    SUBMAIN_5,
    SUBMAIN_6,
    SUBMAIN_7,
    SUBMAIN_8,
    SUBMAIN_CALC,
    SUBMAIN_CUSTOM_CALC,
  } = useSubmain();
  const { MAINLINE_1, MAINLINE_2, MAINLINE_3 } = useMainline();
  const { SYS_SUM_1, SYS_SUM_3, SYS_SUM_4, SYS_SUM_5, SYS_SUM_6 } = useSysSum();
  const {
    EDC_1,
    EDC_2,
    EDC_3,
    EDC_4,
    EDC_6,
    EDC_7,
    EDC_8,
    EDC_9,
    EDC_10,
    EDC_11,
    EDC_12,
    EDC_13,
    EDC_14,
    EDC_15,
    EDC_16,
    EDC_17,
  } = useEDC();
  const { SIDEBAR_1 } = useSideBar();

  const lateralSteps: any = useMemo(
    () => [
      LATERAL_1,
      projectData.integrated ? LATERAL_INTEGRATED_1 : LATERAL_ON_LINE_1,
      projectData.integrated ? LATERAL_INTEGRATED_2 : LATERAL_ON_LINE_2,
      projectData.integrated ? LATERAL_2 : LATERAL_ON_LINE_3,
      projectData.integrated
        ? compareSteps(lateralValues.isMaxLength, LATERAL_DEFINE_1, LATERAL_MAX_1)
        : LATERAL_INTEGRATED_2,
      projectData.integrated ? LATERAL_3 : LATERAL_2,
      projectData.integrated
        ? compareSteps(lateralValues.isMaxLength, LATERAL_MAX_2, LATERAL_DEFINE_2)
        : compareSteps(lateralValues.isMaxLength, LATERAL_MAX_1, LATERAL_DEFINE_1),
      projectData.integrated ? LATERAL_4 : LATERAL_3,
      projectData.integrated ? LATERAL_5 : compareSteps(lateralValues.isMaxLength, LATERAL_MAX_2, LATERAL_DEFINE_2),
      ...(!projectData.integrated ? [LATERAL_4] : []),
      ...(!projectData.integrated ? [LATERAL_5] : []),
      SIDEBAR_1,
    ],
    [projectData.integrated, lateralValues.isMaxLength, units],
  );

  const submainSteps: any = useMemo(
    () => [
      SUBMAIN_1,
      SUBMAIN_2,
      SUBMAIN_3,
      SUBMAIN_4,
      compareSteps(submainValues.isCalcLength, SUBMAIN_CALC, SUBMAIN_CUSTOM_CALC),
      SUBMAIN_5,
      SUBMAIN_6,
      SUBMAIN_7,
      SUBMAIN_8,
      SIDEBAR_1,
    ],
    [projectData.integrated, submainValues.isCalcLength, units],
  );

  const mainlineSteps: any = useMemo(
    () => [
      MAINLINE_1,
      MAINLINE_2,
      SUBMAIN_4,
      compareSteps(submainValues.isCalcLength, SUBMAIN_CALC, SUBMAIN_CUSTOM_CALC),
      SUBMAIN_5,
      SUBMAIN_6,
      SUBMAIN_7,
      MAINLINE_3,
      SIDEBAR_1,
    ],
    [projectData.integrated, submainValues.isCalcLength, units],
  );

  const systemSummarySteps: any = useMemo(() => [SYS_SUM_1, SYS_SUM_3, SYS_SUM_4, SYS_SUM_5, SYS_SUM_6], [units]);

  const edcSteps: any = useMemo(
    () => [
      EDC_1,
      EDC_2,
      EDC_3,
      EDC_4,
      EDC_6,
      EDC_16,
      EDC_17,
      EDC_7,
      EDC_8,
      EDC_9,
      EDC_10,
      EDC_11,
      EDC_12,
      EDC_13,
      EDC_14,
      EDC_15,
      SIDEBAR_1,
    ],
    [units],
  );

  return {
    lateralSteps,
    submainSteps,
    mainlineSteps,
    systemSummarySteps,
    edcSteps,
  };
};
