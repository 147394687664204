import { Box, Button, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { toggleInjectionRateCalculator } from 'shared/slices';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { Input, Modal } from 'shared/ui';
import { calculateInjectionRate } from './lib';
import { formattedInputValue } from 'shared/lib';

type CalculationType = 'outletRate' | 'tankConc';

export const InjectionRate = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { openInjectionRateCalc } = useAppSelector((st) => st.modals);
  const { units } = useAppSelector((st) => st.units);

  const [values, setValues] = useState({ finalConcentration: 5, tankSolutionConc: 10, pumpRate: 10, outletRate: 0 });
  const [calculationType, setCalculationType] = useState<CalculationType>('outletRate');

  const handleValuesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues((prev) => ({ ...prev, [e.target.name]: formattedInputValue(e) }));
  };

  const handleCalculationTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCalculationType(e.target.value as CalculationType);
  };

  const toggle = () => dispatch(toggleInjectionRateCalculator());

  const handleCalculateClick = () => {
    const { finalConcentration, outletRate, pumpRate, tankSolutionConc } = values;

    const result = calculateInjectionRate(
      { calculationType, finalConcentration, outletRate, pumpRate, tankSolutionConc },
      units
    );

    setValues((prev) => ({
      ...prev,
      ...(calculationType === 'outletRate' && { outletRate: result }),
      ...(calculationType === 'tankConc' && { tankSolutionConc: result }),
    }));
  };

  return (
    <Modal maxWidth="xs" title={t('injectionRate')} open={openInjectionRateCalc} onClose={toggle}>
      <Box>
        <Input
          fullWidth
          value={values.finalConcentration}
          onChange={handleValuesChange}
          type="number"
          name="finalConcentration"
          label={`${t('finalConc')} (ppm)`}
        />
        <Input
          fullWidth
          value={values.tankSolutionConc}
          onChange={handleValuesChange}
          type="number"
          name="tankSolutionConc"
          isResultBox={calculationType === 'tankConc'}
          label={`${t('tankSolConc')} (%)`}
        />
        <Input
          fullWidth
          value={values.pumpRate}
          onChange={handleValuesChange}
          type="number"
          name="pumpRate"
          label={`${t('pumpRate')} (${units.totalFlow})`}
        />
        <Input
          fullWidth
          value={values.outletRate}
          onChange={handleValuesChange}
          type="number"
          isResultBox={calculationType === 'outletRate'}
          name="outletRate"
          label={`${t('outletRate')} (${units.flow})`}
        />
        <FormControl fullWidth>
          <RadioGroup
            value={calculationType}
            onChange={handleCalculationTypeChange}
            row
            sx={{ justifyContent: 'space-between' }}
          >
            <FormControlLabel
              sx={{ span: { fontSize: '12px' }, textTransform: 'uppercase', color: 'secondary.main' }}
              value="outletRate"
              control={<Radio size="small" />}
              label={`${t('solForOutRate')}`}
            />
            <FormControlLabel
              sx={{ span: { fontSize: '12px' }, textTransform: 'uppercase', color: 'secondary.main' }}
              value="tankConc"
              control={<Radio size="small" />}
              label={`${t('solForTc')}`}
            />
          </RadioGroup>
        </FormControl>
        <Box display="flex" columnGap={1}>
          <Button onClick={handleCalculateClick} variant="contained" sx={{ flexBasis: '50%' }}>
            {t('calculate')}
          </Button>

          <Button onClick={toggle} variant="outlined" sx={{ flexBasis: '50%' }}>
            {t('cancel')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
