import { FC, PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';
import { ShepherdTour } from 'react-shepherd';
import { useGetShepherdSteps } from 'shared/hooks/useGetShepherdSteps';

const TOUR_OPTIONS: any = {
  defaultStepOptions: {
    showCancelLink: true,
    classes: 'shepherd-container',
    cancelIcon: {
      enabled: true,
    },
  },
  useModalOverlay: true,
  keyboardNavigation: false,
};

export const ShepherdProvider: FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation();

  const { lateralSteps, submainSteps, mainlineSteps, systemSummarySteps, edcSteps } = useGetShepherdSteps();

  const STEPS_DICTIONARY = {
    '/lateral': lateralSteps,
    '/submain': submainSteps,
    '/mainline': mainlineSteps,
    '/system-summary': systemSummarySteps,
    '/edc': edcSteps,
  };

  const path = location.pathname as keyof typeof STEPS_DICTIONARY;

  const pageSteps = STEPS_DICTIONARY[path];

  return (
    <ShepherdTour steps={pageSteps ?? []} tourOptions={TOUR_OPTIONS}>
      {children}
    </ShepherdTour>
  );
};
