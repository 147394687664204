/* eslint-disable no-unused-vars */
import { METRIC_DEFAULTS } from 'shared/constants';
import { Unit } from 'shared/models';
import { CalculateEU } from './calculateEU';
import { CalculateHeadloss } from './calculateHeadloss';
import { Math_round } from './mathRound';
import { ConvertUnit } from './unitConverter';

export function CalculateLaterals2(
  isHW: number,
  HWCoeff: number,
  dia: number,
  A: number,
  B: number,
  Kd: number,
  Length: number,
  spacing: number,
  slopes: any[],
  pInlet: number,
  pMin: number,
  pMax: number,
  cvv: number,
  calcType: string,
  flushVelocity: number,
  arrQ: number[],
  arrVelocity: number[],
  arrPressure: number[],
  arrEU: number[],
  arrHeadloss: number[],
  PRA: number,
  PRB: number,
  PRC: number,
  minEU: number,
  maxQDelta: number,
  bCheckLimit: boolean,
  unitSettings: Unit,
) {
  PRA = Math.fround(PRA);
  PRB = Math.fround(PRB);
  PRC = Math.fround(PRC);
  let orignialpMin = pMin;
  let QFlush = flushVelocity * ((Math.PI * dia * dia * Math.pow(10, -6)) / 4) * 3600 * 1000;
  let iter = 0;
  let minPressure = Number.MAX_VALUE;
  let maxPressure = Number.MIN_VALUE;
  let spacings = 0;

  let i = 0;
  spacings = Math.round(Math.ceil(Length / spacing));
  let arrHeight = []; //new double[spacings+1];
  for (let i = 0; i < spacings + 1; i++) arrHeight[i] = 0;
  if (slopes.length > 0) {
    let slopeIndex = 0;
    let slope = slopes[0].Slope;
    if (slopes[0].SlopeDirection == 'Downhill') slope = slope * -1;
    let len = ConvertUnit(slopes[slopeIndex].Length, unitSettings.length, METRIC_DEFAULTS.Length, null);
    let totalLength = len;
    for (i = 0; i <= spacings; i++) {
      if (i * spacing >= totalLength) {
        if (slopeIndex < slopes.length - 1) {
          slopeIndex += 1;
          len = ConvertUnit(slopes[slopeIndex].Length, unitSettings.length, METRIC_DEFAULTS.Length, null);
          totalLength += len;
          slope = slopes[slopeIndex].Slope;
          if (slopes[slopeIndex].SlopeDirection == 'Downhill') slope = slope * -1;
        } else {
          slope = 0;
        }
      }
      if (spacing * i < Length) arrHeight[i] = (slope / 100.0) * spacing;
      else arrHeight[i] = (slope / 100.0) * (Length - (i - 1) * spacing);
    }
  }
  while (iter < 10) {
    let arrQAcc = []; //new double[spacings];
    arrQ.length = 0; //new double[spacings];
    arrVelocity.length = 0; //new double[spacings];
    arrPressure.length = 0; //new double[spacings+1];
    arrHeadloss.length = 0; //new double[spacings];
    arrEU.length = 0; //new double[spacings];
    arrPressure[0] = pMin;
    let minFlow = Number.MAX_VALUE;
    const roundedB = Math_round(B, 3);
    const roundedA = Math_round(A, 3);
    arrQ[0] = arrQAcc[0] = roundedA * Math.pow(arrPressure[0], roundedB);
    arrHeadloss[0] = CalculateHeadloss(
      isHW,
      Math.round(HWCoeff),
      (arrQAcc[0] + QFlush) / 1000,
      dia,
      spacing,
      Kd,
      PRA,
      PRB,
      PRC,
    );
    arrVelocity[0] = (arrQ[0] / 1000 + QFlush / 1000) / 3600 / ((Math.PI * dia * dia * Math.pow(10, -6)) / 4);
    let recalculate = false;
    minFlow = arrQ[0];
    for (i = 1; i <= spacings - 1; i++) {
      arrQ[i] = roundedA * Math.pow(arrPressure[i - 1] + arrHeadloss[i - 1] + arrHeight[i - 1], roundedB);
      if (arrQ[i] < minFlow) {
        minFlow = arrQ[i];
      }
      arrQAcc[i] = arrQAcc[i - 1] + arrQ[i];
      let len1;
      if (i == spacings - 1) len1 = Length - spacing * i;
      else len1 = spacing;
      arrHeadloss[i] = CalculateHeadloss(
        isHW,
        Math.round(HWCoeff),
        (arrQAcc[i] + QFlush) / 1000,
        dia,
        len1,
        Kd,
        PRA,
        PRB,
        PRC,
      );
      arrPressure[i] = arrPressure[i - 1] + arrHeadloss[i - 1] + arrHeight[i - 1];
      if (minPressure > arrPressure[i]) {
        minPressure = arrPressure[i];
      }
      if (maxPressure < arrPressure[i]) {
        maxPressure = arrPressure[i];
      }
      if (
        (arrPressure[i] + 0.01 < arrPressure[i - 1] && arrPressure[i] + 0.01 < orignialpMin && pInlet == 0) ||
        minPressure <= 0
      ) {
        recalculate = true;
        pMin = pMin + orignialpMin - arrPressure[i];
        arrQ = []; //, 0, arrQ.Length);
        arrQAcc = []; //, 0, arrQAcc.Length);
        arrHeadloss = []; //, 0, arrHeadloss.Length);
        arrVelocity = []; //, 0, arrVelocity.Length);
        minPressure = Number.MAX_VALUE;
        maxPressure = Number.MIN_VALUE;
        break; // TODO: might not be correct. Was : Exit For
      } else {
        recalculate = false;
      }
      arrVelocity[i] = (arrQAcc[i] / 1000 + QFlush / 1000) / 3600 / ((Math.PI * dia * dia * Math.pow(10, -6)) / 4);
      arrEU[i] = CalculateEU(arrQ, i + 1, arrQAcc[i], calcType, cvv);
    }
    arrPressure[spacings] = arrPressure[spacings - 1] + arrHeadloss[spacings - 1] + arrHeight[spacings];
    if (recalculate) {
      minPressure = Number.MAX_VALUE;
      maxPressure = Number.MIN_VALUE;
      continue;
    }
    if (pInlet > 0) {
      let lastPressure = arrPressure[arrPressure.length - 1];
      if (Math.abs(lastPressure - pInlet) > 0.01) {
        pMin = pMin + (pInlet - lastPressure) * 0.3;
        if (pMin < 0) {
          return { spacings: -1, arrEU, arrQ, arrHeadloss, arrPressure, arrVelocity };
        }
      } else {
        return { spacings, arrEU, arrQ, arrHeadloss, arrPressure, arrVelocity };
        //break; // TODO: might not be correct. Was : Exit While
      }
    } else {
      //let lstPressures = [];//new List<double>();
      //lstPressures = lstPressures.concat(arrPressure);
      //lstPressures.sort();
      let min = 999999;
      let max = -999999;
      for (let z = 0; z < arrPressure.length; z++) {
        if (arrPressure[z] < min) min = arrPressure[z];
        if (arrPressure[z] > max) max = arrPressure[z];
      }
      if (min < orignialpMin - 0.01) {
        pMin += (orignialpMin - min) / 3;
        iter += 1;
      } else {
        return { spacings, arrEU, arrQ, arrHeadloss, arrPressure, arrVelocity };
        //break; // TODO: might not be correct. Was : Exit While
      }
    }
  }
  return { spacings, arrEU, arrQ, arrHeadloss, arrPressure, arrVelocity };
}
