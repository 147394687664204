import React from 'react';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';

export const withMuiTheme = (component: () => React.ReactNode) => () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: 'rgba(29, 135, 66, 1)',
        light: 'rgba(218, 233, 226, 1)',
      },
      secondary: {
        main: 'rgba(77, 78, 76, 1)', //rgba(217, 217, 217, 1)
        light: 'rgba(184, 192, 188, 1)', //rgba(44, 44, 44, 1)
      },
      info: {
        main: 'rgba(255, 255, 255, 1)',
        dark: 'rgba(184, 192, 188, 1)',
        light: 'rgba(184, 184, 184, 0.5)',
      },
      error: {
        main: 'rgba(240, 5, 5, 1)',
        light: 'rgba(238, 139, 140, 0.3)',
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {component()}
    </ThemeProvider>
  );
};
