import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type MainlineValues = {
  isCalcLength: boolean;
  velocity1: number;
  velocity2: number;
  velocity3: number;
  pumpPressure: number;
  pressureAtValve: number;
  pipesHeadloss: number;
  mainlineClass: string | number;
  diameter: string | number;
  allowableHeadloss: string | number;
  pumpHeadloss: string | number;
  valveHeadloss: string | number;
  length: string | number;
  maxVelocity: string | number;
  flow: string | number;
};

export type MainlineErrors = {
  velocity1: boolean;
  velocity2: boolean;
  velocity3: boolean;
};

interface MainlineState {
  mainlineValues: MainlineValues;
  mainlineErrors: MainlineErrors;
}

const initialState: MainlineState = {
  mainlineValues: {
    isCalcLength: true,
    velocity1: 0,
    velocity2: 0,
    velocity3: 0,
    pumpPressure: 0,
    pressureAtValve: 0,
    pipesHeadloss: 0,
    mainlineClass: 'All',
    diameter: 'All',
    allowableHeadloss: 10,
    pumpHeadloss: 10,
    valveHeadloss: 5,
    length: 0,
    maxVelocity: 2.5,
    flow: 0,
  },
  mainlineErrors: {
    velocity1: false,
    velocity2: false,
    velocity3: false,
  },
};

const mainlineStateSlice = createSlice({
  name: 'mainlineSate',
  initialState,
  reducers: {
    setMainlineStateValue: (state, action: PayloadAction<Partial<MainlineValues>>) => {
      state.mainlineValues = { ...state.mainlineValues, ...action.payload };
    },
    setMainlineErrorValue: (state, action: PayloadAction<Partial<MainlineErrors>>) => {
      state.mainlineErrors = { ...state.mainlineErrors, ...action.payload };
    },
  },
});

export const { setMainlineStateValue, setMainlineErrorValue } = mainlineStateSlice.actions;

export const mainlineStateReducer = mainlineStateSlice.reducer;
