import { AccordionSummary, AccordionDetails, Accordion as MuiAccordion } from '@mui/material';
import { FC, SyntheticEvent } from 'react';
import { accordion_arrow } from 'shared/assets';

interface Props {
  header: JSX.Element;
  children: JSX.Element;
  defaultExpanded?: boolean;
  expanded?: boolean;
  onChange?: (event: SyntheticEvent<Element, Event>, expanded: boolean) => void;
}

export const Accordion: FC<Props> = ({
  header,
  children,
  defaultExpanded = false,
  expanded = undefined,
  onChange = undefined,
}) => {
  return (
    <MuiAccordion
      expanded={expanded}
      onChange={onChange}
      defaultExpanded={defaultExpanded}
      disableGutters
      sx={{
        width: '100%',
        background: '#F5FBFF',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.25)',
        borderRadius: '10px !important',
      }}
    >
      <AccordionSummary
        expandIcon={<img src={accordion_arrow} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{
          '& .MuiAccordionSummary-content': {
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          },
        }}
      >
        {header}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </MuiAccordion>
  );
};
