import React, { useEffect, useRef } from 'react';

type PlantsItem = {
  color?: string;
  width?: number;
  height?: number;
  text?: number;
  Fill?: any;
  Tag?: string;
  IsHitTestVisible?: boolean;
  stroke?: string;
  left?: number;
  top?: number;
};

interface CanvasPlantsProps {
  item: PlantsItem;
  showGrowth: boolean;
}

export const CanvasPlants: React.FC<CanvasPlantsProps> = ({ item, showGrowth }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    canvas.height = item?.height ?? 0;
    canvas.width = item?.width ?? 0;

    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;

    ctx.fillStyle = '#d3d3d3';
    ctx.strokeStyle = 'white';
    ctx.beginPath();
    ctx.arc(centerX, centerY, canvas.height / 2, 0, 2 * Math.PI);

    if (!showGrowth) {
      //ctx.strokeStyle = '#d3d3d3';
      ctx.fill();
    }

    ctx.stroke();
  }, [showGrowth, item]);

  return (
    <canvas
      style={{
        position: 'absolute',
        top: item.top,
        left: item.left,
      }}
      ref={canvasRef}
    />
  );
};
