import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'shared/hooks';
import { Math_round } from 'shared/lib/calculation/mathRound';

export const useEDCTablesExport = () => {
  const { t } = useTranslation();
  const { tablesResult } = useAppSelector((state) => state.edcState);

  const headers = [t('step'), t('item'), ...tablesResult.map((item) => item.step.toString())];

  const body = tablesResult.map((item) => {
    return Object.entries(item)
      .filter(([key]) => key !== 'step')
      .map(([key, value], idx) => {
        return [
          idx === 0 ? item.step : null,
          key,
          ...(key === 'cu' || key === 'du' || key === 'avg' || key === 'min'
            ? value.map((item: number) => Math_round(item, 3))
            : []),
        ];
      });
  });

  const data = [headers, ...body];

  let csvContent = '';

  data.forEach((rowArray, index) => {
    let row = '';
    if (index === 0) {
      row = rowArray.join(',');
    } else {
      row = rowArray.join(',\r\n');
    }
    csvContent += row + '\r\n';
  });

  return csvContent;
};
