import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'shared/hooks';

export const useMainline = () => {
  const [isMac, setIsMac] = useState<boolean>(false);
  const { units } = useAppSelector((state) => state.units);

  const { t } = useTranslation();

  useEffect(() => {
    setIsMac(window.innerWidth >= 1400 && window.innerWidth < 1500);
  }, []);

  const MAINLINE_1: any = useMemo(
    () => ({
      id: 'mainline-1',
      attachTo: {
        element: '#mainline-input',
        on: 'right',
      },
      scrollTo: true,
      text: [
        `
    <div style='width: ${isMac ? '445px' : '645px'}'>
      <p class='shepherd-text'><strong>${t('interactiveMainline1-1')}</strong></p>
      <ol>
        <li class='shepherd-text'>${t('interactiveMainline1-2')} <strong>${t('interactiveMainline1-3')} (${
          units.pressure
        })</strong></li>
        <li class='shepherd-text'>${t('interactiveMainline1-2')} <strong>${t('interactiveMainline1-5')} (${
          units.pressure
        })</strong></li>
        <li class='shepherd-text'>${t('interactiveMainline1-2')} <strong>${t('interactiveMainline1-7')} (${
          units.pressure
        })</strong></li>
        <li class='shepherd-text'>${t('interactiveMainline1-2')} <strong>${t('interactiveMainline1-9')} (${
          units.length
        })</strong></li>
        <li class='shepherd-text'>${t('interactiveMainline1-2')} <strong>${t('interactiveMainline1-10')} (${
          units.velocity
        })</strong></li>
        <li class='shepherd-text'><strong>${t('interactiveMainline1-12')} (${units.totalFlow})</strong> ${t(
          'interactiveMainline1-13'
        )}</li>
        <li class='shepherd-text'>${t('interactiveMainline1-14')} <strong>${t('interactiveMainline1-12')} (${
          units.totalFlow
        })</strong></li>
      </ol>
    </div>
    `,
      ],
      buttons: [
        {
          classes: 'btn btn-info',
          text: 'Finish',
          type: 'cancel',
        },
        {
          classes: 'btn btn-success',
          text: 'Next',
          type: 'next',
        },
      ],
      modalOverlayOpeningPadding: 3,
      useModalOverlay: true,
      canClickTarget: false,
    }),
    [t, units]
  );

  const MAINLINE_2 = {
    id: 'mainline-2',
    attachTo: {
      element: '#mainline-selected-product',
      on: 'right',
    },
    scrollTo: true,
    text: [
      `
    <div style="width: 582px">
    <p class="shepherd-text"><strong>Select Product</strong></p>
    <ol>
      <li class="shepherd-text">Select the Type of pipe</li>
      <li class="shepherd-text">Select the group in the Sub type.</li>
      <li class="shepherd-text">
        Select the Class
        <ol type="a">
          <li class="shepherd-text">Given all the input values are defined and that you selected the Class, the relevant pipe-file possibilities will be presented in the <strong>Pipe Diameters</strong></li>
        </ol>
      </li>
    </ol> 
    </div>
    `,
    ],
    buttons: [
      {
        classes: 'btn btn-info',
        text: 'Back',
        type: 'back',
      },
      {
        classes: 'btn btn-success',
        text: 'Next',
        type: 'next',
      },
    ],
    modalOverlayOpeningPadding: 4,
    useModalOverlay: true,
    canClickTarget: false,
  };

  const MAINLINE_3 = {
    id: 'mainline-3',
    attachTo: {
      element: '#mainline-result-graphically',
      on: 'top',
    },
    scrollTo: true,
    text: [
      `
    <div style="width: 435px">
    <p class="shepherd-text">The results will be also displayed in this section, graphically.</p>
    </div>
    `,
    ],
    buttons: [
      {
        classes: 'btn btn-info',
        text: 'Back',
        type: 'back',
      },
      {
        classes: 'btn btn-success',
        text: 'Next',
        type: 'next',
      },
    ],
    modalOverlayOpeningPadding: 4,
    useModalOverlay: true,
    canClickTarget: false,
  };

  return {
    MAINLINE_1,
    MAINLINE_2,
    MAINLINE_3,
  };
};
