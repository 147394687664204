import { FC, useState } from 'react';
import { EndUserLicense } from 'features/EndUserLicense';
import { useTranslation } from 'react-i18next';
import { customAxios } from 'shared/api';
import { useAppDispatch, useToast } from 'shared/hooks';
import {
  logout,
  resetEmitterFamilies,
  resetEmitters,
  resetEmittersEDC,
  resetLaterals,
  resetMainlines,
  resetMasterGroups,
  resetProjectData,
  resetProjects,
  resetSubmains,
  resetUnits,
} from 'shared/slices';

interface Props {
  open: boolean;
  handleClose: () => void;
  email: string;
  disableAccept?: boolean;
  loginCallback?: () => void;
}

export const EndUserLicenseAcceptance: FC<Props> = ({ open, handleClose, email, disableAccept, loginCallback }) => {
  const { showError, showSuccess } = useToast();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);

  const handleSendAccept = async (agreementStatus: boolean) => {
    try {
      setLoading(true);

      const body = {
        email,
        eulaVersion: '1.121',
        agreementStatus,
      };

      await customAxios.post('/be/User/update-user-terms-and-contitions', body);

      showSuccess(t(agreementStatus ? 'agreement_accepted' : 'agreement_not_accepted'));
    } catch (e: any) {
      console.log(e);
      showError(t('Something went wrong'));
    } finally {
      setLoading(false);
    }
  };

  const onAccept = async () => {
    if (disableAccept) {
      handleClose();
    } else {
      await handleSendAccept(true);
      await loginCallback?.();

      handleClose();
    }
  };

  const onDoNotAccept = async () => {
    await handleSendAccept(false);

    handleClose();
    dispatch(resetProjects());
    dispatch(resetEmitterFamilies());
    dispatch(resetEmitters());
    dispatch(resetEmittersEDC());
    dispatch(resetLaterals());
    dispatch(resetMainlines());
    dispatch(resetSubmains());
    dispatch(resetProjectData());
    dispatch(resetMasterGroups());
    dispatch(resetUnits());
    dispatch(logout());
  };

  return <EndUserLicense open={open} onAccept={onAccept} onDoNotAccept={onDoNotAccept} loading={loading} />;
};
