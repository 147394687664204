import { Points, SquareSC } from 'shared/lib/calculation/models';
import { Math_round } from './calculation/mathRound';

const ONE_SQUARE_SIZE = 10.24;

const getSquareSum = (startX: number, startY: number, array: number[][], squareSide: number) => {
  let sum = 0;
  const edgeX = startX + squareSide;
  const edgeY = startY + squareSide;

  for (let y = startY; y < edgeY; y++) {
    for (let x = startX; x < edgeX; x++) {
      const target = array?.[y]?.[x];

      if (target < 0) continue;
      sum += target;
    }
  }

  return sum;
};

const getAverageFullValue = (array: number[][]) => {
  let sum = 0;
  const arrHeight = array?.length;
  const arrWidth = array[0]?.length;

  for (let i = 0; i < arrHeight; i++) {
    for (let j = 0; j < arrWidth; j++) {
      const num = array[i][j];
      if (num < 0) continue;
      sum += num;
    }
  }

  // const sum = sumArr.reduce((sum, current) => sum + current, 0);
  const averageFullValue = sum / (arrHeight * arrWidth);

  return averageFullValue;
};

const getCalcEdges = (linePoints: Points[]) => {
  let edgeX: number | null = null;
  let edgeY: number | null = null;

  linePoints?.forEach(({ X1, X2, Y1, Y2 }) => {
    if (X1 === 0 && X2 === 0 && Y1 === 0 && Y2 === 0) return;

    if (X1 === X2) {
      edgeX = Math.floor(X1 / ONE_SQUARE_SIZE);
    }
    if (Y1 === Y2) {
      edgeY = Math.floor(Y1 / ONE_SQUARE_SIZE);
    }
  });
  return { edgeX, edgeY };
};

export const calculateSC = (array: number[][], percent: number, edgeLines: Points[], edge: number) => {
  const { edgeX, edgeY } = getCalcEdges(edgeLines);

  if (edge === 1) {
    array = array.slice(0, +edgeX!);
  }

  if (edge === 2) {
    array = array.map((item) => item.slice(0, +edgeY!));
  }

  if (edge === 3) {
    array = array.slice(0, +edgeX!);
    array = array.map((item) => item.slice(0, +edgeY!));
  }

  const arrHeight = array?.length;
  const arrWidth = array[0]?.length;
  const preCalcSide = Math.round(Math.sqrt(Math.round(((arrHeight * arrWidth) / 100) * percent)));
  const squareSide = preCalcSide > 1 ? preCalcSide : 1;

  const calcWidth = edgeY ? edgeY : arrWidth;
  const calcHeight = edgeX ? edgeX : arrHeight;

  const limitCoordX = calcWidth - squareSide + 1;
  const limitCoordY = calcHeight - squareSide + 1;

  let minSum = Infinity;
  let minSquare: SquareSC | null = null;
  let coordY = 0;
  let coordX = 0;

  while (coordY < limitCoordY) {
    if (coordX === limitCoordX) {
      coordY++;
      coordX = 0;
      continue;
    }

    const sum = getSquareSum(coordX, coordY, array, squareSide);

    if (minSum > sum) {
      minSum = sum;
      minSquare = { X: coordX, Y: coordY, side: squareSide };
    }

    coordX++;
  }

  const averageMinSumValue = minSum / Math.pow(squareSide, 2);
  const averageFullValue = getAverageFullValue(array);

  const sc = Math_round(averageFullValue / averageMinSumValue, 3);

  return { minSquare, sc };
};
export const calculateWettest = (array: number[][], percent: number, edgeLines: Points[], edge: number) => {
  const { edgeX, edgeY } = getCalcEdges(edgeLines);

  if (edge === 1) {
    array = array.slice(0, +edgeX!);
  }

  if (edge === 2) {
    array = array.map((item) => item.slice(0, +edgeY!));
  }

  if (edge === 3) {
    array = array.slice(0, +edgeX!);
    array = array.map((item) => item.slice(0, +edgeY!));
  }

  const arrHeight = array?.length;
  const arrWidth = array[0]?.length;
  const preCalcSide = Math.round(Math.sqrt(Math.round(((arrHeight * arrWidth) / 100) * percent)));
  const squareSide = preCalcSide > 1 ? preCalcSide : 1;

  const calcWidth = edgeY ? edgeY : arrWidth;
  const calcHeight = edgeX ? edgeX : arrHeight;

  const limitCoordX = calcWidth - squareSide + 1;
  const limitCoordY = calcHeight - squareSide + 1;

  let maxSum = 0;
  let maxSquare: SquareSC | null = null;
  let coordY = 0;
  let coordX = 0;

  while (coordY < limitCoordY) {
    if (coordX === limitCoordX) {
      coordY++;
      coordX = 0;
      continue;
    }

    const sum = getSquareSum(coordX, coordY, array, squareSide);

    if (maxSum < sum) {
      maxSum = sum;
      maxSquare = { X: coordX, Y: coordY, side: squareSide };
    }

    coordX++;
  }

  const wettest = Math_round(maxSum / (squareSide * squareSide), 3);

  return { maxSquare, wettest };
};
