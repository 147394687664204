import { List, ListItemButton, ListItemText, ListItemSecondaryAction } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { toggleLanguage } from 'shared/slices';
import { useTranslation } from 'react-i18next';
import { LANGUAGES } from 'shared/constants';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { Modal } from 'shared/ui';

export const Language = () => {
  const { i18n, t } = useTranslation();
  const dispatch = useAppDispatch();
  const { openLanguage } = useAppSelector((st) => st.modals);

  const toggle = () => dispatch(toggleLanguage());

  const handleItemClick = (languageCode: string) => {
    i18n.changeLanguage(languageCode);
  };

  return (
    <Modal maxWidth="xs" title={t('language')} open={openLanguage} onClose={toggle}>
      <List>
        {LANGUAGES.map((lang) => (
          <ListItemButton key={lang.key} onClick={() => handleItemClick(lang.key)}>
            <ListItemText primary={lang.label} />
            {(lang.key === i18n.language || lang.key === i18n.language.split('-')[0]) && (
              <ListItemSecondaryAction>
                <CheckIcon color="primary" />
              </ListItemSecondaryAction>
            )}
          </ListItemButton>
        ))}
      </List>
    </Modal>
  );
};
