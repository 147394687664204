import { Box, Checkbox, FormControlLabel, Link, Stack, styled, Typography, useMediaQuery } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { AuthInput, RegisterButton, ForgotPasswordLink } from 'shared/ui';
import { LoginBody } from 'shared/models';
import { useAppDispatch, useAppSelector, useDialog, useToast } from 'shared/hooks';
import { useTranslation } from 'react-i18next';
import { AccountNotActivatedModal, EndUserLicenseAcceptance, ImportantUpdateModal, LoginHeader } from 'features';
import { loginThunk, setUsernameFromLS } from 'shared/slices';

const Text = styled(Typography)(() => ({
  fontSize: '11px',
  fontWeight: 400,
  margin: '20px 0 8px 20px',
  textDecoration: 'none',
}));
const ErrorMessage = styled(Typography)(() => ({
  marginTop: '3px',
  paddingInline: '5px',
  color: '#F00',
  fontFamily: 'Roboto',
  fontSize: '18px',
  lineHeight: '130%',
  letterSpacing: '0.18px',
}));

const btnStyles = {
  textTransform: 'capitalize',
  borderRadius: '8px',
  minWidth: '120px',
  height: '48px',
  fontSize: 18,
};

const supportEmail = 'ht_support@rivulis.com';

export const LoginForm = () => {
  const { open: openAcceptance, toggle: toggleAcceptance } = useDialog();
  const { open: openActivation, toggle: toggleActivation } = useDialog();

  const { showError } = useToast();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:510px)');
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.auth.loading);

  const nameInputRef = useRef<HTMLInputElement>(null);

  const [loginData, setLoginData] = useState({ username: '', password: '' });
  const [errorState, setErrorState] = useState({ username: false, password: false });
  const [rememberMe, setRememberMe] = useState(false);
  const [isDisabledEmailInput, setIsDisabledEmailInput] = useState(false);

  const [isForOldHtUserPopup, setIsForOldHtUserPopup] = useState(false);
  const [isHtAccountWasUpgraded, setIsHtAccountWasUpgraded] = useState(false);
  const [isWrongCredentials, setIsWrongCredentials] = useState(false);

  const [navigateRegisterLink, setNavigateRegisterLink] = useState('');

  const handleLoginDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoginData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    setErrorState((prev) => ({ ...prev, [e.target.name]: false }));
    setIsHtAccountWasUpgraded(false);
    setIsWrongCredentials(false);
  };
  const handleRememberMeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRememberMe(e.target.checked);
  };

  const statusCallbacks: { [key: number]: () => void } = useMemo(
    () => ({
      400: () => setIsWrongCredentials(true),
      405: () => {
        setNavigateRegisterLink(`/register?oldUserName=${loginData.username}`);
        setIsForOldHtUserPopup(true);
      },
      411: () => {
        toggleAcceptance();
      },
      412: () => {
        toggleActivation();
      },
      608: () => {
        setNavigateRegisterLink(`/register?recreating=1&oldUserName=${loginData.username}`);
        setIsForOldHtUserPopup(true);
      },
      607: () => setIsHtAccountWasUpgraded(true),
      609: () => setIsHtAccountWasUpgraded(true),
      610: () => setIsHtAccountWasUpgraded(true),
    }),
    [loginData.username]
  );

  const loginBody = {
    user: loginData.username,
    password: loginData.password,
    rememberMe,
    isFromOldHT: true,
  };

  const handleLoginClick = async (cachedBody?: LoginBody) => {
    if (!loginData.username.length || !loginData.password.length) {
      setErrorState({ username: !loginData.username.length, password: !loginData.password.length });
      return;
    }

    const body: LoginBody = cachedBody || loginBody;

    setIsHtAccountWasUpgraded(false);
    localStorage.removeItem('ht-redirected-email');

    try {
      await dispatch(loginThunk(body)).unwrap();
      dispatch(setUsernameFromLS());
      localStorage.setItem('appVersion', process.env.REACT_APP_VERSION as string);

      if (rememberMe) {
        const remembered = window.btoa(encodeURIComponent(JSON.stringify(loginData)));
        localStorage.setItem('ht-saved-login-data', remembered);
      } else {
        localStorage.removeItem('ht-saved-login-data');
      }

      navigate('/lateral');
    } catch (e: any) {
      console.log(e);

      const callback = statusCallbacks[e];

      if (callback) {
        callback();
      } else {
        showError(t('wrong_login_credentials'));
      }
    }
  };

  const onCloseImportantUpdateModal = () => {
    navigate(navigateRegisterLink);
    localStorage.removeItem('ht-redirected-email');
  };
  const onRegisterClick = () => {
    navigate('/register');
    localStorage.removeItem('ht-redirected-email');
  };
  const onForgotPasswordClick = () => {
    window.location.href = String(process.env.REACT_APP_FORGOT_PASSWORD_URL);
    localStorage.removeItem('ht-redirected-email');
  };

  const onNeedHelpClick = () => window.open(`mailto:${supportEmail}`, '_blank');

  useEffect(() => {
    const htRedirectedEmail = localStorage.getItem('ht-redirected-email');

    if (htRedirectedEmail) {
      setIsDisabledEmailInput(true);
      setLoginData({ username: htRedirectedEmail, password: '' });
      return;
    }

    const userJson = localStorage.getItem('user');

    if (userJson) {
      const userData = JSON.parse(decodeURIComponent(window.atob(userJson))) as LoginBody;

      if (userData.rememberMe) {
        handleLoginClick(userData);
        return;
      }
    }

    const remembered = localStorage.getItem('ht-saved-login-data');
    if (!remembered) return;

    const savedLoginData = JSON.parse(decodeURIComponent(window.atob(remembered))) as {
      username: string;
      password: string;
    };

    if (!savedLoginData) return;

    if (savedLoginData?.username && savedLoginData?.password) {
      setLoginData(savedLoginData);
      setRememberMe(true);
    }
  }, []);

  return (
    <>
      <Stack alignItems="center" sx={{}}>
        <Box
          sx={{
            p: '40px 24px 10px 24px',
            width: isMobile ? '100%' : '480px',
            boxShadow: '0px -0.5px 4px 0px rgba(0, 0, 0, 0.25)',
            borderRadius: '16px',
          }}
        >
          <LoginHeader />

          <Stack mt={1} sx={{}}>
            <Box flexBasis="100%">
              <Text>{t('login')}</Text>
              <AuthInput
                name="username"
                value={loginData.username}
                onChange={handleLoginDataChange}
                required
                autoFocus
                placeholder={`${t('enter_email')}`}
                disabled={isDisabledEmailInput}
                inputRef={nameInputRef}
                error={errorState.username}
              />
              {errorState.username && <ErrorMessage>{t('Input your login')}</ErrorMessage>}

              {isHtAccountWasUpgraded && (
                <ErrorMessage>
                  {t('your_account_has_been_upgraded_use_your_email_and_the_new_password_to_login')}
                </ErrorMessage>
              )}

              {isWrongCredentials && (
                <ErrorMessage>{t('Incorrect username or password. Please try again.')}</ErrorMessage>
              )}
            </Box>

            <Box flexBasis="100%" mb={2}>
              <Text>{t('password')}</Text>

              <AuthInput
                name="password"
                value={loginData.password}
                onChange={handleLoginDataChange}
                required
                placeholder={`${t('enter_password')}`}
                password={'true'}
                error={errorState.password}
              />
              {errorState.password && <ErrorMessage>{t('Input your password')}</ErrorMessage>}
            </Box>

            <Box mt={1}>{/* <Terms toggleAdditionalTerms={toggleAdditionalTerms} /> */}</Box>

            <Box display="flex" justifyContent="space-between" alignItems="center" mt={1} flexWrap="wrap">
              <FormControlLabel
                control={<Checkbox value={rememberMe} checked={rememberMe} onChange={handleRememberMeChange} />}
                label={`${t('rememberMe')}`}
              />

              <ForgotPasswordLink onClick={onForgotPasswordClick} animated={isWrongCredentials}>
                {t('forgotPassword')}
              </ForgotPasswordLink>
            </Box>

            <Stack direction="row" justifyContent="space-between" columnGap={3} mt={1}>
              <Box>
                <RegisterButton onClick={onRegisterClick} animated={isWrongCredentials}>
                  {t('register')}
                </RegisterButton>
              </Box>
              <Box>
                <LoadingButton
                  onClick={() => handleLoginClick()}
                  loading={loading}
                  fullWidth
                  variant="contained"
                  color="primary"
                  sx={{ ...btnStyles }}
                >
                  {t('login')}
                </LoadingButton>
              </Box>
            </Stack>

            <Stack alignItems="center" mt={2}>
              <Typography onClick={onNeedHelpClick} sx={{ cursor: 'pointer', color: 'blue', fontSize: 16 }}>
                {t('need_help')}
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </Stack>

      <EndUserLicenseAcceptance
        open={openAcceptance}
        handleClose={toggleAcceptance}
        email={loginData.username}
        loginCallback={() => handleLoginClick(loginBody)}
      />

      <ImportantUpdateModal show={isForOldHtUserPopup} onClose={onCloseImportantUpdateModal} />

      <AccountNotActivatedModal open={openActivation} onClose={toggleActivation} email={loginData.username} />
    </>
  );
};
