import { Dialog, Stack, Typography } from '@mui/material';
import React from 'react';
import { Button } from '../../shared/ui';
import { useTranslation } from 'react-i18next';

interface Props {
  open: boolean;
  handleClose: () => void;
  backToLogin: () => void;
}

export const SuccessfulRegister: React.FC<Props> = ({ open, handleClose, backToLogin }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          padding: '48px 24px 40px',
          width: '324px',
          borderRadius: '16px',
          background: '#FFF',
          boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.16), 0px -1px 4px 0px rgba(0, 0, 0, 0.25)',
        },
      }}
    >
      <Stack sx={{ gap: '24px' }}>
        <Typography
          sx={{
            color: '#242731',
            textAlign: 'center',
            fontFamily: 'Roboto',
            fontSize: '18px',
            fontWeight: 600,
            lineHeight: '130%',
            letterSpacing: '0.18px',
          }}
        >
          {t('registration_submitted')}
        </Typography>
        <Typography
          sx={{
            color: '#242731',
            fontFamily: 'Roboto',
            fontSize: '18px',
            lineHeight: '130%',
            letterSpacing: '0.18px',
          }}
        >
          📧 {t('activation_email_is_on_its_way')}
          <br />
          <br />
          🔍 {t('check_your_inbox_and_spam')}
          <br />
          <br />
          👉{t('activate_your_account_to_login')}
          <br />
          <br />
        </Typography>

        <Button
          onClick={backToLogin}
          sx={{
            m: '0 auto',
            display: 'flex',
            width: '150px',
            padding: '8px 16px',
          }}
        >
          {t('back_to_login')}
        </Button>
      </Stack>
    </Dialog>
  );
};
