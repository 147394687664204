export function SetPlants(
  Staggered: boolean,
  Top: number,
  PlantDiameter: number,
  BetweenPlants: number,
  RowLength: number,
  Left_Renamed: number,
) {
  let NewY = 0;

  if (Staggered) {
    NewY = Top - BetweenPlants;
  } else {
    NewY = Top;
  }

  const Plants = [];
  while (NewY <= RowLength) {
    Plants.push({
      X: Left_Renamed,
      y: NewY,
      PlantDiameter: PlantDiameter,
    });
    NewY = NewY + BetweenPlants;
  }
  return Plants;
}
