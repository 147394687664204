export const HydraulicToolAppType = 1;

export const EDC_SPRINKLERS = [
  'S5000 HA',
  'S5000 LA',
  'S6000 HA',
  '5022-SD',
  '5022-SDU',
  '233B',
  '234',
  '423',
  '427AG',
  '427 AG U',
  '501-U',
  '5024SD',
  '5035-G',
  '5035SD',
  '6025-SD',
  'Mamkad 16 -LA',
  'Mamkad 16 - Reg.',
  'Super 10 LA10°',
  'Super 10 LA10° Reg.',
  'Super 10 LA14°',
  'Super 10 LA14° Reg.',
  '6004SD',
  'MagicDrive 14°',
  'MagicDrive 24°',
  'Mamkad 16',
  'Mamkad 16 Road Guard',
  'Mamkad 16 Regulated',

  'Super 10 Road Guard 25°',
  'Super 10 25°',
  'Super 10 25° Regulator',
];

export const EDC_MICRO_SPRINKLERS = [
  'R.F.R',
  'Rondo',
  'S2000',
  'S2000 FR',
  'Super XL',
  '2002',
  '2005',
  '7110-Medium range',
  '7110-Extra range',
  '7110 inverted rotor',
  'Flipper',
  'Flipper Regulated',
  'Green Spin',
  'Modular',
];
export const EDC_FOGGERS = ['Rondo Mist'];

export const LANGUAGES = [
  { key: 'en', label: 'English' },
  { key: 'fr', label: 'Française' },
  { key: 'es', label: 'Español' },
  { key: 'pt', label: 'Portugues' },
  { key: 'it', label: 'Italiano' },
  { key: 'he-IL', label: 'עברית' },
  { key: 'tr', label: 'Türk' },
];

export const UNITS = {
  Pressure: ['m', 'ft', 'bar', 'kpa', 'kg/cm²', 'atm', 'psi'],
  FlowPerLength: ['lph/100m', 'lph/m', 'gpm/100ft', 'gpm/ft'],
  Flow: ['lph', 'lpm', 'lps', 'gph', 'gpm'],
  TotalFlow: ['lph', 'lpm', 'lps', 'gph', 'gpm', 'm³/h', 'm³/s'],
  Length: ['mm', 'cm', 'in', 'm', 'ft', 'miles', 'km'],
  Velocity: ['m/s', 'ft/s', 'kph', 'mph'],
  PipeDiameter: ['mm', 'cm', 'in', 'm', 'ft'],
  EmitterSpacing: ['mm', 'cm', 'in', 'm', 'ft', 'miles', 'km'],
  LateralSpacing: ['mm', 'cm', 'in', 'm', 'ft', 'miles', 'km'],
  Area: ['m²', 'ft²', 'Ha', 'acres', 'dunam'],
  AppnDepth: ['mm', 'cm', 'in', 'm', 'ft'],
  AppnRate: ['mm/h', 'in/h'],
};

export const US_DEFAULTS = {
  Pressure: 'psi',
  FlowPerLength: 'gpm/100ft',
  Flow: 'gph',
  TotalFlow: 'gpm',
  Length: 'ft',
  Velocity: 'ft/s',
  PipeDiameter: 'in',
  EmitterSpacing: 'in',
  LateralSpacing: 'in',
  Area: 'acres',
  AppnDepth: 'in',
  AppnRate: 'in/h',
};

export const METRIC_DEFAULTS = {
  Pressure: 'm',
  FlowPerLength: 'lph/100m',
  Flow: 'lph',
  TotalFlow: 'm³/h',
  Length: 'm',
  Velocity: 'm/s',
  PipeDiameter: 'mm',
  EmitterSpacing: 'm',
  LateralSpacing: 'm',
  Area: 'Ha',
  AppnDepth: 'mm',
  AppnRate: 'mm/h',
};

export const HEADLOSS_CALCULATION = [
  { value: 'H.W', label: 'H.W' },
  { value: 'D.W', label: 'D.W' },
  { value: 'D.W-RE', label: 'D.W-RE' },
];

export const MANIFOLD_HEADLOSS_CALCULATION = [
  { value: 'H.W', label: 'H.W' },
  { value: 'D.W', label: 'D.W' },
];

export const EU_TYPE = [
  { value: 'Classic', label: 'Classic' },
  { value: 'Quarter', label: 'Quarter' },
  { value: 'Standard', label: 'Standard Deviation' },
];

export const SLOPE_DIRECTIONS = [
  { value: 'Uphill', label: 'Uphill' },
  { value: 'Downhill', label: 'Downhill' },
];
export const MAX_LENGTH = [
  { value: 'Minimum EU', label: 'MINIMUM EU' },
  { value: 'Q(delta)', label: 'FLOW VARIATION' },
  { value: 'Pressure', label: 'PRESSURE BASED' },
];

export const REGIONS = [
  {
    value: 'USA',
    label: 'USA',
  },
  {
    value: 'Israel',
    label: 'Israel',
  },
  {
    value: 'Europe',
    label: 'Europe',
  },
  {
    value: 'Australia',
    label: 'Australia',
  },
  {
    value: 'Latin America',
    label: 'Latin America',
  },
  {
    value: 'India',
    label: 'India',
  },
  {
    value: 'Brazil',
    label: 'Brazil',
  },
];

export const FILTER_TYPE = [
  {
    value: 400,
    label: 'F3300 Electric',
  },
  {
    value: 390,
    label: 'F3200 Hydraulic (Parallel)',
  },
  {
    value: 331.5,
    label: 'F3200 Hydraulic (Angled)',
  },
];

export const WATER_QUALITY = [
  {
    value: 10,
    label: 'Clean 10 PPM',
  },
  {
    value: 20,
    label: 'Low 20 PPM',
  },
  {
    value: 40,
    label: 'Medium 40 PPM',
  },
  {
    value: 80,
    label: 'High 80 PPM',
  },
  {
    value: 120,
    label: 'VeryHigh 120 PPM',
  },
];

export const SCREEN_GRADE = [
  {
    value: 80,
    label: '80 Microns',
  },
  {
    value: 100,
    label: '100  Microns',
  },
  {
    value: 130,
    label: '130 Microns',
  },
  {
    value: 150,
    label: '150 Microns',
  },
  {
    value: 200,
    label: '200 Microns',
  },
  {
    value: 250,
    label: '250 Microns',
  },
  {
    value: 300,
    label: '300 Microns',
  },
  {
    value: 400,
    label: '400 Microns',
  },
  {
    value: 500,
    label: '500  Microns',
  },
];

export const TIMER_UNITS = [
  {
    value: 'Minute',
    label: 'Minute',
  },
  {
    value: 'Hour',
    label: 'Hour',
  },
  {
    value: 'Day',
    label: 'Day',
  },
];

export const REGISTRATION_COUNTRIES = [
  '',
  'Afghanistan',
  'Albania',
  'Algeria',
  'American Samoa',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antarctica',
  'Antigua And Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia',
  'Bosnia And Herzegovina',
  'Botswana',
  'Bouvet Island',
  'Brazil',
  'British Indian Ocean Territory',
  'Brunei Darussalam',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cape Verde',
  'Cayman Islands',
  'Central African Republic',
  'Chad',
  'Chile',
  'China',
  'Christmas Island',
  'Cocos (keeling) Islands',
  'Colombia',
  'Comoros',
  'Congo',
  'Congo The Democratic Republic Of The',
  'Cook Islands',
  'Costa Rica',
  'Cote D\'ivoire',
  'Croatia',
  'Cuba',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'East Timor',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Ethiopia',
  'Falkland Islands (malvinas)',
  'Faroe Islands',
  'Fiji',
  'Finland',
  'France',
  'French Guiana',
  'French Polynesia',
  'French Southern Territories',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guam',
  'Guatemala',
  'Guinea',
  'Guinea-bissau',
  'Guyana',
  'Haiti',
  'Heard Island And Mcdonald Islands',
  'Holy See (vatican City State)',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran- Islamic Republic Of',
  'Iraq',
  'Ireland',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jordan',
  'Kazakstan',
  'Kenya',
  'Kiribati',
  'Korea- Democratic People\'s Republic Of',
  'Korea- Republic Of',
  'Kosovo',
  'Kuwait',
  'Kyrgyzstan',
  'Lao People\'s Democratic Republic',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libyan Arab Jamahiriya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macau',
  'Macedonia- The Former Yugoslav Republic Of',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Martinique',
  'Mauritania',
  'Mauritius',
  'Mayotte',
  'Mexico',
  'Micronesia- Federated States Of',
  'Moldova- Republic Of',
  'Monaco',
  'Mongolia',
  'Montserrat',
  'Montenegro',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'Netherlands Antilles',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'Niue',
  'Norfolk Island',
  'Northern Mariana Islands',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestinian Territory- Occupied',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Pitcairn',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Reunion',
  'Romania',
  'Russian Federation',
  'Rwanda',
  'Saint Helena',
  'Saint Kitts And Nevis',
  'Saint Lucia',
  'Saint Pierre And Miquelon',
  'Saint Vincent And The Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome And Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Georgia And The South Sandwich Islands',
  'Spain',
  'Sri Lanka',
  'Sudan',
  'Suriname',
  'Svalbard And Jan Mayen',
  'Swaziland',
  'Sweden',
  'Switzerland',
  'Syrian Arab Republic',
  'Taiwan- Province Of China',
  'Tajikistan',
  'Tanzania- United Republic Of',
  'Thailand',
  'Togo',
  'Tokelau',
  'Tonga',
  'Trinidad And Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks And Caicos Islands',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States',
  'United States Minor Outlying Islands',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Venezuela',
  'Viet Nam',
  'Virgin Islands- British',
  'Virgin Islands- U.s.',
  'Wallis And Futuna',
  'Western Sahara',
  'Yemen',
  'Zambia',
  'Zimbabwe',
];

export const REGISTRATION_OCCUPATION = ['', 'Design', 'Technical', 'Sales', 'Marketing', 'Other'];

export const SCALE_COEFFICIENT = 4.2;
export const SOURCE_CANVAS_COEFFICIENT = 0.38671875;
export const ELLIPSE_SIZE = 10.24 * SOURCE_CANVAS_COEFFICIENT;
