import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'shared/hooks';
import { Math_round } from 'shared/lib/calculation/mathRound';

export const useSystemSummaryReportData = () => {
  const { t } = useTranslation();
  const { units } = useAppSelector((st) => st.units);

  const { username } = useAppSelector((st) => st.auth);
  const { projectData } = useAppSelector((st) => st.projectData);
  const { submainValues } = useAppSelector((st) => st.submainState);
  const { lateralValues } = useAppSelector((st) => st.lateralState);
  const { mainlineValues } = useAppSelector((st) => st.mainlineState);
  const { submains } = useAppSelector((st) => st.submains);
  const { mainlines } = useAppSelector((st) => st.mainlines);

  const pressureAtEnd = Math_round(
    lateralValues.resultReportArray[lateralValues.resultReportArray.length - 1]?.inletPressure ?? 0,
    3
  );

  const infoTable = {
    data: [
      [t('preparedFor'), t('by'), t('_date')],
      [
        `${projectData.tradingName ?? ''} ${projectData.growerName ?? ''}`,
        username,
        new Date(Date.now()).toLocaleString(),
      ],
    ],
  };

  const lateralReport = useMemo(() => {
    return {
      // title: t('lateralRep'),
      name: t('lateralRep'),
      data: [
        [
          t('emitterConstant'),
          t('emitterExponent'),
          `${t('emitterSpacing')} (${units.length})`,
          `${t('nominalFlow')} (${units.flow})`,
          `${t('nominalPr')} (${units.pressure})`,
          `${t('internalDia')} (${units.pipeDiameter})`,
          t('kd'),
          t('lateralRep'),
          `${t('slope')} (%)`,
          `${t('inletPr')} ${units.pressure}`,
          `${t('lateralFlow')} ${units.totalFlow}`,
          t('eu'),
          t('Qmin/Qmax'),
          t('Du'),
          `${t('flushingEndVelocity')} ${units.velocity}`,
          `${t('flushingEndPr')} ${units.pressure}`,
        ],
        [
          '',
          '',
          projectData.emitterSpacing,
          projectData.emitterNominalFlow,
          projectData.emitterNominalPressure,
          projectData.lateralInletDia,
          projectData.lateralKd,
          projectData.lateralLength2,
          projectData.mainlineSlope,
          projectData.lateralInletPressure,
          lateralValues.resultTotalFlow ?? 0,
          lateralValues.resultEU ?? '',
          lateralValues.resultQMinQMax ?? '',
          lateralValues.resultMaxLengthDU ?? '',
          '',
          '',
        ],
      ],
    };
  }, [units, projectData, lateralValues]);

  const manifoldReport = useMemo(() => {
    const manifoldDescriptions = [
      submains.find((item) => item.catlog === projectData.manifoldPipe1)?.catlogDesc ?? '',
      submains.find((item) => item.catlog === projectData.manifoldPipe2)?.catlogDesc ?? '',
      submains.find((item) => item.catlog === projectData.manifoldPipe3)?.catlogDesc ?? '',
    ];

    return {
      name: t('manifoldRep'),
      data: [
        [
          t('catalog'),
          `${t('length')} (${units.length})`,
          `${t('dia')} (${units.pipeDiameter})`,
          `${t('veloctiy')} (${units.velocity})`,
        ],
        [
          manifoldDescriptions[0],
          projectData.manifoldPipe1Length,
          projectData.manifoldPipe1Dia,
          submainValues.velocity1,
        ],
        [
          manifoldDescriptions[1],
          projectData.manifoldPipe2Length,
          projectData.manifoldPipe2Dia,
          submainValues.velocity2,
        ],
        [
          manifoldDescriptions[2],
          projectData.manifoldPipe3Length,
          projectData.manifoldPipe3Dia,
          submainValues.velocity3,
        ],
      ],
    };
  }, [submains, units, projectData, submainValues]);

  const generalDataManifold = {
    data: [
      [t('flowRate'), 'headloss'],
      [projectData.totalFlow ?? '', projectData.manifoldHeadloss ?? ''],
    ],
  };

  const mainlineReport = useMemo(() => {
    const mainlineDescriptions = [
      mainlines.find((item) => item.catlog === projectData.mainlinePipe1)?.catlogDesc ?? '',
      mainlines.find((item) => item.catlog === projectData.manifoldPipe2)?.catlogDesc ?? '',
      mainlines.find((item) => item.catlog === projectData.manifoldPipe3)?.catlogDesc ?? '',
    ];

    return {
      name: t('mainlineRep'),
      data: [
        [
          t('catalog'),
          `${t('length')} (${units.length})`,
          `${t('dia')} (${units.pipeDiameter})`,
          `${t('veloctiy')} (${units.velocity})`,
        ],
        [
          mainlineDescriptions[0],
          projectData.mainlinePipe1Length,
          projectData.mainlinePipe1Dia,
          mainlineValues.velocity1,
        ],
        [
          mainlineDescriptions[1],
          projectData.mainlinePipe2Length,
          projectData.mainlinePipe2Dia,
          mainlineValues.velocity2,
        ],
        [
          mainlineDescriptions[2],
          projectData.mainlinePipe3Length,
          projectData.mainlinePipe3Dia,
          mainlineValues.velocity3,
        ],
      ],
    };
  }, [mainlines, projectData, mainlineValues]);

  const generalDataMainline = {
    data: [
      [t('flowRate'), t('headloss')],
      [projectData.totalFlow ?? '', projectData.mainlinePipeHeadloss ?? ''],
    ],
  };

  const systemReport = useMemo(() => {
    return {
      name: t('systemRep'),
      data: [
        [
          `${t('pumpPressure')} (${units.pressure})`,
          `${t('pumpHeadloss')} (${units.pressure})`,
          `${t('pressureAtHead')} (${units.pressure})`,
          `${t('pipesHeadloss')} (${units.pressure})`,
          `${t('nominalPr')} (${units.pressure})`,
          `${t('pressureAtValve')} (${units.pressure})`,
          `${t('valveHeadloss')} (${units.pressure})`,
          `${t('prAtManifoldInlet')} (${units.pressure})`,
          `${t('manifoldHeadloss')} (${units.pressure})`,
          `${t('inletPr')} (${units.pressure})`,
          `${t('lateralHL')} (${units.pressure})`,
          `${t('prAtLateralEnd')} (${units.pressure})`,
        ],
        [
          projectData.mainlinePumpPressure,
          mainlineValues.pumpHeadloss,
          +projectData.mainlinePumpPressure - +mainlineValues.pumpHeadloss,
          projectData.mainlinePipeHeadloss,
          projectData.emitterNominalPressure,
          projectData.mainlinePressureAtValve,
          mainlineValues.valveHeadloss,
          projectData.valvePressure,
          projectData.manifoldHeadloss,
          projectData.lateralInletPressure,
          lateralValues.resultTotalHeadloss || lateralValues.resultMaxLengthTotalHeadloss || 0,
          isNaN(pressureAtEnd) ? 0 : pressureAtEnd,
        ],
      ],
    };
  }, [units, projectData, mainlineValues, lateralValues, pressureAtEnd]);

  const pdfTableData = [
    lateralReport,
    manifoldReport,
    generalDataManifold,
    mainlineReport,
    generalDataMainline,
    systemReport,
  ];

  return {
    infoTable,
    pdfTableData,
  };
};
