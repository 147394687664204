import { Emitter } from '../models/Emitter';
import { SINGLE } from '../models/SINGLE';
import { GetEmission } from './getMaxMinEmissions';
import { v_at } from './v_at';
export function CalcSINGLE(CalcSIN: SINGLE) {
  let X = 0;
  let y = 0;
  let temp = 0;
  let FieldRainAt = CalcSIN.FieldRainAt;

  let erecord = CalcSIN.cbEDCEmitter.SelectedItem;
  let EffectivePlaces = GetEffectivePlaces(erecord);

  let dRange = EffectivePlaces * erecord.Basedis;
  CalcSIN.maxrange = dRange;
  CalcSIN.xlength = dRange * 2;
  CalcSIN.Ylength = dRange * 2;
  CalcSIN.fieldintervalX = CalcSIN.xlength / CalcSIN.FIELDRES;
  CalcSIN.fieldintervalY = CalcSIN.Ylength / CalcSIN.FIELDRES;

  for (y = 0; y <= Math.floor(CalcSIN.FIELDRES / 2); y++) {
    for (X = 0; X <= Math.floor(CalcSIN.FIELDRES / 2); X++) {
      FieldRainAt[X][y] = 0;
      FieldRainAt[X][y] = v_at(
        Math.floor(CalcSIN.FIELDRES / 2) - X,
        Math.floor(CalcSIN.FIELDRES / 2) - y,
        CalcSIN.fieldintervalX,
        CalcSIN.fieldintervalY,
        dRange,
        CalcSIN.cbEDCEmitter,
        CalcSIN.TUBENUM,
      );
      temp = FieldRainAt[X][y];
      if (FieldRainAt[X][y] == 0) {
        FieldRainAt[X][y] = -1;
      } else {
        if (temp > CalcSIN.max) {
          CalcSIN.max = temp;
        } else if (temp < CalcSIN.min) {
          CalcSIN.min = FieldRainAt[X][y];
          CalcSIN.MinChange = true;
        }
      }

      FieldRainAt[X][CalcSIN.FIELDRES - y] = FieldRainAt[X][y];
      FieldRainAt[CalcSIN.FIELDRES - X][CalcSIN.FIELDRES - y] = FieldRainAt[X][y];
      FieldRainAt[CalcSIN.FIELDRES - X][y] = FieldRainAt[X][y];
    }
  }
  let res = {
    dRange: dRange,
    FieldRainAt: FieldRainAt,
    EffectivePlaces: EffectivePlaces,
    max: CalcSIN.max,
    min: CalcSIN.min,
  };
  return res;
}

export function GetEffectivePlaces(rec: Emitter) {
  for (let i = 0; i <= 52; i++) {
    if (GetEmission(rec, i) == 0) {
      return i;
    }
  }
  return 0;
}
