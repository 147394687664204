import { Box, Grid, Stack } from '@mui/material';
import { EDCSwitchButton, PaperBox, Row, ShepherdBlock } from 'shared/ui';
import { Tools } from './Tools';
import { Gap } from './Gap';
import { Map } from './Map';
import { ThreeDChart } from './ThreeDChart';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { setEDCStateToolBar } from 'shared/slices';

export const IrrigationRateDiagram = () => {
  const dispatch = useAppDispatch();

  const {
    edcToolBar: { activeGraph },
  } = useAppSelector((st) => st.edcState);

  const toggleActiveGraph = () => dispatch(setEDCStateToolBar({ activeGraph: activeGraph === '2D' ? '3D' : '2D' }));

  return (
    <PaperBox sx={{ flexGrow: 1 }}>
      <Row sx={{ height: '100%' }} direction={{ xs: 'column', sm: 'row' }} alignItems="flex-start">
        {activeGraph === '2D' && <Tools />}

        <Stack height="100%" width="100%">
          <Box flexGrow={1}>
            {activeGraph === '2D' && (
              <ShepherdBlock id="edc-diagram">
                <Map />
              </ShepherdBlock>
            )}
            {activeGraph === '3D' && <ThreeDChart />}

            <Grid item xs={12} container justifyContent="center" columnSpacing={2} mt={2} mb={3}>
              <Grid item>
                <EDCSwitchButton text="2D" onClick={toggleActiveGraph} isActive={activeGraph === '2D'} />
              </Grid>
              <Grid item>
                <EDCSwitchButton text="3D" onClick={toggleActiveGraph} isActive={activeGraph === '3D'} />
              </Grid>
            </Grid>
          </Box>

          <Gap />
        </Stack>
      </Row>
    </PaperBox>
  );
};
