import { Typography } from '@mui/material';
import React from 'react';
import { ResponsiveContainer, LineChart, XAxis, YAxis, Tooltip, CartesianGrid, Legend, Line } from 'recharts';
import { useAppSelector } from 'shared/hooks';

const renderColorfulLegendText = (value: string) => (
  <Typography component="span" color="primary">
    {value}
  </Typography>
);

export const EDCTablesGraph: React.FC = () => {
  const { tablesGraph } = useAppSelector((state) => state.edcState);

  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart
        style={{ overflow: 'hidden' }}
        data={tablesGraph.values}
        margin={{
          top: 5,
          left: -15,
          right: 5,
        }}
      >
        <XAxis stroke="black" dataKey="step" />
        <YAxis stroke="black" />
        <Tooltip />
        <CartesianGrid strokeDasharray="3 3" />
        <Legend
          formatter={renderColorfulLegendText}
          iconSize={25}
          payload={[{ value: tablesGraph.item, type: 'line', color: '#428d4d' }]}
        />
        <Line
          dot={{ stroke: 'black', strokeWidth: 2 }}
          type="monotone"
          stroke="#245e2b"
          dataKey="value"
          strokeWidth={3}
          activeDot={{ r: 5 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
