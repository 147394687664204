import { EmitterEDC } from '../models';
import { Emitter } from '../lib/calculation/edc/models/Emitter';

export const convertToEmitterCalc = (emitterEDC: EmitterEDC) => {
  const emitter: Emitter = {
    ...emitterEDC,
    Basedis: emitterEDC.basedis,
    Ename: emitterEDC.ename,
    FlowRate: emitterEDC.flowRate,
    LastUpdate: emitterEDC.lastUpdate,
    Nozzle: emitterEDC.nozzle,
    Orient: emitterEDC.orient,
    Range: emitterEDC.range,
    RIser: emitterEDC.riser,
    Spinner: emitterEDC.spinner,
  };
  return emitter;
};
