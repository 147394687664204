import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from 'shared/hooks';
import { toCamelCase } from 'shared/lib';
import { SideButton } from 'widgets/SideMenu/ui';

interface Props {
  route: string;
}

export const NavButton: FC<Props> = ({ route }) => {
  const push = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const projectsLoading = useAppSelector((state) => state.projects.loading);
  const masterGroupsLoading = useAppSelector((state) => state.masterGroups.loading);
  const unitsLoading = useAppSelector((state) => state.units.loading);
  const projectDataLoading = useAppSelector((state) => state.projectData.loading);
  const emittersLoadedOnce = useAppSelector((state) => state.emitters.emittersLoadedOnce);
  const lateralsLoadedOnce = useAppSelector((state) => state.laterals.lateralsLoadedOnce);
  const mainlinesLoadedOnce = useAppSelector((state) => state.mainlines.mainlinesLoadedOnce);
  const submainsLoadedOnce = useAppSelector((state) => state.submains.submainsLoadedOnce);

  const loading =
    projectsLoading ||
    masterGroupsLoading ||
    unitsLoading ||
    projectDataLoading ||
    !lateralsLoadedOnce ||
    !emittersLoadedOnce ||
    !mainlinesLoadedOnce ||
    !submainsLoadedOnce;

  const menuTitle = route.replace('-', ' ').replace('/', '');

  const handleButtonClick = () => push(route);

  return (
    <SideButton
      disabled={loading}
      title={`${t(toCamelCase(menuTitle))}`}
      onClick={handleButtonClick}
      isWhite={pathname === route}
    />
  );
};
