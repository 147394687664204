import { Unit } from 'shared/models';
import { Math_round } from '../../mathRound';
import { ConvertUnit } from '../../unitConverter';
import { Emitter } from '../models/Emitter';
import { EmitterInputs } from '../models/EmitterInputs';
import { GetMaxMinEmissions } from './getMaxMinEmissions';

export function InputEmitterCalculation(emitter: Emitter, unit: Unit): EmitterInputs {
  let EffectivePlaces = 0;
  let max = 0;
  let minRate = 0;
  let TUBENUM = 51;
  const effectivePlaces = GetMaxMinEmissions(emitter, EffectivePlaces, TUBENUM, max, minRate).EffectivePlaces;
  // const baseDis = ConvertUnit(0.25, METRIC_DEFAULTS.EmitterSpacing, unit.emitterSpacing, null);
  const effeBaseDisResult = (effectivePlaces - 1) * emitter.Basedis;
  const effeBaseDisResult2 = (effectivePlaces / 5) * emitter.Basedis;

  let emitterInputs: EmitterInputs = {
    bedWith: Math_round(effeBaseDisResult, 3),
    emitterSpacing: Math_round(effeBaseDisResult, 3),
    lateralSpacing: Math_round(effeBaseDisResult, 3),
    maxEmitters: Math_round(effeBaseDisResult, 3),
    maxLaterals: Math_round(effeBaseDisResult, 3),
    minEmitters: Math_round(effeBaseDisResult2, 3),
    minLaterals: Math_round(effeBaseDisResult2, 3),
    stepEmitters: Math_round(effeBaseDisResult2, 3),
    stepLaterals: Math_round(effeBaseDisResult2, 3),
    edgeInRow: Math_round(
      ConvertUnit((effectivePlaces * emitter.Basedis) / 2, unit.emitterSpacing, unit.length, null),
      3
    ),
    edgeOffRow: Math_round(
      ConvertUnit((effectivePlaces * emitter.Basedis) / 2, unit.emitterSpacing, unit.length, null),
      3
    ),
  };

  return emitterInputs;
}
