import { FC } from 'react';
import { Collapse, List, ListItemButton, ListItemText } from '@mui/material';
import { SideButton } from '../../ui';
import { Row } from 'shared/ui';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { TOGGLE_ACTION } from 'widgets/SideMenu/constants';
import { modalActions } from 'shared/slices';
import { useTranslation } from 'react-i18next';

export const RegionBlock: FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { projectData } = useAppSelector((state) => state.projectData);
  const projectsLoading = useAppSelector((state) => state.projects.loading);
  const masterGroupsLoading = useAppSelector((state) => state.masterGroups.loading);
  const unitsLoading = useAppSelector((state) => state.units.loading);
  const projectDataLoading = useAppSelector((state) => state.projectData.loading);
  const emittersLoadedOnce = useAppSelector((state) => state.emitters.emittersLoadedOnce);
  const lateralsLoadedOnce = useAppSelector((state) => state.laterals.lateralsLoadedOnce);
  const mainlinesLoadedOnce = useAppSelector((state) => state.mainlines.mainlinesLoadedOnce);
  const submainsLoadedOnce = useAppSelector((state) => state.submains.submainsLoadedOnce);

  const handleItemClick = () => {
    dispatch(modalActions[TOGGLE_ACTION.TOGGLE_REGIONS]());
  };

  const loading =
    projectsLoading ||
    masterGroupsLoading ||
    unitsLoading ||
    projectDataLoading ||
    !lateralsLoadedOnce ||
    !emittersLoadedOnce ||
    !mainlinesLoadedOnce ||
    !submainsLoadedOnce;

  return (
    <>
      <Row sx={{ position: 'relative' }}>
        <SideButton title={t('region')} divider />
      </Row>

      <Collapse in={true} unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton
            disabled={loading}
            key={projectData.region}
            onClick={handleItemClick}
            sx={{ p: 0, pl: '20px' }}
          >
            <ListItemText
              primary={projectData.region}
              sx={{
                span: { fontSize: 12 },
                textTransform: 'uppercase',
                color: 'info.main',
              }}
            />
          </ListItemButton>
        </List>
      </Collapse>
    </>
  );
};
