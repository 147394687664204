import { LateralCondition } from './HandleFiled/LateralCondition';
import { TriangularCondition } from './HandleFiled/TriangularCondition';

export function StoreFieldValues(
  FieldRainAt: Array<Array<number>>,
  Placement: number,
  TRIANGULAR_Y_LAYOUT: number,
  BILATERAL_TRIANGULAR_LAYOUT: number,
  LATERAL_LAYOUT: number,
  tempdrainat: Array<Array<number>>,
) {
  let StoreField = Array(Array(FieldRainAt.length + 1));
  let StoreTemp = Array(Array(FieldRainAt.length + 1));

  for (let c = 0; c < FieldRainAt.length; c++) {
    StoreField[c] = Array(FieldRainAt[0].length);
    for (let k = 0; k < FieldRainAt[0].length; k++) {
      StoreField[c][k] = FieldRainAt[c][k];
    }
  }

  if (
    TriangularCondition(Placement, TRIANGULAR_Y_LAYOUT, BILATERAL_TRIANGULAR_LAYOUT) ||
    LateralCondition(Placement, LATERAL_LAYOUT)
  ) {
    for (let c = 0; c < FieldRainAt.length; c++) {
      StoreTemp[c] = Array(FieldRainAt[0].length);
      for (let k = 0; k < FieldRainAt[0].length; k++) {
        StoreTemp[c][k] = tempdrainat[c][k];
      }
    }
  }

  const result = {
    StoreTemp: StoreTemp,
    StoreField: StoreField,
  };
  return result;
}
