import { EmitterEDC } from 'shared/models';
import { Math_round } from './calculation/mathRound';

export const formatEDCProductName = (emitter: EmitterEDC) => {
  return `Emitter: ${emitter.ename} Spinner: ${emitter.spinner} Nozzle: ${emitter.nozzle} ${
    emitter.orient ? 'UPSD' : 'UP'
  } Pressure: ${emitter.pressure}[bar] Riser: ${emitter.riser}[cm] FlowRate: ${Math_round(
    emitter.flowRate,
    3
  )}[l/h] Range: ${emitter.range}[m]`;
};
