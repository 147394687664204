import { FC } from 'react';
import { Dialog, Link, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

interface Props {
  show: boolean;
  onClose: () => void;
  mergeData?: {
    oldUserName: string;
    userName: string;
    email: string;
    organizationName: string;
    phoneNumber: string;
    occupation: string;
    country: string;
    receiveUpdates: boolean;
  };
}

export const EmailAlreadyRegisteredModal: FC<Props> = ({ show, onClose, mergeData }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleNavigateToRedirectedLogin = () => {
    localStorage.setItem('mergeData', JSON.stringify(mergeData));
    navigate('/redirected-login');
  };

  return (
    <Dialog
      open={show}
      onClose={onClose}
      PaperProps={{
        sx: {
          padding: '48px 24px 60px',
          width: '324px',
          borderRadius: '16px',
          background: '#FFF',
          boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.16), 0px -1px 4px 0px rgba(0, 0, 0, 0.25)',
        },
      }}
    >
      <Stack sx={{ gap: '24px' }}>
        <Typography
          sx={{
            color: '#242731',
            textAlign: 'center',
            fontFamily: 'Roboto',
            fontSize: '18px',
            fontWeight: 600,
            lineHeight: '130%',
            letterSpacing: '0.18px',
          }}
        >
          {t('Email already registered')}
        </Typography>
        <Typography
          sx={{
            color: '#242731',
            fontFamily: 'Roboto',
            fontSize: '18px',
            lineHeight: '130%',
            letterSpacing: '0.18px',
          }}
        >
          {t('Go back to the ')}
          <Link onClick={() => navigate('/login')} sx={{ cursor: 'pointer', mx: 0.5, color: 'blue' }}>
            {t('login page')}
          </Link>
        </Typography>
        <Typography
          sx={{
            color: '#242731',
            fontFamily: 'Roboto',
            fontSize: '18px',
            lineHeight: '130%',
            letterSpacing: '0.18px',
          }}
        >
          {t('Or click here to ')}
          <Link onClick={handleNavigateToRedirectedLogin} sx={{ cursor: 'pointer', mx: 0.5, color: 'blue' }}>
            {t('merge accounts')}
          </Link>
        </Typography>
      </Stack>
    </Dialog>
  );
};
