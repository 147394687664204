import { Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useLayoutEffect, useRef, useState } from 'react';

export const ValidationError = ({ content }: { content: string }) => {
  const [height, setHeight] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!ref.current) return;

    setHeight(ref.current.clientHeight + 12);
  }, [ref]);

  return (
    <Paper
      ref={ref}
      sx={{
        backgroundColor: 'transparent',
        boxShadow: 'none',
        borderRadius: 0,
        position: 'absolute',
        right: 15,
        //client height + arrow height
        top: -height,
        zIndex: 9999,
        maxWidth: '95px',
      }}
    >
      <Box
        sx={{
          backgroundColor: '#F6DCDD',
          borderBottom: '1px solid #F48B8C',
          borderRight: '1px solid #F48B8C',
          content: '""',
          display: 'block',
          position: 'absolute',
          width: 11,
          height: 11,
          bottom: '-5px',
          transform: 'rotate(45deg)',
          left: 'calc(50% - 6px)',
        }}
      />
      <Typography
        sx={{
          p: '10px 6px',
          fontWeight: 500,
          fontSize: '10px',
          lineHeight: '13px',
          textAlign: 'center',
          background: '#F6DCDD',
          border: '1px solid #F48B8C',
          borderRadius: '9px',
          color: '#FA5557',
          maxWidth: '95px',
        }}
      >
        {content}
      </Typography>
    </Paper>
  );
};
