import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

export const useSysSum = () => {

  const [isMac, setIsMac] = useState<boolean>(false)

  const {t} = useTranslation()

  useEffect(() => {
    setIsMac(window.innerWidth >= 1400 && window.innerWidth < 1500);
  }, []);

const SYS_SUM_1: any = useMemo(() => ({
  id: 'sys-sum-1',
  attachTo: {
    element: '#sys-sum-input',
    on: 'right-end',
  },
  scrollTo: true,
  text: [
    `
    <div style="width: 385px">
    <p class="shepherd-text"><strong>${t('interactiveSysSum1-1')}</strong> ${t('interactiveSubmainCalc1-9')} <strong>${t('interactiveSysSum1-2')}</strong></p>
    <ol type="a">
      <li class="shepherd-text">${t('interactiveSysSum1-3')}</li>
      <li class="shepherd-text">${t('interactiveSysSum1-4')}</li>
    </ol>
    </div>
    `,
  ],
  buttons: [
    {
      classes: 'btn btn-info',
      text: 'Finish',
      type: 'cancel',
    },
    {
      classes: 'btn btn-success',
      text: 'Next',
      type: 'next',
    },
  ],
  modalOverlayOpeningPadding: 3,
  useModalOverlay: true,
  canClickTarget: false,
}), [t]);

const SYS_SUM_3: any = useMemo(() => ({
  id: 'sys-sum-3',
  attachTo: {
    element: '#sys-sum-buttons',
    on: 'right',
  },
  scrollTo: true,
  text: [
    `
    <ol style="width: 359px">
      <li class="shepherd-text">${t('interactiveLateral2-1')} <strong>${t('calculate')}</strong> ${t('interactiveSysSum1-5')}</li>
      <li class="shepherd-text">${t('interactiveLateral2-1')} <strong>${t('interactiveLateral4-2')}</strong> ${t('interactiveSysSum1-6')}</li>
    </ol>
    `,
  ],
  buttons: [
    {
      classes: 'btn btn-info',
      text: 'Back',
      type: 'back',
    },
    {
      classes: 'btn btn-success',
      text: 'Next',
      type: 'next',
    },
  ],
  modalOverlayOpeningPadding: 4,
  useModalOverlay: true,
  canClickTarget: false,
}),[t]);

const SYS_SUM_4: any = useMemo(() => ({
  id: 'sys-sum-4',
  attachTo: {
    element: '#sys-sum-pressure-table',
    on: 'left-start',
  },
  scrollTo: true,
  text: [
    `
    <div style="width: 354px">
    <p class="shepherd-text"><strong>${t('interactiveSysSum1-7')}</strong> -</p>

    <p class="shepherd-text">${t('interactiveSysSum1-8')}</p>
  </div>    
`,
  ],
  buttons: [
    {
      classes: 'btn btn-info',
      text: 'Back',
      type: 'back',
    },
    {
      classes: 'btn btn-success',
      text: 'Next',
      type: 'next',
    },
  ],
  modalOverlayOpeningPadding: 4,
  useModalOverlay: true,
  canClickTarget: false,
}), [t]);

const SYS_SUM_5: any = useMemo(() => ({
  id: 'sys-sum-5',
  attachTo: {
    element: '#sys-sum-result-graphically',
    on: 'left',
  },
  scrollTo: true,
  text: [
    `
    <div style="width: 435px">
      <p class="shepherd-text"><strong>${t('interactiveLateral5')}</strong></p>
    </div>
    `,
  ],
  buttons: [
    {
      classes: 'btn btn-info',
      text: 'Back',
      type: 'back',
    },
    {
      classes: 'btn btn-success',
      text: 'Next',
      type: 'next',
    },
  ],
  modalOverlayOpeningPadding: 4,
  useModalOverlay: true,
  canClickTarget: false,
}), [t]);

const SYS_SUM_6: any = useMemo(() => ({
  id: 'sys-sum-6',
  attachTo: {
    element: '#sidebar-help',
    on: 'right-start',
  },
  scrollTo: true,
  text: [
    `
    <div style="width: 700px">
    <p class="shepherd-text" style="font-weight: 400;>${t('interactiveSysSum1-9')}</p>

    <p class="shepherd-text">${t('Click on the ‘Help’ button')}</p>
  </div>    
`,
  ],
  buttons: [
    {
      classes: 'btn btn-info',
      text: 'Back',
      type: 'back',
    },
    {
      classes: 'btn btn-success',
      text: 'Finish',
      type: 'cancel',
    },
  ],
  modalOverlayOpeningPadding: 3,
  useModalOverlay: true,
  canClickTarget: false,
}), [t]);

return {
  SYS_SUM_1,
  SYS_SUM_3,
  SYS_SUM_4,
  SYS_SUM_5,
  SYS_SUM_6
}

}