import { SelectChangeEvent } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { customAxios } from 'shared/api';
import { useAppSelector } from 'shared/hooks';
import { CatalogItem, CatalogsResponse, GroupsResponse } from 'shared/models';

const ALL = 'All';

export const useSubmains = ({
  region,
  handleSubmainDataChange,
}: {
  region: string;
  handleSubmainDataChange: (
    value: Partial<{
      masterGroup: string;
      group: string;
      submain: string;
    }>
  ) => void;
}) => {
  const { submainGroups, submains: defaultSubmains } = useAppSelector((state) => state.submains);
  const { projectData } = useAppSelector((state) => state.projectData);

  const [groupTypes, setGroupTypes] = useState(submainGroups);
  const [submains, setSubmains] = useState<CatalogItem[]>(defaultSubmains);

  const [groupsLoading, setGroupsLoading] = useState(false);
  const [submainsLoading, setSubmainsLoading] = useState(false);

  const [masterGroupId, setMasterGroupId] = useState(projectData.submainMasterGroup);
  const [groupTypeId, setGroupTypeId] = useState(projectData.submainGroup);
  const [classType, setClassType] = useState<string | number>(ALL);
  const [submain, setSubmain] = useState<CatalogItem>(
    () => submains.find((item) => item.catlog === projectData.submainCatalog) as CatalogItem
  );

  useEffect(() => {
    handleSubmainDataChange({ submain: submain?.catlog });
  }, [submain]);

  useEffect(() => {
    handleSubmainDataChange({ group: groupTypeId });
  }, [groupTypeId]);

  useEffect(() => {
    handleSubmainDataChange({ masterGroup: masterGroupId });
  }, [masterGroupId]);

  useEffect(() => {
    updateSubmains(groupTypeId, region);
  }, [region]);

  const updateSubmains = async (group: string, region: string) => {
    setSubmainsLoading(true);
    try {
      const submainsResponse = await customAxios.get<CatalogsResponse>(
        'be/HtCatalog/GetCatalogPerDiaClassFlowSpacing',
        {
          params: {
            group,
            region,
          },
        }
      );

      setSubmains(submainsResponse.data.data);
      setSubmain(submainsResponse.data.data[0] ?? {});
    } catch (e) {
      setSubmains([]);
      setSubmain({} as CatalogItem);
      console.error(e);
    }

    setSubmainsLoading(false);
  };

  const onMasterGroupChange = async (e: SelectChangeEvent<unknown>) => {
    setMasterGroupId(e.target.value as string);
    resetFilters();

    setGroupsLoading(true);
    const groupTypesResponse = await customAxios.get<GroupsResponse>('be/HtCatalog/GetGroupTypes', {
      params: { masterGroup: e.target.value },
    });

    setGroupTypes(groupTypesResponse.data.data.rootResults);
    setGroupTypeId(groupTypesResponse.data.data.rootResults[0]?.groups);
    setGroupsLoading(false);

    await updateSubmains(groupTypesResponse.data.data.rootResults[0]?.groups, region);
  };
  const onGroupTypeChange = async (e: SelectChangeEvent<unknown>) => {
    setGroupTypeId(e.target.value as string);
    resetFilters();

    await updateSubmains(e.target.value as string, region);
  };
  const onSubmainChange = (e: any, item: CatalogItem) => {
    setSubmain(item);
  };

  const onClassTypeChange = (e: SelectChangeEvent<unknown>) => {
    setClassType(e.target.value as string);
  };

  const classTypeFilter = (item: CatalogItem) => {
    // if (projectData.Region === 'USA' && item.AltClass !== null) {
    //   return submainClassType ? item.AltClass?.toString().trim() === submainClassType : true;
    // }

    return classType !== 'All' ? item.class?.toString().trim() === classType : true;
  };

  const resetFilters = () => {
    setClassType(ALL);
  };

  const submainsFiltered = useMemo(() => {
    const result = submains.filter((item) => classTypeFilter(item));

    if (!result.find((item) => item.catlog === submain?.catlog)) {
      setSubmain(result[0]);
    }

    return result;
  }, [classType, submains]);

  const classTypes = useMemo(() => {
    setClassType(ALL);

    return [ALL, ...new Set(submains.map((item) => item.class))];
  }, [submains]);

  return {
    masterGroupId,
    onMasterGroupChange,
    onGroupTypeChange,
    classType,
    onClassTypeChange,
    classTypes,
    submain,
    onSubmainChange,
    submainsFiltered,
    groupTypes,
    groupsLoading,
    submainsLoading,
    groupTypeId,
  };
};
