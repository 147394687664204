import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { customAxios } from 'shared/api';
import { EmittersEnamesItem, EmittersEnamesResult } from 'shared/models';

interface emitterFamiliesState {
  loading: boolean;
  emitterFamiliesLoadedOnce: boolean;
  currentEmitterFamily: EmittersEnamesItem;
  emitterFamilies: EmittersEnamesItem[];
  comparisonLoading: boolean;
  comparisonEmitterFamiliesLoadedOnce: boolean;
  comparisonCurrentEmitterFamily: EmittersEnamesItem;
  comparisonEmitterFamilies: EmittersEnamesItem[];
}

const initialState: emitterFamiliesState = {
  loading: true,
  emitterFamiliesLoadedOnce: true,
  currentEmitterFamily: {} as EmittersEnamesItem,
  emitterFamilies: [],
  comparisonLoading: true,
  comparisonEmitterFamiliesLoadedOnce: true,
  comparisonCurrentEmitterFamily: {} as EmittersEnamesItem,
  comparisonEmitterFamilies: [],
};

export const getEmittersFamiliesThunk = createAsyncThunk('getEmittersFamilies', async (_, thunkAPI) => {
  try {
    const response = await customAxios.get<EmittersEnamesResult>('be/HtCatalog/GetEmitterNames');
    return response.data;
  } catch (e) {
    if (e instanceof Error) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
});

export const getComparisonEmittersFamiliesThunk = createAsyncThunk(
  'getComparisonEmittersFamilies',
  async (_, thunkAPI) => {
    try {
      const response = await customAxios.get<EmittersEnamesResult>('be/HtCatalog/GetEmitterNames');
      return response.data;
    } catch (e) {
      if (e instanceof Error) {
        return thunkAPI.rejectWithValue(e.message);
      }
    }
  }
);

const emitterFamiliesSlice = createSlice({
  name: 'emitterFamilies',
  initialState,
  reducers: {
    setCurrentEmitterFamily(state, action: PayloadAction<EmittersEnamesItem>) {
      state.currentEmitterFamily = action.payload;
    },
    setComparisonCurrentEmitterFamily(state, action: PayloadAction<EmittersEnamesItem>) {
      state.comparisonCurrentEmitterFamily = action.payload;
    },
    resetEmitterFamilies: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEmittersFamiliesThunk.pending.type, (state) => {
        state.loading = true;
      })
      .addCase(getEmittersFamiliesThunk.fulfilled.type, (state, action: PayloadAction<EmittersEnamesResult>) => {
        state.emitterFamilies = action.payload;
        state.currentEmitterFamily = action.payload[0];
        state.loading = false;
        if (!state.emitterFamiliesLoadedOnce) state.emitterFamiliesLoadedOnce = true;
      })
      .addCase(getEmittersFamiliesThunk.rejected.type, (state) => {
        state.loading = false;
      })
      .addCase(getComparisonEmittersFamiliesThunk.pending.type, (state) => {
        state.comparisonLoading = true;
      })
      .addCase(
        getComparisonEmittersFamiliesThunk.fulfilled.type,
        (state, action: PayloadAction<EmittersEnamesResult>) => {
          state.comparisonEmitterFamilies = action.payload;
          state.comparisonCurrentEmitterFamily = action.payload[1] ?? action.payload[0];
          state.comparisonLoading = false;
          if (!state.emitterFamiliesLoadedOnce) state.comparisonEmitterFamiliesLoadedOnce = true;
        }
      )
      .addCase(getComparisonEmittersFamiliesThunk.rejected.type, (state) => {
        state.comparisonLoading = false;
      });
  },
});

export const { resetEmitterFamilies, setCurrentEmitterFamily, setComparisonCurrentEmitterFamily } =
  emitterFamiliesSlice.actions;

export const emitterFamiliesReducer = emitterFamiliesSlice.reducer;
