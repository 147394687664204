import { FC, useState } from 'react';
import { Collapse, List, ListItemButton, ListItemText } from '@mui/material';
import { SideButton } from '../../ui';
import { arrow } from 'shared/assets';
import { Icon, Row } from 'shared/ui';
import { useAppDispatch, useAppSelector, useToast } from 'shared/hooks';
import { ACTION_TYPE, NestedItemType, TOGGLE_ACTION } from 'widgets/SideMenu/constants';
import { modalActions, saveProjectThunk } from 'shared/slices';
import { toCamelCase } from 'shared/lib';
import { useTranslation } from 'react-i18next';

export const NestedItemsButton: FC<NestedItemType> = ({ title, items }) => {
  //TODO: add loaded once params
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { showError, showSuccess } = useToast();
  const { currentProject } = useAppSelector((state) => state.projects);
  const { projectData } = useAppSelector((state) => state.projectData);
  const projectsLoading = useAppSelector((state) => state.projects.loading);
  const masterGroupsLoading = useAppSelector((state) => state.masterGroups.loading);
  const unitsLoading = useAppSelector((state) => state.units.loading);
  const projectDataLoading = useAppSelector((state) => state.projectData.loading);
  const emittersLoadedOnce = useAppSelector((state) => state.emitters.emittersLoadedOnce);
  const lateralsLoadedOnce = useAppSelector((state) => state.laterals.lateralsLoadedOnce);
  const mainlinesLoadedOnce = useAppSelector((state) => state.mainlines.mainlinesLoadedOnce);
  const submainsLoadedOnce = useAppSelector((state) => state.submains.submainsLoadedOnce);

  const [expand, setExpand] = useState(false);
  const handleClick = () => setExpand((prev) => !prev);

  //TODO: not the best solution, need to think about it!
  const saveProject = async () => {
    const result = await dispatch(
      saveProjectThunk({
        projectData: { ...projectData, id: currentProject.projectID },
        projectId: currentProject.projectID,
      })
    ).unwrap();

    if (result?.data) showSuccess(t('projectSaved'));
    if (!result?.data) showError(t('SomethingWentWrong'));
  };

  const handleItemClick = (action: TOGGLE_ACTION, type: ACTION_TYPE) => {
    if (type === ACTION_TYPE.MODAL) {
      dispatch(modalActions[action]());
    }

    if (type === ACTION_TYPE.UTIL) {
      saveProject();
    }
  };

  const loading =
    projectsLoading ||
    masterGroupsLoading ||
    unitsLoading ||
    projectDataLoading ||
    !lateralsLoadedOnce ||
    !emittersLoadedOnce ||
    !mainlinesLoadedOnce ||
    !submainsLoadedOnce;

  return (
    <>
      <Row sx={{ position: 'relative' }}>
        <SideButton title={title} divider onClick={handleClick} />
        <Icon
          onClick={handleClick}
          src={arrow}
          sx={{
            position: 'absolute',
            right: 15,
            transform: `rotate(${expand ? 180 : 0}deg)`,
            transition: 'all 0.3s',
            cursor: 'pointer',
          }}
        />
      </Row>

      <Collapse in={expand} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {items.map(({ subTitle, action, type }) => (
            <ListItemButton
              disabled={loading && subTitle !== 'New'}
              key={subTitle}
              onClick={() => handleItemClick(action, type)}
              sx={{ p: 0, pl: '20px' }}
            >
              <ListItemText
                primary={`${t(toCamelCase(subTitle))}`}
                sx={{
                  span: { fontSize: 12 },
                  // textTransform: 'uppercase',
                  color: 'info.main',
                }}
              />
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    </>
  );
};
