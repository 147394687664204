export function ConvertUnit(value: number, sourceUnit: string, destUnit: string, unitType: string | null) {
  const f1 = GetMetricFactor(unitType, sourceUnit);
  const f2 = GetMetricFactor(unitType, destUnit);
  return (Math.fround(value) * Math.fround(f2)) / Math.fround(f1);
}

function GetMetricFactor(unitType: string | null, unit: string) {
  let factor = 1.0;
  switch (unitType) {
    case 'Flow':
      switch (unit) {
        case 'lpm':
          factor = 1.0 / 60.0;
          break;
        case 'gph':
          factor = 0.2642;
          break;
        case 'gpm':
          factor = 0.004403;
          break;
      }
      break;
    case 'PipeDiameter':
      switch (unit) {
        case 'in':
          factor = 0.03937;
          break;
        case 'mm':
          factor = 1;
          break;
        case 'cm':
          factor = 0.1;
          break;
        case 'm':
          factor = 0.001;
          break;
        case 'ft':
          factor = 0.003281;
          break;
      }
      break;
    default:
      switch (unit) {
        case 'psi':
          factor = 1.422;
          break;
        case 'ft':
          factor = 3.28083;
          break;
        case 'bar':
          factor = 0.09804;
          break;
        case 'kpa':
          factor = 9.804;
          break;
        case 'kg/cm²':
          factor = 0.1;
          break;
        case 'atm':
          factor = 0.09678;
          break;
        case 'lph/m':
          factor = 0.01;
          break;
        case 'gpm/100ft':
          factor = 0.00134;
          break;
        case 'gpm/ft':
          factor = 1.34e-5;
          break;
        case 'm³/m':
          factor = 1.0 / 60.0;
          break;
        case 'm³/s':
          factor = 1.0 / 3600.0;
          break;
        case 'lps':
          factor = 1.0 / 3.6;
          break;
        case 'lpm':
          factor = 60.0 / 3.6;
          break;
        case 'lph':
          factor = 1000;
          break;
        case 'gpm':
          factor = 4.403;
          break;
        case 'gps':
          factor = 0.07338;
          break;
        case 'gph':
          factor = 264.2;
          break;
        //m
        case 'km':
          factor = 1.0 / 1000.0;
          break;
        case 'cm':
          factor = 100.0;
          break;
        case 'miles':
          factor = 0.0006214;
          break;
        case 'in':
          factor = 3.28083 * 12;
          break;
        case 'mm':
          factor = 1000.0;
          break;
        //"m/s"
        case 'ft/s':
          factor = 3.28083;
          break;
        case 'kph':
          factor = 3.6;
          break;
        case 'mph':
          factor = 2.237;
          break;
        //ha
        case 'm²':
          factor = 10000.0;
          break;
        case 'dunam':
          factor = 10.0;

          break;
        case 'acres':
          factor = 2.471;
          break;
        case 'ft²':
          factor = 107600.0;
          break;
        //"mm/h"
        case 'in/h':
          factor = 0.03937;
          break;
        default:
          factor = 1.0;
          break;
      }
      break;
  }
  return factor;
}

export type METRIC_DEFAULTS = {
  Pressure: 'm';
  FlowPerLength: 'lph/100m';
  Flow: 'lph';
  TotalFlow: 'm³/h';
  Length: 'm';
  Velocity: 'm/s';
  PipeDiameter: 'mm';
  EmitterSpacing: 'm';
  LateralSpacing: 'm';
  Area: 'Ha';
  AppnDepth: 'mm';
  AppnRate: 'mm/h';
};
