export function GetCU(CUavGDEV: number, CUAverage: number, CUSilence: number) {
  let temp = 0;
  if (CUAverage == 0) {
    if (CUSilence < 0) {
      console.log('lg_msg116', 'lg_msg71');
    }
  } else {
    var res = (1 - CUavGDEV / CUAverage) * 100 + 2;
    temp = Math.round(res);
  }
  if (temp < 0) {
    temp = 0;
  } else if (temp > 100) {
    temp = 100;
  }
  return temp;
}
