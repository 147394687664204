export function getRange(upper: number, lower: number, steps: number) {
  const difference = upper - lower;
  const increment = difference / (steps - 1);
  return [
    Number(lower).toFixed(1),
    ...Array(steps - 2)
      .fill('')
      .map((_, index) => (lower + increment * (index + 1)).toFixed(1)),
    Number(upper).toFixed(1),
  ];
}
