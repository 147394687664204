type Props = {
  placement: number;
  edge: number;
  heightStep: number;
  widthStep: number;
};

export const getEmitterIconPositions = ({ edge, heightStep, placement, widthStep }: Props) => {
  if (+edge === 1) {
    return [
      { top: 0, left: 0 },
      { top: 0, right: 0 },
    ];
  }
  if (+edge === 2) {
    return [
      { top: 0, left: 0 },
      { bottom: 0, left: 0 },
    ];
  }
  if (+edge === 3) {
    return [{ top: 0, left: 0 }];
  }

  if (+placement === 0) {
    return [
      { top: 0, left: 0 },
      { top: 0, right: 0 },
      { bottom: 0, left: 0 },
      { right: 0, bottom: 0 },
    ];
  }

  if (+placement === 1) {
    return [
      { top: 0, left: 0 },
      { top: 20 + heightStep * 2, right: 0 },
      { bottom: 0, left: 0 },
    ];
  }

  if (+placement === 2) {
    return [
      { top: 0, left: 20 + widthStep * 2 },
      { bottom: 0, left: 20 + widthStep * 2 },
    ];
  }
};
