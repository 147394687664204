import { FC, ReactNode } from 'react';
import { Stack, Typography, Box } from '@mui/material';

interface Props {
  iconSrc: string;
  children: ReactNode;
  additionalIcon?: ReactNode;
}

export const IconLabel: FC<Props> = ({ iconSrc, children, additionalIcon }) => {
  return (
    <Stack direction="row" alignItems="center" height="20px" overflow="hidden">
      <Box component="img" src={iconSrc} alt={iconSrc} maxHeight="20px" maxWidth="20px" />
      <Typography
        noWrap
        sx={{
          color: 'secondary.main',
          textTransform: 'uppercase',
          fontSize: 16,
          lineHeight: '16px',
          fontWeight: 600,
          ml: '5px',
        }}
      >
        {children}
      </Typography>
      {additionalIcon}
    </Stack>
  );
};
