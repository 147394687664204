import { Math_round } from '../mathRound';
import { ConvertUnit } from '../unitConverter';
import { maxVelocityCalc } from '../ManifoldDesign/ManifoldCalculate';
import { MainLine } from './mainLine';
import { Unit } from 'shared/models';
import { METRIC_DEFAULTS } from 'shared/constants';

export function mainLineCalculate(
  inputData: Partial<MainLine>,
  unitSettings: Unit,
  isCalculateLengths: boolean,
  showReport: boolean,
) {
  const validationResult = inputDataValidation(inputData);
  // eslint-disable-next-line no-prototype-builtins
  if (validationResult!.hasOwnProperty('error')) {
    return validationResult;
  }
  let result = {};
  var reportResult = {};

  let HWCoef = inputData.MainlineHWCoef!;
  let AddlHeadloss = calcAddlHeadloss(inputData.MainlineAddlHeadloss!, unitSettings);
  let MainlineFlow = calMainlineFlow(inputData.MainlineFlow!, unitSettings);
  let Length1 = length1Calc(inputData.MainlinePipe1Length!, unitSettings);
  let Length2 = length2Calc(inputData.MainlinePipe2Length!, unitSettings);
  let Length3 = length3Calc(inputData.MainlinePipe3Length!, unitSettings);
  let Dia1 = dia1Calc(inputData.MainlinePipe1Dia!, unitSettings);
  let Dia2 = dia2Calc(inputData.MainlinePipe2Dia!, unitSettings);
  let Dia3 = dia3Calc(inputData.MainlinePipe3Dia!, unitSettings);
  let PumpPressure = calcPumpPressure(inputData.MainlinePumpPressure!, unitSettings);
  let PipesHeadloss = calcHeadloss(inputData.MainlinePipeHeadloss!, unitSettings);
  let PressureAtValve = calcPressureAtValve(inputData.MainlinePressureAtValve!, unitSettings);
  let slope = calcSlope(inputData.MainlineSlope!, unitSettings);
  if (inputData.MainlineSlopeDirection != null && inputData.MainlineSlopeDirection === 'Downhill') {
    slope = slope * -1;
  }
  let HeadlossPipe1 = 0;
  let MainlineHeadlossCalc = 'H.W';
  if (inputData.MainlineHeadlossCalculation) {
    MainlineHeadlossCalc = inputData.MainlineHeadlossCalculation!;
  }
  let mainlinelength = calcMainlineLength(inputData.MainlineLength!, unitSettings);
  let mainlineallowedhl = calcMainlineLength(inputData.MainlineAllowedHL!, unitSettings);

  if (Dia1 != 0) {
    if (MainlineHeadlossCalc == 'H.W') {
      HeadlossPipe1 = (1.131 * Math.pow(10.0, 12.0) * Math.pow(MainlineFlow / HWCoef, 1.852)) / Math.pow(Dia1, 4.87);
    } else {
      if (Dia1 < 125) {
        HeadlossPipe1 = 8.38 * Math.pow(10, 7) * Math.pow(MainlineFlow, 1.75) * Math.pow(Dia1, -4.75);
      } else {
        HeadlossPipe1 = 9.19 * Math.pow(10, 7) * Math.pow(MainlineFlow, 1.83) * Math.pow(Dia1, -4.83);
      }
    }
  }
  // HeadlossPipe1 = 1.131 * Math.pow(10.0, 12.0) * Math.pow((MainlineFlow / HWCoef), 1.852) / Math.pow(Dia1, 4.87) * Length1 / 1000

  let HeadlossPipe2 = 0;
  // HeadlossPipe2 = 1.131 * Math.pow(10.0, 12.0) * Math.pow((MainlineFlow / HWCoef), 1.852) / Math.pow(Dia2, 4.87) * Length2 / 1000
  if (Dia2 != 0) {
    if (MainlineHeadlossCalc == 'H.W') {
      HeadlossPipe2 = calcHeadlossPipe2(MainlineFlow, HWCoef!, Dia2);
    } else {
      if (Dia2 < 125) {
        HeadlossPipe2 = 8.38 * Math.pow(10, 7) * Math.pow(MainlineFlow, 1.75) * Math.pow(Dia2, -4.75);
      } else {
        HeadlossPipe2 = 9.19 * Math.pow(10, 7) * Math.pow(MainlineFlow, 1.83) * Math.pow(Dia2, -4.83);
      }
    }
  }
  let DoneCalculate = false;
  let HeadlossPipe3 = 0;
  //  HeadlossPipe3 = 1.131 * Math.pow(10.0, 12.0) * Math.pow((MainlineFlow / HWCoef), 1.852) / Math.pow(Dia3, 4.87) * Length3 / 1000
  if (Dia3 != 0) {
    if (MainlineHeadlossCalc == 'H.W') {
      HeadlossPipe3 = (1.131 * Math.pow(10.0, 12.0) * Math.pow(MainlineFlow / HWCoef, 1.852)) / Math.pow(Dia3, 4.87);
    } else {
      if (Dia3 < 125) {
        HeadlossPipe3 = 8.38 * Math.pow(10, 7) * Math.pow(MainlineFlow, 1.75) * Math.pow(Dia3, -4.75);
      } else {
        HeadlossPipe3 = 9.19 * Math.pow(10, 7) * Math.pow(MainlineFlow, 1.83) * Math.pow(Dia3, -4.83);
      }
    }
  }
  if (isCalculateLengths) {
    Length1 = 0;
    Length2 = 0;
    Length3 = 0;
    mainlineallowedhl = mainlineallowedhl + (slope * mainlinelength) / 100;
    if (mainlinelength != 0) {
      if (
        ((HeadlossPipe3 * mainlinelength) / 1000 == mainlineallowedhl || (Dia1 == 0 && Dia2 == 0)) &&
        HeadlossPipe3 != 0
      ) {
        HeadlossPipe3 = mainlineallowedhl;
        HeadlossPipe1 = 0;
        HeadlossPipe2 = 0;
        DoneCalculate = true;
        Length3 = mainlinelength;
      } else {
        if (
          ((HeadlossPipe2 * mainlinelength) / 1000 == mainlineallowedhl || (Dia1 == 0 && Dia3 == 0)) &&
          HeadlossPipe2 != 0
        ) {
          HeadlossPipe2 = mainlineallowedhl;
          HeadlossPipe1 = 0;
          HeadlossPipe3 = 0;
          Length2 = mainlinelength;
          DoneCalculate = true;
        } else {
          if (
            ((HeadlossPipe1 * mainlinelength) / 1000 == mainlineallowedhl || (Dia3 == 0 && Dia2 == 0)) &&
            HeadlossPipe1 != 0
          ) {
            HeadlossPipe1 = mainlineallowedhl;
            HeadlossPipe3 = 0;
            HeadlossPipe2 = 0;
            Length1 = mainlinelength;
            DoneCalculate = true;
          }
        }
      }

      if (DoneCalculate == false) {
        if ((HeadlossPipe1 * mainlinelength) / 1000 > mainlineallowedhl) {
          if (HeadlossPipe1 < HeadlossPipe2 && HeadlossPipe1 < HeadlossPipe3 && HeadlossPipe2 != HeadlossPipe3) {
            Length1 = mainlinelength;
            Length2 = 0;
            Length3 = 0;
            DoneCalculate = true;
          }
        }
      }
      if (DoneCalculate == false) {
        if ((HeadlossPipe2 * mainlinelength) / 1000 > mainlineallowedhl) {
          if (HeadlossPipe2 < HeadlossPipe1 && HeadlossPipe2 < HeadlossPipe3) {
            Length2 = mainlinelength;
            Length1 = 0;
            Length3 = 0;
            DoneCalculate = true;
          } else {
            if (HeadlossPipe2 > HeadlossPipe1 && HeadlossPipe2 > HeadlossPipe3 && HeadlossPipe1 != HeadlossPipe3) {
              console.log('i');
            } else {
              if (HeadlossPipe2 - HeadlossPipe1 > 0) {
                Length2 =
                  ((mainlineallowedhl - (mainlinelength / 1000) * HeadlossPipe1) / (HeadlossPipe2 - HeadlossPipe1)) *
                  1000;
                if (Length2 < 0) {
                  Length2 = 0;
                }
                if (Length2 > mainlinelength) {
                  Length2 = mainlinelength;
                }
                Length1 = mainlinelength - Length2;
                Length3 = 0;
                DoneCalculate = true;
              } else {
                if (HeadlossPipe1 - HeadlossPipe2 > 0) {
                  Length1 =
                    ((mainlineallowedhl - (mainlinelength / 1000) * HeadlossPipe2) / (HeadlossPipe1 - HeadlossPipe2)) *
                    1000;
                  if (Length1 < 0) {
                    Length1 = 0;
                  }
                  if (Length1 > mainlinelength) {
                    Length1 = mainlinelength;
                  }
                  Length2 = mainlinelength - Length1;
                  Length3 = 0;
                  DoneCalculate = true;
                }
              }
            }
          }
        }
      }
      if (DoneCalculate == false) {
        if ((HeadlossPipe3 * mainlinelength) / 1000 > mainlineallowedhl) {
          if (HeadlossPipe3 < HeadlossPipe1 && HeadlossPipe3 <= HeadlossPipe2 && HeadlossPipe2 != HeadlossPipe1) {
            Length2 = mainlinelength;
            Length3 = 0;
            Length1 = 0;
            DoneCalculate = true;
          } else {
            if (HeadlossPipe3 > HeadlossPipe1 && HeadlossPipe3 > HeadlossPipe2 && HeadlossPipe1 != HeadlossPipe2) {
              if (HeadlossPipe2 - HeadlossPipe3 > 0) {
                Length2 =
                  ((mainlineallowedhl - (mainlinelength / 1000) * HeadlossPipe3) / (HeadlossPipe2 - HeadlossPipe3)) *
                  1000;
                if (Length2 < 0) {
                  Length2 = 0;
                }
                if (Length2 > mainlinelength) {
                  Length2 = mainlinelength;
                }
                Length3 = mainlinelength - Length2;
                Length1 = 0;
                DoneCalculate = true;
              } else {
                if (HeadlossPipe3 != HeadlossPipe2) {
                  Length3 =
                    ((mainlineallowedhl - (mainlinelength / 1000) * HeadlossPipe2) / (HeadlossPipe3 - HeadlossPipe2)) *
                    1000;
                  if (Length3 < 0) {
                    Length3 = 0;
                  }
                  if (Length3 > mainlinelength) {
                    Length3 = mainlinelength;
                  }
                } else {
                  Length3 = 0;
                }
                if (Length3 > mainlinelength) Length3 = mainlinelength;
                Length2 = mainlinelength - Length3;
                Length1 = 0;
                DoneCalculate = true;
              }
            } else {
              if (HeadlossPipe3 - HeadlossPipe2 > 0) {
                Length3 =
                  ((mainlineallowedhl - (mainlinelength / 1000) * HeadlossPipe2) / (HeadlossPipe3 - HeadlossPipe2)) *
                  1000;
                if (Length3 < 0) {
                  Length3 = 0;
                }
                if (Length3 > mainlinelength) {
                  Length3 = mainlinelength;
                }
                Length2 = mainlinelength - Length3;
                Length1 = 0;
                DoneCalculate = true;
              } else {
                if (HeadlossPipe2 - HeadlossPipe3 > 0) {
                  Length2 =
                    ((mainlineallowedhl - (mainlinelength / 1000) * HeadlossPipe3) / (HeadlossPipe2 - HeadlossPipe3)) *
                    1000;
                  if (Length2 < 0) {
                    Length2 = 0;
                  }
                  if (Length2 > mainlinelength) {
                    Length2 = mainlinelength;
                  }
                  Length3 = mainlinelength - Length2;
                  Length1 = 0;
                  DoneCalculate = true;
                }
              }
            }
          }
        } else {
          if (HeadlossPipe2 - HeadlossPipe3 > 0) {
            Length2 =
              ((mainlineallowedhl - (mainlinelength / 1000) * HeadlossPipe3) / (HeadlossPipe2 - HeadlossPipe3)) * 1000;
            if (Length2 < 0) {
              Length2 = 0;
            }
            if (Length2 > mainlinelength) {
              Length2 = mainlinelength;
            }
            Length3 = mainlinelength - Length2;
            Length1 = 0;
            DoneCalculate = true;
          } else {
            if (HeadlossPipe3 != HeadlossPipe2) {
              Length3 =
                ((mainlineallowedhl - (mainlinelength / 1000) * HeadlossPipe2) / (HeadlossPipe3 - HeadlossPipe2)) *
                1000;
              if (Length3 < 0) {
                Length3 = 0;
              }
              if (Length3 > mainlinelength) {
                Length3 = mainlinelength;
              }
            } else {
              Length3 = 0;
            }
            if (Length3 > mainlinelength) Length3 = mainlinelength;
            Length2 = mainlinelength - Length3;
            Length1 = 0;
            DoneCalculate = true;
          }
        }
      }
    }
  }
  HeadlossPipe1 = (HeadlossPipe1 * Length1) / 1000;
  HeadlossPipe2 = (HeadlossPipe2 * Length2) / 1000;
  HeadlossPipe3 = (HeadlossPipe3 * Length3) / 1000;

  PipesHeadloss = HeadlossPipe1 + HeadlossPipe2 + HeadlossPipe3;
  let TotalHeadloss = 0;
  // eslint-disable-next-line no-unused-vars
  TotalHeadloss = PipesHeadloss + AddlHeadloss;
  // result = {
  //   ...result,
  //   MainlinePipeHeadloss: calcPipesHeadloss(PipesHeadloss, unitSettings),
  // };
  PumpPressure = PressureAtValve + PipesHeadloss + AddlHeadloss + (slope * mainlinelength) / 100;

  result = {
    ...result,
    Length1: length1CalcRes(Length1, unitSettings),
  };
  result = {
    ...result,
    Length2: length2CalcRes(Length2, unitSettings),
  };
  result = {
    ...result,
    Length3: length3CalcRes(Length3, unitSettings),
  };
  result = {
    ...result,
    PumpPressure: calcPumpPressureRes(PumpPressure, unitSettings),
  };
  result = {
    ...result,
    PipesHeadloss: calcHeadlossRes(PipesHeadloss, unitSettings),
  };
  result = {
    ...result,
    PressureAtValve: calcPressureAtValveRes(PressureAtValve, unitSettings),
  };

  if (showReport) {
    let cat1 = '';
    let cat2 = '';
    let cat3 = '';
    cat1 = inputData.MainlinePipe1!.toString();
    cat2 = inputData.MainlinePipe1!.toString();
    cat3 = inputData.MainlinePipe1!.toString();
    let vel1 = 0;
    let vel2 = 0;
    let vel3 = 0;
    vel1 = velocity1Calc(inputData.MainlineVelocity1!, unitSettings);
    vel2 = velocity1Calc(inputData.MainlineVelocity2!, unitSettings);
    vel3 = velocity1Calc(inputData.MainlineVelocity3!, unitSettings);

    reportResult = { ...reportResult, cat1: cat1 };
    reportResult = { ...reportResult, cat2: cat2 };
    reportResult = { ...reportResult, cat3: cat3 };
    reportResult = { ...reportResult, length1: length1CalcRes(Length1, unitSettings) };
    reportResult = { ...reportResult, length2: length2CalcRes(Length2, unitSettings) };
    reportResult = { ...reportResult, length3: length3CalcRes(Length3, unitSettings) };
    reportResult = { ...reportResult, dia1: Dia1 };
    reportResult = { ...reportResult, dia2: Dia2 };
    reportResult = { ...reportResult, dia3: Dia3 };
    reportResult = { ...reportResult, velocity1: vel1 };
    reportResult = { ...reportResult, velocity2: vel2 };
    reportResult = { ...reportResult, velocity3: vel3 };
    reportResult = { ...reportResult, MainlineFlow: MainlineFlow };
    reportResult = {
      ...reportResult,
      maxVelocity: maxVelocityCalc(inputData.MainlineMaxVelocity!, unitSettings),
    };
    reportResult = { ...reportResult, PipesHeadloss: PipesHeadloss };

    result = { ...result, reportResult: reportResult };
  }
  return result;
}

export function calcAddlHeadloss(headloss: number, unitSettings: Unit) {
  let headlossRes = ConvertUnit(headloss, unitSettings.pressure, METRIC_DEFAULTS.Pressure, null);
  return Math_round(headlossRes, 3);
}

export function calMainlineFlow(flow2: number, unitSettings: Unit) {
  let flowRes = ConvertUnit(flow2, unitSettings.totalFlow, METRIC_DEFAULTS.TotalFlow, null);
  return Math_round(flowRes, 3);
}

export function length1Calc(length1: number, unitSettings: Unit) {
  let length1Res = ConvertUnit(length1, unitSettings.length, METRIC_DEFAULTS.Length, null);
  return Math_round(length1Res, 3);
}

export function length2Calc(length2: number, unitSettings: Unit) {
  let length2Res = ConvertUnit(length2, unitSettings.length, METRIC_DEFAULTS.Length, null);
  return Math_round(length2Res, 3);
}

export function length3Calc(length3: number, unitSettings: Unit) {
  let length3Res = ConvertUnit(length3, unitSettings.length!, METRIC_DEFAULTS.Length, null);
  return Math_round(length3Res, 3);
}

export function length1CalcRes(length1: number, unitSettings: Unit) {
  let length1Res = ConvertUnit(length1, METRIC_DEFAULTS.Length, unitSettings.length, null);
  return Math_round(length1Res, 3);
}

export function length2CalcRes(length2: number, unitSettings: Unit) {
  let length2Res = ConvertUnit(length2, METRIC_DEFAULTS.Length, unitSettings.length, null);
  return Math_round(length2Res, 3);
}

export function length3CalcRes(length3: number, unitSettings: Unit) {
  let length3Res = ConvertUnit(length3, METRIC_DEFAULTS.Length, unitSettings.length!, null);
  return Math_round(length3Res, 3);
}

export function dia1Calc(dia1: number, unitSettings: Unit) {
  let dia1Res = ConvertUnit(dia1, unitSettings.pipeDiameter, METRIC_DEFAULTS.PipeDiameter, 'PipeDiameter');
  return Math_round(dia1Res, 3);
}

export function dia2Calc(dia2: number, unitSettings: Unit) {
  let dia2Res = ConvertUnit(dia2, unitSettings.pipeDiameter, METRIC_DEFAULTS.PipeDiameter, 'PipeDiameter');
  return Math_round(dia2Res, 3);
}

export function dia3Calc(dia3: number, unitSettings: Unit) {
  let dia3Res = ConvertUnit(dia3, unitSettings.pipeDiameter, METRIC_DEFAULTS.PipeDiameter, 'PipeDiameter');
  return Math_round(dia3Res, 3);
}

export function calcPumpPressure(valvePressure: number, unitSettings: Unit) {
  let valvePressureRes = ConvertUnit(valvePressure, unitSettings.pressure, METRIC_DEFAULTS.Pressure, null);
  return Math_round(valvePressureRes, 3);
}

export function calcHeadloss(allowableHeadloss: number, unitSettings: Unit) {
  let allowableHeadlossRes = ConvertUnit(allowableHeadloss, unitSettings.pressure, METRIC_DEFAULTS.Pressure, null);
  return Math_round(allowableHeadlossRes, 3);
}

export function calcPressureAtValve(Pressure: number, unitSettings: Unit) {
  let PressureRes = ConvertUnit(Pressure, unitSettings.pressure, METRIC_DEFAULTS.Pressure, null);
  return Math_round(PressureRes, 3);
}

export function calcPumpPressureRes(valvePressure: number, unitSettings: Unit) {
  let valvePressureRes = ConvertUnit(valvePressure, METRIC_DEFAULTS.Pressure, unitSettings.pressure, null);
  return Math_round(valvePressureRes, 3);
}

export function calcHeadlossRes(allowableHeadloss: number, unitSettings: Unit) {
  let allowableHeadlossRes = ConvertUnit(allowableHeadloss, METRIC_DEFAULTS.Pressure, unitSettings.pressure, null);
  return Math_round(allowableHeadlossRes, 3);
}

export function calcPressureAtValveRes(Pressure: number, unitSettings: Unit) {
  let PressureRes = ConvertUnit(Pressure, METRIC_DEFAULTS.Pressure, unitSettings.pressure, null);
  return Math_round(PressureRes, 3);
}

export function calcSlope(slope: number, unitSettings: Unit) {
  let slopeRes = ConvertUnit(slope, unitSettings.lateralSpacing, METRIC_DEFAULTS.LateralSpacing, null);
  return Math_round(slopeRes, 3);
}

export function calcMainlineLength(mainlineLength: number, unitSettings: Unit) {
  let mainlineLengthRes = ConvertUnit(mainlineLength, unitSettings.length, METRIC_DEFAULTS.Length, null);
  return Math_round(mainlineLengthRes, 3);
}

export function calcPipesHeadloss(pipesHeadloss: number, unitSettings: Unit) {
  let pipesHeadlossRes = ConvertUnit(pipesHeadloss, unitSettings.pressure, METRIC_DEFAULTS.Pressure, null);
  return Math_round(pipesHeadlossRes, 3);
}

export function velocity1Calc(velocity1: number, unitSettings: Unit) {
  let velocity1Res = ConvertUnit(velocity1, unitSettings.velocity, METRIC_DEFAULTS.Velocity, null);
  return Math_round(velocity1Res, 3);
}

export function velocity2Calc(velocity2: number, unitSettings: Unit) {
  let velocity2Res = ConvertUnit(velocity2, unitSettings.velocity, METRIC_DEFAULTS.Velocity, null);
  return Math_round(velocity2Res, 3);
}

export function velocity3Calc(velocity3: number, unitSettings: Unit) {
  let velocity3Res = ConvertUnit(velocity3, unitSettings.velocity, METRIC_DEFAULTS.Velocity, null);
  return Math_round(velocity3Res, 3);
}

function calcHeadlossPipe2(MainlineFlow: number, HWCoef: number, Dia2: number) {
  // (1.131 * Math.pow(10.0, 12.0) * Math.pow(MainlineFlow / HWCoef, 1.852)) / Math.pow(Dia2, 4.87);
  let first = 1.131;
  let sec = Math.pow(10.0, 12.0);
  let third = Math.pow(MainlineFlow / HWCoef, 1.852);
  let four = Math.pow(Dia2, 4.87);
  return (first * sec * third) / four;
}

function inputDataValidation(inputData: Partial<MainLine>) {
  if (isNaN(inputData.MainlineAllowedHL!)) {
    return { data: inputData, error: 'msgInvalidMainlineHL' };
  }
  if (inputData.MainlineHeadlossCalculation === 'H.W' && inputData.MainlineHWCoef === 0) {
    return { data: inputData, error: 'msgInvalidHW' };
  }
  return inputData;
}
