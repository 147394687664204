import { PDFDocument, PDFFont, PDFPage, rgb, StandardFonts } from 'pdf-lib';
import { splitTextIntoLines } from 'shared/lib';
import { CatalogItem } from 'shared/models';
import { rivulisLogo } from 'shared/assets';
import { saveAs } from 'file-saver';
import { TFunction } from 'i18next';

export type PDFLibProps = {
  type: 'lateral' | 'submain' | 'mainline' | 'summary';
  title: string;
  currentProduct?: CatalogItem | undefined;
  infoData: Table;
  tables: Table[];
  reportName?: string;
  t: TFunction;
};

type TableRow = (string | number)[];
type TableData = TableRow[];

type Table = {
  name?: string;
  data: TableData;
};

const A4_WIDTH = 595;
const A4_HEIGHT = 842;
const marginX = 20;
const marginTop = 36;
const greenColor = rgb(0.114, 0.529, 0.259);
const textColor = rgb(0.125, 0.129, 0.129);
const borderColor = rgb(0.878, 0.878, 0.878);

export const createPDF = async (pdfProps: PDFLibProps): Promise<void> => {
  const { type, title, currentProduct, infoData, tables, reportName, t } = pdfProps;

  const pdfDoc = await PDFDocument.create();
  const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
  let page = addPage(pdfDoc);

  // report title
  page.drawText(title.toUpperCase(), {
    x: marginX,
    y: A4_HEIGHT - marginTop,
    size: 16,
    font,
    color: greenColor,
  });

  const pngImageBytes = await fetch(rivulisLogo).then((res) => res.arrayBuffer());
  const image = await pdfDoc.embedPng(pngImageBytes);

  // logo
  const imageX = A4_WIDTH - 72 - marginX;
  const imageY = A4_HEIGHT - marginTop - 2;
  page.drawImage(image, {
    x: imageX,
    y: imageY,
    width: 72,
    height: 18,
  });

  // divider line
  addBorderBottom(page, marginX, A4_HEIGHT - marginTop - 10 - 2, A4_WIDTH - marginX * 2, 2, greenColor);

  // info table
  const { lastY, page: newPage } = drawTable(pdfDoc, page, font, infoData, marginX, A4_HEIGHT - marginTop - 12 - 10);
  page = newPage;

  if (type === 'lateral' && currentProduct?.catlogDesc) {
    page.drawText(currentProduct?.catlogDesc, {
      x: marginX,
      y: lastY - 8 - 8,
      size: 12,
      font,
      color: textColor,
    });
  } else if (type === 'submain' || type === 'mainline') {
    const tableName = type === 'submain' ? t('manifoldRep') : t('mainlineRep');
    page.drawText(tableName, {
      x: marginX,
      y: lastY - 8 - 8,
      size: 8,
      font,
      color: textColor,
    });
  }

  let prevY = lastY - 16 - 8 - 8;

  if (type === 'summary') {
    const firstTable = drawHorizontalTable(pdfDoc, page, font, tables[0], marginX, prevY);
    prevY = firstTable.lastY - 16;
    page = firstTable.page;

    // render other tables
    tables.slice(1, -1).forEach((table) => {
      const result = drawTable(pdfDoc, page, font, table, marginX, prevY, true);
      prevY = result.lastY - 16;
      page = result.page;
    });

    const lastTable = drawHorizontalTable(pdfDoc, page, font, tables[tables.length - 1], marginX, prevY);
    prevY = lastTable.lastY - 16;
    page = lastTable.page;
  } else {
    tables.forEach((table) => {
      const result = drawTable(pdfDoc, page, font, table, marginX, prevY, true);
      prevY = result.lastY - 16;
      page = result.page;
    });
  }

  const lines = splitTextIntoLines(t('repDisclaimer') as string, font, 8, A4_WIDTH - marginX * 2);

  const disclaimerHeight = 8 + 8 * lines.length;

  if (prevY - disclaimerHeight < marginTop) {
    page = addPage(pdfDoc);
    prevY = A4_HEIGHT - marginTop; // reset Y value
  }

  lines.forEach((line, lineIndex) => {
    page.drawText(line, {
      x: marginX,
      y: prevY - 8 / 2 - lineIndex * 10,
      size: 8,
      font,
      color: textColor,
    });
  });

  const pdfBytes = await pdfDoc.save();
  const blob = new Blob([pdfBytes], { type: 'application/pdf' });
  saveAs(blob, `${reportName ?? 'report'}.pdf`);
};

const drawTable = (
  pdfDoc: PDFDocument,
  page: PDFPage,
  font: PDFFont,
  table: Table,
  startX: number,
  startY: number,
  sameWidth?: boolean
): { lastY: number; page: PDFPage } => {
  const { name, data } = table;
  const rowWidth = sameWidth ? (A4_WIDTH - marginX * 2) / data[0].length : 110;
  const cellPadding = 8;
  const minY = 36;

  let lastY = startY;

  if (lastY - 12 < minY) {
    page = addPage(pdfDoc);
    lastY = A4_HEIGHT - marginTop; // reset Y value
  }

  if (name) {
    const textWidth = font.widthOfTextAtSize(name, 12);
    const xPosition = (A4_WIDTH - textWidth) / 2;
    page.drawText(name, {
      x: xPosition,
      y: lastY - 8,
      size: 12,
      font,
      color: textColor,
    });
    lastY -= 12;
  }

  data.forEach((row, rowIndex) => {
    let maxLinesNumber = 1;

    row.forEach((cell) => {
      const cellValue = cell ?? '';
      const lines = splitTextIntoLines(cellValue, font, 8, rowWidth - cellPadding * 2);
      if (lines.length > maxLinesNumber) maxLinesNumber = lines.length;
    });

    const currentRowHeight = 12 + 8 * maxLinesNumber;

    // check if there is enough space on the page
    if (lastY - currentRowHeight < minY) {
      page = addPage(pdfDoc);
      lastY = A4_HEIGHT - marginTop; // reset Y value
    }

    row.forEach((cell, cellIndex) => {
      const cellValue = cell ?? '';
      const x = startX + cellIndex * rowWidth;
      const y = lastY;

      const lines = splitTextIntoLines(cellValue, font, 8, rowWidth - cellPadding * 2);
      lines.forEach((line, lineIndex) => {
        page.drawText(line.toString(), {
          x: x + cellPadding,
          y: y - 12 - lineIndex * 10,
          size: 8,
          font,
          color: rowIndex === 0 ? greenColor : textColor,
        });
      });

      // border bottom
      addBorderBottom(page, x, y - currentRowHeight, rowWidth);
    });

    lastY -= currentRowHeight; // update Y
  });

  return { lastY, page };
};

const drawHorizontalTable = (
  pdfDoc: PDFDocument,
  page: PDFPage,
  font: PDFFont,
  table: Table,
  startX: number,
  startY: number
): { lastY: number; page: PDFPage } => {
  const { name, data } = table;
  const rowWidth = (A4_WIDTH - marginX * 2) / 2;
  const cellPadding = 8;
  const minY = 36;

  let lastY = startY;

  if (lastY - 12 < minY) {
    page = addPage(pdfDoc);
    lastY = A4_HEIGHT - marginTop; // reset Y value
  }

  if (name) {
    const textWidth = font.widthOfTextAtSize(name, 12);
    const xPosition = (A4_WIDTH - textWidth) / 2;
    page.drawText(name, {
      x: xPosition,
      y: lastY - 8,
      size: 12,
      font,
      color: textColor,
    });
    lastY -= 12;
  }

  data[0].forEach((_, index) => {
    const headerValue = data[0][index] ?? '';
    const headerLines = splitTextIntoLines(headerValue, font, 8, rowWidth - cellPadding * 2);
    const cellValue = data[1][index] ?? '';
    const cellLines = splitTextIntoLines(cellValue, font, 8, rowWidth - cellPadding * 2);
    const maxLinesNumber = Math.max(headerLines.length, cellLines.length);

    const currentRowHeight = 12 + 8 * maxLinesNumber;

    // check if there is enough space on the page
    if (lastY - currentRowHeight < minY) {
      page = addPage(pdfDoc);
      lastY = A4_HEIGHT - marginTop; // reset Y value
    }

    const headerX = startX;
    const y = lastY;

    headerLines.forEach((line, lineIndex) => {
      page.drawText(line.toString(), {
        x: headerX + cellPadding,
        y: y - 12 - lineIndex * 10,
        size: 8,
        font,
        color: greenColor,
      });
    });

    cellLines.forEach((line, lineIndex) => {
      page.drawText(line.toString(), {
        x: A4_WIDTH / 2 + cellPadding,
        y: y - 12 - lineIndex * 10,
        size: 8,
        font,
        color: textColor,
      });
    });

    // border bottom
    addBorderBottom(page, startX, y - currentRowHeight, rowWidth * 2);
    lastY -= currentRowHeight; // update Y
  });

  return { lastY, page };
};

const addPage = (pdfDoc: PDFDocument) => {
  const page = pdfDoc.addPage([A4_WIDTH, A4_HEIGHT]); // add new page
  // add page background
  page.drawRectangle({
    x: 0,
    y: 0,
    width: A4_WIDTH,
    height: A4_HEIGHT,
    color: rgb(0.961, 0.984, 1),
  });

  return page;
};

const addBorderBottom = (page: PDFPage, x: number, y: number, width: number, height = 1, color = borderColor) => {
  page.drawRectangle({
    x,
    y,
    width,
    height,
    color,
  });
};
