import { Loader } from 'shared/ui';
import { useAppSelector } from 'shared/hooks';
import { Mainline } from 'widgets/Mainline';

export const MainlinePage = () => {
  const projectsLoading = useAppSelector((state) => state.projects.loading);
  const masterGroupsLoading = useAppSelector((state) => state.masterGroups.loading);
  const unitsLoading = useAppSelector((state) => state.units.loading);
  const projectDataLoading = useAppSelector((state) => state.projectData.loading);
  const mainlinesLoadedOnce = useAppSelector((state) => state.mainlines.mainlinesLoadedOnce);

  const loading = projectsLoading || masterGroupsLoading || unitsLoading || projectDataLoading || !mainlinesLoadedOnce;

  return !loading ? <Mainline /> : <Loader centeredAbsolute />;
};
