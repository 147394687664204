export const GetX = (y: number, X1: number, X2: number, Y1: number, Y2: number) => {
  let functionReturnValue = 0;
  if (Y2 != Y1) {
    if (Y2 > Y1) {
      functionReturnValue = (y * (X2 - X1) - X2 * Y1 + X1 * Y2) / (Y2 - Y1);
    } else {
      functionReturnValue = (y * (X1 - X2) + X2 * Y1 - X1 * Y2) / (Y1 - Y2);
    }
  } else {
    functionReturnValue = Y1;
  }
  return Math.round(functionReturnValue);
};
