import { METRIC_DEFAULTS } from 'shared/constants';
import { LimitsRangeMainLines, range } from '../common/limitRange';

import { Math_round } from '../mathRound';
import { ConvertUnit } from '../unitConverter';
import { Unit } from 'shared/models';

export type MainlineVelocities = {
  flow1: number;
  mainlinePipe1Dia: number;
  mainlinePipe2Dia: number;
  mainlinePipe3Dia: number;
  mainlineMaxVelocity: number;
};

export type MainlineVelocitiesResult = {
  velocity1: ResultVelocity;
  velocity2: ResultVelocity;
  velocity3: ResultVelocity;
};
export type ResultVelocity = {
  value: number;
  range: range;
};

export function updateMainlineVelocities(inputData: MainlineVelocities, unitsSettings: Unit) {
  let dia1 = 0;
  let dia2 = 0;
  let dia3 = 0;
  let flow1 = 0;

  let velocity1 = 0;
  let velocity2 = 0;
  let velocity3 = 0;

  flow1 = ConvertUnit(inputData.flow1, unitsSettings.totalFlow, METRIC_DEFAULTS.TotalFlow, null);
  dia1 = ConvertUnit(
    inputData.mainlinePipe1Dia,
    unitsSettings.pipeDiameter,
    METRIC_DEFAULTS.PipeDiameter,
    'PipeDiameter',
  );
  dia2 = ConvertUnit(
    inputData.mainlinePipe2Dia,
    unitsSettings.pipeDiameter,
    METRIC_DEFAULTS.PipeDiameter,
    'PipeDiameter',
  );
  dia3 = ConvertUnit(
    inputData.mainlinePipe3Dia,
    unitsSettings.pipeDiameter,
    METRIC_DEFAULTS.PipeDiameter,
    'PipeDiameter',
  );

  let limitsRangeMainLine: LimitsRangeMainLines = {};

  if (dia1 > 0) {
    velocity1 = flow1 / ((((Math.PI * dia1) / 1000) * dia1) / 1000 / 4) / 3600;
  }
  if (dia2 > 0) {
    velocity2 = flow1 / ((((Math.PI * dia2) / 1000) * dia2) / 1000 / 4) / 3600;
  }
  if (dia3 > 0) {
    velocity3 = flow1 / ((((Math.PI * dia3) / 1000) * dia3) / 1000 / 4) / 3600;
  }

  velocity1 = Math_round(ConvertUnit(velocity1, METRIC_DEFAULTS.Velocity, unitsSettings.velocity, null), 3);
  velocity2 = Math_round(ConvertUnit(velocity2, METRIC_DEFAULTS.Velocity, unitsSettings.velocity, null), 3);
  velocity3 = Math_round(ConvertUnit(velocity3, METRIC_DEFAULTS.Velocity, unitsSettings.velocity, null), 3);

  let maxVelocity = inputData.mainlineMaxVelocity;
  if (velocity1 > maxVelocity) {
    limitsRangeMainLine.velocity1 = { range: 'over' };
  } else {
    limitsRangeMainLine.velocity1 = { range: 'in' };
  }

  if (velocity2 > maxVelocity) {
    limitsRangeMainLine.velocity2 = { range: 'over' };
  } else {
    limitsRangeMainLine.velocity2 = { range: 'in' };
  }

  if (velocity3 > maxVelocity) {
    limitsRangeMainLine.velocity3 = { range: 'over' };
  } else {
    limitsRangeMainLine.velocity3 = { range: 'in' };
  }

  let ManiLineVelocitiesResult: Partial<MainlineVelocitiesResult> = {};
  ManiLineVelocitiesResult.velocity1 = {
    value: velocity1,
    range: limitsRangeMainLine.velocity1.range,
  };
  ManiLineVelocitiesResult.velocity2 = {
    value: velocity2,
    range: limitsRangeMainLine.velocity2.range,
  };
  ManiLineVelocitiesResult.velocity3 = {
    value: velocity3,
    range: limitsRangeMainLine.velocity3.range,
  };

  return ManiLineVelocitiesResult;
}
