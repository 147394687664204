import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

export const useSideBar = () => {

  const [isMac, setIsMac] = useState<boolean>(false)

const {t} = useTranslation()

useEffect(() => {
  setIsMac(window.innerWidth >= 1400 && window.innerWidth < 1500);
}, []);

const SIDEBAR_1: any = useMemo(() => ({
  id: 'sidebar-1',
  attachTo: {
    element: '#sidebar-help',
    on: 'right-start',
  },
  text: [
    `
    <div style="width: ${isMac ? '300px' : '700px'}">
    <p class="shepherd-text" style="font-weight: 400;">${t("interactiveSysSum1-9")}</p>

    <p class="shepherd-text">${t('interactiveSysSum1-10')}</p>
  </div>    
`,
  ],
  buttons: [
    {
      classes: 'btn btn-info',
      text: 'Back',
      type: 'back',
    },
    {
      classes: 'btn btn-success',
      text: 'Finish',
      type: 'cancel',
    },
  ],
  modalOverlayOpeningPadding: 3,
  useModalOverlay: true,
  canClickTarget: false,
}),[t]);

return {
  SIDEBAR_1
}

}