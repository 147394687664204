import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  authReducer,
  emitterFamiliesReducer,
  emittersEDCReducer,
  emittersReducer,
  lateralsReducer,
  mainlinesReducer,
  masterGroupsReducer,
  modalReducer,
  projectDataReducer,
  projectsReducer,
  submainsReducer,
  unitsReducer,
  lateralStateReducer,
  submainStateReducer,
  mainlineStateReducer,
  systemSummaryReducer,
  edcStateReducer,
} from 'shared/slices';

const rootReducer = combineReducers({
  auth: authReducer,
  projects: projectsReducer,
  masterGroups: masterGroupsReducer,
  units: unitsReducer,
  projectData: projectDataReducer,
  laterals: lateralsReducer,
  emitters: emittersReducer,
  submains: submainsReducer,
  mainlines: mainlinesReducer,
  emitterFamilies: emitterFamiliesReducer,
  emittersEDC: emittersEDCReducer,
  modals: modalReducer,
  lateralState: lateralStateReducer,
  submainState: submainStateReducer,
  mainlineState: mainlineStateReducer,
  systemSummaryState: systemSummaryReducer,
  edcState: edcStateReducer,
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
