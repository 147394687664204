import { METRIC_DEFAULTS } from 'shared/constants';
import { Unit } from 'shared/models';

import { ConvertUnit } from '../unitConverter';
import { CalcResultLength } from './CalcResultLength';
import { Manifold } from './Manifold';
import {
  length1CalcRes,
  length2CalcRes,
  length3CalcRes,
  velocity1Calc,
  velocity2Calc,
  velocity3Calc,
} from './ManifoldCalculate';

export function CalculateManifoldLengths(
  dia1: number,
  dia2: number,
  dia3: number,
  lateralFlow: number,
  HWcoefDef: number,
  HWCoefPipe1: number,
  HWCoefPipe2: number,
  HWCoefPipe3: number,
  arrHeights: any[],
  maxVelocity: number,
  spacing: number,
  rows: number,
  inputData: Manifold,
  unitSettings: Unit,
  calculateLengths: boolean,
): CalcResultLength {
  let length1 = 0;
  let length2 = 0;
  let length3 = 0;
  let HeadlossPipe1 = 0;
  let HeadlossPipe2 = 0;
  let HeadlossPipe3 = 0;
  let ManifoldFlow = lateralFlow * rows;
  //   let ManifoldHeadloss = 0;
  let ManifoldLength = 0;

  ManifoldLength = ConvertUnit(inputData.TotalSubmainLength, unitSettings.length, METRIC_DEFAULTS.Length, null);
  let HWCoef = 0;
  //  let lateralinletPressure = 0;

  //   lateralinletPressure = ConvertUnit(
  //     inputData.LateralInletPressure,
  //     unitSettings.pressureUnit!.destination,
  //     unitSettings.pressureUnit!.source,
  //     null,
  //   );
  let Manifoldallowedhl = ConvertUnit(
    inputData.ManifoldAllowedHL,
    unitSettings.pressure,
    METRIC_DEFAULTS.Pressure,
    null,
  );
  let firstLateral = 0.0;

  firstLateral = ConvertUnit(inputData.DistanceTo1stLateral, unitSettings.length, METRIC_DEFAULTS.Length, null);
  ManifoldLength += firstLateral;

  let HeadlossFactorForHeadloss = 1;
  if (rows > 0) {
    HeadlossFactorForHeadloss = 0.1538 / Math.pow(rows, 2) + 0.5 / rows + 0.35;
  }
  let ManifoldHeadlossCalc = 'H.W';

  if (inputData.ManifoldHeadlossCalculation) {
    ManifoldHeadlossCalc = inputData.ManifoldHeadlossCalculation;
  }

  if (dia1 != 0) {
    HWCoef = HWCoefPipe1;
    if (ManifoldHeadlossCalc == 'H.W') {
      HeadlossPipe1 = (1.131 * Math.pow(10.0, 12.0) * Math.pow(ManifoldFlow / HWCoef, 1.852)) / Math.pow(dia1, 4.87);
    } else {
      if (dia1 < 125) {
        HeadlossPipe1 = 8.38 * Math.pow(10, 7) * Math.pow(ManifoldFlow, 1.75) * Math.pow(dia1, -4.75);
      } else {
        HeadlossPipe1 = 9.19 * Math.pow(10, 7) * Math.pow(ManifoldFlow, 1.83) * Math.pow(dia1, -4.83);
      }
    }
  }
  HeadlossPipe1 = HeadlossPipe1 * HeadlossFactorForHeadloss;

  if (dia2 != 0) {
    HWCoef = HWCoefPipe2;
    if (ManifoldHeadlossCalc == 'H.W') {
      HeadlossPipe2 = (1.131 * Math.pow(10.0, 12.0) * Math.pow(ManifoldFlow / HWCoef, 1.852)) / Math.pow(dia2, 4.87);
    } else {
      if (dia2 < 125) {
        HeadlossPipe2 = 8.38 * Math.pow(10, 7) * Math.pow(ManifoldFlow, 1.75) * Math.pow(dia2, -4.75);
      } else {
        HeadlossPipe2 = 9.19 * Math.pow(10, 7) * Math.pow(ManifoldFlow, 1.83) * Math.pow(dia2, -4.83);
      }
    }
  }
  HeadlossPipe2 = HeadlossPipe2 * HeadlossFactorForHeadloss;

  if (dia3 != 0) {
    HWCoef = HWCoefPipe3;
    if (ManifoldHeadlossCalc == 'H.W') {
      HeadlossPipe3 = (1.131 * Math.pow(10.0, 12.0) * Math.pow(ManifoldFlow / HWCoef, 1.852)) / Math.pow(dia3, 4.87);
    } else {
      if (dia3 < 125) {
        HeadlossPipe3 = 8.38 * Math.pow(10, 7) * Math.pow(ManifoldFlow, 1.75) * Math.pow(dia3, -4.75);
      } else {
        HeadlossPipe3 = 9.19 * Math.pow(10, 7) * Math.pow(ManifoldFlow, 1.83) * Math.pow(dia3, -4.83);
      }
    }
  }
  HeadlossPipe3 = HeadlossPipe3 * HeadlossFactorForHeadloss;

  let DoneCalculate = false;
  let slope = 0;

  if (calculateLengths) {
    length1 = 0;
    length2 = 0;
    length3 = 0;
    if (ManifoldLength != 0) {
      if (slope < 0) {
        Manifoldallowedhl -= (slope * ManifoldLength) / 100;
      }
      if (
        (HeadlossPipe3 * ManifoldLength) / 1000 == Manifoldallowedhl ||
        (dia1 == 0 && dia2 == 0 && HeadlossPipe3 != 0)
      ) {
        HeadlossPipe3 = Manifoldallowedhl;
        HeadlossPipe1 = 0;
        HeadlossPipe2 = 0;
        DoneCalculate = true;
        length3 = ManifoldLength;
      } else {
        if (
          (HeadlossPipe2 * ManifoldLength) / 1000 == Manifoldallowedhl ||
          (dia1 == 0 && dia3 == 0 && HeadlossPipe2 != 0)
        ) {
          HeadlossPipe2 = Manifoldallowedhl;
          HeadlossPipe1 = 0;
          HeadlossPipe3 = 0;
          length2 = ManifoldLength;
          DoneCalculate = true;
        } else {
          if (
            (HeadlossPipe1 * ManifoldLength) / 1000 == Manifoldallowedhl ||
            (dia3 == 0 && dia2 == 0 && HeadlossPipe1 != 0)
          ) {
            HeadlossPipe1 = Manifoldallowedhl;
            HeadlossPipe3 = 0;
            HeadlossPipe2 = 0;
            length1 = ManifoldLength;
            DoneCalculate = true;
          }
        }
      }

      if (DoneCalculate == false) {
        if ((HeadlossPipe1 * ManifoldLength) / 1000 > Manifoldallowedhl) {
          if (HeadlossPipe1 < HeadlossPipe2 && HeadlossPipe1 < HeadlossPipe3 && HeadlossPipe2 != HeadlossPipe3) {
            length1 = ManifoldLength;
            length2 = 0;
            length3 = 0;
            DoneCalculate = true;
          }
        }
      }
      if (DoneCalculate == false) {
        if ((HeadlossPipe2 * ManifoldLength) / 1000 > Manifoldallowedhl) {
          if (HeadlossPipe2 < HeadlossPipe1 && HeadlossPipe2 < HeadlossPipe3) {
            length2 = ManifoldLength;
            length1 = 0;
            length3 = 0;
            DoneCalculate = true;
          } else {
            if (HeadlossPipe2 > HeadlossPipe1 && HeadlossPipe2 > HeadlossPipe3 && HeadlossPipe1 != HeadlossPipe3) {
              console.log('test');
            } else {
              if (HeadlossPipe2 - HeadlossPipe1 > 0) {
                length2 =
                  ((Manifoldallowedhl - (ManifoldLength / 1000) * HeadlossPipe1) / (HeadlossPipe2 - HeadlossPipe1)) *
                  1000;
                if (length2 < 0) {
                  length2 = 0;
                }
                if (length2 > ManifoldLength) {
                  length2 = ManifoldLength;
                }
                length1 = ManifoldLength - length2;
                length3 = 0;
                DoneCalculate = true;
              } else {
                if (HeadlossPipe1 - HeadlossPipe2 > 0) {
                  length1 =
                    ((Manifoldallowedhl - (ManifoldLength / 1000) * HeadlossPipe2) / (HeadlossPipe1 - HeadlossPipe2)) *
                    1000;
                  if (length1 < 0) {
                    length1 = 0;
                  }
                  if (length1 > ManifoldLength) {
                    length1 = ManifoldLength;
                  }
                  length2 = ManifoldLength - length1;
                  length3 = 0;
                  DoneCalculate = true;
                }
              }
            }
          }
        }
      }
      if (DoneCalculate == false) {
        if ((HeadlossPipe3 * ManifoldLength) / 1000 > Manifoldallowedhl) {
          if (HeadlossPipe3 < HeadlossPipe1 && HeadlossPipe3 <= HeadlossPipe2 && HeadlossPipe2 != HeadlossPipe1) {
            length2 = ManifoldLength;
            length3 = 0;
            length1 = 0;
            DoneCalculate = true;
          } else {
            if (HeadlossPipe3 > HeadlossPipe1 && HeadlossPipe3 > HeadlossPipe2 && HeadlossPipe1 != HeadlossPipe2) {
              if (HeadlossPipe2 - HeadlossPipe3 > 0) {
                length2 =
                  ((Manifoldallowedhl - (ManifoldLength / 1000) * HeadlossPipe3) / (HeadlossPipe2 - HeadlossPipe3)) *
                  1000;
                if (length2 < 0) {
                  length2 = 0;
                }
                if (length2 > ManifoldLength) {
                  length2 = ManifoldLength;
                }
                length3 = ManifoldLength - length2;
                length1 = 0;
                DoneCalculate = true;
              } else {
                if (HeadlossPipe3 != HeadlossPipe2) {
                  length3 =
                    ((Manifoldallowedhl - (ManifoldLength / 1000) * HeadlossPipe2) / (HeadlossPipe3 - HeadlossPipe2)) *
                    1000;
                  if (length3 < 0) {
                    length3 = 0;
                  }
                  if (length3 > ManifoldLength) {
                    length3 = ManifoldLength;
                  }
                } else {
                  length3 = 0;
                }
                if (length3 > ManifoldLength) length3 = ManifoldLength;
                length2 = ManifoldLength - length3;
                length1 = 0;
                DoneCalculate = true;
              }
            } else {
              if (HeadlossPipe3 - HeadlossPipe2 > 0) {
                length3 =
                  ((Manifoldallowedhl - (ManifoldLength / 1000) * HeadlossPipe2) / (HeadlossPipe3 - HeadlossPipe2)) *
                  1000;
                if (length3 < 0) {
                  length3 = 0;
                }
                if (length3 > ManifoldLength) {
                  length3 = ManifoldLength;
                }
                length2 = ManifoldLength - length3;
                length1 = 0;
                DoneCalculate = true;
              } else {
                if (HeadlossPipe2 - HeadlossPipe3 > 0) {
                  length2 =
                    ((Manifoldallowedhl - (ManifoldLength / 1000) * HeadlossPipe3) / (HeadlossPipe2 - HeadlossPipe3)) *
                    1000;
                  if (length2 < 0) {
                    length2 = 0;
                  }
                  if (length2 > ManifoldLength) {
                    length2 = ManifoldLength;
                  }
                  length3 = ManifoldLength - length2;
                  length1 = 0;
                  DoneCalculate = true;
                }
              }
            }
          }
        } else {
          if (HeadlossPipe2 - HeadlossPipe3 > 0) {
            length2 =
              ((Manifoldallowedhl - (ManifoldLength / 1000) * HeadlossPipe3) / (HeadlossPipe2 - HeadlossPipe3)) * 1000;
            if (length2 < 0) {
              length2 = 0;
            }
            if (length2 > ManifoldLength) {
              length2 = ManifoldLength;
            }
            length3 = ManifoldLength - length2;
            length1 = 0;
            DoneCalculate = true;
          } else {
            if (HeadlossPipe3 != HeadlossPipe2) {
              length3 =
                ((Manifoldallowedhl - (ManifoldLength / 1000) * HeadlossPipe2) / (HeadlossPipe3 - HeadlossPipe2)) *
                1000;
              if (length3 < 0) {
                length3 = 0;
              }
              if (length3 > ManifoldLength) {
                length3 = ManifoldLength;
              }
            } else {
              length3 = 0;
            }
            if (length3 > ManifoldLength) length3 = ManifoldLength;
            length2 = ManifoldLength - length3;
            length1 = 0;
            DoneCalculate = true;
          }
        }
      }
    }
  }
  if (DoneCalculate == false) {
    length1 = ManifoldLength;
  }

  let velocity1 = 0;
  let velocity2 = 0;
  let velocity3 = 0;

  velocity1 = ManifoldFlow / ((((Math.PI * dia1) / 1000) * dia1) / 1000 / 4) / 3600;
  velocity2 = (((length3 + length2) / spacing) * lateralFlow) / ((((Math.PI * dia2) / 1000) * dia2) / 1000 / 4) / 3600;
  velocity3 = ((length3 / spacing) * lateralFlow) / ((((Math.PI * dia3) / 1000) * dia3) / 1000 / 4) / 3600;

  velocity1 = velocity1Calc(velocity1, unitSettings);
  velocity2 = velocity2Calc(velocity2, unitSettings);
  velocity3 = velocity3Calc(velocity3, unitSettings);

  length1 = length1CalcRes(length1, unitSettings);
  length2 = length2CalcRes(length2, unitSettings);
  length3 = length3CalcRes(length3, unitSettings);

  const result: CalcResultLength = {
    length1,
    length2,
    length3,
    velocity1,
    velocity2,
    velocity3,
  };
  return result;
}
