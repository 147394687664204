import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ELLIPSE_SIZE, SOURCE_CANVAS_COEFFICIENT } from 'shared/constants';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { getTransformedArray } from 'shared/lib';
import { setEDCStateToolBar } from 'shared/slices';
import { CanvasLines, CanvasPlants, Modal } from 'shared/ui';
import { CanvasRange } from 'shared/ui';

export const EDCZoom = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    calcResult: { ELIPS, ranges, GrowthResult },
    edcValues: { placement, edge },
    edcToolBar: { showZoom, showRange, showGrowth },
  } = useAppSelector((st) => st.edcState);

  const toggleZoomModal = () => dispatch(setEDCStateToolBar({ showZoom: !showZoom }));
  const toggleRange = () => dispatch(setEDCStateToolBar({ showRange: !showRange }));
  const toggleGrowth = () => dispatch(setEDCStateToolBar({ showGrowth: !showGrowth }));

  const mapContainerRef = useRef<HTMLDivElement>(null);

  const mapComponents = useMemo(() => {
    return new Array(9).fill(0).map((item, idx) => (
      <div
        // item
        ref={mapContainerRef}
        key={`${item}${idx}`}
        style={{
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        {ELIPS &&
          getTransformedArray(ELIPS, +placement, +edge).map((ellipses, idxRow) => (
            <div key={idxRow} style={{ display: 'flex' }}>
              {ellipses.map((colors, idxEllipse) => (
                <div
                  key={idxEllipse}
                  style={{
                    borderRadius: '2px',
                    width: ELLIPSE_SIZE,
                    height: ELLIPSE_SIZE,
                    color: 'black',
                    backgroundColor: colors.color,
                  }}
                >
                  {/* {showNumbers && idxColumn % 2 === 0 && idxRow % 2 === 0 && <span>{edcCalcResult.FieldRainAt[idxColumn][idxRow] > 0 && Math.ceil(edcCalcResult.FieldRainAt[idxColumn][idxRow])}</span>} */}
                </div>
              ))}
            </div>
          ))}
        {ranges &&
          showRange &&
          ranges.map((item, idx) => {
            const scaledItem = {
              width: item.width * SOURCE_CANVAS_COEFFICIENT,
              height: item.height * SOURCE_CANVAS_COEFFICIENT,
              left: item.left * SOURCE_CANVAS_COEFFICIENT,
              top: item.top * SOURCE_CANVAS_COEFFICIENT,
            };
            return <CanvasRange key={idx} item={scaledItem} />;
          })}

        {showGrowth &&
          GrowthResult?.resElip.length &&
          GrowthResult.resElip.map((item, idx) => <CanvasPlants key={idx} item={item} showGrowth={showGrowth} />)}

        {showGrowth && GrowthResult?.resLine.length && (
          <CanvasLines
            lines={GrowthResult.resLine}
            width={mapContainerRef.current?.offsetWidth ?? 0}
            height={mapContainerRef.current?.offsetHeight ?? 0}
          />
        )}
      </div>
    ));
  }, [ELIPS, ranges, GrowthResult, showRange, showGrowth]);

  return (
    <Modal maxWidth="sm" title={t('Zoom')} open={showZoom} onClose={toggleZoomModal}>
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: 5 }}>{mapComponents}</div>

      <Grid container item xs={12} justifyContent="center">
        <Grid item xs="auto">
          <FormControlLabel control={<Checkbox checked={showRange} onChange={toggleRange} />} label={`${t('Range')}`} />
        </Grid>
        <Grid item xs="auto">
          <FormControlLabel
            control={<Checkbox checked={showGrowth} onChange={toggleGrowth} />}
            label={`${t('Growth')}`}
          />
        </Grid>
      </Grid>
    </Modal>
  );
};
