import {
  logout,
  resetProjects,
  resetEmitterFamilies,
  resetEmitters,
  resetEmittersEDC,
  resetLaterals,
  resetMainlines,
  resetMasterGroups,
  resetProjectData,
  resetSubmains,
  resetUnits,
  toggleExit,
} from 'shared/slices';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { Modal } from 'shared/ui';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const Exit = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { openExit } = useAppSelector((st) => st.modals);

  const toggle = () => dispatch(toggleExit());

  const handleOkClick = () => {
    dispatch(resetProjects());
    dispatch(resetEmitterFamilies());
    dispatch(resetEmitters());
    dispatch(resetEmittersEDC());
    dispatch(resetLaterals());
    dispatch(resetMainlines());
    dispatch(resetSubmains());
    dispatch(resetProjectData());
    dispatch(resetMasterGroups());
    dispatch(resetUnits());
    dispatch(logout());
    toggle();
  };

  return (
    <Modal maxWidth="xs" title={t('logout_confirmation')} open={openExit} onClose={toggle}>
      <Typography mb={2}>{t('are_you_sure_want_to_log_out')}</Typography>
      <Box display="flex" columnGap={1}>
        <Button onClick={handleOkClick} variant="contained" sx={{ flexBasis: '50%' }}>
          {t('ok')}
        </Button>

        <Button onClick={toggle} variant="outlined" sx={{ flexBasis: '50%' }}>
          {t('cancel')}
        </Button>
      </Box>
    </Modal>
  );
};
