import { parse } from 'path';

export function GetDU(
  Average: number,
  FieldEdgeX: number,
  FieldEdgeY: number,
  FieldRainAt: Array<Array<number>>,
  ActuallCounter: number
) {
  let SUM = 0;
  let dudev = 0;
  let temp = 0;
  let Count = 0;
  let i = 0;
  let X = 0;
  let y = 0;

  let DUFIELD = [];
  for (let i = 0; i < (FieldEdgeX + 1) * (FieldEdgeY + 1) + 1; i++) {
    DUFIELD[i] = 0;
  }
  Count = 0;
  if (ActuallCounter > 0) {
    for (y = 0; y <= FieldEdgeY; y++) {
      for (X = 0; X <= FieldEdgeX; X++) {
        if (FieldRainAt?.[X]?.[y] >= 0) {
          DUFIELD[Count] = FieldRainAt[X][y];
          Count = Count + 1;
        }
      }
    }
  }
  qsort(0, ActuallCounter, DUFIELD);
  SUM = 0;
  for (i = 0; i <= Math.round(ActuallCounter / 4); i++) {
    SUM = SUM + Math.abs(DUFIELD[i] - Average);
  }
  if (ActuallCounter > 0) {
    dudev = SUM / (ActuallCounter / 4);
  } else {
    dudev = 0;
  }
  if (Average != 0) {
    var res = (1 - dudev / Average) * 100 + 2;
    temp = Math.round(res);
  } else {
    temp = 0;
  }
  if (temp < 0) {
    temp = 0;
  } else if (temp > 100) {
    temp = 100;
  }
  return temp;
}

export function qsort(Start: number, Finish: number, DUFIELD: Array<number>) {
  let PIVOT = 0;
  let LEFTI = 0;
  let RIGHTI = 0;
  let temp = 0;

  LEFTI = Start;
  RIGHTI = Finish;
  PIVOT = DUFIELD[Math.round((Start + Finish) / 2)];

  do {
    while (LEFTI < DUFIELD.length && DUFIELD[LEFTI] < PIVOT) {
      LEFTI = LEFTI + 1;
    }
    while (RIGHTI > 0 && PIVOT < DUFIELD[RIGHTI]) {
      RIGHTI = RIGHTI - 1;
    }
    if (LEFTI <= RIGHTI) {
      temp = DUFIELD[LEFTI];
      DUFIELD[LEFTI] = DUFIELD[RIGHTI];
      DUFIELD[RIGHTI] = temp;
      LEFTI = LEFTI + 1;
      RIGHTI = RIGHTI - 1;
    }
  } while (!(RIGHTI <= LEFTI));

  if (Start < RIGHTI) {
    qsort(Start, RIGHTI, DUFIELD);
  }

  if (LEFTI < Finish) {
    qsort(LEFTI, Finish, DUFIELD);
  }
  return;
}
