import { Box } from '@mui/system';
import { LoginForm } from 'widgets';

export const LoginPage = () => {
  return (
    <Box>
      <LoginForm />
    </Box>
  );
};
