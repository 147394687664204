import { AppBar, Typography, IconButton, Tooltip } from '@mui/material';
import { Menu } from '@mui/icons-material';
import { FC } from 'react';
import { folder, rivulis } from 'shared/assets';
import { useAppSelector, useSideMenu } from 'shared/hooks';
import { Icon, Row } from 'shared/ui';
import { useTranslation } from 'react-i18next';

export const Header: FC = () => {
  const { projectName } = useAppSelector((state) => state.projects.currentProject);
  const { toggleSideMenu } = useSideMenu();

  const [t] = useTranslation();

  return (
    <AppBar elevation={0} position="static" sx={{ height: '44px' }}>
      <Row height="100%" width="100%" justifyContent={{ xs: 'space-between', sm: 'flex-start' }}>
        <Row
          order={{ xs: 1, sm: 2, lg: 1 }}
          width={{ xs: '100%', sm: '33%', lg: 'auto' }}
          justifyContent={{ xs: 'center', lg: 'flex-start' }}
        >
          <Icon src={rivulis} sx={{ px: '45px' }} />
        </Row>

        <Row
          order={{ xs: 2, sm: 1, lg: 2 }}
          display={{ xs: 'none', sm: 'flex' }}
          sx={{ pl: '32px' }}
          width={{ xs: '33%', lg: 'auto' }}
        >
          <Icon src={folder} sx={{ mr: '10px' }} />
          <Tooltip placement="top" title={t('projectName')}>
            <Typography color="info.main">{projectName}</Typography>
          </Tooltip>
        </Row>

        <Row
          order={3}
          display={{ xs: 'flex', lg: 'none' }}
          width={{ xs: '0%', sm: '33%' }}
          justifyContent="flex-end"
          mr={3}
        >
          <IconButton
            onClick={toggleSideMenu(true)}
            size="large"
            sx={{
              color: 'info.main',
              p: 0,
              ml: 'auto',
              order: 3,
            }}
          >
            <Menu />
          </IconButton>
        </Row>
      </Row>
    </AppBar>
  );
};
