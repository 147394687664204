import { Box, Fade, Grid, MenuItem, SelectChangeEvent, Stack, useMediaQuery, useTheme } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { define_slopes, remove } from 'shared/assets';
import { SLOPE_DIRECTIONS } from 'shared/constants';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { convertSlopes, formattedInputValue, getFormattedElevation, parseSlopes, Slope } from 'shared/lib';
import { Math_round } from 'shared/lib/calculation/mathRound';
import { setProjectValue } from 'shared/slices';
import { Accordion, Button, Icon, IconLabel, Input, PaperBox, Row, Select } from 'shared/ui';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const DefineSlopesContent = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { palette } = useTheme();
  const { projectData } = useAppSelector((state) => state.projectData);
  const { units } = useAppSelector((state) => state.units);

  const isTablet = useMediaQuery('(max-width:850px)');
  const isMobile = useMediaQuery('(max-width:500px)');

  const [inputFields, setInputFields] = useState<Slope[]>(() => {
    const slopesData = parseSlopes(projectData.lateralSlopes);

    return slopesData?.map((item, idx) => {
      const elevation = Math_round((item.length / 100) * item.slope, 2);
      const direction = item.direction === 'U' ? 'Uphill' : 'Downhill';

      const formattedElevation = getFormattedElevation(direction, elevation);

      return {
        name: `slope${idx + 1}`,
        slope: item.slope,
        length: item.length,
        direction: item.direction === 'U' ? 'Uphill' : 'Downhill',
        elevation: formattedElevation,
      };
    });
  });

  const onLengthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValues = inputFields.find((item) => item.name === e.target.name) as Slope;
    const inputValuesIdx = inputFields.findIndex((item) => item.name === e.target.name);

    const elevation = Math_round((+e.target.value / 100) * +inputValues.slope, 2);

    const formattedElevation = getFormattedElevation(inputValues.direction, elevation);

    const updatedValue = { ...inputValues, length: formattedInputValue(e), elevation: formattedElevation };

    const result = [...inputFields.slice(0, inputValuesIdx), updatedValue, ...inputFields.slice(inputValuesIdx + 1)];
    setInputFields(result);

    dispatch(setProjectValue({ lateralSlopes: convertSlopes(result) }));
  };

  const onSlopeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValues = inputFields.find((item) => item.name === e.target.name) as Slope;
    const inputValuesIdx = inputFields.findIndex((item) => item.name === e.target.name);

    const elevation = Math_round((+inputValues.length / 100) * +e.target.value, 2);

    const formattedElevation = getFormattedElevation(inputValues.direction, elevation);

    const updatedValue = { ...inputValues, slope: formattedInputValue(e), elevation: formattedElevation };

    const result = [...inputFields.slice(0, inputValuesIdx), updatedValue, ...inputFields.slice(inputValuesIdx + 1)];
    setInputFields(result);

    dispatch(setProjectValue({ lateralSlopes: convertSlopes(result) }));
  };

  const onDirectionChange = (e: SelectChangeEvent<unknown>) => {
    const inputValues = inputFields.find((item) => item.name === e.target.name) as Slope;
    const inputValuesIdx = inputFields.findIndex((item) => item.name === e.target.name);

    const elevation = Math_round((+inputValues.length / 100) * +inputValues.slope, 2);

    const formattedElevation = getFormattedElevation(e.target.value as string, elevation);

    const updatedValue = { ...inputValues, direction: e.target.value as string, elevation: formattedElevation };

    const result = [...inputFields.slice(0, inputValuesIdx), updatedValue, ...inputFields.slice(inputValuesIdx + 1)];
    setInputFields(result);

    dispatch(setProjectValue({ lateralSlopes: convertSlopes(result) }));
  };

  const onDeleteClick = (fieldName: string) => {
    const updatedFields = inputFields.filter((item) => item.name !== fieldName);

    dispatch(setProjectValue({ lateralSlopes: convertSlopes(updatedFields) }));
    setInputFields(updatedFields);
  };

  const onAddClick = () => {
    const updatedFields = [
      ...inputFields,
      { name: `slope${inputFields.length + 1}`, slope: 0, length: 0, direction: 'Uphill', elevation: '0' },
    ];

    dispatch(setProjectValue({ lateralSlopes: convertSlopes(updatedFields) }));
    setInputFields(updatedFields);
  };

  const onClearClick = () => {
    dispatch(setProjectValue({ lateralSlopes: convertSlopes([]) }));
    setInputFields([]);
  };

  useEffect(() => {
    if (!projectData.lateralSlopes) setInputFields([]);
  }, [projectData.lateralSlopes]);

  return (
    <Stack direction={isTablet ? 'column-reverse' : 'column'}>
      <Grid
        item
        minHeight={125}
        maxHeight={365}
        sx={{
          pb: '15px',
          overflow: 'auto',
          // scrollbarWidth: 'auto',
          scrollbarColor: 'primary.main secondary.main',
          '&::-webkit-scrollbar': {
            width: '7px',
            height: '7px',
          },
          '&::-webkit-scrollbar-track': {
            background: 'primary.main',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'primary.main',
            borderRadius: '13px',
          },
        }}
      >
        {inputFields?.map((field, i) => (
          <Grid
            item
            container
            xs={12}
            columnSpacing={1}
            key={field.name}
            mt={1}
            pt={isTablet ? 1 : 0}
            sx={{
              borderTop: isTablet && i > 0 ? `3px solid ${palette.primary.light}` : '',
            }}
          >
            <Grid item xs={isMobile ? 5.5 : 2.75}>
              <Input
                type="number"
                name={field.name}
                value={field.length}
                onChange={onLengthChange}
                label={`${t('length')} (${units.length})`}
              />
            </Grid>
            <Grid item xs={isMobile ? 5.5 : 2.75} onChange={onSlopeChange}>
              <Input type="number" name={field.name} value={field.slope} label={`${t('slope')} (%)`} />
            </Grid>
            <Grid item xs={isMobile ? 5.5 : 2.75}>
              <Select
                name={field.name}
                label={t('direction') ?? ''}
                value={field.direction}
                onChange={onDirectionChange}
              >
                {SLOPE_DIRECTIONS.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={isMobile ? 5.5 : 2.75}>
              <Input
                name={field.name}
                value={field.elevation}
                isResultBox
                type="text"
                label={`${t('elevation')} (${units.length})`}
              />
            </Grid>
            <Grid item xs={isMobile ? 1 : 1}>
              <Icon onClick={() => onDeleteClick(field.name)} src={remove} sx={{ cursor: 'pointer', mt: '25px' }} />
            </Grid>
          </Grid>
        ))}
      </Grid>

      <Row
        justifyContent="center"
        sx={{ mr: isMobile ? 0 : '15px', py: '9px', borderTop: isTablet ? '' : `3px solid ${palette.primary.light}` }}
      >
        <Row spacing="10px" direction={isMobile ? 'column' : 'row'} width={isMobile ? '100%' : 'auto'}>
          <Button sx={{ width: isMobile ? '100%' : '200px' }} onClick={onAddClick}>
            {t('add')}
          </Button>
          <Button variant="outlined" onClick={onClearClick} sx={{ width: isMobile ? '100%' : '200px' }}>
            {t('clear')}
          </Button>
        </Row>
      </Row>
    </Stack>
  );
};

export const DefineSlopesBlock: FC = () => {
  const { projectData } = useAppSelector((state) => state.projectData);
  const [showSlopes, setShowSlopes] = useState(!!projectData.lateralSlopes);

  const handleArrowClick = () => {
    if (!projectData.lateralSlopes) {
      setShowSlopes((prev) => !prev);
    }
  };

  const isTablet = useMediaQuery('(max-width:850px)');

  const { t } = useTranslation();

  return (
    <>
      {isTablet ? (
        <Box width="100%">
          <Accordion
            expanded={showSlopes}
            onChange={handleArrowClick}
            header={<IconLabel iconSrc={define_slopes}>{t('defineSlopes')}</IconLabel>}
          >
            <DefineSlopesContent />
          </Accordion>
        </Box>
      ) : (
        <Box width="60%">
          <Box display="flex">
            <IconLabel iconSrc={define_slopes}>{t('defineSlopes')}</IconLabel>
            <KeyboardArrowDownIcon
              onClick={handleArrowClick}
              sx={{
                mt: '-3px',
                cursor: 'pointer',
                transform: showSlopes ? 'rotate(180deg)' : 'none',
                transition: 'transform .1s',
              }}
            />
          </Box>
          <Fade in={showSlopes}>
            <div>
              <PaperBox
                sx={{
                  minHeight: '185px',
                  maxHeight: '450px',
                  pr: 0,
                }}
              >
                <DefineSlopesContent />
              </PaperBox>
            </div>
          </Fade>
        </Box>
      )}
    </>
  );
};
