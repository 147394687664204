import { ChangeEvent, FC, useState } from 'react';
import {
  Box,
  FormControlLabel as MuiFormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  useMediaQuery,
  styled,
  Tooltip,
  IconButton,
} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import { positioning, positioning_ico } from 'shared/assets';
import { Accordion, IconLabel, PaperBox } from 'shared/ui';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { setEDCStateValue } from 'shared/slices';
import { useTranslation } from 'react-i18next';

const FormControlLabel = styled(MuiFormControlLabel)({
  span: {
    fontSize: '14px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
});

const PositioningContent: FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width:550px)');
  const { edcValues } = useAppSelector((state) => state.edcState);

  const onPlacementChange = (_: ChangeEvent<HTMLInputElement>, value: string) => {
    dispatch(setEDCStateValue({ placement: +value }));
  };

  const onEdgeChange = (_: ChangeEvent<HTMLInputElement>, value: string) => {
    dispatch(setEDCStateValue({ edge: +value }));
  };

  const onLateralChange = (_: ChangeEvent<HTMLInputElement>, value: string) => {
    dispatch(setEDCStateValue({ lateral: +value }));
  };

  const isEdgeDisabled = edcValues.placement === 2 || edcValues.placement === 3;
  const edgeValue = isEdgeDisabled ? '' : edcValues.edge;

  return (
    <Grid item container xs={12} rowSpacing={1}>
      <Grid item xs={isMobile ? 6 : 4} sx={{ overflow: 'hidden', pl: '10px' }}>
        <RadioGroup value={edcValues.placement} onChange={onPlacementChange}>
          <FormControlLabel value={0} control={<Radio size="small" />} label={`${t('rectangular')}`} />
          <FormControlLabel value={1} control={<Radio size="small" />} label={`${t('triangular')}`} />
          <FormControlLabel value={2} control={<Radio size="small" />} label={`${t('lateral')}`} />
          <FormControlLabel value={3} control={<Radio size="small" />} label={`${t('single')}`} />
        </RadioGroup>
      </Grid>

      <Grid item xs={isMobile ? 6 : 4}>
        <div id="edc-planting-diagram">
          <RadioGroup value={edgeValue} onChange={onEdgeChange} sx={{ overflow: 'hidden', pl: '10px' }}>
            <FormControlLabel
              disabled={isEdgeDisabled}
              value={0}
              control={<Radio size="small" />}
              label={`${t('none')}`}
            />
            <FormControlLabel
              disabled={isEdgeDisabled}
              value={1}
              control={<Radio size="small" />}
              label={`${t('inRow')}`}
            />
            <FormControlLabel
              disabled={isEdgeDisabled}
              value={2}
              control={<Radio size="small" />}
              label={`${t('offRow')}`}
            />
            <FormControlLabel
              disabled={isEdgeDisabled}
              value={3}
              control={<Radio size="small" />}
              label={`${t('fieldCorner')}`}
            />
          </RadioGroup>
        </div>
      </Grid>

      <Grid item xs={isMobile ? 12 : 4}>
        <RadioGroup
          value={edcValues.lateral}
          onChange={onLateralChange}
          sx={{ overflow: 'hidden', pl: isMobile ? '35%' : '10px' }}
        >
          <Tooltip placement="top" title="Coming soon">
            <FormControlLabel disabled value={0} control={<Radio size="small" />} label={`Bi-${t('lateral')}`} />
          </Tooltip>
          <Tooltip placement="top" title="Coming soon">
            <FormControlLabel disabled value={1} control={<Radio size="small" />} label={`3-${t('lateral')}`} />
          </Tooltip>
          <Tooltip placement="top" title="Coming soon">
            <FormControlLabel disabled value={2} control={<Radio size="small" />} label={`4-${t('lateral')}`} />
          </Tooltip>
          <FormControlLabel value={3} control={<Radio size="small" />} label={`${t('infinite')}`} />
        </RadioGroup>
      </Grid>
    </Grid>
  );
};

export const PositioningBlock: FC = () => {
  const isTablet = useMediaQuery('(max-width:850px)');
  const [showHelper, setShowHelper] = useState(false);
  const { t } = useTranslation();

  return (
    <Box mt={1} width="100%">
      {isTablet ? (
        <Accordion header={<IconLabel iconSrc={positioning_ico}>{t('positioning')}</IconLabel>}>
          <PositioningContent />
        </Accordion>
      ) : (
        <Box sx={{ position: 'relative' }}>
          <IconLabel
            iconSrc={positioning_ico}
            additionalIcon={
              <IconButton
                size="small"
                disableRipple
                disableFocusRipple
                disableTouchRipple
                sx={{ p: 0, ml: 0.5 }}
                onMouseEnter={(e) => {
                  setShowHelper(true);
                }}
                onMouseLeave={(e) => {
                  setShowHelper(false);
                }}
              >
                <HelpIcon fontSize="small" />
              </IconButton>
            }
          >
            {t('positioning')}
          </IconLabel>
          {showHelper && (
            <img
              src={positioning}
              style={{ position: 'absolute', bottom: '20%', left: 175, width: 397, height: 451, zIndex: 9999 }}
            />
          )}
          <PaperBox height="200px">
            <PositioningContent />
          </PaperBox>
        </Box>
      )}
    </Box>
  );
};
