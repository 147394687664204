import { Plants } from '../../models/Plants';
import { SetRows } from './SetRows';

export function BuildPlantsField(buildPlants: Plants) {
  let RelRangeX = 0;
  // let RelRangeY = 0;

  let GroupSize = 0;
  let EDGE_LAYOUT = 5;
  let X_EDGE_LAYOUT = 1;
  let CORNER_EDGE_LAYOUT = 3;
  let Y_EDGE_LAYOUT = 2;

  let RowsInOneGroup = 0;

  if (buildPlants.BetweenPlants == 0) {
    buildPlants.BetweenPlants = 1;
  }
  if (buildPlants.BetweenRows == 0) {
    buildPlants.BetweenRows = 1;
  }

  if (buildPlants.GroupPlantRows) {
    GroupSize = buildPlants.BetweenRows * (buildPlants.RowsInGroup - 1) + buildPlants.BetweenGroups;
    if (
      buildPlants.Placement == EDGE_LAYOUT &&
      (buildPlants.EdgeType == X_EDGE_LAYOUT || buildPlants.EdgeType == CORNER_EDGE_LAYOUT)
    ) {
      RelRangeX = (buildPlants.dEDGEPOSITIONX - buildPlants.OffRow) / GroupSize;
    } else {
      if (GroupSize > 0) {
        RelRangeX = (buildPlants.dXPOSITION - buildPlants.OffRow) / GroupSize;
      }
    }
    RowsInOneGroup = buildPlants.RowsInGroup;
  } else {
    GroupSize = buildPlants.dXPOSITION;
    RelRangeX = 0;

    RowsInOneGroup = (buildPlants.dXPOSITION - buildPlants.OffRow) / buildPlants.BetweenRows + 1;
  }

  if (
    buildPlants.Placement == EDGE_LAYOUT &&
    (buildPlants.EdgeType == Y_EDGE_LAYOUT || buildPlants.EdgeType == CORNER_EDGE_LAYOUT)
  ) {
    // RelRangeY =
    //   (buildPlants.dEDGEPOSITIONY - buildPlants.InRows) / buildPlants.BetweenPlants - 1;
  } else {
    // RelRangeY = (buildPlants.dYPOSITION - buildPlants.InRows) / buildPlants.BetweenPlants;
  }
  let _with1 = [];
  for (let i = 0; i <= RelRangeX; i++) {
    let Left_Renamed = buildPlants.OffRow + GroupSize * i;

    let Group = {
      BetweenRows: buildPlants.BetweenRows,
      Top: buildPlants.InRows,
      RowCount: RowsInOneGroup,
      Rows: SetRows(
        RowsInOneGroup,
        buildPlants.InRows,
        buildPlants.BetweenPlants,
        buildPlants.StaggeredRows,
        buildPlants.PlantDiameter,
        Left_Renamed,
        buildPlants.dYPOSITION,
      ),
    };

    _with1.push(Group);
  }
  return _with1;
}
