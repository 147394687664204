import { Box, Grid, MenuItem, SelectChangeEvent, useMediaQuery } from '@mui/material';
import { t } from 'i18next';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { select_product } from 'shared/assets';
import { useAppSelector } from 'shared/hooks';
import { MasterGroup } from 'shared/models';
import { Accordion, IconLabel, PaperBox, Select } from 'shared/ui';

interface Props {
  masterGroups: MasterGroup[];
  masterGroupId: string;
  onMasterGroupChange: (e: SelectChangeEvent<unknown>) => Promise<void>;
  submainSubtype: string;
  onGroupTypeChange: (e: SelectChangeEvent<unknown>) => Promise<void>;
  classType: string | number;
  onClassTypeChange: (e: SelectChangeEvent<unknown>) => void;
  classTypes: string[];
}

const SelectProductContent: FC<Props> = ({
  classType,
  classTypes,
  submainSubtype,
  masterGroupId,
  masterGroups,
  onClassTypeChange,
  onGroupTypeChange,
  onMasterGroupChange,
}) => {
  const { t } = useTranslation();
  const { submainGroups, submainGroupsLoading } = useAppSelector((st) => st.submains);
  const isMobile = useMediaQuery('(max-width:550px)');

  return (
    <Grid item container xs={12} columnSpacing={3} rowSpacing={1}>
      <Grid item xs={isMobile ? 12 : 4}>
        <Select label={`${t('type')}`} value={masterGroupId} onChange={onMasterGroupChange}>
          {masterGroups.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.mastergroupname}
            </MenuItem>
          ))}
        </Select>
      </Grid>

      <Grid item xs={isMobile ? 12 : 4}>
        <Select
          label={`${t('subtype')}`}
          value={submainSubtype}
          onChange={onGroupTypeChange}
          loading={submainGroupsLoading}
          disabled={submainGroupsLoading}
        >
          {submainGroups.map((item) => (
            <MenuItem key={item.groups} value={item.groups}>
              {item.grouptypename}
            </MenuItem>
          ))}
        </Select>
      </Grid>

      <Grid item xs={isMobile ? 12 : 4}>
        <Select label={`${t('class')}`} value={classType} onChange={onClassTypeChange}>
          {classTypes.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </Grid>
  );
};

export const SelectProductBlock: FC<Props> = ({
  classType,
  classTypes,
  submainSubtype,
  masterGroupId,
  masterGroups,
  onClassTypeChange,
  onGroupTypeChange,
  onMasterGroupChange,
}) => {
  const isTablet = useMediaQuery('(max-width:850px)');

  return (
    <Box mt="10px">
      {isTablet ? (
        <Accordion defaultExpanded header={<IconLabel iconSrc={select_product}>{t('selectProduct')}</IconLabel>}>
          <SelectProductContent
            masterGroups={masterGroups}
            masterGroupId={masterGroupId}
            onMasterGroupChange={onMasterGroupChange}
            submainSubtype={submainSubtype}
            onGroupTypeChange={onGroupTypeChange}
            classType={classType}
            onClassTypeChange={onClassTypeChange}
            classTypes={classTypes}
          />
        </Accordion>
      ) : (
        <>
          <IconLabel iconSrc={select_product}>{t('selectProduct')}</IconLabel>

          <PaperBox>
            <SelectProductContent
              masterGroups={masterGroups}
              masterGroupId={masterGroupId}
              onMasterGroupChange={onMasterGroupChange}
              submainSubtype={submainSubtype}
              onGroupTypeChange={onGroupTypeChange}
              classType={classType}
              onClassTypeChange={onClassTypeChange}
              classTypes={classTypes}
            />
          </PaperBox>
        </>
      )}
    </Box>
  );
};
