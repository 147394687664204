import { Box, TableCell, TableRow } from '@mui/material';

export const Divider = () => {
  return (
    <TableRow
      sx={{
        td: {
          borderBottom: 'none',
          '&:first-of-type': { borderTopLeftRadius: '3px', borderBottomLeftRadius: '3px' },
          '&:last-of-type': { borderTopRightRadius: '3px', borderBottomRightRadius: '3px' },
        },
      }}
    >
      <TableCell sx={{ p: '5px 0 10px !important' }}>
        <Box
          sx={{
            backgroundColor: '#DAE9E2',
            height: '3px',
          }}
        />
      </TableCell>
      <TableCell sx={{ p: '5px 0 10px !important' }}>
        <Box
          sx={{
            backgroundColor: '#DAE9E2',
            height: '3px',
          }}
        />
      </TableCell>
    </TableRow>
  );
};
