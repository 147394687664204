import { Alert, AlertColor, Box, Grow, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { FC, PropsWithChildren, useState } from 'react';
import { ToastContext } from 'shared/contexts';

const autoHideDelay = 3000;

export const ToastProvider: FC<PropsWithChildren> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState<AlertColor>();
  const [message, setMessage] = useState('');

  const showMessage = (message: string) => {
    setMessage(message);
    setOpen(true);

    setTimeout(() => {
      setOpen(false);
    }, autoHideDelay);
  };

  const showSuccess = (message: string) => {
    setSeverity('success');
    showMessage(message);
  };

  const showError = (message: string) => {
    setSeverity('error');
    showMessage(message);
  };
  const showInfo = (message: string) => {
    setSeverity('info');
    showMessage(message);
  };

  const showWarning = (message: string) => {
    setSeverity('warning');
    showMessage(message);
  };

  return (
    <ToastContext.Provider value={{ showSuccess, showError, showInfo, showWarning }}>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: '50%',
          maxWidth: 300,
          transform: 'translateX(-50%)',
          zIndex: open ? 9999 : -1,
        }}
      >
        <Grow in={open}>
          <Alert
            variant="filled"
            severity={severity}
            action={
              <IconButton aria-label="close" color="inherit" size="small" onClick={() => setOpen(false)}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {message}
          </Alert>
        </Grow>
      </Box>
      {children}
    </ToastContext.Provider>
  );
};
