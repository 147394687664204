import { METRIC_DEFAULTS } from 'shared/constants';
import { ConvertUnit } from '../../unitConverter';
import { Math_round } from '../../mathRound';
import { CatalogItem, Unit } from 'shared/models';

export const calculateNominalFlow = (catalogItem: CatalogItem, units: Unit, value: number) => {
  const nominalPressure = ConvertUnit(value, units.pressure, METRIC_DEFAULTS.Pressure, null);
  const roundedCatalogItemEmitterQA = Math_round(catalogItem.emitterQa, 3);
  const roundedCatalogItemEmitterQB = Math_round(catalogItem.emitterQb, 3);

  // if (!nominalPressure) {
  //   return value;
  // }

  // if (!roundedCatalogItemEmitterQA) {
  //   return value;
  // }

  // if (!roundedCatalogItemEmitterQB) {
  //   return value;
  // }

  const nominalFlow = Math_round(
    roundedCatalogItemEmitterQA * Math.pow(nominalPressure, roundedCatalogItemEmitterQB),
    3
  );

  return ConvertUnit(nominalFlow, METRIC_DEFAULTS.Flow, units.flow, 'Flow');
};
