import { Slide, Dialog, Box, Typography, Button, DialogTitle, IconButton } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector, useMobileDetect } from 'shared/hooks';
import IosShareIcon from '@mui/icons-material/IosShare';
import { logo } from 'shared/assets';
import { toggleAddToHome } from 'shared/slices';
import { usePWAInstall } from 'react-use-pwa-install';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" timeout={500} ref={ref} {...props} />;
});

export const AddToHome: FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const mobileInfo = useMobileDetect();
  const { openAddToHome } = useAppSelector((st) => st.modals);
  const install = usePWAInstall();

  const onInstall = useCallback(() => {
    try {
      install()?.finally(() => {
        dispatch(toggleAddToHome());
      });
    } catch (error) {
      alert(install);
      console.log('####: error', error);
    }
  }, [install]);

  const onClose = () => {
    dispatch(toggleAddToHome());
    localStorage.setItem('hasSeenAddToHome', JSON.stringify(true));
  };

  return (
    <Dialog
      open={openAddToHome}
      onClose={onClose}
      TransitionComponent={Transition}
      fullScreen
      PaperProps={{
        sx: {
          background: '#F5FBFF',
          position: 'fixed',
          height: '35%',
          width: '95%',
          bottom: '10%',
          left: 0,
          right: 0,
          m: '0 auto',
          textAlign: 'center',
          borderRadius: '10px',
        },
      }}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            color="primary"
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <Box component="img" sx={{ width: 56, m: '0 auto' }} alt="Rivulis Logo" src={logo} />
      <Typography
        color="primary"
        variant="body1"
        sx={{
          margin: '10px auto',
          maxWidth: 80,
          fontSize: 18,
          lineHeight: '16px',
          fontStyle: 'Myriad Pro',
          fontWeight: 600,
          letterSpacing: '1%',
          textAlign: 'center',
          color: '#4D4E4C',
        }}
      >
        Hydraulic Tool
      </Typography>
      {/* <Typography color="primary" variant="body1" sx={{ fontWeight: 600, marginBottom: '32px', fontSize: '1.1rem' }}>
        {t('dialog')}
      </Typography> */}
      {mobileInfo?.isIos() ? (
        <Button
          sx={{
            textTransform: 'none',
            alignSelf: 'center',
            width: '90%',
            mb: 3,
          }}
          disableRipple
          variant="contained"
          color="primary"
        >
          <Typography
            variant="h6"
            sx={{
              fontSize: 18,
              textAlign: 'start',
            }}
          >
            {t('dialogButtonIOS1')}
            <IosShareIcon sx={{ verticalAlign: 'text-bottom', ml: 0.7, mr: 0.7 }} />
            {t('dialogButtonIOS2')}
          </Typography>
        </Button>
      ) : (
        mobileInfo?.isAndroid() && (
          <Button
            variant="contained"
            color="primary"
            sx={{ textTransform: 'none', alignSelf: 'center', width: '90%', mb: 3 }}
            onClick={onInstall}
          >
            <Typography variant="h6">{t('dialogButtonAndroid')}</Typography>
          </Button>
        )
      )}
    </Dialog>
  );
};
