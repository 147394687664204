import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'shared/hooks';
import { textFormatter } from 'shared/lib';

export const useSubmain = () => {
  const { units } = useAppSelector((state) => state.units);
  const [isMac, setIsMac] = useState<boolean>(false);

  const { t } = useTranslation();

  useEffect(() => {
    setIsMac(window.innerWidth >= 1400);
  }, []);

  const SUBMAIN_1: any = useMemo(
    () => ({
      id: 'submain-1',
      attachTo: {
        element: '#submain-lateral-input',
        on: 'right-end',
      },
      scrollTo: true,
      text: [
        `
    <div style='width: ${isMac ? '590px' : '790px'}; overflow-wrap: break-word'>
      <ol>
        <li class='shepherd-text'>
          <strong>${t('interactiveSubmain1-1')}</strong>
          <ol type='a'>
            <li class='shepherd-text' style='white-space: normal'>${t('interactiveSubmain1-2')}</li>
            <li class='shepherd-text'>${t('interactiveSubmain1-3')}</li>
          </ol>
        </li>
        <li class='shepherd-text'>${t('interactiveSubmain1-4')} <strong>${t('interactiveSubmain1-5')}</strong> ${t(
          'interactiveSubmain1-6'
        )} <strong>${t('interactiveSubmain1-7')}</strong></li>
        <li class='shepherd-text'>
          <strong>${t('interactiveSubmain1-8')}</strong>
          <ol type='a'>
            <li class='shepherd-text'>${t('interactiveSubmain1-9')}</li>
            <li class='shepherd-text'>${t('interactiveSubmain1-10')}</li>
          </ol>
        </li>
      </ol>
    </div>
    `,
      ],
      buttons: [
        {
          classes: 'btn btn-info',
          text: 'Finish',
          type: 'cancel',
        },
        {
          classes: 'btn btn-success',
          text: 'Next',
          type: 'next',
        },
      ],
      modalOverlayOpeningPadding: 3,
      useModalOverlay: true,
      canClickTarget: false,
    }),
    [t]
  );

  const SUBMAIN_2: any = useMemo(
    () => ({
      id: 'submain-2',
      attachTo: {
        element: '#submain-input',
        on: 'right',
      },
      scrollTo: true,
      text: [
        `
    <ol style="width: ${isMac ? '555px' : '855px'}">
      <li class="shepherd-text">${t('interactiveMainline1-2')} <strong>${t('interactiveMainline1-3')} (${
          units.pressure
        })</strong></li>
      <li class="shepherd-text">${t('interactiveMainline1-2')} <strong>${t('interactiveMainline1-10')} (${
          units.velocity
        })</strong></li>
      <li class="shepherd-text">${t('interactiveMainline1-2')} <strong>${
          textFormatter(t('interactiveSubmain2-4'), [`(${units.lateralSpacing})`]).output
        }</strong></li>
      <li class="shepherd-text">${t('interactiveSubmain1-4')} <strong>${t('interactiveSubmain2-5')}</strong> ${t(
          'interactiveSubmain2-6'
        )} <strong>${t('interactiveSubmain2-7')}</strong> ${t('interactiveSubmain2-8')}</li>
      <li class="shepherd-text">${t('interactiveSubmain2-9')}</li>
      <li class="shepherd-text">${t('interactiveSubmain2-10')}</li>
      <li class="shepherd-text">${t('interactiveSubmain2-11')} <strong>${t('interactiveSubmain2-12')}</strong> ${t(
          'interactiveSubmain2-13'
        )}</li>
    </ol>
    `,
      ],
      buttons: [
        {
          classes: 'btn btn-info',
          text: 'Back',
          type: 'back',
        },
        {
          classes: 'btn btn-success',
          text: 'Next',
          type: 'next',
        },
      ],
      modalOverlayOpeningPadding: 3,
      useModalOverlay: true,
      canClickTarget: false,
    }),
    [t, units]
  );

  const SUBMAIN_3: any = useMemo(
    () => ({
      id: 'submain-3',
      attachTo: {
        element: '#submain-selected-product',
        on: 'right',
      },
      scrollTo: true,
      text: [
        `
    <div style="width: ${isMac ? '514px' : '614px'}">
    <p class="shepherd-text"><strong>${t('interactiveLateralIntegrated1-4')}</strong></p>
    <ol>
      <li class="shepherd-text">${t('interactiveSubmain3-1')}</li>
      <li class="shepherd-text">${t('interactiveSubmain3-2')}</li>
      <li class="shepherd-text">
        ${t('interactiveSubmain3-3')}
        <ol type="a">
          <li class="shepherd-text">${t('interactiveSubmain3-4')} <strong>${t('interactiveSubmain3-5')}</strong></li>
        </ol>
      </li>
    </ol> 
    </div>
    `,
      ],
      buttons: [
        {
          classes: 'btn btn-info',
          text: 'Back',
          type: 'back',
        },
        {
          classes: 'btn btn-success',
          text: 'Next',
          type: 'next',
        },
      ],
      modalOverlayOpeningPadding: 4,
      useModalOverlay: true,
      canClickTarget: false,
    }),
    [t]
  );

  const SUBMAIN_4: any = useMemo(
    () => ({
      id: 'submain-4',
      attachTo: {
        element: '#submain-length',
        on: 'right',
      },
      scrollTo: true,
      text: [
        `
    <div style="width: 444px">
    <p class="shepherd-text">${t('interactiveLateral2-1')} <strong>${t('interactiveSubmain4-1')}</strong> ${t(
          'interactiveLateral2-5'
        )}</p>

    <p class="shepherd-text">${t('interactiveLateral2-1')} <strong>${t('interactiveSubmain4-2')}</strong> ${t(
          'interactiveLateral2-3'
        )}</p>
    </div>
    `,
      ],
      buttons: [
        {
          classes: 'btn btn-info',
          text: 'Back',
          type: 'back',
        },
        {
          classes: 'btn btn-success',
          text: 'Next',
          type: 'next',
        },
      ],
      modalOverlayOpeningPadding: 4,
      useModalOverlay: true,
      canClickTarget: false,
    }),
    [t]
  );

  const SUBMAIN_CALC: any = useMemo(
    () => ({
      id: 'submain-calc',
      attachTo: {
        element: '#submain-calc',
        on: 'right',
      },
      scrollTo: true,
      text: [
        `
    <div style="width: ${isMac ? '530px' : '630px'}">
    <p class="shepherd-text"><strong>V</strong> = ${t('interactiveSubmainCalc1-1')}</p>
    <p class="shepherd-text"><strong>I.D</strong> = ${t('interactiveSubmainCalc1-2')}</p>
    <p class="shepherd-text"><strong>L</strong> = ${t('interactiveSubmainCalc1-3')}</p>

    <p class="shepherd-text"><strong>${t('interactiveSubmainCalc1-4')}</strong> ${t('interactiveSubmainCalc1-5')}</p>
    <p class="shepherd-text">${t('interactiveSubmainCalc1-6')} <strong>L</strong> ${t(
          'interactiveSubmainCalc1-7'
        )}</br> <strong>${t('interactiveSubmainCalc1-8')}</strong> ${t('interactiveSubmainCalc1-9')} <strong>${t(
          'interactiveSubmainCalc1-10'
        )}</strong></p>
    </div>
    `,
      ],
      buttons: [
        {
          classes: 'btn btn-info',
          text: 'Back',
          type: 'back',
        },
        {
          classes: 'btn btn-success',
          text: 'Next',
          type: 'next',
        },
      ],
      modalOverlayOpeningPadding: 4,
      useModalOverlay: true,
      canClickTarget: false,
    }),
    [t]
  );

  const SUBMAIN_CUSTOM_CALC: any = useMemo(
    () => ({
      id: 'submain-custom-calc',
      attachTo: {
        element: '#submain-calc',
        on: 'right',
      },
      scrollTo: true,
      text: [
        `
    <strong>V</strong> = ${t('interactiveSubmainCalc1-1')}</br>
    <strong>I.D</strong> = ${t('interactiveSubmainCalc1-2')}</br>
    <strong>L</strong> = ${t('length')} </br></br>

    <strong>${t('interactiveSubmainCustomCalc1-1')}</strong> ${t('interactiveSubmainCalc1-5')}
    ${t('interactiveSubmainCustomCalc1-2')} <strong>${t('length')}</strong> ${t('interactiveSubmainCustomCalc1-3')}

    `,
      ],
      buttons: [
        {
          classes: 'btn btn-info',
          text: 'Back',
          type: 'back',
        },
        {
          classes: 'btn btn-success',
          text: 'Next',
          type: 'next',
        },
      ],
      modalOverlayOpeningPadding: 4,
      useModalOverlay: true,
      canClickTarget: false,
    }),
    [t]
  );

  const SUBMAIN_5: any = useMemo(
    () => ({
      id: 'submain-5',
      attachTo: {
        element: '#submain-calc-button',
        on: 'right',
      },
      scrollTo: true,
      text: [
        `
    <div style="width: 291px">
      <p class="shepherd-text"><strong>${t('interactiveSubmain5-1')}</strong></p>
    </div>
    `,
      ],
      buttons: [
        {
          classes: 'btn btn-info',
          text: 'Back',
          type: 'back',
        },
        {
          classes: 'btn btn-success',
          text: 'Next',
          type: 'next',
        },
      ],
      modalOverlayOpeningPadding: 4,
      useModalOverlay: true,
      canClickTarget: false,
    }),
    [t]
  );

  const SUBMAIN_6: any = useMemo(
    () => ({
      id: 'submain-6',
      attachTo: {
        element: '#submain-result',
        on: 'right',
      },
      scrollTo: true,
      text: [
        `
    <div style="width: 354px">
    <p class="shepherd-text"><strong>${t('interactiveLateralDefine2-1')}</strong></p>

    <p class="shepherd-text"><strong> ${t('interactiveLateralDefine2-2')}</strong> ${t(
          'interactiveLateralDefine2-3'
        )}</p>
    </div>
`,
      ],
      buttons: [
        {
          classes: 'btn btn-info',
          text: 'Back',
          type: 'back',
        },
        {
          classes: 'btn btn-success',
          text: 'Next',
          type: 'next',
        },
      ],
      modalOverlayOpeningPadding: 4,
      useModalOverlay: true,
      canClickTarget: false,
    }),
    [t]
  );

  const SUBMAIN_7: any = useMemo(
    () => ({
      id: 'submain-7',
      attachTo: {
        element: '#submain-result-report',
        on: 'right',
      },
      scrollTo: true,
      text: [
        `
    <div style="width: 354px">
      <p class="shepherd-text">${t('interactiveLateral4-1')} <strong>${t('interactiveLateral4-2')}</strong> ${t(
          'interactiveLateral4-3'
        )}</p>
    </div>
    `,
      ],
      buttons: [
        {
          classes: 'btn btn-info',
          text: 'Back',
          type: 'back',
        },
        {
          classes: 'btn btn-success',
          text: 'Next',
          type: 'next',
        },
      ],
      modalOverlayOpeningPadding: 4,
      useModalOverlay: true,
      canClickTarget: false,
    }),
    [t]
  );

  const SUBMAIN_8: any = useMemo(
    () => ({
      id: 'submain-8',
      attachTo: {
        element: '#submain-result-graphically',
        on: 'left',
      },
      scrollTo: true,
      text: [
        `
    <div style="width: 435px">
    <p class="shepherd-text">${t('interactiveLateral5')}</p>
    </div>
    `,
      ],
      buttons: [
        {
          classes: 'btn btn-info',
          text: 'Back',
          type: 'back',
        },
        {
          classes: 'btn btn-success',
          text: 'Next',
          type: 'next',
        },
      ],
      modalOverlayOpeningPadding: 4,
      useModalOverlay: true,
      canClickTarget: false,
    }),
    [t]
  );

  return {
    SUBMAIN_1,
    SUBMAIN_2,
    SUBMAIN_3,
    SUBMAIN_4,
    SUBMAIN_5,
    SUBMAIN_6,
    SUBMAIN_7,
    SUBMAIN_8,
    SUBMAIN_CALC,
    SUBMAIN_CUSTOM_CALC,
  };
};
