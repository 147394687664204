import { METRIC_DEFAULTS } from 'shared/constants';
import { Unit } from 'shared/models';
import { LimitsRangeManiFolds, range } from '../common/limitRange';

import { Math_round } from '../mathRound';
import { ConvertUnit } from '../unitConverter';

export type ManifoldVelocities = {
  ManifoldFlow: number;
  flow1: number;
  flow2: number;
  InternalDiameter1: number;
  InternalDiameter2: number;
  InternalDiameter3: number;
  manifoldSpacing: number;
  Pipe1Length: number;
  Pipe2Length: number;
  Pipe3Length: number;
  maxVelocity: number;
};

export type ResultVelocity = {
  value: number;
  range: range;
};

export type ManifoldVelocitiesResult = {
  velocity1: ResultVelocity;
  velocity2: ResultVelocity;
  velocity3: ResultVelocity;
};

const LateralSpacing = 'm';
const LateralSpacingMetric = 'm';

export function updateManifoldVelocities(inputData: ManifoldVelocities, bothSize: boolean, units: Unit) {
  let flow = 0;
  let spacing = 0;
  let dia1 = 0;
  let dia2 = 0;
  let dia3 = 0;
  //let length1 = 0;
  let length2 = 0;
  let length3 = 0;
  let velocity1 = 0;
  let velocity2 = 0;
  let velocity3 = 0;

  let lateralFlow = inputData.flow1;
  if (bothSize) {
    lateralFlow = lateralFlow + inputData.flow2;
  }

  let limitRangeMainFolds: LimitsRangeManiFolds = {};

  spacing = ConvertUnit(inputData.manifoldSpacing, units.lateralSpacing, METRIC_DEFAULTS.LateralSpacing, null);
  flow = ConvertUnit(inputData.ManifoldFlow, units.totalFlow, METRIC_DEFAULTS.TotalFlow, null);
  lateralFlow = ConvertUnit(lateralFlow, units.totalFlow, METRIC_DEFAULTS.TotalFlow, null);
  dia1 = ConvertUnit(inputData.InternalDiameter1, units.pipeDiameter, METRIC_DEFAULTS.PipeDiameter, 'PipeDiameter');
  dia2 = ConvertUnit(inputData.InternalDiameter2, units.pipeDiameter, METRIC_DEFAULTS.PipeDiameter, 'PipeDiameter');
  dia3 = ConvertUnit(inputData.InternalDiameter3, units.pipeDiameter, METRIC_DEFAULTS.PipeDiameter, 'PipeDiameter');
  //   length1 = ConvertUnit(
  //     inputData.Pipe1Length,
  //     LateralSpacing,
  //     LateralSpacingMetric,
  //     null,
  //   );
  length2 = ConvertUnit(inputData.Pipe2Length, units.length, METRIC_DEFAULTS.Length, null);
  length3 = ConvertUnit(inputData.Pipe3Length, units.length, METRIC_DEFAULTS.Length, null);

  if (dia1 > 0) {
    velocity1 = flow / ((((Math.PI * dia1) / 1000) * dia1) / 1000 / 4) / 3600;
  }
  if (dia2 > 0) {
    velocity2 =
      (((length2 + length3) / spacing) * lateralFlow) / ((((Math.PI * dia2) / 1000) * dia2) / 1000 / 4) / 3600;
    if (isNaN(velocity2)) {
      velocity2 = 0;
    }
  }
  if (dia3 > 0) {
    velocity3 = ((length3 / spacing) * lateralFlow) / ((((Math.PI * dia3) / 1000) * dia3) / 1000 / 4) / 3600;
    if (isNaN(velocity3)) {
      velocity3 = 0;
    }
  }

  velocity1 = Math_round(ConvertUnit(velocity1, METRIC_DEFAULTS.Velocity, units.velocity, null), 3);
  velocity2 = Math_round(ConvertUnit(velocity2, METRIC_DEFAULTS.Velocity, units.velocity, null), 3);
  velocity3 = Math_round(ConvertUnit(velocity3, METRIC_DEFAULTS.Velocity, units.velocity, null), 3);

  if (velocity1 > inputData.maxVelocity) {
    limitRangeMainFolds.velocity1 = { range: 'over' };
  } else {
    limitRangeMainFolds.velocity1 = { range: 'in' };
  }

  if (velocity2 > inputData.maxVelocity) {
    limitRangeMainFolds.velocity2 = { range: 'over' };
  } else {
    limitRangeMainFolds.velocity2 = { range: 'in' };
  }

  if (velocity3 > inputData.maxVelocity) {
    limitRangeMainFolds.velocity3 = { range: 'over' };
  } else {
    limitRangeMainFolds.velocity3 = { range: 'in' };
  }

  let ManifoldVelocitiesResult: Partial<ManifoldVelocitiesResult> = {};
  ManifoldVelocitiesResult.velocity1 = {
    value: velocity1,
    range: limitRangeMainFolds.velocity1.range,
  };
  ManifoldVelocitiesResult.velocity2 = {
    value: velocity2,
    range: limitRangeMainFolds.velocity2.range,
  };
  ManifoldVelocitiesResult.velocity3 = {
    value: velocity3,
    range: limitRangeMainFolds.velocity3.range,
  };

  return ManifoldVelocitiesResult;
}
