import { METRIC_DEFAULTS } from 'shared/constants';
import { ConvertUnit } from '../../unitConverter';
import { LATERAL } from '../models/LATERAL';
import { v_at } from './v_at';
export function CalcLATERAL(calcLATERAl: LATERAL) {
  let FieldRainAt = calcLATERAl.Common.FieldRainAt;
  let tempdrainat = calcLATERAl.tempdrainat;
  // if (!calcLATERAl.txtBedWidth) {
  //   return { error: 'Please enter valid Bed Width' };
  // }

  let dBedWidth = parseFloat(String(calcLATERAl.txtBedWidth));
  dBedWidth = ConvertUnit(dBedWidth, calcLATERAl.lblBedWidthUnit, METRIC_DEFAULTS.Pressure, null);

  for (let y = 0; y <= calcLATERAl.Common.Yint; y++) {
    for (let X = 0; X <= calcLATERAl.Common.Xint; X++) {
      FieldRainAt[X][y] = 0;
      for (let j = -calcLATERAl.Common.Ky; j <= calcLATERAl.Common.Ky + 1; j++) {
        if (j != 0) {
          FieldRainAt[X][y] =
            FieldRainAt[X][y] +
            v_at(
              Math.trunc(calcLATERAl.Common.XMAMTEROT / 2 - X),
              Math.trunc(Math.abs(j) * calcLATERAl.Common.YMAMTEROT - (j / Math.abs(j)) * y),
              calcLATERAl.Common.fieldintervalX,
              calcLATERAl.Common.fieldintervalY,
              calcLATERAl.Common.dRange,
              calcLATERAl.Common.cbEDCEmitter,
              calcLATERAl.Common.TUBENUM
            );
        } else if (j == 0) {
          FieldRainAt[X][y] =
            FieldRainAt[X][y] +
            v_at(
              Math.trunc(calcLATERAl.Common.XMAMTEROT / 2 - X),
              y,
              calcLATERAl.Common.fieldintervalX,
              calcLATERAl.Common.fieldintervalY,
              calcLATERAl.Common.dRange,
              calcLATERAl.Common.cbEDCEmitter,
              calcLATERAl.Common.TUBENUM
            );
        }
      }
      let temp = FieldRainAt[X][y];
      if (temp > calcLATERAl.Common.max) {
        calcLATERAl.Common.max = temp;
      } else if (temp < calcLATERAl.Common.min) {
        calcLATERAl.Common.min = temp;
        calcLATERAl.Common.MinChange = true;
      }

      if (X > calcLATERAl.Common.Xint - (dBedWidth * calcLATERAl.Common.XMAMTEROT) / (2 * calcLATERAl.dXPOSITION)) {
        tempdrainat[X][y] = temp;
        tempdrainat[X][calcLATERAl.Common.YMAMTEROT - y] = temp;
        tempdrainat[calcLATERAl.Common.XMAMTEROT - X][calcLATERAl.Common.YMAMTEROT - y] = temp;
        tempdrainat[calcLATERAl.Common.XMAMTEROT - X][y] = temp;
      } else {
        temp = -1;
        FieldRainAt[X][y] = temp;
      }
      FieldRainAt[X][calcLATERAl.Common.YMAMTEROT - y] = temp;
      FieldRainAt[calcLATERAl.Common.XMAMTEROT - X][calcLATERAl.Common.YMAMTEROT - y] = temp;
      FieldRainAt[calcLATERAl.Common.XMAMTEROT - X][y] = temp;
    }
  }
  // for (let k = 0; k < tempdrainat.length; k++) {
  //   if (tempdrainat[k][0] === 0) {
  //     let res = tempdrainat[k].reduce(function (acc: any, el: any) {
  //       acc[el] = (acc[el] || 0) + 1;
  //       return acc;
  //     }, {});
  //     if (+JSON.stringify(res).substr(5).split('}')[0] == tempdrainat[k].length) {
  //       for (let c = 0; c < tempdrainat[k].length; c++) {
  //         tempdrainat[k][c] = -1;
  //       }
  //     }
  //   }
  // }

  let res = {
    FieldRainAt: FieldRainAt,
    tempdrainat: tempdrainat,
    max: calcLATERAl.Common.max,
    min: calcLATERAl.Common.min,
    MinChange: calcLATERAl.Common.MinChange,
  };
  return res;
}
