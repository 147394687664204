import { FC } from 'react';
import { ListItem, ListItemButton, ListItemText, ListItemButtonProps } from '@mui/material';

interface Props extends ListItemButtonProps {
  title: string;
  isWhite?: boolean;
}

export const SideButton: FC<Props> = ({ title, isWhite, onClick, divider, disabled }) => {
  return (
    <ListItem
      sx={{
        p: 0,
        pl: '11px',
        borderTop: divider ? '1px solid grey' : '',
        borderColor: divider ? 'info.light' : '',
        maxWidth: '185px',
      }}
    >
      <ListItemButton
        disabled={disabled}
        disableRipple
        onClick={onClick}
        sx={{
          bgcolor: isWhite ? '#F5FBFF' : 'primary.main',
          m: 0,
          p: 0,
          borderTopLeftRadius: '10px',
          borderBottomLeftRadius: '10px',
          '&:hover': {
            bgcolor: isWhite ? '#F5FBFF' : 'primary.main',
          },
        }}
      >
        <ListItemText
          primary={title}
          sx={{
            span: { fontSize: 16, fontWeight: 600, lineHeight: '18px' },
            // textTransform: 'uppercase',
            color: isWhite ? 'primary.main' : 'info.main',
            m: 0,
            p: '13px 9px',
          }}
        />
      </ListItemButton>
    </ListItem>
  );
};
