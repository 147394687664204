import { Routing } from 'pages';
import { withProviders } from './providers';
import { Modals, PrefetchData, ToastProvider } from 'processes';
import 'shepherd.js/dist/css/shepherd.css';
import './shepherd.css';
import 'shared/i18n';
import { useEffect } from 'react';

const App = () => {
  useEffect(() => {
    const version = process.env.REACT_APP_VERSION as string;
    const userVersion = localStorage.getItem('appVersion');

    if (!userVersion || userVersion !== version) {
      localStorage.clear();
    }
  }, []);


  return (
    <ToastProvider>
      <Modals>
        <PrefetchData>
          <Routing />
        </PrefetchData>
      </Modals>
    </ToastProvider>
  );
};

export default withProviders(App);
