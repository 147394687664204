import { FC } from 'react';
import { Button } from '../../shared/ui';
import { Dialog, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
  show: boolean;
  onClose: () => void;
}

export const ImportantUpdateModal: FC<Props> = ({ show, onClose }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={show}
      onClose={onClose}
      PaperProps={{
        sx: {
          padding: '48px 24px 40px',
          width: '324px',
          borderRadius: '16px',
          background: '#FFF',
          boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.16), 0px -1px 4px 0px rgba(0, 0, 0, 0.25)',
        },
      }}
    >
      <Stack sx={{ gap: '24px' }}>
        <Typography
          sx={{
            color: '#242731',
            textAlign: 'center',
            fontFamily: 'Roboto',
            fontSize: '18px',
            fontWeight: 600,
            lineHeight: '130%',
            letterSpacing: '0.18px',
          }}
        >
          {t('new_activation_required')}
        </Typography>
        <Typography
          sx={{
            color: '#242731',
            fontFamily: 'Roboto',
            fontSize: '18px',
            lineHeight: '130%',
            letterSpacing: '0.18px',
          }}
        >
          🛠️ {t('We_are_upgrading_our_app_for_a_better_experience')}
        </Typography>
        <Typography
          sx={{
            color: '#242731',
            fontFamily: 'Roboto',
            fontSize: '18px',
            lineHeight: '130%',
            letterSpacing: '0.18px',
          }}
        >
          📝 {t('kindly_fill_in_the_registration_form_again')}
        </Typography>
        <Typography
          sx={{
            color: '#242731',
            fontFamily: 'Roboto',
            fontSize: '18px',
            lineHeight: '130%',
            letterSpacing: '0.18px',
          }}
        >
          🙏 {t('sorry_for_any_inconvenience')}
        </Typography>
        <Typography
          sx={{
            color: '#242731',
            fontFamily: 'Roboto',
            fontSize: '18px',
            lineHeight: '130%',
            letterSpacing: '0.18px',
          }}
        >
          {t('thanks_for_your_understanding')}
        </Typography>

        <Button
          onClick={onClose}
          sx={{
            m: '0 auto',
            display: 'flex',
            width: '126px',
            padding: '8px 16px',
          }}
        >
          {t('go_to_form')}
        </Button>
      </Stack>
    </Dialog>
  );
};
