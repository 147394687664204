import { MenuItem, Box, FormControlLabel, Checkbox } from '@mui/material';
import React, { FC } from 'react';

interface Props {
  checked: boolean;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
}

export const EDCSelectCategory: FC<Props> = ({ checked, handleChange, label }) => {
  return (
    <MenuItem sx={{ padding: 0 }} disableRipple>
      <Box sx={{ width: '100%', height: '100%', px: '16px' }} onClick={(e) => e.stopPropagation()}>
        <FormControlLabel
          sx={{ ml: 0, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          control={<Checkbox disableRipple size="small" checked={checked} onChange={handleChange} />}
          label={label}
          labelPlacement="start"
        />
      </Box>
    </MenuItem>
  );
};
