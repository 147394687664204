import { Box, Stack } from '@mui/material';
import { FC, ReactNode } from 'react';
import { Outlet } from 'react-router';

interface Props {
  header: ReactNode;
  sidebar: ReactNode;
}

export const MainLayout: FC<Props> = ({ header, sidebar }) => {
  return (
    <Box
      sx={{
        background: '#F5FBFF',
        width: '100vw',
        height: '100vh',
      }}
    >
      {header}
      <Stack
        direction="row"
        sx={{
          height: 'calc(100% - 44px)',
          width: '100%',
        }}
      >
        {sidebar}
        <Box
          id="scroll_container"
          sx={{
            position: 'relative',
            flexGrow: 1,
            height: '100%',
            px: { xs: '10px', sm: '19px' },
            pt: '5px',
            pb: '7px',
            overflow: 'auto',
            scrollbarWidth: 'auto',
            scrollbarColor: 'primary.main secondary.main',
            '&::-webkit-scrollbar': {
              width: '7px',
              height: '7px',
            },
            '&::-webkit-scrollbar-track': {
              background: 'primary.main',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'primary.main',
              borderRadius: '13px',
            },
          }}
        >
          <Outlet />
        </Box>
      </Stack>
    </Box>
  );
};
