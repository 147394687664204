import { Box, Checkbox, Grid, useMediaQuery } from '@mui/material';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { lateral_input } from 'shared/assets';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { formattedInputValue } from 'shared/lib';
import { setProjectValue } from 'shared/slices';
import { Accordion, IconLabel, Input, PaperBox } from 'shared/ui';

const LateralInputContent = () => {
  const isMobile = useMediaQuery('(max-width:550px)');

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { units } = useAppSelector((state) => state.units);

  const { projectData } = useAppSelector((st) => st.projectData);

  const changeFlow1 = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setProjectValue({ flow1: formattedInputValue(e) }));
  };
  const changeFlow2 = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setProjectValue({ flow2: formattedInputValue(e) }));
  };
  const changeLateralInletPressure = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setProjectValue({ lateralInletPressure: formattedInputValue(e) }));
  };

  const onBothSidesChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setProjectValue({ isBothSides: e.target.checked }));
  };
  return (
    <Grid item container xs={12} columnSpacing={3} rowSpacing={1}>
      <Grid item xs={isMobile ? 12 : 4}>
        <Input label={`${t('flow1')} (${units.totalFlow})`} value={projectData.flow1} onChange={changeFlow1} />
      </Grid>

      <Grid item xs={isMobile ? 12 : 4}>
        <Input
          label={`${t('flow2')} (${units.totalFlow})`}
          value={projectData.flow2}
          onChange={changeFlow2}
          isResultBox={!projectData.isBothSides}
          labelInput={
            <Checkbox
              size='small'
              sx={{ p: '0 5px 0 0' }}
              value={projectData.isBothSides}
              checked={projectData.isBothSides}
              onChange={onBothSidesChange}
            />
          }
        />
      </Grid>

      <Grid item xs={isMobile ? 12 : 4}>
        <Input
          label={`${t('lateralInletPr')} (${units.pressure})`}
          value={projectData.lateralInletPressure}
          onChange={changeLateralInletPressure}
        />
      </Grid>
    </Grid>
  );
};

export const LateralInputBlock = () => {
  const isTablet = useMediaQuery('(max-width:850px)');

  return (
    <Box mt='10px'>
      {isTablet ? (
        <Accordion defaultExpanded header={<IconLabel iconSrc={lateral_input}>Lateral Input</IconLabel>}>
          <LateralInputContent />
        </Accordion>
      ) : (
        <PaperBox>
          <LateralInputContent />
        </PaperBox>
      )}
    </Box>
  );
};
