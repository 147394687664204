import { useAppSelector } from 'shared/hooks';
import { Loader } from 'shared/ui';
import { Submain } from 'widgets';

export const SubmainPage = () => {
  const projectsLoading = useAppSelector((state) => state.projects.loading);
  const masterGroupsLoading = useAppSelector((state) => state.masterGroups.loading);
  const unitsLoading = useAppSelector((state) => state.units.loading);
  const projectDataLoading = useAppSelector((state) => state.projectData.loading);
  const submainsLoadedOnce = useAppSelector((state) => state.submains.submainsLoadedOnce);

  const loading = projectsLoading || masterGroupsLoading || unitsLoading || projectDataLoading || !submainsLoadedOnce;

  return !loading ? <Submain /> : <Loader centeredAbsolute />;
};
